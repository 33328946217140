export const getBinaryCommission = async (userRank) => {
  if (!userRank || (!userRank.leadership && !userRank.userPackage)) return null;
  // console.log("userRank: ", userRank);
  const binaryCap = userRank.leadership
    ? userRank.leadership.weeklyCap
    : userRank.userPackage
    ? userRank.userPackage.binaryCap
    : 0;
  const binaryPercentage = userRank.leadership
    ? userRank.leadership.binaryPercent
    : userRank.userPackage
    ? userRank.userPackage.binaryPercent
    : 0;
  const min =
    userRank.binaryCV.left < userRank.binaryCV.right
      ? userRank.binaryCV.left
      : userRank.binaryCV.right;

  const valueAfterPercentage = min * (binaryPercentage / 1e2 / 100);
  const commission = valueAfterPercentage > binaryCap ? binaryCap : valueAfterPercentage;
  // console.debug({ valueAfterPercentage, commission });

  const BinaryCommissionResult = {
    binaryCap,
    binaryPercentage,
    commissionBeforePercentage: min,
    valueAfterPercentage,
    total: commission,
    binaryCV: userRank.binaryCV,
  };
  // console.debug({ BinaryCommissionResult });
  return BinaryCommissionResult;
};
