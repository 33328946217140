import { AppLayout } from "@layouts/AppLayout";
import { remove6Decimals } from "common/helpers";
import { useFetchTurboFundsSummary } from "common/hooks";

export const TurboFundPage = () => {
  const { data } = useFetchTurboFundsSummary()
  

  const tableData = [
    { name: "Yacht", date: "Nov 1, 2024", status: "Complete", amount: -350, balance: 350 },
    { name: "Cancun Trip", date: "Nov 1, 2024", status: "Complete", amount: -350, balance: 350 },
    { name: "Two-floor Apt.", date: "Nov 1, 2024", status: "Complete", amount: -460, balance: 350 },
    { name: "iPhone 15 PRO", date: "Nov 1, 2024", status: "Complete", amount: -460, balance: 350 },
    {
      name: "Amazon Gift Card",
      date: "Nov 1, 2024",
      status: "Complete",
      amount: -460,
      balance: 350,
    },
    { name: "Golf Cart", date: "Nov 1, 2024", status: "Complete", amount: -460, balance: 350 },
    { name: "Jet Ski", date: "Nov 1, 2024", status: "Complete", amount: -350, balance: 350 },
    { name: "Tulum Trip", date: "Nov 1, 2024", status: "Complete", amount: -350, balance: 350 },
    { name: "Apple Watch 9", date: "Nov 1, 2024", status: "Complete", amount: -460, balance: 350 },
  ];
  return (
    <AppLayout>
      <div className="grid grid-cols-2 gap-3 w-full">
        <div className="col-span-2 md:col-span-1 bg-white flex flex-col justify-between p-2">
          <p className="font-bold text-xl">TurboFunds:</p>
          {/* <p className="text-center font-bold text-3xl py-8">{ data && remove6Decimals(data.turbofundTotal) } USDT</p> */}
          <p className="text-center font-bold text-3xl py-8">-- USDT</p>
          <p>#000000000000</p>
        </div>
        <div className="col-span-2 md:col-span-1 flex flex-col min-h-[200px]">
          <p className="font-bold w-full pb-2">Compra nueva:</p>
          <div className="w-full h-full bg-[url(https://via.placeholder.com/600x400)] bg-center bg-cover">
            <div className="h-[20%] flex items-center justify-center font-semibold bg-[#87BAD7]">
              Price $80,000 USDT
            </div>
            <div
              className="h-[80%] flex items-end"
              style={{
                background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%)",
              }}
            >
              <p className="p-2 text-white">Product&apos;s Name</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full">
        <p className="mt-8 mb-6 font-bold text-xl">Fund&apos;s Use:</p>
        <div className="grid grid-cols-3 gap-3 w-full">
          {[1, 2, 3, 4, 5, 6].map((_, index) => (
            <div className="col-span-3 md:col-span-1 flex flex-col min-h-[200px]" key={index}>
              <div className="w-full h-full bg-[url(https://via.placeholder.com/800x400)] bg-center bg-cover">
                <div className="h-[20%] flex items-center justify-center font-semibold bg-[#87BAD7]">
                  Price $80,000 USDT
                </div>
                <div
                  className="h-[80%] flex items-end"
                  style={{
                    background:
                      "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%)",
                  }}
                >
                  <p className="p-2 text-white">Product&apos;s Name</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="bg-white w-full mt-8 p-4">
        <p className="font-bold text-lg pb-4">Summary</p>
        <div className="border-b border-b-gray-600 border-dashed flex gap-5 pb-3">
          <div>
            <p className="text-[#87BAD7]">Initial balance</p>
            <p>$0.00</p>
          </div>
          <div>
            <p className="text-[#87BAD7]">Final balance</p>
            <p>$0.00</p>
          </div>
        </div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-2 px-4 text-left">Product Name</th>
                <th className="py-2 px-4 text-left">Date</th>
                <th className="py-2 px-4 text-left">Status</th>
                <th className="py-2 px-4 text-left">Amount</th>
                <th className="py-2 px-4 text-left">Balance</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => (
                <tr key={index} className={`${index % 2 === 0 ? "bg-gray-100" : "bg-white"}`}>
                  <td className="py-2 px-4">{item.name}</td>
                  <td className="py-2 px-4">{item.date}</td>
                  <td className="py-2 px-4">{item.status}</td>
                  <td className="py-2 px-4 text-[#BCE210]">{`-$${Math.abs(item.amount)}`}</td>
                  <td className="py-2 px-4">{`$${item.balance}`}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </AppLayout>
  );
};
