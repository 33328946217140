import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useCalendarService } from "modules/admin/services/Calendar";

export const useFetchCategories = (queryKey = "calendarCategories", initialParams = {}) => {
  const [params, setParams] = useState({
    ...initialParams,
  });

  const { fetchCategories } = useCalendarService();

  const queryResults = useQuery({
    queryKey: [queryKey],
    queryFn: () => fetchCategories(params),
    select: (data) => data.data,
  });

  return { ...queryResults, setParams };
};
