import { AppLayout } from "@src/layouts/AppLayout";
import { OrganizationTree } from "../components/Organigram";
import { useEffect, useState } from "react";
import { useGetUserInfo } from "common/hooks/useGetUserInfo";
import { Button, Input, ModalComponent } from "common/components";
import { useAccount } from "wagmi";
// import { onWriteContract } from "common/services";
import ABI from "../../../common/contracts/FortuneGoat.json";
import { toast } from "react-toastify";
import { RegisterAffiliate } from "../components/RegisterAffiliate";
import { onReadContract } from "common/services";
import { useRequestBranchSideChange } from "common/hooks/useRequestBranchChange";
import { SentryService } from "common/services/SentryErrorCapture";
import { useBinaryTreeUsersCounter } from "../hooks/useBinaryTreeUsersCounter";
import { useGetRankAndCommissions } from "common/hooks/useGetRankAndCommissions";

export const AffiliatesDashboardPage = () => {
  const { userInfo, mutateAsync } = useGetUserInfo();
  const { isConnected, address } = useAccount();
  const { requestBranchSideChange } = useRequestBranchSideChange();
  const [graphicType, setGraphicType] = useState("binary");
  const { data: binaryTreeUsersCounter } = useBinaryTreeUsersCounter({
    address,
    enabled: graphicType === "binary",
  });
  const { data } = useGetRankAndCommissions({ address, commissions: false });
  const userRank = data ? data.userRank : {};
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [nodeInfo, setNodeInfo] = useState();
  const [searchInput, setSearchInput] = useState();
  const [startingNode, setStartingNode] = useState();
  const [searchError, setSearchError] = useState();
  const [userLevel, setUserLevel] = useState();
  const selectedSyle = "border-b-4 border-b-[#BCE210]";

  console.debug({ userRank });
  const handleChange = () => {
    if (graphicType === "binary") {
      setGraphicType("unilevel");
    }
    if (graphicType === "unilevel") {
      setGraphicType("binary");
    }
  };
  const handleSelectBranch = async (e) => {
    e.preventDefault();
    if (e.target.value !== 0 || e.target.value !== 1 || e.target.value !== 2) {
      try {
        if (!isConnected) {
          throw new Error("User is not connected");
        }
        if (!address) {
          throw new Error("No address found");
        }
        setLoading(true);
        const userData = {
          userId: address,
          branch: e.target.value,
          onSuccess: async () => {
            toast.success("Successfully changed branch");
          },
          onError: async (error) => {
            toast.error(`Something wrong: ${error}`);
          },
          onSettled: async () => {
            await mutateAsync();
            await new Promise((resolve) => setTimeout(resolve, 350));
            setLoading(false);
          },
        };
        await requestBranchSideChange({ userData });
        // await onWriteContract({
        //   contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS,
        //   contractABI: ABI,
        //   functionName: "setBinaryTreeUserBranchSide",
        //   args: [address, e.target.value],
        //   from: address,
        // });
        // mutateAsync();
        // toast.success("Successfully changed");
      } catch (err) {
        // console.log(err);
        SentryService("AffiliateDashboardPage", "handleSelectBranch", err);
        toast.error(`Something wrong: ${err.message}`);
      }
    }
  };
  const handleCopyText = () => {
    navigator.clipboard.writeText(
      `https://fortunegoat.com/join?sponsor=${
        userInfo && userInfo.info.username.replace(/ /g, "_")
      }`
    );
    toast.success("Copied to clipboard");
  };
  const handleInputChange = (e) => {
    setSearchError();
    setSearchInput(e.target.value);
  };

  const validateInput = async () => {
    if (searchInput === undefined || searchInput === "") {
      setSearchError("El input no puede estar vacio");
      return undefined;
    }
    const addressTested = /^(0x)?[0-9a-fA-F]{40}$/.test(searchInput);
    const usernameTested = /^[a-z\s]+$/.test(searchInput);
    if (!addressTested && !usernameTested) {
      setSearchError(
        "El campo ingresado no coincide con una wallet address y los usuarios no pueden tener mayúsculas, símbolos ni números"
      );
      return undefined;
    }
    if (addressTested) return searchInput;
    if (usernameTested) {
      const addresResp = await onReadContract({
        contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS,
        contractABI: ABI,
        functionName: "usernamesMap",
        args: [searchInput],
      });
      if (addresResp && addresResp !== "0x0000000000000000000000000000000000000000") {
        return addresResp;
      } else {
        setSearchError("Usuario no encontrado");
      }
    }
  };
  const levelValidation = async (addressToValidate) => {
    console.log(addressToValidate);
    if (!addressToValidate) return;
    const levelResp = await onReadContract({
      contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS,
      contractABI: ABI,
      functionName: "getTreePosition",
      args: [addressToValidate],
    });
    if (levelResp) {
      const parsedLevelResp = parseInt(levelResp[0]);
      if (parsedLevelResp <= userLevel) {
        setSearchError("Usuario no encontrado");
      } else {
        // logica de subir hasta la altura del buscador y ver si esta dentro de su binario
        const userInfoResp = await onReadContract({
          contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS,
          contractABI: ABI,
          functionName: "getUserInfo",
          args: [addressToValidate],
        });
        const fatherLevel = await onReadContract({
          contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS,
          contractABI: ABI,
          functionName: "getTreePosition",
          args: [userInfoResp[1].parentId],
        });
        console.log(userLevel, " & ", parseInt(fatherLevel[0]));
        if (userLevel === parseInt(fatherLevel[0])) {
          const fatherInfoResp = await onReadContract({
            contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS,
            contractABI: ABI,
            functionName: "getUserInfo",
            args: [userInfoResp[1].parentId],
          });
          console.log(fatherInfoResp[0].id, " === ", address);
          if (fatherInfoResp[0].id === address) {
            return true;
          }
        } else {
          return levelValidation(userInfoResp[1].parentId);
        }
      }
    }
  };
  const handleSubmitSearch = async () => {
    setLoading(true);
    const inputValidationOutput = await validateInput();
    if (inputValidationOutput !== undefined) {
      const validatedAddress = await levelValidation(inputValidationOutput);
      if (validatedAddress) {
        setStartingNode(inputValidationOutput);
      } else {
        setSearchError("Usuario no encontrado");
      }
    }
    setSearchInput("");
    setLoading(false);
  };

  const [side, setSide] = useState(2);
  const setLastChild = async (side) => {
    setSide(side);
    setSearchInput("");
    if (side === 1 || side === 2) {
      setLoading(true);
      const resp = await onReadContract({
        contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS,
        contractABI: ABI,
        functionName: "getBinaryTreeLastRegistered",
        args: [address, side],
      });
      if (resp.id !== address) {
        setStartingNode(resp.parentId);
      } else {
        setStartingNode(address);
      }
      setLoading(false);
    }
  };
  const getCurrentFather = async () => {
    setLoading(true);
    const resp = await onReadContract({
      contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS,
      contractABI: ABI,
      functionName: "getUserInfo",
      args: [startingNode],
    });
    if (resp) {
      setStartingNode(resp[1].parentId);
    } else {
      toast.error("Hubo un problema");
    }
    setLoading(false);
  };
  useEffect(() => {
    const readUserLevel = async () => {
      setLoading(true);
      const resp = await onReadContract({
        contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS,
        contractABI: ABI,
        functionName: "getTreePosition",
        args: [address],
      });
      if (resp) {
        setUserLevel(parseInt(resp[0]));
      }
      setLoading(false);
    };
    if (address) {
      readUserLevel();
    }
  }, [address]);
  return (
    <>
      <ModalComponent open={openModal} setOpen={setOpenModal}>
        <RegisterAffiliate setOpenModal={setOpenModal} nodeInfo={nodeInfo} />
      </ModalComponent>
      <AppLayout>
        <div className="bg-dark-blue p-4 mb-5">
          <div className="grid grid-cols-1 place-items-start gap-4 md:grid-cols-2">
            <h3 className="text-white md:col-span-2">CREAR NUEVO AFILIADO</h3>
            <div className="bg-white border-2 border-solid border-black grid grid-cols-1 place-items-center w-full xl:grid-cols-2 xl:h-14">
              <p className="">{`https://fortunegoat.com/join?sponsor=${
                userInfo && userInfo.info.username
              }`}</p>
              <Button
                label="COPIAR"
                className="text-xs m-1 bg-highlight-primary border-0 max-h-8 !py-1 !px-3"
                onClick={() => {
                  handleCopyText();
                }}
              />
            </div>
            <div className="bg-white border-4 border-solid border-black grid grid-cols-1 place-items-center w-full gap-4 py-2 md:grid-cols-4 md:px-2 xl:h-14">
              <p className="text-sm md: col-span-1 font-bold">Posición del Afiliado</p>
              {loading ? (
                <p>Cargando..</p>
              ) : (
                <>
                  <Button
                    label="DEFAULT"
                    value={0}
                    onClick={(e) => handleSelectBranch(e)}
                    className={`w-3/4 text-xs h-9 m-0 border-none rounded-none !py-1 !px-3 bg-${
                      userInfo && userInfo.binaryInfo.userSelectedBranch === 0
                        ? "[#87BAD7]"
                        : "sky-200"
                    } md:w-full`}
                    disabled={userInfo && userInfo.binaryInfo.userSelectedBranch === 0}
                  />
                  <Button
                    label="IZQUIERDA"
                    value={1}
                    onClick={(e) => handleSelectBranch(e)}
                    className={`w-3/4 text-xs h-9 m-0 border-none rounded-none !py-1 !px-3 bg-${
                      userInfo && userInfo.binaryInfo.userSelectedBranch === 1
                        ? "[#87BAD7]"
                        : "[#87BAD7]"
                    } md:w-full md:!px-1`}
                    disabled={userInfo && userInfo.binaryInfo.userSelectedBranch === 1}
                  />
                  <Button
                    label="DERECHA"
                    value={2}
                    onClick={(e) => handleSelectBranch(e)}
                    className={`w-3/4 text-xs h-9 m-0 border-none rounded-none !py-1 !px-3 bg-${
                      userInfo && userInfo.binaryInfo.userSelectedBranch === 2
                        ? "[#87BAD7]"
                        : "[#87BAD7]"
                    } md:w-full`}
                    disabled={userInfo && userInfo.binaryInfo.userSelectedBranch === 2}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="flex w-full gap-10 bg-white">
          <div
            onClick={() => handleChange()}
            className={`px-4 py-2 pb-2 cursor-pointer uppercase ${
              graphicType === "binary" ? selectedSyle : ""
            }`}
          >
            Genealogía Binario
          </div>
          <div
            onClick={() => handleChange()}
            className={`px-4 py-2 pb-2 cursor-pointer uppercase ${
              graphicType === "unilevel" ? selectedSyle : ""
            }`}
          >
            Genealogía Uninivel
          </div>
        </div>
        {loading ? (
          <p>Cargando...</p>
        ) : (
          <>
            {graphicType === "binary" && (
              <div className="flex flex-col gap-8 items-start justify-start bg-white p-4">
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col gap-4 md:flex-row">
                    <div>
                      <p className="uppercase text-xl font-semibold">Buscador de usuarios</p>
                    </div>
                  </div>
                  <div className="grid grid-cols-4 gap-8">
                    <div className="flex flex-col col-span-4 md:col-span-2">
                      <div className="flex items-center">
                        <div
                          className={`w-3 h-3 rounded-full ${
                            side === 2 ? "bg-[#BCE210]" : "bg-[#808080]"
                          }`}
                        />
                        <button
                          className="text-left border-none !px-2 p-0 font-semibold"
                          onClick={() => setLastChild(2)}
                        >
                          Visualizar último usuario en el extremo derecho
                        </button>
                      </div>
                      <div className="flex items-center">
                        <div
                          className={`w-3 h-3 rounded-full ${
                            side === 1 ? "bg-[#BCE210]" : "bg-[#808080]"
                          }`}
                        />
                        <button
                          className="text-left border-none !px-2 p-0 font-semibold"
                          onClick={() => setLastChild(1)}
                        >
                          Visualizar último usuario en el extremo izquierdo
                        </button>
                      </div>
                    </div>
                    <div className="col-span-4 md:col-span-2 flex gap-2">
                      <Input
                        placeholder="Buscar por nombre o wallet"
                        className="bg-white border-2 border-solid border-black"
                        label="Buscar"
                        onChange={handleInputChange}
                        value={searchInput}
                      />
                      <Button
                        label="Buscar"
                        onClick={handleSubmitSearch}
                        className="border-none bg-[#BCE210] !px-2"
                      />
                      {startingNode && startingNode !== address && (
                        <Button
                          label="Reset"
                          className="bg-baby-blue text-electric-blue border-none"
                          onClick={() => {
                            setSearchInput("");
                            setStartingNode(address);
                          }}
                        />
                      )}
                      {searchError && <p className="text-red-400"> {searchError} </p>}
                    </div>
                    {startingNode && startingNode !== address && (
                      <Button label="Subir 1" className="my-4" onClick={() => getCurrentFather()} />
                    )}
                  </div>
                </div>
                <div className="mx-auto">
                  <div>
                    {binaryTreeUsersCounter && (
                      <div className="flex flex-col">
                        <p className="bg-[#F3FFBE] flex flex-col justify-center items-center px-4">
                          <span className="uppercase text-sm">Usuarios a la izquierda:</span>
                          <strong>{binaryTreeUsersCounter.left}</strong>
                        </p>
                        <p className="bg-[#DAF561] flex flex-col justify-center items-center px-4">
                          <span className="uppercase text-sm">Usuarios a la derecha:</span>{" "}
                          <strong>{binaryTreeUsersCounter.right}</strong>
                        </p>
                        {userRank && (
                          <>
                            <p>
                              <strong>Afiliados activos a la izquierda:</strong>{" "}
                              {userRank.activeChildren && userRank.activeChildren.current
                                ? userRank.activeChildren.current?.leftCount
                                : 0}
                            </p>
                            <p>
                              <strong>Afiliados activos a la derecha:</strong>{" "}
                              {userRank.activeChildren && userRank.activeChildren.current
                                ? userRank.activeChildren.current?.rightCount
                                : 0}
                            </p>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                  <div>
                    {userRank && (
                      <div className="flex flex-col">
                        <p>
                          <strong>BCV izquierda:</strong>{" "}
                          {userRank.binaryCV ? userRank.binaryCV.left / 10 ** 6 : 0}
                        </p>
                        {/* <p>
                          <strong>Volume izquierda:</strong>{" "}
                          {userRank.binaryLegsVolume && userRank.binaryLegsVolume.current
                            ? userRank.binaryLegsVolume.current?.left / 10 ** 6
                            : 0}
                        </p> */}
                        <p>
                          <strong>BCV derecha:</strong>{" "}
                          {userRank.binaryCV ? userRank.binaryCV.right / 10 ** 6 : 0}
                        </p>
                        {/* <p>
                          <strong>Volume derecha:</strong>{" "}
                          {userRank.binaryLegsVolume && userRank.binaryLegsVolume.current
                            ? userRank.binaryLegsVolume.current?.right / 10 ** 6
                            : 0}
                        </p> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="bg-white">
              <OrganizationTree
                key={graphicType}
                treeType={graphicType}
                setOpenModal={setOpenModal}
                setNodeInfo={setNodeInfo}
                startingNode={startingNode}
                setStartingNode={setStartingNode}
              />
            </div>
          </>
        )}
      </AppLayout>
    </>
  );
};
