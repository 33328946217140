// @ts-expect-error something is wrong with the types
import { OrgChart } from "d3-org-chart";
import { useEffect, useRef, useState } from "react";
import IMG from "../../../assets/logos/fortune-goat-dark-isotype.svg";
import { useTreeChartData } from "../hooks/useTreeChartData";
import { Button } from "common/components";
import { useAccount } from "wagmi";
import { onReadContract } from "common/services";
import FortuneGoatABI from "common/contracts/FortuneGoat.json";

export const OrganizationTree = ({
  treeType,
  showEmpty = true,
  startingNode,
  setOpenModal,
  setNodeInfo,
  setStartingNode,
}) => {
  const chartContainer = useRef(null);
  const { TreeData, loading, readBinaryTree, readUnilevelTree, mutateAsync } = useTreeChartData({
    treeType,
    startingNode,
    showEmpty,
  });
  const [browser, setBrowser] = useState();
  const { address } = useAccount();
  // @ts-expect-error something is wrong with the types
  let chart = null;

  const updateBinaryTreeChildren = async ({ currentNode }) => {
    //get new values from currentnode
    const updatedNode = await readBinaryTree({ startingNode: currentNode.id });
    //delete placeholders
    currentNode.children.forEach((child) => {
      chart.removeNode(child.data.Id);
    });
    //add children recursively
    const addChildren = async (nodeIteration) => {
      const nodeIterationChildren = updatedNode.filter((node) => node.parentId == nodeIteration);
      const sortedChildren = nodeIterationChildren.sort((a, b) => a.parentBranch > b.parentBranch);
      sortedChildren.forEach((node) => {
        chart.addNode(node);
        if (node.userName !== "Empty" && node.userName !== "Loading...") {
          addChildren(node.Id);
        }
      });
    };
    addChildren(currentNode.id);
  };

  const updateUnilevelTreeChildren = async ({ currentNode }) => {
    const updatedNode = await readUnilevelTree({ startingNode: currentNode.id });
    currentNode.children.forEach((child) => {
      chart.removeNode(child.data.Id);
    });
    updatedNode.forEach((node) => {
      if (node.parentId) {
        chart.addNode(node);
      }
    });
  };

  useEffect(() => {
    const checkBrowser = () => {
      // Get the user-agent string
      let userAgentString = navigator.userAgent;
      // Detect Chrome
      let chromeAgent = userAgentString.indexOf("Chrome") > -1;
      // Detect Firefox
      let firefoxAgent = userAgentString.indexOf("Firefox") > -1;
      // Detect Safari
      let safariAgent = userAgentString.indexOf("Safari") > -1;
      // Discard Safari since it also matches Chrome
      if (chromeAgent && safariAgent) safariAgent = false;
      // Detect Opera
      let operaAgent = userAgentString.indexOf("OP") > -1;
      // Discard Chrome since it also matches Opera
      if (chromeAgent && operaAgent) chromeAgent = false;
      return {
        chromeAgent,
        firefoxAgent,
        safariAgent,
        operaAgent,
      };
    };
    setBrowser(checkBrowser());
  }, []);
  useEffect(() => {
    if (chartContainer.current && TreeData && !loading) {
      // @ts-expect-error something is wrong with the types
      if (!chart) {
        chart = new OrgChart();
      }
      chart
        .container(chartContainer.current)
        .compact(treeType == "binary")
        .data(TreeData)
        // @ts-expect-error something is wrong with the types

        .nodeId((dataItem) => {
          // console.log(dataItem);
          return dataItem.Id;
        })
        // @ts-expect-error something is wrong with the types

        .parentNodeId((dataItem) => {
          return dataItem.parentId;
        })
        .nodeWidth(() => 400)
        // @ts-expect-error something is wrong with the types

        .onNodeClick((data, i, arr) => {
          console.debug({ data });
          if (data.data.username === "Empty") {
            setNodeInfo({
              branch: data.data.parentBranch,
              fatherUsername: data.parent.data.userName,
            });
            setOpenModal(true);
          } else {
            navigator.clipboard.writeText(data.data.Id);
          }
        })
        // @ts-expect-error something is wrong with the types

        .nodeContent((d) => {
          const color = "#FFFFFF";
          // console.log("ACAAAASD");
          // console.log({ d });
          if (browser.safariAgent) {
            return `
              <div 
                style="font-family: 'Inter',sans-serif;
                background-color:${color};
                width:${d.width}px;
                height:${d.height}px;
                border-radius:15px;
                border: 2px solid #E4E2E9;
                box-shadow: 2px 2px 3px #004976;
                margin-top:-1px; 
                margin-left:-1px;
                ">
                <img 
                  src="${IMG}"
                  alt="FG"
                    style="
                      margin-top:2px;
                      margin-left:${20}px;
                      border-radius:100px;
                      width:40px;
                      height:40px;" />
                      <div 
                      style="display:flex;
                      justify-content:flex-end;
                      margin-top:-30px;
                      margin-right:8px">
                      #${d.data.Id}</div>
                <div 
                   style="font-size:24px;
                   font-weight: bold;
                   color:#08011E;
                   text-align: center;"> ${d.data.userName || d.data.username || "vacio"}</div>
                   ${
                     d.data.sponsorUsername
                       ? `<div 
                     style="color:#716E7B;
                     margin-top:3px;
                     font-size:12px;
                     text-align: center;"><strong>Sponsor:</strong> ${d.data.sponsorUsername}</div>`
                       : `<div></div>`
                   }${
                     d.data.amountPaid != undefined
                       ? `<div 
                     style="color:#716E7B;
                     margin-top:3px;
                     font-size:12px;
                     text-align: center;"><strong>USDTs paid:</strong> ${
                       d.data.amountPaid / 10 ** 6
                     }</div>`
                       : `<div></div>`
                   }${
                     d.data.purchaseAmount != undefined
                       ? `<div 
                     style="color:#716E7B;
                     margin-top:3px;
                     font-size:12px;
                     text-align: center;"><strong>USDTs paid:</strong> ${
                       d.data.purchaseAmount / 10 ** 6
                     }</div>`
                       : `<div></div>`
                   }
                      ${
                        treeType == "binary" && d.data.sponsorId
                          ? `<div 
                      style="color:#716E7B;
                      margin-top:3px;
                      font-size:12px;
                      text-align: center;"><strong>Sponsor branch:</strong> ${
                        d.data.sponsorBranch == 1
                          ? "Left"
                          : d.data.sponsorBranch == 2
                          ? "Right"
                          : "Auto"
                      }</div>`
                          : `<div></div>`
                      }
                      ${
                        treeType == "binary" && d.data.sponsorId
                          ? `<div 
                      style="color:#716E7B;
                      margin-top:3px;
                      font-size:12px;
                      text-align: center;"><strong>Next child position:</strong> ${
                        d.data.userSelectedBranch == 0
                          ? d.data.lastBranchUsed != 1
                            ? "Left"
                            : "Right"
                          : d.data.userSelectedBranch == 1
                          ? "Left"
                          : "Right"
                      }</div>`
                          : `<div></div>`
                      }
                      ${
                        treeType == "binary" && d.data.childToBranch
                          ? `<div 
                      style="color:#716E7B;
                      margin-top:3px;
                      font-size:12px;
                      text-align: center;"><strong>Children branch:</strong> ${
                        d.data.childToBranch == 1
                          ? "Left"
                          : d.data.childToBranch == 2
                          ? "Right"
                          : "Auto"
                      }</div>`
                          : `<div></div>`
                      }
                      
                   ${
                     treeType == "binary" && d.data.status != undefined
                       ? `<div 
                   style="color:#716E7B;
                   margin-top:3px;
                   font-size:12px;
                   text-align: center;"><strong>Status:</strong> ${
                     d.data.status ? "Active" : "Inactive"
                   }</div>`
                       : `<div></div>`
                   }
              </div>`;
          } else {
            return `
                        <div 
                           style="font-family: 'Inter',sans-serif;background-color:${color}; 
                              position:absolute;
                              margin-top:-1px; 
                              margin-left:-1px;
                              width:${d.width}px;
                              height:${d.height}px;
                              opacity:${d.data.username == "Empty" ? 0.5 : 1};
                              border-radius: 0;"
                              >
                           <div 
                              style="background-color:${color};
                              position:absolute;
                              margin-top:-25px;
                              margin-left:${15}px;
                              border-radius:100px;
                              width:50px;
                              height:50px;" >
                           </div>
                           <div style="position:absolute;

                                    margin-top:20px;
                                    margin-left:${20}px;
                                    border-radius:100px;
                                    background-color:#D9D9D9;
                                    font-size:20px;
                                    font-weight: bold;
                                    width:40px;
                                    display:flex;
                                    justify-content:center;
                                    align-items:center;
                                    height:40px; z-index: 10">
                                    ${
                                      d.data.userName
                                        ? d.data.userName.charAt(0).toUpperCase()
                                        : d.data.username
                                        ? d.data.username.charAt(0).toUpperCase()
                                        : ""
                                    }
                           </div>
                              
                           <div 
                              style="display:flex;
                              justify-content:flex-end;
                              margin-top:5px;
                              margin-right:8px">
                              #${d.data.Id}</div>
                        <div 
                           style="font-size:24px;
                           font-weight: bold;
                           color:#08011E;
                           margin-top:18px;
                           text-align: center;"> ${
                             d.data.userName || d.data.username || "vacio"
                           }</div>
                           ${
                             d.data.sponsorUsername
                               ? `<div 
                             style="color:#716E7B;
                             margin-top:3px;
                             font-size:12px;
                             margin-left:140px;
                             text-align: left;"><strong>Sponsor:</strong> ${d.data.sponsorUsername}</div>`
                               : `<div></div>`
                           }${
                             d.data.amountPaid != undefined
                               ? `<div 
                             style="color:#716E7B;
                             margin-top:3px;
                             font-size:12px;
                             margin-left:140px;
                             text-align: left;"><strong>USDTs paid:</strong> ${
                               d.data.amountPaid / 10 ** 6
                             }</div>`
                               : `<div></div>`
                           }${
                             d.data.purchaseAmount != undefined
                               ? `<div 
                             style="color:#716E7B;
                             margin-top:3px;
                             font-size:12px;
                             margin-left:140px;
                             text-align: left;"><strong>USDTs paid:</strong> ${
                               d.data.purchaseAmount / 10 ** 6
                             }</div>`
                               : `<div></div>`
                           }
                              ${
                                treeType == "binary" && d.data.sponsorId
                                  ? `<div 
                              style="color:#716E7B;
                              margin-top:3px;
                              font-size:12px;
                              margin-left:140px;
                              text-align: left;"><strong>Sponsor branch:</strong> ${
                                d.data.sponsorBranch == 1
                                  ? "Left"
                                  : d.data.sponsorBranch == 2
                                  ? "Right"
                                  : "Auto"
                              }</div>`
                                  : `<div></div>`
                              }
                              ${
                                treeType == "binary" && d.data.sponsorId
                                  ? `<div 
                              style="color:#716E7B;
                              margin-top:3px;
                              font-size:12px;
                              margin-left:140px;
                              text-align: left;"><strong>Next child position:</strong> ${
                                d.data.userSelectedBranch == 0
                                  ? d.data.lastBranchUsed != 1
                                    ? "Left"
                                    : "Right"
                                  : d.data.userSelectedBranch == 1
                                  ? "Left"
                                  : "Right"
                              }</div>`
                                  : `<div></div>`
                              }
                              ${
                                treeType == "binary" && d.data.childToBranch
                                  ? `<div 
                              style="color:#716E7B;
                              margin-top:3px;
                              font-size:12px;
                              text-align: center;"><strong>Children branch:</strong> ${
                                d.data.childToBranch == 1
                                  ? "Left"
                                  : d.data.childToBranch == 2
                                  ? "Right"
                                  : "Auto"
                              }</div>`
                                  : `<div></div>`
                              }
                              
                           ${
                             treeType == "binary" && d.data.status != undefined
                               ? `<div 
                           style="color:#716E7B;
                           margin-top:3px;
                           font-size:12px;
                           text-align: center;"><strong>Status:</strong> ${
                             d.data.status ? "Active" : "Inactive"
                           }</div>`
                               : `<div></div>`
                           }
                     </div>
                  `;
          }
        })
        .onExpandOrCollapse(async (node) => {
          if (
            node.children &&
            node.children.some((child) => child.data.username === "loading...")
          ) {
            if (treeType == "binary") {
              updateBinaryTreeChildren({ currentNode: node });
            } else {
              updateUnilevelTreeChildren({ currentNode: node });
            }
          }
        })
        .render();
    }
  }, [loading, TreeData]);
  return (
    <div>
      <>{loading && <h1>Loading...</h1>}</> <div ref={chartContainer}></div>
    </div>
  );
};
