export const userCommissions = async ({
  address,
  onGetRank,
  onGetCommissions,
  onGetFastStart,
  onGetBinaryMatchBonus,
  onGetUnilevelMatchBonus,
  onGetLeadership,
  getCommissionsParam,
  getBinaryMatchingBonusParam,
  getUnilevelMatchingBonusParam,
  getFastStartParam,
  getLeadershipParam,
}) => {
  /*
	const { getUserInfo, getMaxLevel, getUsersTotalPaid, getUserRank } = onGetRankFunctions;
	const { getBinaryCommission, getUnilevelCommission } = onGetCommissionsFunctions;
	const { getLevelAddresses, getLevelSum } = onGetFastStartFunctions;
	const { getMatchingBonusPercentages, getLevelAddresses, getLevelSum } = onGetBinaryMatchBonusFunctions;

	const userRank = await onGetRank({ ...onGetRankFunctions, address, contractFunctions });
	const commissions = await onGetCommissions({ ...onGetCommissionsFunctions, userRank });
	const fastStart = await onGetFastStart({ ...onGetFastStartFunctions, address, contractFunctions });
	const matchingBonus = await onGetBinaryMatchBonus({
		...onGetBinaryMatchBonusFunctions,
		...onGetRankFunctions,
		...onGetCommissionsFunctions,
		userRank,
		address,
		contractFunctions,
		onGetRank,
		onGetCommissions,
	});
    */

  const userRank = await onGetRank({ address });
  console.log("userRank: ", userRank);

  await new Promise((resolve) => setTimeout(resolve, 50));
  const commissions = getCommissionsParam ? await onGetCommissions({ userRank }) : null;
  console.log("commissions: ", commissions);

  await new Promise((resolve) => setTimeout(resolve, 50));
  const fastStart = getFastStartParam ? await onGetFastStart({ address }) : null;
  console.log("fastStart: ", fastStart);

  // alert("fastStart");
  await new Promise((resolve) => setTimeout(resolve, 50));
  const matchingBonus = getBinaryMatchingBonusParam
    ? await onGetBinaryMatchBonus({
        userRank,
        address,
      })
    : null;
  console.log("matchingBonus: ", matchingBonus);

  // alert("matchingBonus");
  const unilevelMatchingBonus = getUnilevelMatchingBonusParam
    ? await onGetUnilevelMatchBonus({
        userRank,
      })
    : null;
  console.log("matchingBonus: ", matchingBonus);

  // alert("matchingBonus");
  const leadership = getLeadershipParam
    ? await onGetLeadership({
        userRank,
      })
    : null;
  console.log("leadership: ", leadership);
  // alert("leadership");
  return {
    userRank,
    commissions,
    fastStart,
    matchingBonus,
    unilevelMatchingBonus,
    leadership,
  };
};
