import { onReadContract } from "common/services";
import { useState } from "react";
import FortuneGoatABI from "common/contracts/FortuneGoat.json";
import { SentryService } from "common/services/SentryErrorCapture";
import { isAddress } from "viem";

export const AddressField = ({
  setAddress,
  resetSendFundsState = () => {},
  inputPlaceholder = "Enter destination address",
  showLabel = true,
  disableChanges,
  dontAllowUsername = false,
}) => {
  const [fundTo, setFundTo] = useState("wallet");
  const [username, setUsername] = useState("");
  const [addressTo, setAddressTo] = useState("");
  const [error, setError] = useState(null);

  const setAddressToSend = async () => {
    setError(null);
    if (fundTo === "wallet") {
      if (!addressTo) return;
      if (isAddress(addressTo)) {
        setAddress(addressTo);
      } else {
        setAddress(null);
        setError("Invalid address. Pleace check the wallet address.");
      }
    } else {
      try {
        if (!username) return;
        const address = await onReadContract({
          contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS,
          contractABI: FortuneGoatABI,
          functionName: "usernamesMap",
          args: [username],
        });
        if (address === "0x0000000000000000000000000000000000000000") {
          setAddress(null);
          setError("Username address not found. Please check the username.");
        } else {
          setAddress(address);
        }
      } catch (error) {
        setAddress(null);
        SentryService("AddresField", "setAddressToSend", error);
      }
    }
  };

  const handleFundType = (target) => {
    if (disableChanges) return;
    setFundTo(target);
    setError(null);
    setAddressTo("");
    setUsername("");
  };
  return (
    <>
      <div className="flex justify-center w-full gap-5 pb-4 text-lg">
        <div
          className={`cursor-pointer ${
            fundTo === "wallet" ? `text-black underline` : `text-gray-500`
          }`}
          onClick={() => {
            handleFundType("wallet");
          }}
        >
          Wallet Addresss
        </div>
        <div className="w-[1px] border-[1px] border-gray"></div>
        {!dontAllowUsername && (
          <div
            className={`cursor-pointer ${
              fundTo === "wallet"
                ? `text-gray-500`
                : `color-black decoration-solid decoration-black decoration-1 underline`
            }`}
            onClick={() => {
              handleFundType("username");
            }}
          >
            Username
          </div>
        )}
      </div>
      <div className="mb-4">
        {showLabel && (
          <label htmlFor="address" className="block text-sm font-medium text-gray-600">
            {fundTo === "wallet" ? `Reciever wallet address` : `Reciever username`}
          </label>
        )}
        <input
          type="text"
          value={fundTo === "wallet" ? addressTo : username}
          onChange={(e) => {
            resetSendFundsState();
            if (fundTo === "wallet") {
              setAddressTo(e.target.value);
            } else {
              setUsername(e.target.value);
            }
          }}
          onBlur={() => setAddressToSend()}
          id="address"
          name="address"
          className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
          placeholder={inputPlaceholder}
          disabled={disableChanges}
        />
        {error && <small className="text-red-700">{error}</small>}
      </div>
    </>
  );
};
