import { onReadContract } from "common/services";
import FortuneGoatABI from "common/contracts/FortuneGoat.json";

export const getDashboardAccess = async (address) => {
  const response = await onReadContract({
    contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS,
    contractABI: FortuneGoatABI,
    functionName: "getUserInfo",
    args: [address],
  });

  console.log("user info", response, response?.username !== "");
  //response[2] is user status. 0 => active, 1 => inactive
  if (
    response &&
    response[0]?.username &&
    response[0]?.username !== "" &&
    response[2] === BigInt(0)
  ) {
    return true;
  }
  return false;
};
