import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useWaitForTransactionReceipt } from "wagmi";
import { useRequestSignature } from "./useRequestSignature";
import { onPayCommission } from "common/services/onPayCommission";

export const usePayCommission = () => {
  const requestSignature = useRequestSignature();
  const [isEnabled, setIsEnabled] = useState(false);
  const [commissionData, setCommissionData] = useState({});
  const [signature, setSignature] = useState("");

  const { isError, isSuccess, isFetching, status, data, error } = useQuery({
    queryKey: [`payCommission`],
    retry: 1,
    retryDelay: 3000,
    refetchOnWindowFocus: false,
    enabled: isEnabled,
    queryFn: () =>
      onPayCommission({
        signature,
        data: { ...commissionData },
      }),
  });
  const {
    isError: isErrorTx,
    isSuccess: isSuccessTx,
    data: dataTx,
    isFetching: isFetchingTx,
    error: errorTx,
  } = useWaitForTransactionReceipt({
    queryKey: [`payCommission${data ? data.data : ""}`],
    hash: data ? data.data : "",
    retry: 3,
    retryDelay: 3000,
    refetchOnWindowFocus: false,
    enabled: isEnabled,
    confirmations: 2,
  });

  useEffect(() => {
    const onResult = async () => {
      if (!isFetching && !isFetchingTx && commissionData) {
        if (isError) {
          if (commissionData.onError) await commissionData.onError(data ? data.error : error);
          if (commissionData.onSettled) await commissionData.onSettled();
          setIsEnabled(false);
        } else if (isSuccess && data) {
          if (isErrorTx) {
            if (commissionData.onError)
              await commissionData.onError(dataTx ? dataTx.error : errorTx);
            if (commissionData.onSettled) await commissionData.onSettled();
            setIsEnabled(false);
          } else if (isSuccessTx && dataTx) {
            if (commissionData.onSuccess) await commissionData.onSuccess();
            if (commissionData.onSettled) await commissionData.onSettled();
            setIsEnabled(false);
          }
        }
      }
    };
    if (!isFetching) onResult();
  }, [
    isError,
    isSuccess,
    isFetching,
    status,
    data,
    isFetchingTx,
    commissionData,
    error,
    isErrorTx,
    isSuccessTx,
    dataTx,
    errorTx,
  ]);

  const requestPayCommission = async ({ commissionData }) => {
    if (!commissionData) throw new Error("missing payment request side data");
    const signature = await requestSignature();
    setSignature(signature);
    setCommissionData(commissionData);
    setIsEnabled(true);
  };

  return {
    requestPayCommission,
    isFetchingTx,
    isError,
    error,
    data,
    isErrorTx,
    isSuccessTx,
    dataTx,
    isFetchingTx,
  };
};
