import founderBronze from "@assets/icons/founder-bronze.svg";
import founderSilver from "@assets/icons/founder-silver.svg";
import founderGold from "@assets/icons/founder-gold.svg";
import upgradeFounderSilverToGold from "@assets/icons/upgrade-founder-silver-to-gold.svg";
import diamondFounder from "@assets/icons/diamond-founder.svg";
import platinumFounder from "@assets/icons/platinum-founder.svg";

export const parsePackagesResponse = ({ data, decimals }) => {
    const parsedData = [];

    data.forEach((item) => {
        const [image, value] = getImageAndValueByName(item.fullPackage.name);

        const newParsedItem = {
            image,
            title: item.fullPackage.name,
            totalPrice:
                (Number(item.fullPackage.price) + Number(item.fullPackage.vat))/(10**Number(decimals)),
            value,
            price: Number(item.fullPackage.price)/(10**Number(decimals)),
            vat: (Number(item.fullPackage.vat))/(10**Number(decimals)),
            list: [
                `Bids Quantity: ${Number(item.fullPackageBid.totalBids)}`,
                `Startup bonus: $${Number(item.fullPackageBonus.startupBonus)/(10**Number(decimals))}`
            ],
            qv: Number(item.fullPackage.qv),
            bcv: Number(item.fullPackage.bcv),
            ucv: Number(item.fullPackage.ucv),
            binaryPercent: Number(item.fullPackageBinary.binaryPercent),
            binaryCap: Number(item.fullPackageBinary.binaryCap)/(10**Number(decimals)),
            qualifTime: (new Date(
                Number(
                    item.fullPackageExpirations.qualificationExpirationDays
                )*1000
            )).toLocaleDateString(),
            bidPrice: Number(item.fullPackageBid.bidPrice)/(10**Number(decimals)),
            totalBids: Number(item.fullPackageBid.totalBids),
            cashback: Number(item.fullPackageCashback.cashback)/(10**Number(decimals)),
            amount: 0,
            bidExpiration: (new Date(
                Number(
                    item.fullPackageExpirations.qualificationExpirationDays
                )*1000
            )).toLocaleDateString(),
            matchingBonus: Number(item.fullPackageBonus.matchingBonus),
            unilevelBonus: Number(item.fullPackageBonus.unilevelBonus),
            turboFund: Number(item.fullPackage.turboFund)/(10**Number(decimals))
        };
        parsedData.push(newParsedItem);
    });

    return parsedData;
};

const getImageAndValueByName = (name) => {
    name = name.toLowerCase();
    if (name.includes("founder bronze")) {
        return [founderBronze, "founder-bronze"];
    } else if (name.includes("upgrade founder silver")) {
        return [upgradeFounderSilverToGold, "upgrade-founder-silver-to-gold"];
    } else if (name.includes("founder silver")) {
        return [founderSilver, "founder-silver"];
    } else if (name == "founder gold") {
        return [founderGold, "founder-gold"];
    } else if (name.includes("diamond")) {
        return [diamondFounder, "founder-diamond"];
    } else if (name.includes("platinum")) {
        return [platinumFounder, "founder-platinum"];
    }
    return ["", ""];
};
