export const getLevelAddresses = async ({ addresses, contractFunctions }) => {
  addresses = addresses.filter((v) => v);
  if (!addresses || addresses.length == 0) return [];
  const levelAddressesPromises = async (addressToGet) => {
    const sponsored = await contractFunctions.unilevel.unilevelUsersSponsoredBy(addressToGet);
    return sponsored.map((sponsor) => sponsor.id);
  };

  const sponsoredList = [];
  for (const currentAddress of addresses) {
    const levelAddressesResponse = await levelAddressesPromises(currentAddress);
    sponsoredList.push(levelAddressesResponse);
  }

  return sponsoredList.flatMap((v) => v);
};
