import * as Sentry from "@sentry/react";

export const SentryService = (file, functionName, error) => {
  if (process.env.REACT_APP_ENVIRONMENT !== "production") {
    console.error(`Something went wrong 
      function: ${functionName}
      file: ${file}
      error: ${JSON.stringify(error)}
      errorMessage: ${JSON.stringify(error?.message)}`);
  }
  if (window.location.href.includes("localhost")) return;
  Sentry.captureMessage(`Something went wrong 
    function: ${functionName}
    file: ${file}
    error: ${JSON.stringify(error)}
    errorMessage: ${JSON.stringify(error?.message)}`);
  Sentry.captureException(error);
};
