import { sendTransaction, waitForTransactionReceipt } from "@wagmi/core";
import { SentryService } from "./SentryErrorCapture";
import { wagmiConfig } from "App";

export const onSendFunds = async ({ address, amount, confirmations }) => {
  if (!address) {
    throw new Error("Address is required");
  }
  if (!amount) {
    throw new Error("Amount in matic is required");
  }
  try {
    const hash = await sendTransaction(wagmiConfig, {
      to: address,
      value: BigInt(parseInt(Number(amount) * 10 ** 18)),
    });
    console.debug({ hash });
    if (confirmations) {
      const receipt = await waitForTransactionReceipt(wagmiConfig, {
        hash,
        ...(confirmations ? { confirmations } : {}),
      });
      console.debug({ receipt });
    }
    return hash;
  } catch (error) {
    SentryService("onSendFunds", "onSendFunds", error);
    throw new Error(error ? error : "Error sending funds");
  }
};
