import { Logo } from "common/components";
import DropdownComponent from "common/components/Dropdown";
// import { AppLayout } from "layouts/AppLayout";
import BellIcon from "@assets/bell.svg";
import { useAuctionService } from "modules/penny-auction/context/auctionProvider";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ProductsComponents } from "../components/productPage";
import { useParams } from "react-router-dom";
import { web3AuthInstance } from "App";
import { useGetUserInfo } from "common/hooks/useGetUserInfo";
import { Navbar } from "modules/landing/components";
import { FooterMin } from "common/components";
import Countdown from "react-countdown";
import LeftCowntdownTextImage from "@assets/left-countdown-text.svg";
import RightCowntdownTextImage from "@assets/right-countdown-text.svg";
import UserPlaceholder from '@assets/icons/user.png'

export const ProductPage = () => {
  const {
    connectionStatus,
    handleSearchOptions,
    items,
    autoBuy,
    itemId,
    addBid,
    handleFollowClick,
    user,
    itemDetails,
    address,
    auctionEnd,
    serverTime,
  } = useAuctionService();
  const [bidding, setBidding] = useState(false);
  const resetBidding = useRef(null);
  const [changingStatus, setChangingStatus] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date().getTime());
  const { id: paramsItemId } = useParams();
  const pinataGatewayKey = `?pinataGatewayToken=${process.env.REACT_APP_PINATA_GATEWAY_KEY}`;
  useEffect(() => {
    if (itemDetails && itemDetails.status == 1) {
      setBidding(false);
      clearTimeout(resetBidding.current);
      // console.debug("itemDetails change");
    }
  }, [itemDetails]);

  const changeState = useCallback(() => {
    // This line won't result in calling your useEffect
    // setAuctionStatus("live") // 'hello' will be printed one time only.

    // You need to use a state value that won't be similar to the previous one.
    setCurrentDate(new Date().getTime());
    setChangingStatus(false);
    // console.debug("changeState called");
  }, [setCurrentDate, setChangingStatus]);

  useEffect(() => {
    if (changingStatus) {
      changeState();
    }
  }, [changingStatus, changeState]);

  const getCanBid = useCallback(() => {
    if (!itemDetails || !address) return false;
    let canBid = true;
    if (bidding) return false;
    if (address && itemDetails?.lastBid?.bidder.toLowerCase() === address.toLowerCase()) {
      canBid = false;
    }
    if (
      itemDetails.bidders &&
      itemDetails.bidders.length > 0 &&
      itemDetails.status == 1 &&
      itemDetails.endDate > currentDate &&
      itemDetails.currentPrice >= itemDetails.maxBidders
    ) {
      const inBidders = itemDetails.bidders.filter(
        (bidder) => address && bidder.toLowerCase() === address.toLowerCase()
      );
      if (inBidders.length == 0) {
        canBid = false;
      }
    }
    return canBid;
  }, [itemDetails, address, currentDate, bidding]);

  const [activeImage, setActiveImage] = useState(
    itemDetails && itemDetails.info && itemDetails.info.images && itemDetails.info.images.length > 0
      ? `${itemDetails?.info?.images[0]}${pinataGatewayKey}`
      : "https://via.placeholder.com/150"
  );
  // console.debug({ itemDetails });

  useEffect(() => {
    if (connectionStatus && paramsItemId != itemId) {
      handleSearchOptions({ newItemId: paramsItemId });
    }
  }, [connectionStatus, itemId, paramsItemId, handleSearchOptions]);

  useEffect(() => {
    if (
      itemDetails &&
      itemDetails.info &&
      itemDetails.info.images &&
      itemDetails.info.images.length > 0
    )
      setActiveImage(`${itemDetails?.info?.images[0]}${pinataGatewayKey}`);
  }, [itemDetails, pinataGatewayKey]);

  // console.debug({ items, itemDetails });

  const itemDescriptionTitle = `Item description`;
  const buyItNowCTAText = `Buy it now for $`;

  const [profileImg, setProfileImg] = useState("");
  const { userInfo } = useGetUserInfo();

  const options = [
    { title: "Dashboard", redirectionUrl: "/app/dashboard" },
    { title: "Store", redirectionUrl: "/app/store" },
    { title: "Profile", redirectionUrl: "/app/profile" },
    { title: "Logout", redirectionUrl: "/logout" },
  ];

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const response = await web3AuthInstance.getUserInfo();
        if (response?.profileImage) {
          setProfileImg(response.profileImage);
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    if (web3AuthInstance.connected) getUserInfo();
  }, [web3AuthInstance.connected]);

  const getSign = useCallback(() => {
    if (!itemDetails) return "";
    if (itemDetails.status == 2 || (itemDetails.status == 1 && itemDetails.endDate < currentDate))
      return "Auction ended";
    if (itemDetails.status == 3) return "sold";
    if (
      itemDetails.status == 1 &&
      itemDetails.endDate + (serverTime?.offset ?? 0) > currentDate &&
      itemDetails.currentPrice >= itemDetails.maxBidders
    )
      return "no new biders";
  }, [itemDetails, currentDate, serverTime]);

  const extraItemDetails = useMemo(
    () => ({
      bidNow:
        itemDetails.status == 1 &&
        itemDetails.startDate <= currentDate &&
        currentDate <= itemDetails.endDate,
      sold: itemDetails.status == 3,
      startingSoon: itemDetails.status == 1 && itemDetails.startDate > currentDate,
      ended:
        itemDetails.status == 2 || (itemDetails.status == 1 && itemDetails.endDate < currentDate),
      buyNow: itemDetails.status > 0,
      isFav: false,
      sign: getSign(),
      canBid: getCanBid(),
    }),
    [itemDetails, currentDate, getCanBid, getSign]
  );

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (!completed) {
      // Render a countdown
      const totalHours = days * 24 + hours;
      return (
        <div className="inline-flex gap-1">
          <span className="flex mx-auto">
            {totalHours < 10 ? `0${totalHours}` : totalHours}:
            {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </span>
          {/* <div className="relative justify-center align-center rounded-full min-w-[1rem] w-[1rem] min-h-[1rem] h-[1rem] bg-[#1840ce] text-[#ffffff] font-bold">
            <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              !
            </span>
          </div> */}
        </div>
      );
    }
  };

  return (
    <div>
      {/* <div className="w-full flex bg-[#F2F2EF] justify-between items-center py-2 px-5">
        <Logo variant="dark-letters" />
        <div className="flex items-center">
          <div className="px-4 font-semibold">See our winners</div>
          <hr className="h-6 border-r-2 border-clair-gray mr-4" />
          <div>
            <div className="flex items-center gap-x-2">
              {profileImg.length > 0 ? (
                <img
                  className="h-12 w-12 cursor-pointer rounded-full object-cover hidden md:block"
                  src={profileImg}
                />
              ) : (
                <div className="h-12 w-12 bg-clair-gray rounded-full"></div>
              )}
              <DropdownComponent title={userInfo && `${userInfo.info?.name}`} items={options} />
            </div>
          </div>
        </div>
      </div> */}
      <Navbar userBids={user ? user.currentBids : undefined} />
      <div className="grid grid-cols-5 !max-w-screen bg-[#E3E3D7]">
        <div className="col-span-5 container mx-auto grid grid-cols-4 pb-4">
          <div className="col-span-5 md:col-span-2 flex flex-col p-10">
            <h1 className="text-2xl p-2 font-semibold capitalize">
              {itemDetails && itemDetails?.info?.name}
            </h1>
            <div className="border border-gray-400">
              <img src={activeImage} className="w-full" />
            </div>
            <div className="flex flex-start gap-2 mt-2">
              {itemDetails ? (
                <>
                  {itemDetails?.info?.images &&
                    itemDetails?.info?.images.length > 1 &&
                    itemDetails?.info?.images.map((image, index) => {
                      return (
                        <div
                          className="max-w-[90px] max-h-[90px] flex"
                          key={`${image}_${index}`}
                          onClick={() => {
                            setActiveImage(`${image}${pinataGatewayKey}`);
                          }}
                        >
                          <img
                            src={`${image}${pinataGatewayKey}`}
                            className="border border-gray-400 object-contain"
                          />
                        </div>
                      );
                    })}
                </>
              ) : (
                <>
                  <div className="">
                    <img src="https://via.placeholder.com/150" className="border border-gray-400" />
                  </div>
                  <div className="">
                    <img src="https://via.placeholder.com/150" className="border border-gray-400" />
                  </div>
                  <div className="">
                    <img src="https://via.placeholder.com/150" className="border border-gray-400" />
                  </div>
                </>
              )}
            </div>
            {/* <div className="flex mt-2">
                <p className="text-sm">Category: {itemDetails ? itemDetails?.category : ""}</p>
              </div> */}
          </div>
          <div className="col-span-5 md:col-span-2 flex flex-col p-10 pt-[90px]">
            <div className="flex flex-col items-center border-b border-b-gray-200 bg-white">
              {null && (
                <div className="w-full flex p-4 bg-[#87BAD7] justify-center font-semibold text-lg">
                  Buy now for ${itemDetails ? itemDetails?.autoBuyPrice / 10 ** 6 : `0.00`}
                </div>
              )}
              <div className="w-full flex items-center p-4">
                <div className="flex items-center md:w-1/2">
                  <div className="flex w-20 h-20">
                    <img src={UserPlaceholder} className="w-20 h-20 rounded-full" />
                  </div>
                  <div className="px-4">
                    <p className="uppercase font-semibold text-md">Current Bidder</p>
                    <p className="font-bold text-xl">
                      {itemDetails?.lastBid?.username
                        ? ` ${itemDetails?.lastBid?.username}`
                        : itemDetails?.lastBid?.bidder
                          ? ` ${itemDetails?.lastBid?.bidder.slice(0, 8)}`
                          : ""}
                    </p>
                    <p className="text-sm">
                      {itemDetails && itemDetails?.lastBid
                        ? new Date(itemDetails?.lastBid?.date).toLocaleString()
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="md:flex flex-col md:w-1/2 hidden">
                  <p className="text-sm text-right text-[#1840CE]">Delaware</p>
                  <p className="text-sm text-right">Status:</p>
                  <p className="text-sm text-right">Hello! I&apos;m using Fortune Goat!</p>
                </div>
              </div>
              <div className="flex w-full justify-between items-center p-5">
                <div className="flex w-full justify-around items-center p-5 py-7 border-y border-y-[#132440] bg-light-gray">
                  <p className="font-bold">CURRENT BID</p>
                  <p className="text-3xl font-bold">${itemDetails?.currentPrice / 10 ** 6}</p>
                </div>
              </div>
              <div className="flex justify-center items-center mb-4 font-bold w-full">
                {(extraItemDetails.startingSoon || extraItemDetails.bidNow) && (
                  <div className="bg-[#87BAD7] flex w-full mx-4 justify-center text-[#fff] py-2 flex-col items-center">
                    <p className="text-3xl text-ellipsis flex gap-2">
                      {extraItemDetails.startingSoon &&
                        itemDetails.startDate - new Date().getTime() > 1000 * 60 * 60 * 24 * 7 * 1 ? (
                        new Date(itemDetails.startDate + (serverTime?.offset ?? 0)).toLocaleString()
                      ) : (
                        <Countdown
                          key={`${extraItemDetails.startingSoon}_${itemDetails.endDate}_${serverTime?.offset}`}
                          precision={50}
                          date={
                            extraItemDetails.startingSoon
                              ? +itemDetails.startDate + (serverTime?.offset ?? 0)
                              : +itemDetails.endDate + (serverTime?.offset ?? 0)
                          }
                          renderer={renderer}
                          onComplete={() => {
                            if (
                              itemDetails?.lastBid?.bidder &&
                              address &&
                              address.toLowerCase() == itemDetails.lastBid.bidder.toLowerCase() &&
                              currentDate > +itemDetails.endDate + (serverTime?.offset ?? 0)
                            ) {
                              if (extraItemDetails.bidNow && itemDetails.status == 1) {
                                setTimeout(() => {
                                  auctionEnd(cardInfo.id);
                                }, 500);
                              }
                            }

                            setChangingStatus(true);
                          }}
                        />
                      )}
                    </p>
                    <div className="flex gap-2 items-center justify-center">
                      <img src={LeftCowntdownTextImage} alt="" />
                      <p className="text-[#132440]">
                        {extraItemDetails.startingSoon ? `STARTING SOON` : "TIME LEFT"}
                      </p>
                      <img src={RightCowntdownTextImage} alt="" />
                    </div>
                  </div>
                )}
              </div>
              <div className="grid grid-cols-2 gap-2 w-full px-5 pt-0 pb-5">
                <button className="bg-[#1840CE] text-white py-4 px-8 col-span-1">Alert Me</button>
                {itemDetails ? (
                  extraItemDetails?.bidNow ? (
                    <button
                      className={` text-center text-lg font-bold py-4 px-8 col-span-1 ${extraItemDetails.canBid
                          ? `bg-highlight-secondary`
                          : `bg-[#CCCCCC] text-[#4C4C4C]`
                        } `}
                      onClick={() => {
                        addBid(itemDetails?.id);
                        setBidding(true);
                        resetBidding.current = setTimeout(() => {
                          setBidding(false);
                        }, 5000);
                      }}
                      disabled={bidding || !extraItemDetails.canBid}
                    >
                      {bidding ? "Bidding..." : "Bid Now"}
                    </button>
                  ) : extraItemDetails?.startingSoon ? (
                    <div
                      className="text-center text-lg bg-custom-yellow font-bold py-4 px-8 col-span-1"
                      onClick={() => { }}
                    >
                      Starting soon
                    </div>
                  ) : extraItemDetails?.sold ? (
                    <div
                      className="text-center text-lg bg-clair-gray font-bold text-[#4C4C4C] py-4 px-8 col-span-1"
                      onClick={() => { }}
                    >
                      SOLD
                    </div>
                  ) : (
                    extraItemDetails?.buyNow && (
                      <div
                        className="text-center text-lg bg-[#CCCCCC] font-bold text-[#4C4C4C] py-4 px-8 col-span-1"
                        onClick={() => { }}
                      >
                        Bid Now
                      </div>
                    )
                  )
                ) : (
                  <button
                    className="bg-[#BCE210] text-white "
                    onClick={() => {
                      addBid(paramsItemId);
                    }}
                  >
                    Bid Now
                  </button>
                )}
                {/* <button
                  className="bg-[#BCE210] text-white "
                  onClick={() => {
                    addBid(paramsItemId);
                  }}
                >
                  Bid Now
                </button> */}
              </div>
              <div className="w-full flex justify-center">
                {extraItemDetails.sign !== undefined && (
                  <div
                    className={`h-6 flex items-center justify-center mb-3 ${extraItemDetails.sign === "sold"
                        ? "bg-brick-red"
                        : extraItemDetails.sign === "no new biders"
                          ? "bg-[#1840ce]"
                          : "bg-baby-blue"
                      } font-bold px-4 py-5 top-1/3 text-white`}
                  >
                    {extraItemDetails.sign.toUpperCase()}
                  </div>
                )}
              </div>
            </div>
            <div className="col-span-5 md:col-span-3 mb-8 px-4 mt-4">
              <h2 className="text-xl font-bold">{itemDescriptionTitle}</h2>
              <div class="prose prose-lg">
                {itemDetails && itemDetails?.info?.description && (
                  <div dangerouslySetInnerHTML={{ __html: itemDetails?.info?.description }} />
                )}
              </div>
            </div>
          </div>
          <div className="col-span-5 px-10 py-2 font-semibold text-lg">
            <h3 className="">SIMILAR PRODUCTS</h3>
          </div>
          <ProductsComponents.ItemDetailsProductsCards
            items={items}
            itemId={itemId}
            autoBuy={autoBuy}
            addBid={addBid}
            likes={user ? [...user.likes] : []}
            favButtonHandler={handleFollowClick}
            auctionEnd={auctionEnd}
          />
        </div>
      </div>
      <FooterMin />
    </div>
  );
};
