import axios from "axios";

const API = process.env.REACT_APP_AWS_API_URL;

export const usePackagesService = () => {
  return {
    async fetchPackages(params) {
      const response = await axios.get(`${API}/contracts/fg/package`, {
        params,
      });
      return response.data;
    },
    async fetchPackage(params) {
      const response = await axios.get(`${API}/contracts/fg/package`, {
        params,
      });
      return response.data;
    },
  };
};
