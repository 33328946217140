import { getPublicClient } from "@wagmi/core";
import { SentryService } from "./SentryErrorCapture";
import { wagmiConfig } from "App";
export const onEstimateWriteContractGas = async ({
  contractAddress,
  contractABI,
  functionName,
  args,
  from,
  value,
  chainId,
}) => {
  try {
    console.log("--onEstimateWriteContractGas--", {
      contractAddress,
      contractABI,
      functionName,
      args,
      from,
      value,
      chainId,
    });
    const publicClient = getPublicClient(wagmiConfig);
    console.debug({ publicClient });
    const gas = await publicClient.estimateContractGas({
      address: contractAddress,
      abi: contractABI,
      functionName,
      args,
      ...(chainId ? { chainId } : {}),
      ...(from ? { account: from } : {}),
      ...(value ? { value } : {}),
    });
    if (gas) return Number(gas);
    return null;
  } catch (error) {
    SentryService("onEstimateGas", "onEstimateWriteContractGas", error.message);
    // console.log({ onEstimateGasError: error });
    throw new Error(error);
  }
};
