import { FC } from "react";
import { useNavigate } from "react-router-dom";
import FortuneGoatWhiteIcon from "@assets/logos/fortune-goat-white.svg";
import FortuneGoatDarkIcon from "@assets/logos/fortune-goat-dark.svg";
import FortuneGoatDarkIsotypeIcon from "@assets/logos/fortune-goat-dark-isotype.svg";
import LettersFortuneGoat from '@assets/logos/letters-fortune-goat.svg'

// interface ILogoProps {
//     variant?: "white" | "black" | "dark-isotype" | "dark-letters";
// }

export const Logo = ({ variant = "black", layoutLoading, width }) => {
    const navigate = useNavigate();
    const renderLogo = () => {
        switch (variant) {
            case "white":
                return (
                    <img
                        src={FortuneGoatWhiteIcon}
                        alt="Fortune Goat"
                        className={width || "w-40"}
                    />
                );
            case "black":
                return (
                    <img
                        src={FortuneGoatDarkIcon}
                        alt="Fortune Goat"
                        className={width || "w-40"}
                    />
                );
            case "dark-isotype":
                return (
                    <img
                        src={FortuneGoatDarkIsotypeIcon}
                        alt="Fortune Goat"
                        className={width || "w-40"}
                    />
                );
            case "dark-letters":
                return (
                    <img
                        src={LettersFortuneGoat}
                        alt="Fortune Goat"
                        className={width || "w-52"}
                    />
                );
            default:
                return (
                    <img
                        src={FortuneGoatDarkIcon}
                        alt="Fortune Goat"
                        className={width || "w-40"}
                    />
                );
        }
    };

    return (
        <div className={!layoutLoading && 'cursor-pointer'} onClick={() => !layoutLoading && navigate("/")}>
            {renderLogo()}
        </div>
    );
};
