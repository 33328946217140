import { onGetUnilevelMatchBonus } from "./onGetUnilevelMatchBonus";
import { getLevelAddresses as getLevelAddressesDefault } from "./getLevelAddresses";
import { getLevelSum as defaultGetLevelSum } from "../common/getLevelSum";
import { getMatchingBonusPercentages as getMatchingBonusPercentagesDefault } from "./getMatchingBonusPercentages";

export const getUnilevelMatchingBonus = ({
  contractFunctions,
  getLevelAddresses = getLevelAddressesDefault,
  getLevelSum = defaultGetLevelSum,
  getMatchingBonusPercentages = getMatchingBonusPercentagesDefault,
  getBinaryCommission,
  getUnilevelCommission,
}) => {
  return async ({ userRank }) =>
    onGetUnilevelMatchBonus({
      userRank,
      getLevelAddresses,
      getLevelSum,
      getMatchingBonusPercentages,
      contractFunctions,
      getBinaryCommission,
      getUnilevelCommission,
    });
};
