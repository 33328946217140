import { Navbar } from "modules/landing/components";
import { useLocation } from "react-router-dom";
import cs from "classnames";
import { Footer } from "common/components";

export const LegalInfoLayout = ({
    children,
    title,
    menu,
}) => {
    const location = useLocation();
    return (
        <>
            <Navbar />
            <div className="bg-dark-blue min-h-[150px] flex items-end">
                <div className="container px-4 mx-auto">
                    <h1 className="text-white text-4xl mb-6 uppercase">{title}</h1>
                </div>
            </div>
            <div className="container px-4 mx-auto">
                <div className="grid grid-cols-3">
                    <div className="col-span-3 md:col-span-1 px-4 mx-8">
                        <h2 className="text-2xl font-bold my-4">QUICK LINKS</h2>
                        <div>
                            {menu.map((item, index) => (
                                <>
                                    <a
                                        key={index}
                                        href={`#${item.id}`}
                                        className={cs("block py-2 text-dark-blue hover:text-primary", location.hash === `#${item.id}` && "font-semibold")}
                                    >
                                        {item.title}
                                    </a>
                                    {
                                        location.hash === `#${item.id}` && (
                                            <div className="flex justify-end w-full">
                                                <div className="border-b border-4 border-dark-blue w-2/3" />
                                            </div>
                                        )
                                    }
                                </>
                            ))}
                        </div>
                    </div>
                    <div className="col-span-3 md:col-span-2">
                        {children}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};
