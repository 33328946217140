import { onReadContract } from "common/services";
import { useCallback, useEffect, useState } from "react";
import { useAccount } from "wagmi";
import ABI from "../contracts/FortuneGoat.json";
import { SentryService } from "common/services/SentryErrorCapture";

export const useGetUserInfo = () => {
  const { isConnected, address } = useAccount();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
  const [userInfo, setUserInfo] = useState();
  const getUserInfo = useCallback(async () => {
    try {
      setLoading(true);
      if (!isConnected) {
        // throw new Error("User is not connected");
        return undefined;
      }
      if (!address) {
        // throw new Error("No address found");
        return undefined;
      }
      const userInfo = await onReadContract({
        contractAddress,
        contractABI: ABI,
        functionName: "getUserInfo",
        args: [address],
        from: address,
      });
      const userPackage = await onReadContract({
        contractAddress,
        contractABI: ABI,
        functionName: "getUserPackage",
        args: [address],
        from: address,
      });
      const packageInfo = await onReadContract({
        contractAddress,
        contractABI: ABI,
        functionName: "getPackagesList",
        args: [userPackage.packageId],
        from: address,
      });
      setUserInfo({
        info: userInfo[0],
        binaryInfo: userInfo[1],
        package: { ...userPackage, packageName: packageInfo[0].fullPackage.name },
      });
    } catch (err) {
      // console.log({ error });
      SentryService("useGetUserInfo", "getUserInfo", err);
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [isConnected, address, contractAddress]);
  useEffect(() => {
    getUserInfo();
  }, [getUserInfo]);
  const mutateAsync = async () => {
    getUserInfo();
  };
  return { userInfo, loading, error, mutateAsync };
};
