import { AppLayout } from "@layouts/AppLayout";
import { useState} from 'react';
// import { useFetchRangeAndCommisions } from "modules/admin/hooks/RangeAndCommissions/useFetchRangeAndCommisions";
import { useAccount } from "wagmi";
import { remove6Decimals } from "common/helpers";
import { remove1Decimals } from "common/helpers";
import { useGetRankAndCommissions } from "common/hooks/useGetRankAndCommissions";
import Skeleton from "react-loading-skeleton";


export const CommissionsPage = () => {

   const { address } = useAccount();

   const { data, loading: isLoading } =
     useGetRankAndCommissions({
       address,
       commissions: true,
     });

//    const {
//     data ,
//   } = useFetchRangeAndCommisions("rangeCommissions", {
//     userId: address ,
//     commissions: true,
//   });


   const [isOpen01, setIsOpen01] = useState(false);
   const [isOpen03, setIsOpen03] = useState(false);
   const [isOpen05, setIsOpen05] = useState(false);

   const toggleOpen01 = () => {
      setIsOpen01(!isOpen01);
   };

   const toggleOpen03 = () => {
      setIsOpen03(!isOpen03);
   };

   const toggleOpen05 = () => {
      setIsOpen05(!isOpen05);
   };


  return (
    <AppLayout>
      <p className="text-dark font-bold text-xl">COMMISSIONS BREAKDOWN</p>

      {/* Fast Start Component */}
      <div className="w-full font-semibold my-6 mx-auto px-0 bg-white border border-gray-400">
        <div>
          <div className="p-4">
            <details className={`group ${isOpen01 ? "open" : ""}`}>
              <summary
                className="flex justify-between items-center font-medium cursor-pointer list-none"
                onClick={toggleOpen01}
              >
                <div className="flex gap-10">
                  <p>1) FAST START:</p>
                  {
                     isLoading ? (
                        <p>
                           <Skeleton width={"4rem"} height={"1.2rem"} />
                        </p>
                     ): (
                        <p className="border-b-4 border-b-[#BCE210]">${ data && remove6Decimals(data?.commissionsBreakdown?.fastStart?.total) } USDT</p>
                     )
                  }
                </div>
                <span className="transition group-open:rotate-180">
                  <svg
                    fill="none"
                    height="24"
                    shapeRendering="geometricPrecision"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    viewBox="0 0 24 24"
                    width="24"
                  >
                    <path d="M6 9l6 6 6-6"></path>
                  </svg>
                </span>
              </summary>
              <div className="relative">
                <div className="flex gap-10">
                  <p
                    className={` absolute text-neutral-600 mt-14 ${
                      isOpen01 && data?.commissionsBreakdown?.fastStart?.levels?.length > 0  ? "animate-moveToCorner1" : "animate-moveToCorner0"
                    }`}
                  >
                    ${ data && remove6Decimals(data?.commissionsBreakdown?.fastStart?.total) } USDT
                  </p>
                </div>
                <table className="mt-3 w-full divide-y divide-gray-200 dark:divide-gray-300">
                  <thead>
                    <tr>
                      <th className="pl-4 text-left font-normal">Level</th>
                      {/* <th className="text-left font-normal">Invoice</th>
                      <th className="text-left font-normal">Date</th> */}
                      <th className="text-left font-normal">Address</th>
                      {/* <th className="text-left font-normal">Product</th>
                      <th className="text-left font-normal">QV</th> */}
                      <th className="text-left font-normal">%</th>
                      <th className="text font-normal">Commission</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data && data?.commissionsBreakdown?.fastStart?.levels?.map((item, index4)=> (
                        <>
                        <tr className="font-normal" key={index4} >
                          <td className="p-4">{ item.level }</td>
                          {/* <td className="font-medium">#124678</td>
                          <td className="font-medium">02/03/24</td> */}
                          <td className="font-medium w-fit">{ item.addressList}</td>
                          {/* <td className="font-medium">Product 1</td> */}
                          {/* <td className="font-medium">120 pts</td> */}
                          <td className="font-medium">{ item.percentage }</td>
                          <td></td>
                        </tr>
                        </>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </details>
          </div>
        </div>
      </div>

      {/* Binary Component */}
      <div className="w-full font-semibold  my-6 mx-auto px-0 bg-white min-h-sceen border border-gray-400">
        <div>
          <div className="p-4">
            <details className="group">
              <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                <div className="flex gap-10">
                  <p>2) BINARY:</p>
                  {
                     isLoading ? (
                          <p>
                           <Skeleton width={"4rem"} height={"1.2rem"} />
                        </p>
                     ) :
                     <p className="border-b-4 border-b-[#BCE210]">${data && remove6Decimals(data?.commissionsBreakdown?.binary?.total) } USDT</p>
                  }
                </div>
                <span className="transition group-open:rotate-180">
                  <svg
                    fill="none"
                    height="24"
                    shape-rendering="geometricPrecision"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    viewBox="0 0 24 24"
                    width="24"
                  >
                    <path d="M6 9l6 6 6-6"></path>
                  </svg>
                </span>
              </summary>
              <table className="mt-4 w-3/4 divide-y divide-gray-200 dark:divide-gray-300">
                <thead>
                  <tr>
                    <th className="pl-4 text-left font-normal">Right Points</th>
                    <th className="text-center font-normal">Left Points</th>
                    <th className="text-center font-normal">Smaller Leg %</th>
                    <th className="text-center font-normal">CAP</th>
                    <th className="text-center font-normal">Commission</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="font-normal">
                    <td className="font-medium p-4">{data && data?.commissionsBreakdown?.binary?.rightBranch } pts.</td>
                    <td className="font-medium text-center">{ data && data?.commissionsBreakdown?.binary?.leftBranch } pts.</td>
                    <td className="font-medium text-center">{ data && data?.commissions?.binaryCommission?.binaryCV?.lowest}%</td>
                    <td className="font-medium text-center">{ data && data?.commissionsBreakdown?.binary?.binaryCap } </td>
                    <td className="font-medium text-center">${ data && data.commissionsBreakdown.binary.total } USDT</td>
                  </tr>
                </tbody>
              </table>
            </details>
          </div>
        </div>
      </div>

      {/* Binary Matching Bonus Component */}
      <div className="w-full font-semibold my-6 mx-auto px-0 bg-white border border-gray-400">
        <div>
          <div className="p-4">
            <details className={`group ${isOpen03 ? "open" : ""}`}>
              <summary
                className="flex justify-between items-center font-medium cursor-pointer list-none"
                onClick={toggleOpen03}
              >
                <div className="flex gap-10">
                  <p>3) BINARY MATCHING BONUS:</p>
                  {
                     isLoading ? (
                        <p>
                           <Skeleton width={"4rem"} height={"1.2rem"} />
                        </p>
                     ) :
                     <p className="border-b-4 border-b-[#BCE210]">${ data && data?.commissionsBreakdown?.matchingBonus?.total } USDT</p>
                  }
                </div>
                <span className="transition group-open:rotate-180">
                  <svg
                    fill="none"
                    height="24"
                    shapeRendering="geometricPrecision"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    viewBox="0 0 24 24"
                    width="24"
                  >
                    <path d="M6 9l6 6 6-6"></path>
                  </svg>
                </span>
              </summary>
              <div className="relative">
                <div className="flex gap-10">
                  <p
                    className={` absolute text-neutral-600 mt-14 pt-1  ${
                      isOpen03 && data?.commissionsBreakdown?.matchingBonus?.levels.length > 0 ? "animate-moveToCorner2" : "animate-moveToCorner0"
                    }`}
                  >
                    ${ data && data?.commissionsBreakdown?.matchingBonus?.total } USDT
                  {/* data.commissionsBreakdown.matchingBonus */}
                  </p>
                </div>
                <table className="mt-4 w-1/2 divide-y divide-gray-200 dark:divide-gray-300">
                  <thead>
                    <tr>
                      <th className="pl-4 text-left font-normal">Level</th>
                      <th className="text-center font-normal">Volume</th>
                      <th className="text-center font-normal">%</th>
                      <th className="text-center font-normal">Comission</th>
                    </tr>
                  </thead>

                  {
                    // data.commissionsBreakdown.matchingBonus.levels
                        data && data?.commissionsBreakdown?.matchingBonus?.levels.map((item, index1)=> (
                        <>
                        <tr className="font-normal" key={index1} >
                          <td className=" p-4">{item?.level}</td>
                          <td className="text-center font-medium w-fit">{ item?.total }</td>
                          <td className="text-center font-medium w-fit">{ item?.percentage}</td>
                          {/* data.matchingBonus.levels[0].binary.total */}
                          {/* <td className="font-medium">{ item.percentage}</td> */}
                          <td></td>
                        </tr>
                        </>
                      ))
                    }
                </table>
              </div>
            </details>
          </div>
        </div>
      </div>

      {/* Unilevel Component */}
      <div className="w-full font-semibold  my-6 mx-auto px-0 bg-white min-h-sceen border border-gray-400">
        <div>
          <div className="p-4">
            <details className="group">
              <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                <div className="flex gap-10">
                  <p>4) UNILEVEL:</p>
                  {
                     isLoading ? (
                        <p>
                           <Skeleton width={"4rem"} height={"1.2rem"} />
                        </p>
                     ): (
                        <p className="border-b-4 border-b-[#BCE210]">${data && data?.commissionsBreakdown?.unilevel?.total} USDT</p>
                     )
                  }
                </div>
                <span className="transition group-open:rotate-180">
                  <svg
                    fill="none"
                    height="24"
                    shape-rendering="geometricPrecision"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    viewBox="0 0 24 24"
                    width="24"
                  >
                    <path d="M6 9l6 6 6-6"></path>
                  </svg>
                </span>
              </summary>
              <table className="md:table-fixed mt-3 w-5/6 divide-y divide-gray-200 dark:divide-gray-300">
                <thead>
                  <tr>
                    <th className="pl-4 text-left font-normal">Level</th>
                    {/* <th className="text-left font-normal">Username</th> */}
                    {/* <th className="text-left font-normal">Invoice</th> */}
                    <th className="text-left font-normal">Package</th>
                    <th className="text-left font-normal">Points</th>
                    <th className="text-left font-normal">%</th>
                    <th className="text-left font-normal">Commission</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="font-normal">
                    <td className="p-4">1</td>
                    {/* <td className="font-medium">J. Smith</td> */}
                    {/* <td className="font-medium">#12487</td> */}
                    <td className="font-medium">{ data && data?.paymentLevel?.actualRange }</td>
                    <td className="font-medium">{ data && data?.commissionsBreakdown?.unilevel?.total } pts</td>
                    <td className="font-medium">{ data && remove1Decimals(data?.commissionsBreakdown?.unilevel?.percentage) } %</td>
                    <td className="font-medium">{  data &&  remove6Decimals( data?.commissions?.unilevelCommission?.total) } USDT</td>
                  </tr>
                </tbody>
              </table>
            </details>
          </div>
        </div>
      </div>

      {/* Unilevel Matching Bonus */}
      <div className="w-full font-semibold my-6 mx-auto px-0 bg-white border border-gray-400">
        <div>
          <div className="p-4">
            <details className={`group ${isOpen05 ? "open" : ""}`}>
              <summary
                className="flex justify-between items-center font-medium cursor-pointer list-none"
                onClick={toggleOpen05}
              >
                <div className="flex gap-10">
                  <p>5) UNILEVEL MATCHING BONUS:</p>
                  {
                     isLoading ? (
                        <p>
                           <Skeleton width={"4rem"} height={"1.2rem"} />
                        </p>
                     ): (
                        <p className="border-b-4 border-b-[#BCE210]">${ data && data?.commissionsBreakdown?.unilevelMatchingBonus?.total } USDT</p>
                     )
                  }
                </div>
                <span className="transition group-open:rotate-180">
                  <svg
                    fill="none"
                    height="24"
                    shapeRendering="geometricPrecision"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    viewBox="0 0 24 24"
                    width="24"
                  >
                    <path d="M6 9l6 6 6-6"></path>
                  </svg>
                </span>
              </summary>
              <div className="relative">
                <div className="flex gap-10">
                  <p
                    className={` absolute text-neutral-600 mt-14 pt-1  ${
                      isOpen05 && data?.commissionsBreakdown?.unilevelMatchingBonus?.levels.length > 0 ? "animate-moveToCorner3" : "animate-moveToCorner0"
                    }`}
                  >
                    ${data && data?.commissionsBreakdown?.unilevelMatchingBonus?.total } USDT
                  </p>
                </div>
                <table className="mt-4 w-1/2 divide-y divide-gray-200 dark:divide-gray-300">
                  <thead>
                    <tr >
                      <th className="pl-4 text-left font-normal">Level</th>
                      <th className="text-center font-normal">Volume</th>
                      <th className="text-center font-normal">%</th>
                      <th className="text-center font-normal">Comission</th>
                    </tr>
                  </thead>

                  {
                        data && data?.commissionsBreakdown?.unilevelMatchingBonus?.levels?.map((item, index3)=> (
                        <>
                        <tbody>
                          <tr className="font-normal" key={index3} >
                            <td className=" p-4">{item?.level}</td>
                            <td className="text-center font-medium w-fit">{ item?.total }</td>
                            <td className="text-center font-medium w-fit">{ item?.percentage}</td>
                            <td></td>
                          </tr>
                        </tbody>
                        </>
                      ))
                    }
                </table>
              </div>
            </details>
          </div>
        </div>
      </div>
      
    </AppLayout>
  );
};
