import { forwardRef } from "react";

export const Select = forwardRef(({ label, id, options, ...rest }, ref) => {
  return (
    <>
      <div className="flex flex-col">
        {label && (
          <label htmlFor="select" className="text-dark font-semibold pb-2">
            {label}
          </label>
        )}
        <div className="pr-2 bg-light-gray rounded-r">
          <select
            {...rest}
            ref={ref}
            id={id}
            className="bg-light-gray text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-4 py-3.5 cursor-pointer"
          >
            {options.map((option, i) => (
              <option key={`${id}_${i}`}>{option}</option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
});
