import axios from "axios";
import { Button } from "common/components";
import { Input } from "common/components";
// import { TextArea } from "common/components/TextArea";
import AdminLayout from "layouts/AdminLayout";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
// import { useEditBid } from "../hooks";
import { useEditExpenseProductTurboFunds } from "../../admin/hooks/TurboFunds/useEditExpenseProductTurboFunds";

// import { dateToTimestamp } from "common/helpers/dateToTimestamp";
import { useAccount } from "wagmi";
import { toast } from "react-toastify";
import { Select } from "common/components";
import { onGetCategoriesList } from "modules/penny-auction/services/onGetCategoriesList";
import { useQuery } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

export const EditExpensePage = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { isFetching: isLoadingCategories, data: categoriesList } = useQuery({
    queryKey: [`categoriesList`],
    queryFn: async () => await onGetCategoriesList(),
    staleTime: 360000,
    refetchOnWindowFocus: false,
  });

  const { id } = useParams();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const { requestEditExpenseProduct } = useEditExpenseProductTurboFunds();
  const { address } = useAccount();

  useEffect(() => {
    const fetchExpenseProducts = async () => {
      await axios
        .get(`${process.env.REACT_APP_AWS_API_URL}/contracts/tf/product?id=${id}`)
        .then((response) => {

          setValue("name", response.data.data.name);
          setValue("category", response.data.data.category);
          setValue("amount", response.data.data.amount / 1e6);
        });
    };
    fetchExpenseProducts();
  }, [id, setValue]);

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    let parsedInputs = {
      name: data.name || "",
      description: data.description || "",
      category: data.category || "",
      amount: data.amount || "",
      image: Array.from(data.image),
    };

    await requestEditExpenseProduct({
      expenseProductData: {
        userId: address,
        id,
        ...parsedInputs,
        onSuccess: async () => {
          toast.success("Expense Product updated successfully.");
          navigate("/admin/turbo-funds");
        },
        onError: async (error) => {
          setIsSubmitting(false);
          toast.error(`Failed to edit expense product. ${error}`);
        },
      },
    });
  };
  const categoriesOptions = categoriesList ? categoriesList.map((cat) => cat.name) : [];
  return (
    <AdminLayout>
      <div className="max-w-screen-lg bg-white">
        <div className="w-full px-8">
          <h1 className="text-2xl px-4 pt-8">Edit Expense</h1>
          <form className="grid grid-cols-3 mt-4" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-span-3 md:col-span-3 px-4">
              <div className="mb-2">
                <div className="flex gap-4 items-center">
                  <label className="text-lg font-semibold" htmlFor="name">
                    Product´s Name
                  </label>
                  <div className="flex" title="Name of the product">
                    <FontAwesomeIcon icon={faCircleInfo} color="#4C4C4C" />
                  </div>
                </div>
                <Input
                  disabled={isSubmitting}
                  type="text"
                  {...register("name", { required: "This field is required." })}
                  id="name"
                />
                {errors.name && <span className="text-red-500">{errors.name.message}</span>}
              </div>

              <div className="mb-2">
                <div className="flex gap-4 items-center">
                  <label className="text-lg font-semibold" htmlFor="category">
                    Category
                  </label>
                  <div className="flex" title="Category of the product">
                    <FontAwesomeIcon icon={faCircleInfo} color="#4C4C4C" />
                  </div>
                </div>
                {isLoadingCategories ? (
                  <p>Loading categories list...</p>
                ) : (
                  Array.isArray(categoriesList) && (
                    <Select
                      disabled={isSubmitting}
                      id="category"
                      options={categoriesOptions}
                      {...register("category", { required: "This field is required." })}
                    />
                  )
                )}
                {errors.category && <span className="text-red-500">{errors.category.message}</span>}
              </div>

              <div className="mb-2">
                <div className="flex gap-4 items-center">
                  <label className="text-lg font-semibold" htmlFor="amount">
                    Cost
                  </label>
                  <div className="flex" title="Cost">
                    <FontAwesomeIcon icon={faCircleInfo} color="#4C4C4C" />
                  </div>
                </div>
                <Input
                  disabled={isSubmitting}
                  type="number"
                  {...register("amount", { required: "This field is required." })}
                  id="amount"
                  step="0.1"
                />
                {errors.amount && <span className="text-red-500">{errors.amount.message}</span>}
              </div>
            </div>
            <div className="col-span-3 md:col-span-3 px-4">
              <div className="mb-2">
                <label className="text-lg font-semibold" htmlFor="image">
                  Images <small>(Current images will be replaced)</small>
                </label>
                <Input
                  disabled={isSubmitting}
                  type="file"
                  multiple
                  {...register("image")}
                  id="image"
                />
                {errors.image && <span className="text-red-500">{errors.image.message}</span>}
              </div>
            </div>
            <div className="col-span-3 md:col-span-3 px-4 flex justify-end gap-x-2 mt-4">
              <div className="my-4">
                <Button
                  disabled={isSubmitting}
                  label={isSubmitting ? "Updating expense..." : "Done"}
                  className="border-0 bg-[#BCE210] text-[#051F40] font-semibold px-4 py-2"
                />
              </div>
            </div>
            {isSubmitting && (
              <div className="w-full col-span-3 md:col-span-3">
                <div className="h-1.5 w-full overflow-hidden">
                  <div className="animate-progress w-full h-full bg-[#BCE210] origin-left-right"></div>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </AdminLayout>
  );
};
