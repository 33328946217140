import React, { Component } from "react";
import { EditorState, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { stateFromHTML } from "draft-js-import-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

class CustomEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(), // Inicialmente vacío
    };
  }

  componentDidMount() {
    if (this.props.initialHtml) {
      const contentState = stateFromHTML(this.props.initialHtml);
      const editorState = EditorState.createWithContent(contentState);
      this.setState({ editorState });
    }
  }

  componentDidUpdate(prevProps) {
    // Solo actualizar si el HTML inicial cambia y no si el editor ya ha sido modificado
    if (prevProps.initialHtml !== this.props.initialHtml && this.props.initialHtml) {
      const contentState = stateFromHTML(this.props.initialHtml);
      const editorState = EditorState.createWithContent(contentState);
      this.setState({ editorState });
    }
  }

  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
    this.props.onChange(editorState); // Notifica a react-hook-form
  };

  render() {
    return (
      <div className="p-4 border border-gray-300 rounded-md">
        <Editor
          editorState={this.state.editorState}
          onEditorStateChange={this.onEditorStateChange}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
        />
      </div>
    );
  }
}

export default CustomEditor;
