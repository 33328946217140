import { onReadContract } from "@common/services/onReadContract";
import { useCallback, useEffect, useState } from "react";
import { useAccount } from "wagmi";
import ABI from "../../../common/contracts/FortuneGoat.json";
import { parseTreeFromContractResponse } from "../utils/orgChart";
import { SentryService } from "common/services/SentryErrorCapture";

export const useTreeChartData = ({ startingNode, treeType, showEmpty = true, startDepth = 0 }) => {
  const { isConnected, address } = useAccount();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [TreeData, setTreeData] = useState([]);
  const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;

  const readBinaryTree = useCallback(
    async ({ startingNode, startDepth = 0 }) => {
      try {
        if (!isConnected) {
          throw new Error("User is not connected");
        }
        if (!address) {
          throw new Error("No address found");
        }
        const binaryTreeUsersResponse = await onReadContract({
          contractAddress,
          contractABI: ABI,
          functionName: "getBinaryTreeUsers",
          args: [startingNode || address, startDepth || 0],
          // @ts-expect-error something is wrong with the types
          // from: address,
        });
        const binaryTreeUsersData = parseTreeFromContractResponse(
          binaryTreeUsersResponse,
          "binary",
          showEmpty
        );
        return binaryTreeUsersData;
      } catch (error) {
        SentryService("useTreeChartData","readBinaryTree",error)
        // console.log(error);
        return [];
      }
    },
    [address, contractAddress, isConnected, showEmpty]
  );

  const readUnilevelTree = useCallback(
    async ({ startingNode, startDepth = 0 }) => {
      try {
        if (!isConnected) {
          throw new Error("User is not connected");
        }
        if (!address) {
          throw new Error("No address found");
        }
        const unilevelTreeDataResponse = await onReadContract({
          contractAddress,
          contractABI: ABI,
          functionName: "getUnilevelTreeUsers",
          args: [startingNode || address, startDepth || 0],
          // @ts-expect-error something is wrong with the types
          from: address,
        });
        const unilevelTreeData = parseTreeFromContractResponse(
          unilevelTreeDataResponse,
          "unilevel",
          showEmpty
        );
        return unilevelTreeData;
      } catch (error) {
        SentryService("useTreeChartData","readUnilevelTree",error)
        // console.log(error);
        return [];
      }
    },
    [address, contractAddress, isConnected, showEmpty]
  );

  const getBinaryTreeChartData = useCallback(
    async ({ startingNode, startDepth }) => {
      try {
        setLoading(true);
        const binaryTreeUsersData = await readBinaryTree({ startingNode, startDepth });
        setTreeData(binaryTreeUsersData);
      } catch (error) {
        SentryService("useTreeChartData","getBinaryTreeChartData",error)
        // console.log({ error });
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [readBinaryTree]
  );

  const getUnilevelTreeChartData = useCallback(
    async ({ startingNode, startDepth }) => {
      try {
        setLoading(true);
        const unilevelTreeData = await readUnilevelTree({ startingNode, startDepth });
        setTreeData(unilevelTreeData);
      } catch (error) {
        SentryService("useTreeChartData","getUnilevelTreeChartData",error)
        // console.log({ error });
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [readUnilevelTree]
  );
  useEffect(() => {
    if (treeType == "binary") {
      getBinaryTreeChartData({ startingNode, startDepth });
    } else {
      getUnilevelTreeChartData({ startingNode, startDepth });
    }
  }, [getBinaryTreeChartData, treeType, getUnilevelTreeChartData, startingNode, startDepth]);

  const mutateAsync = async () => {
    if (treeType == "binary") {
      await getBinaryTreeChartData();
    } else {
      await getUnilevelTreeChartData();
    }
  };
  return { TreeData, loading, error, mutateAsync, readBinaryTree, readUnilevelTree };
};
