export const ModalComponent = ({ open, setOpen, children }) => {
  const handleClose = () => setOpen(false);
  return (
    open && (
      <div
        className="absolute w-full h-full backdrop-blur grid grid-cols-2 place-items-center z-1"
        // onClick={handleClose}
      >
        <div className="bg-white rounded-sm shadow-black md:w-1/3 col-span-2 grid grid-cols-2 z-50">
          <div className="col-span-2">{children}</div>
        </div>
      </div>
    )
  );
};
