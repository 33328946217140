import { onReadContract } from "../../../onReadContract";

export const binaryFunctions = ({ binaryContract, binaryABI }) => {
  const readBinary = async ({ functionName, args }) => {
    return await onReadContract({
      contractAddress: binaryContract,
      contractABI: binaryABI,
      functionName,
      args,
    });
  };
  const binaryVolume = async (address, branch) => {
    const args = [address, branch, 0];
    const binaryVolumeResponse = await readBinary({
      functionName: "getVolumeUnderBranch",
      args,
    });

    console.debug("binaryVolumeResponse: ", binaryVolumeResponse);
    if (binaryVolumeResponse) {
      return Number(binaryVolumeResponse);
    } else {
      return 0;
    }
  };
  const binaryBCV = async (address, branch) => {
    const args = [address, branch, 1];
    const binaryVolumeResponse = await readBinary({
      functionName: "getVolumeUnderBranch",
      args,
    });

    console.debug("binaryVolumeResponse: ", binaryVolumeResponse);
    if (binaryVolumeResponse) {
      return Number(binaryVolumeResponse);
    } else {
      return 0;
    }
  };
  const binaryUsersSponsoredBy = async (address) => {
    const args = [address];
    const UsersSponsoredBy = await readBinary({
      functionName: "getUsersSponsoredBy",
      args,
    });

    console.debug("binaryUsersSponsoredBy: ", UsersSponsoredBy);
    if (UsersSponsoredBy) {
      return UsersSponsoredBy.map((value) => {
        return {
          id: value.id,
          parentId: value.parentId,
          leftChild: value.leftChild,
          rightChild: value.rightChild,
          sponsorId: value.sponsorId,
          userName: value.userName,
          sponsorUsername: value.sponsorUsername,
          sponsorBranch: Number(value.sponsorBranch),
          userSelectedBranch: Number(value.userSelectedBranch),
          lastBranchUsed: Number(value.lastBranchUsed),
        };
      });
    }
    return [];
  };
  return { binaryVolume, binaryBCV, binaryUsersSponsoredBy };
};
