import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    petDataIpfsUrl: "",
};

const updatePetSlice = createSlice({
    name: "updatePet",
    initialState,
    reducers: {
        setUpdatePetData(state, action) {
            state.petDataIpfsUrl = action.payload.petDataIpfsUrl;
        },
        clearUpdatePetData(state) {
            state.petDataIpfsUrl = initialState.petDataIpfsUrl;
        },
    },
});

export const { setUpdatePetData, clearUpdatePetData } = updatePetSlice.actions;
export default updatePetSlice.reducer;
