import { onGetWinnersList } from "modules/penny-auction/services/onGetWinnersList";
import { useState } from "react";
import { OurWinnersCard } from "../winnersCard/OurWinnersCard";
import { OurWinnersCardSkeleton } from "../winnersCard/WinnersCardSkeleton";
import { useQuery } from "@tanstack/react-query";
import { Pagination } from "./pagination";

const LIMIT = 6;

export const WinnersList = () => {
  const [page, setPage] = useState(1);
  const {
    isFetching: loading,
    data: winnersListData,
    error: error,
  } = useQuery({
    queryKey: [`winnersList_${page}`],
    queryFn: async () => await onGetWinnersList({ page, limit: LIMIT }),
    staleTime: 360000,
    refetchOnWindowFocus: false,
  });

  const winnersList = loading || error ? [] : winnersListData?.winners;
  const handlePageChange = (page) => {
    setPage(page);
  };
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 w-full p-2 lg:p-4 mt-10">
      {loading ? (
        (Array(LIMIT).fill(0)).map((i, index) => <OurWinnersCardSkeleton key={`our_winners_skeleton_${index}`} />)
      ) : error ? (
        <h2>{"Error loading winners list"}</h2>
      ) : (
        <>
          {winnersList?.map((winner) => (
            <OurWinnersCard
              key={`our_winners_${winner.item.id}`}
              productName={winner.item.info.name}
              productDescription={winner.item.info.description}
              winner={winner.user.username || winner.user.id}
              country={winner.user.country}
              finalBid={winner.item.finalPrice}
              totalCost={winner.item.autobuyPrice}
              bidsAmount={winner.item.totalBids}
              auctionEnd={winner.item.endDate}
              image={winner.item.info.images[0]}
            />
          ))}
        </>
      )}
      {winnersListData?.pagination && <div className="col-span-1 lg:col-span-2 my-4 text-2xl flex justify-center w-full">
        <Pagination
          pagination={winnersListData.pagination}
          onPreviousClick={() => {
            handlePageChange(page - 1);
          }}
          onNextClick={() => {
            handlePageChange(page + 1);
          }}
        />
      </div>}
    </div>
  );
};
