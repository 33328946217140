import cs from "classnames";

export const Button = ({
    label,
    className,
    disabled,
    icon,
    ...rest
}) => {
    return (
        <button
            className={cs(
                "border border-dark text-dark font-bold py-3 px-8 rounded",
                {
                    "border-gray-400 text-gray-400 cursor-default": disabled,
                },
                className
            )}
            disabled={disabled}
            {...rest}
        >
            {icon && <img src={icon}/>}
            {label}
        </button>
    );
};
