import { onReadContract } from "../../../onReadContract";

export const packagesFunctions = ({ packagesContract, packagesABI }) => {
  const packagesListCache = [];
  const readPackages = async ({ functionName, args }) => {
    return await onReadContract({
      contractAddress: packagesContract,
      contractABI: packagesABI,
      functionName,
      args,
    });
  };

  const packagesList = async () => {
    if (packagesListCache && packagesListCache.length > 0) {
      return packagesListCache;
    }
    const args = [0];
    const packagesListResponse = await readPackages({
      functionName: "getPackages",
      args,
    });
    console.debug("packagesListResponse: ", packagesListResponse);
    if (packagesListResponse) {
      const packagesList = packagesListResponse[0].map((currentPackage, index) => {
        return {
          fullPackage: {
            id: Number(currentPackage.fullPackage.id),
            price: Number(currentPackage.fullPackage.price),
            vat: Number(currentPackage.fullPackage.vat),
            bidConsumable: Number(currentPackage.fullPackage.bidConsumable),
            turboFund: Number(currentPackage.fullPackage.turboFund),
            qv: Number(currentPackage.fullPackage.qv),
            ucv: Number(currentPackage.fullPackage.ucv),
            bcv: Number(currentPackage.fullPackage.bcv),
            name: currentPackage.fullPackage.name,
            consumablePercent: Number(currentPackage.fullPackage.consumablePercent),
            isUpgradePackage: currentPackage.fullPackage.isUpgradePackage,
          },
          fullPackageBinary: {
            packageId: Number(currentPackage.fullPackageBinary.packageId),
            binaryPercent: Number(currentPackage.fullPackageBinary.binaryPercent),
            binaryCap: Number(currentPackage.fullPackageBinary.binaryCap),
          },
          fullPackageBid: {
            packageId: Number(currentPackage.fullPackageBid.packageId),
            bidPrice: Number(currentPackage.fullPackageBid.bidPrice),
            totalBids: Number(currentPackage.fullPackageBid.totalBids),
          },
          fullPackageExpirations: {
            packageId: Number(currentPackage.fullPackageExpirations.packageId),
            bidExpirationDays: Number(currentPackage.fullPackageExpirations.bidExpirationDays),
            qualificationExpirationDays: Number(currentPackage.fullPackageExpirations.qualificationExpirationDays),
            cashbackExpirationDays: Number(currentPackage.fullPackageExpirations.cashbackExpirationDays),
          },
          fullPackageBonus: {
            packageId: Number(currentPackage.fullPackageBonus.packageId),
            startupBonus: Number(currentPackage.fullPackageBonus.startupBonus),
            matchingBonus: Number(currentPackage.fullPackageBonus.matchingBonus),
            unilevelBonus: Number(currentPackage.fullPackageBonus.unilevelBonus),
          },
          fullPackageCashback: {
            packageId: Number(currentPackage.fullPackageCashback.packageId),
            cashback: Number(currentPackage.fullPackageCashback.cashback),
          },
          status: packagesListResponse[1][index],
          globalPoolAndTokens: {
            packageId: Number(packagesListResponse[2][index].packageId),
            globalPoolPercentage: Number(packagesListResponse[2][index].globalPoolPercentage),
            tokensPercentage: Number(packagesListResponse[2][index].tokensPercentage),
            globalPoolExpirationDays: Number(packagesListResponse[2][index].globalPoolExpirationDays),
            tokensPercentageExpirationDays: Number(packagesListResponse[2][index].tokensPercentageExpirationDays),
          },
          info: packagesListResponse[3][index],
        };
      });
      console.debug("packagesList: ", packagesListResponse);
      packagesListCache.push(...packagesList);
      return packagesList;
    }
    return [];
  };

  return { packagesList };
};
