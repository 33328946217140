import { getMaxLevel as defaultGetMaxLevel } from "./getMaxLevel";
import { getUserInfo as defaultGetUserInfo } from "./getUserInfo";
import { getUserRank as defaultGetUserRank } from "./getUserRank";
import { getUsersTotalPaid as defaultGetUsersTotalPaid } from "./getUsersTotalPaid";
import { onGetRank } from "./onGetRank";

export const getRank = ({
  contractFunctions,
  getUserInfo = defaultGetUserInfo,
  getMaxLevel = defaultGetMaxLevel,
  getUsersTotalPaid = defaultGetUsersTotalPaid,
  getUserRank = defaultGetUserRank,
  userRanksCache,
}) => {
  return async ({ address }) =>
    onGetRank({
      address,
      getUserInfo,
      getMaxLevel,
      getUsersTotalPaid,
      getUserRank,
      contractFunctions,
      userRanksCache,
    });
};
