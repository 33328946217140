import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAccount, useDisconnect } from "wagmi";

export function Logout() {
  const navigate = useNavigate();
  const { isConnected } = useAccount();
  const { status, disconnect } = useDisconnect({});

  useEffect(() => {
    if (isConnected) {
      disconnect();
    } else {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/");
    }
  }, [disconnect, status, navigate, isConnected]);

  return <></>;
}
