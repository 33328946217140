import { readContract } from "@wagmi/core";
import { SentryService } from "./SentryErrorCapture";
import { wagmiConfig } from "App";

export const onReadContract = async ({
    contractAddress,
    contractABI,
    functionName,
    args,
    from,
    value,
    gas,
    chainId,
}) => {
    try {
        console.log("--onReadContract--", {
            contractAddress,
            contractABI,
            functionName,
            args,
            from,
            value,
            gas,
            chainId,
        });
        const data = await readContract(wagmiConfig,{
            // @ts-expect-error something is wrong with the types
            address: contractAddress,
            abi: contractABI,
            functionName,
            args,
            // ...(chainId ? { chainId } : {}),
            // ...(from ? { account: from } : {}),
            // ...(value ? { value } : {}),
            // ...(gas ? { gas } : {}),
        });
        console.log("--onReadContract response--", { data });
        return data;
    } catch (error) {
        SentryService("onReadContract", `${contractAddress}, ${functionName}`, error.message)
        console.error({ errorOnReadContract: error });
        throw new Error(error);
    }
};
