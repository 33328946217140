import axios from "axios";

const API = process.env.REACT_APP_AWS_API_URL;

export const useRolesService = () => {
  return {
    async fetchRoles() {
      const response = await axios.get(`${API}/contracts/auth/role`);
      return response.data;
    },
  };
};
