import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useWaitForTransactionReceipt } from "wagmi";
import { useRequestSignature } from "./useRequestSignature";
import { onCreateBid } from "common/services/onCreateBid";

export const useCreateProduct = () => {
  const requestSignature = useRequestSignature();
  const [isEnabled, setIsEnabled] = useState(false);
  const [productData, setProductData] = useState({});
  const [signature, setSignature] = useState("");

  const { isError, isSuccess, isFetching, status, data, error } = useQuery({
    queryKey: [`createProduct`],
    retry: 1,
    retryDelay: 3000,
    refetchOnWindowFocus: false,
    enabled: isEnabled,
    queryFn: () =>
      onCreateBid({
        signature,
        data: { ...productData },
      }),
  });
  const {
    isError: isErrorTx,
    isSuccess: isSuccessTx,
    data: dataTx,
    isFetching: isFetchingTx,
    error: errorTx,
  } = useWaitForTransactionReceipt({
    queryKey: [`createProduct${data ? data.data : ""}`],
    hash: data ? data.data : "",
    retry: 3,
    retryDelay: 3000,
    refetchOnWindowFocus: false,
    enabled: isEnabled,
    confirmations: 2,
  });

  useEffect(() => {
    const onResult = async () => {
      if (!isFetching && !isFetchingTx && productData) {
        if (isError) {
          if (productData.onError) await productData.onError(data ? data.error : error);
          if (productData.onSettled) await productData.onSettled();
          setIsEnabled(false);
        } else if (isSuccess && data) {
          if (isErrorTx) {
            if (productData.onError) await productData.onError(dataTx ? dataTx.error : errorTx);
            if (productData.onSettled) await productData.onSettled();
            setIsEnabled(false);
          } else if (isSuccessTx && dataTx) {
            if (productData.onSuccess) await productData.onSuccess();
            if (productData.onSettled) await productData.onSettled();
            setIsEnabled(false);
          }
        }
      }
    };
    if (!isFetching) onResult();
  }, [
    isError,
    isSuccess,
    isFetching,
    status,
    data,
    isFetchingTx,
    productData,
    error,
    isErrorTx,
    isSuccessTx,
    dataTx,
    errorTx,
  ]);

  const requestCreateProduct = async ({ productData }) => {
    if (!productData) throw new Error("missing payment request side data");
    const signature = await requestSignature();
    setSignature(signature);
    setProductData(productData);
    setIsEnabled(true);
  };

  return {
    requestCreateProduct,
    isFetchingTx,
    isError,
    error,
    data,
    isErrorTx,
    isSuccessTx,
    dataTx,
    isFetchingTx,
  };
};
