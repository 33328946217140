export const formatNumber = (input, stringStyle = "decimal", minFractionDigits = 0) => {
  // Convert the input to a number
  let number = Number(input);

  // Check if the number is valid (not NaN)
  if (isNaN(number)) {
    return 0;
  }

  // Format the number as USD
  return number.toLocaleString("en-US", {
    style: stringStyle,
    currency: "USD",
    minimumFractionDigits: minFractionDigits,
    maximumFractionDigits: 2,
  });
};
