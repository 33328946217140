import { useQuery } from "@tanstack/react-query";
import { onGetFeaturedWinners } from "modules/penny-auction/services/onGetFeaturedWinners";
import { OurWinnersCard } from "../winnersCard/OurWinnersCard";
import { OurWinnersCardSkeleton } from "../winnersCard/WinnersCardSkeleton";
import Carousel from "react-multi-carousel";
import { useRef, useState } from "react";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1280 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 1280, min: 1024 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 1024, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};
export const FeaturedWinners = () => {
  const fwCarouselRef = useRef(null);
  const [currentItem, setCurrentItem] = useState(0);

  const {
    isFetching: loading,
    data: featuredWinners,
    error: error,
  } = useQuery({
    queryKey: [`featuredWinnersTest`],
    queryFn: async () => await onGetFeaturedWinners(),
    staleTime: 360000,
    refetchOnWindowFocus: false,
  });
  
  return (
    <div className="w-full p-4 fwClass">
      <style>{`
        .fwClass .react-multi-carousel-list{display: flex; flex-direction: row; overflow: hidden;}
        .fwClass .react-multi-carousel-track{display: flex; flex-direction: row;}
        .fwClass .react-multi-carousel-track > li{
          padding: 0.5rem;
          display: grid;
      }  
      `}</style>
      <h2 className="text-3xl  w-full py-4">Featured Winners within last 24 hours!</h2>
      {loading ? (
        <div className="grid grid-cols-3 p-4 bg-[#87BAD7] gap-4">
          {[1, 2, 3].map((item, index) => (
            <OurWinnersCardSkeleton key={`our_winners_skeleton_${index}`} />
          ))}
        </div>
      ) : error ? (
        <h2>Error loading featured winners</h2>
      ) : (
        <div className="grid w-full h-full p-2 bg-[#87BAD7]">
          <div className="grid grid-cols-1 ">
            <div className="block col-span-1 min-h-full">
              <Carousel
                ref={fwCarouselRef}
                responsive={responsive}
                autoPlay={false}
                swipeable={true}
                draggable={true}
                showDots={false}
                infinite={false}
                partialVisible={false}
                carouselItem={"carouselItem"}
              >
                {featuredWinners.length > 0 ? (
                  featuredWinners.map((winner) => {
                    return (
                      <OurWinnersCard
                        key={`featured_winners_${winner.item.id}`}
                        productName={winner.item.info.name}
                        productDescription={winner.item.info.description}
                        winner={winner.user.username || winner.user.id}
                        country={winner.user.country}
                        finalBid={winner.item.finalPrice}
                        totalCost={winner.item.autobuyPrice}
                        bidsAmount={winner.item.totalBids}
                        image={winner.item.info.images[0]}
                      />
                    );
                  })
                ) : (
                  <div className="w-[15rem] text-center bg-white flex justify-center items-center p-10">
                    <h2>No new Featured winners yet</h2>
                  </div>
                )}
              </Carousel>
            </div>
          </div>
          <div className="flex justify-center p-2">
            {featuredWinners.map((i, index) => {
              return (
                <div
                  key={`dot_index_${index}`}
                  onClick={() => {
                    if (fwCarouselRef.current) {
                      fwCarouselRef.current.goToSlide(index);
                      setCurrentItem(index);
                    }
                  }}
                  className={`w-3 h-3 cursor-pointer rounded-full ${
                    index === currentItem ? "bg-[#1840CE]" : "bg-white"
                  } mx-1`}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
