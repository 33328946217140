import { Logo } from "@common/components";
import StarIcon from "@assets/star.svg";
import DashboardIcon from "@assets/icons/appBar/dashboard.svg";
import PeopleIcon from "@assets/icons/appBar/people.svg";
import MessagesIcon from "@assets/icons/appBar/messages.svg";
import MoneySendIcon from "@assets/icons/appBar/money-send.svg";
import CrownIcon from "@assets/icons/appBar/crown.svg";
import Receipt2Icon from "@assets/icons/appBar/receipt-2.svg";
import { useLocation, useNavigate } from "react-router-dom";
import DropdownComponent from "common/components/Dropdown";
import { useGetUserInfo } from "common/hooks/useGetUserInfo";
import { useEffect, useState } from "react";
import { web3AuthInstance } from "App";
import { ModalComponent } from "common/components/Modal";
import { Footer } from "common/components";

export const AppLayout = ({ children }) => {
  const navigate = useNavigate();
  const [active, setActive] = useState("");
  const location = useLocation();
  const { userInfo, loading } = useGetUserInfo();
  const options = [
    { title: "Dashboard", redirectionUrl: "/app/dashboard" },
    { title: "Store", redirectionUrl: "/app/store" },
    { title: "Profile", redirectionUrl: "/app/profile" },
    { title: "Logout", redirectionUrl: "/logout" },
  ];

  const [profileImg, setProfileImg] = useState("");

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const response = await web3AuthInstance.getUserInfo();
        if (response?.profileImage) {
          setProfileImg(response.profileImage);
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    if (web3AuthInstance.connected) getUserInfo();
  }, [web3AuthInstance.connected]);

  const handleActive = () => {
    switch (location.pathname) {
      case "/app/dashboard":
        setActive("home");
        break;
      case "/app/affiliates":
        setActive("affiliates");
        break;
      case "/app/store":
        setActive("store");
        break;
      case "/app/profile":
        setActive("profile");
        break;
      case "/app/turbo-fund":
        setActive("turbo-fund");
        break;
      case "/app/range":
        setActive("range");
        break;
      case "/app/commissions":
        setActive("commissions");
        break;
      default:
        setActive("");
        break;
    }
  };

  useEffect(() => {
    handleActive();
  }, [location.pathname]);

  return (
    <div className="grid grid-cols-12 !max-w-screen bg-[#E3E3D7]">
      <div className="col-span-12 bg-clair-blue flex justify-between items-center p-4 bg-[#F8F8F8]">
        <Logo variant="dark-letters" />
        <div className="flex items-center gap-x-5">
          <div className="flex items-center gap-x-2">
            {profileImg.length > 0 ? (
              <img
                className="h-12 w-12 cursor-pointer rounded-full object-cover hidden md:block"
                src={profileImg}
              />
            ) : (
              <div className="h-12 w-12 bg-clair-gray rounded-full"></div>
            )}
            <DropdownComponent title={userInfo && `${userInfo.info.username}`} items={options} />
          </div>
        </div>
      </div>
      <div className="col-span-12 md:col-span-2 bg-clair-blue flex flex-col">
        <div>
          {profileImg.length > 0 ? (
            <img className="w-full bg-clair-gray" src={profileImg.split("=")[0]} />
          ) : (
            <div className="w-full h-52 bg-clair-gray"></div>
          )}
          <div className="flex flex-col gap-y-3">
            <div className="flex items-center gap-x-2">
              <h3 className="text-lg font-semibold bg-[#87BAD7] w-full p-2">
                Rank:{" "}
                {`${userInfo && userInfo.package.packageId ? userInfo.package.packageId : 0}`}
              </h3>
              {userInfo && parseInt(userInfo.package.packageId) === 1 ? (
                <img src={StarIcon} />
              ) : userInfo && parseInt(userInfo.package.packageId) === 2 ? (
                <>
                  <img src={StarIcon} /> <img src={StarIcon} />
                </>
              ) : userInfo && parseInt(userInfo.package.packageId) === 3 ? (
                <>
                  <img src={StarIcon} /> <img src={StarIcon} /> <img src={StarIcon} />
                </>
              ) : userInfo && parseInt(userInfo.package.packageId) === 4 ? (
                <>
                  <img src={StarIcon} /> <img src={StarIcon} /> <img src={StarIcon} />{" "}
                  <img src={StarIcon} />
                </>
              ) : userInfo && parseInt(userInfo.package.packageId) === 5 ? (
                <>
                  <img src={StarIcon} /> <img src={StarIcon} /> <img src={StarIcon} />{" "}
                  <img src={StarIcon} /> <img src={StarIcon} />
                </>
              ) : userInfo && parseInt(userInfo.package.packageId) === 6 ? (
                <>
                  <img src={StarIcon} /> <img src={StarIcon} /> <img src={StarIcon} />{" "}
                  <img src={StarIcon} /> <img src={StarIcon} /> <img src={StarIcon} />
                </>
              ) : (
                <></>
              )}
            </div>
            {/* <div className="flex justify-between">
              <p className="font-medium">COMISIÓN:</p>
              <p>{"$5000"}</p>
            </div>
            <div className="flex justify-between">
              <p className="font-medium">PRODUCIENDO:</p>
              <p>{"$27000"}</p>
            </div> */}
            {/* <div className="flex-col">
                <h4>MONTO PARA SIGUIENTE NIVEL</h4>
                <div>
                  <svg width="242" height="18" viewBox="0 0 242 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="242" height="18" rx="9" fill="#F2F2F2"/>
                    <rect width="161" height="18" rx="9" fill="#DAF561"/>
                  </svg>
                  <div className="relative -top-4">
                    <p className="text-end font-semibold text-xs">{"$3000"}</p>
                    <p className="text-xs">{"Estarías ganando $7,000 en comisiones."}</p>
                  </div>
                </div>
              </div> */}
          </div>
        </div>
        <div className="flex flex-row md:flex-col w-full justify-between">
          <div
            className={`flex items-center cursor-pointer justify-center flex-col py-4 px-5 ${active === "home" && "bg-[#F8F8F8]"
              }`}
            onClick={() => navigate("/app/dashboard")}
          >
            <img src={DashboardIcon} />
            <p className="text-sm font-semibold">Dashboard</p>
          </div>
          <div
            className={`flex items-center cursor-pointer justify-center flex-col py-4 px-5 ${active === "affiliates" && "bg-[#F8F8F8]"
              }`}
            onClick={() => navigate("/app/affiliates")}
          >
            <img src={PeopleIcon} />
            <p className="text-sm font-semibold">Affiliates</p>
          </div>
          {/* <div
            className={`flex items-center cursor-pointer justify-center flex-col py-4 px-5 ${
              active === "support" && "bg-[#F8F8F8]"
            }`}
          >
            <img src={MessagesIcon} />
            <p className="text-sm font-semibold">Support</p>
          </div> */}
          <div
            className={`hidden items-center cursor-pointer justify-center flex-col py-4 px-5 ${active === "turbo-fund" && "bg-[#F8F8F8]"
              }`}
            onClick={() => navigate("/app/turbo-fund")}
          >
            <img src={MoneySendIcon} />
            <p className="text-sm font-semibold">Turbo fund</p>
          </div>
          <div
            className={`flex items-center cursor-pointer justify-center flex-col py-4 px-5 ${active === "range" && "bg-[#F8F8F8]"
              }`}
            onClick={() => navigate("/app/range")}
          >
            <img src={CrownIcon} />
            <p className="text-sm font-semibold">Rank</p>
          </div>
          <div
            className={`flex items-center cursor-pointer justify-center flex-col py-4 px-5 ${active === "commissions" && "bg-[#F8F8F8]"
              }`}
            onClick={() => navigate("/app/commissions")}
          >
            <img src={Receipt2Icon} />
            <p className="text-sm font-semibold">Comissions</p>
          </div>
          {/* <div className="flex items-center cursor-pointer" onClick={()=>navigate("/app/dashboard")}>
              <img className="pr-4" src={ShoppingIcon}/>
              <p className="text-sm font-semibold">COMPRAS</p>
            </div>
            <div className="flex items-center cursor-pointer" onClick={()=>navigate("/app/dashboard")}>
              <img className="pr-4" src={CommissionsIcon}/>
              <p className="text-sm font-semibold">COMISIONES</p> 
            </div>
            <div className="flex items-center cursor-pointer" onClick={()=>navigate("/app/dashboard")}>
              <img className="pr-4" src={RankingIcon}/>
              <p className="text-sm font-semibold">RANKING</p> 
            </div>*/}
        </div>
      </div>
      <div className="col-span-12 p-4 md:px-10 py-4 md:col-span-10">{children}</div>
      <div className="col-span-12 border mt-32">
        <Footer />
      </div>
    </div>
  );
};
