import { Select } from "common/components";
import { Button } from "common/components";
import { Input } from "common/components";
import { TextArea } from "common/components/TextArea";
import { dateToTimestamp } from "common/helpers/dateToTimestamp";
import { useCreatePackage } from "common/hooks";
import AdminLayout from "layouts/AdminLayout";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAccount } from "wagmi";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { useCreateOrganizationUser } from "common/hooks/useCreateOrganizationUser";

export const CreateOrganizationUserPage = () => {
  const navigate = useNavigate();
  const { address } = useAccount();
  const { requestCreateOrganizationUser } = useCreateOrganizationUser();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    setIsSubmitting(true);

    let parsedInputs = {
      userId: data.address || "",
      name: data.name || "",
      lastName: data.lastName || "",
      username: data.username || "",
      email: data.email || "",
      country: data.country || "",
      zip: data.zipCode || "",
      phone: data.phoneNumber || "",
    };

    console.log({ parsedInputs });

    await requestCreateOrganizationUser({
      orgUserData: {
        ...parsedInputs,
        onSuccess: async () => {
          setIsSubmitting(false);
          toast.success("Organization user created successfully.");
          navigate("/admin/organization-users");
        },
        onError: async () => {
          setIsSubmitting(false);
          toast.error("Failed to create organization user.");
        },
      },
    });
  };

  return (
    <AdminLayout>
      <div className="w-full bg-white">
        <div className="w-full px-8">
          <h1 className="text-2xl px-4 pt-8">New Organization User</h1>
          <form className="grid grid-cols-3 mt-4" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-span-3 md:col-span-2 px-4">
              <div className="mb-2">
                <label className="text-lg font-semibold" htmlFor="address">
                  User Address
                </label>
                <Input
                  type="text"
                  {...register("address", { required: "This field is required." })}
                  id="address"
                />
                {errors.address && <span className="text-red-500">{errors.address.message}</span>}
              </div>
              <div className="mb-2">
                <label className="text-lg font-semibold" htmlFor="address">
                  Name
                </label>
                <Input
                  type="text"
                  {...register("name", { required: "This field is required." })}
                  id="name"
                />
                {errors.name && <span className="text-red-500">{errors.name.message}</span>}
              </div>
              <div className="mb-2">
                <label className="text-lg font-semibold" htmlFor="name">
                  Last Name
                </label>
                <Input
                  type="text"
                  {...register("lastName", { required: "This field is required." })}
                  id="lastName"
                />
                {errors.lastName && <span className="text-red-500">{errors.lastName.message}</span>}
              </div>
              <div className="mb-2">
                <label className="text-lg font-semibold" htmlFor="username">
                  Username
                </label>
                <Input
                  type="text"
                  {...register("username", { required: "This field is required." })}
                  id="username"
                />
                {errors.username && <span className="text-red-500">{errors.username.message}</span>}
              </div>
              <div className="mb-2">
                <label className="text-lg font-semibold" htmlFor="email">
                  Email
                </label>
                <Input
                  type="email"
                  {...register("email", { required: "This field is required." })}
                  id="email"
                />
                {errors.email && <span className="text-red-500">{errors.email.message}</span>}
              </div>
              <div className="mb-2">
                <label className="text-lg font-semibold" htmlFor="country">
                  Country
                </label>
                <Select id="country" {...register("country")} options={["México", "Costa Rica"]} />
                {errors.country && <span className="text-red-500">{errors.country.message}</span>}
              </div>
              <div className="mb-2">
                <label className="text-lg font-semibold" htmlFor="price">
                  Zip Code
                </label>
                <Input
                  type="number"
                  {...register("zipCode", { required: "This field is required." })}
                  id="zipCode"
                />
                {errors.zipCode && <span className="text-red-500">{errors.zipCode.message}</span>}
              </div>
              <div className="mb-2">
                <label className="text-lg font-semibold" htmlFor="phoneNumber">
                  Phone Number
                </label>
                {/*  <Input
                  type="number"
                  {...register("phoneNumber", { required: "This field is required." })}
                  id="phoneNumber"
                />
                {errors.phoneNumber && (
                  <span className="text-red-500">{errors.phoneNumber.message}</span>
                )} */}
                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{
                    validate: (value) => {
                      if (value === "") {
                        return true;
                      }
                      return (
                        isValidPhoneNumber(String(value)) ||
                        !value ||
                        value?.lenght < 0 ||
                        "Teléfono no válido"
                      );
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      value={value || ""}
                      onChange={onChange}
                      placeholder="Introduce el teléfono"
                      defaultCountry="MX"
                      limitMaxLength
                    />
                  )}
                />
                {errors.phoneNumber && (
                  <span className="text-red-500">{errors.phoneNumber.message}</span>
                )}{" "}
              </div>
            </div>
            <div className="col-span-3 md:col-span-2 px-4">
              <div className="my-4">
                <Button
                  className="mr-2"
                  label="Back"
                  type="button"
                  onClick={() => window.history.back()}
                />
                <Button
                  disabled={isSubmitting}
                  label={isSubmitting ? "Creating user..." : "Create new organization user"}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </AdminLayout>
  );
};
