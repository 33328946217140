import { useNavigate } from "react-router-dom";
import Countdown from "react-countdown";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const ProductCard2 = ({
  favButtonHandler,
  cardInfo,
  autoBuy,
  addBid,
  followed,
  address,
  auctionEnd,
  serverTime,
}) => {
  const navigate = useNavigate();
  const pinataGatewayKey = `?pinataGatewayToken=${process.env.REACT_APP_PINATA_GATEWAY_KEY}`;
  // console.debug({serverTime})
  const [currentDate, setCurrentDate] = useState(new Date().getTime());
  const [bidding, setBidding] = useState(false);
  const resetBidding = useRef(null);
  const [changingStatus, setChangingStatus] = useState(false);

  useEffect(() => {
    if (cardInfo && cardInfo.status == 1) {
      setBidding(false);
      clearTimeout(resetBidding.current);
      // console.debug("cardinfo change");
    }
  }, [cardInfo]);

  const changeState = useCallback(() => {
    // This line won't result in calling your useEffect
    // setAuctionStatus("live") // 'hello' will be printed one time only.

    // You need to use a state value that won't be similar to the previous one.
    setCurrentDate(new Date().getTime());
    setChangingStatus(false);
    // console.debug("changeState called");
  }, [setCurrentDate, setChangingStatus]);

  useEffect(() => {
    if (changingStatus) {
      changeState();
    }
  }, [changingStatus, changeState]);

  const getSign = useCallback(() => {
    if (!cardInfo) return "";
    if (cardInfo.status == 2 || (cardInfo.status == 1 && cardInfo.endDate < currentDate))
      return "Auction ended";
    if (cardInfo.status == 3) return "sold";
    if (
      cardInfo.status == 1 &&
      +cardInfo.endDate + (serverTime?.offset ?? 0) > currentDate &&
      cardInfo.currentPrice >= cardInfo.maxBidders
    )
      return "no new biders";

    // console.debug({
    //   status: cardInfo.status,
    //   currentDate,
    //   endDatePlusOffset: +cardInfo.endDate + (serverTime?.offset ?? 0),
    //   offset: serverTime?.offset,
    //   maxBidders: cardInfo.maxBidders,
    // });
  }, [cardInfo, currentDate, serverTime]);

  const getCanBid = useCallback(() => {
    if (!cardInfo || !address) return false;
    let canBid = true;
    if (bidding) return false;
    if (address && cardInfo?.lastBid?.bidder.toLowerCase() === address.toLowerCase()) {
      return false;
    }
    if (
      cardInfo.bidders &&
      cardInfo.bidders.length > 0 &&
      cardInfo.status == 1 &&
      cardInfo.endDate > currentDate &&
      cardInfo.currentPrice >= cardInfo.maxBidders
    ) {
      const inBidders = cardInfo.bidders.filter(
        (bidder) => address && bidder.toLowerCase() === address.toLowerCase()
      );
      if (inBidders.length == 0) {
        canBid = false;
      }
    }
    return canBid;
  }, [cardInfo, address, currentDate, bidding]);

  const extraCardInfo = useMemo(
    () => ({
      bidNow:
        cardInfo.status == 1 &&
        cardInfo.startDate <= currentDate &&
        currentDate <= cardInfo.endDate,
      sold: cardInfo.status == 3,
      startingSoon: cardInfo.status == 1 && cardInfo.startDate > currentDate,
      ended: cardInfo.status == 2 || (cardInfo.status == 1 && cardInfo.endDate < currentDate),
      buyNow: cardInfo.status > 0,
      isFav: false,
      sign: getSign(),
      canBid: getCanBid(),
    }),
    [cardInfo, currentDate, getCanBid, getSign]
  );
  // console.debug({ extraCardInfo });

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (!completed) {
      // Render a countdown
      const totalHours = days * 24 + hours;
      return (
        <div className="inline-flex gap-1">
          <span>
            {totalHours < 10 ? `0${totalHours}` : totalHours}:
            {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </span>
          <div className="relative justify-center align-center rounded-full min-w-[1rem] w-[1rem] min-h-[1rem] h-[1rem] bg-[#1840ce] text-[#ffffff] font-bold">
            <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              !
            </span>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="bg-white relative flex flex-col items-around text-xs w-full overflow-hidden gap-1 cursor-pointer">
      <div className="w-full">
        <div className="w-full">
          {null && extraCardInfo.buyNow && (
            <div
              className="bg-baby-blue w-full text-center text-lg sm:text-base xxl:text-lg px-1 py-[0.35rem]"
              onClick={() => {
                autoBuy(cardInfo.id);
              }}
            >
              Buy now for ${cardInfo ? cardInfo.autoBuyPrice / 10 ** 6 : `0.00`} USDT
            </div>
          )}
        </div>
        <div
          style={{
            height: "200px",
            position: "relative",
          }}
          onClick={() => (cardInfo ? navigate(`/app/store/product/${cardInfo.id}`) : undefined)}
        >
          <div
            style={{
              width: "100%",
              background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%)",
              position: "absolute",
              height: "100%",
              bottom: 0,
            }}
          >
            <div className="w-full flex justify-center">
              {extraCardInfo.sign !== undefined && (
                <div
                  className={`absolute h-6 flex items-center justify-center ${
                    extraCardInfo.sign === "sold"
                      ? "bg-brick-red"
                      : extraCardInfo.sign === "no new biders"
                      ? "bg-[#1840ce]"
                      : "bg-baby-blue"
                  } font-bold px-4 py-5 top-1/3 text-white`}
                >
                  {extraCardInfo.sign.toUpperCase()}
                </div>
              )}
            </div>
            <div
              style={{
                bottom: 0,
                position: "absolute",
                display: "flex",
                width: "100%",
              }}
              className="text-white pl-2 font-semibold text-base text-center py-2 capitalize"
            >
              {cardInfo?.info?.name || "LOREM IPSUM"}
            </div>
          </div>
          {
            cardInfo?.info?.images[0] && (
              <LazyLoadImage
                src={
                  cardInfo?.info?.images
                  ? `${cardInfo.info.images[0]}${pinataGatewayKey}`
                  : "https://via.placeholder.com/150"
                }
                className="w-full"
                alt="product"
              />
            )
          }
          {/* <img
            src={
              cardInfo?.info?.images
                ? `${cardInfo.info.images[0]}${pinataGatewayKey}`
                : "https://via.placeholder.com/150"
            }
            className="w-full"
            alt="product"
          /> */}
        </div>
      </div>
      <div className="w-full flex flex-col justify-between bg-white z-10 min-h-[160px]">
        <div
          className="flex flex-col justify-between flex-1"
          onClick={() => (cardInfo ? navigate(`/app/store/product/${cardInfo.id}`) : undefined)}
        >
          <p className="text-2xl pl-2 tracking-wide flex gap-2">
            Price:
            <span
              className={
                cardInfo && cardInfo.discount > 0 && cardInfo.currentPrice > 0
                  ? "line-through text-[#666666]"
                  : ""
              }
            >
              ${cardInfo?.currentPrice / 10 ** 6}
            </span>
            {cardInfo && cardInfo.discount && cardInfo.discount > 0 ? (
              <>
                {cardInfo.currentPrice > 0 && cardInfo.discount > 0 && (
                  <span>
                    $
                    {(cardInfo.currentPrice - (cardInfo.currentPrice * cardInfo.discount) / 1e2) /
                      10 ** 6}
                  </span>
                )}
                <div className="mt-1 text-[#ff2222] text-xs font-bold">
                  <span>{cardInfo.discount}%</span>
                  <span>OFF</span>
                </div>
              </>
            ) : (
              <></>
            )}
          </p>

          <div>
            {(extraCardInfo.startingSoon || extraCardInfo.bidNow) && (
              <p className="text-xs text-ellipsis flex pl-2 gap-2">
                {extraCardInfo.startingSoon ? `Starting Soon: ` : "Time left: "}
                {extraCardInfo.startingSoon &&
                cardInfo.startDate - currentDate > 1000 * 60 * 60 * 24 * 7 * 1 ? (
                  new Date(cardInfo.startDate + (serverTime?.offset ?? 0)).toLocaleString()
                ) : (
                  <Countdown
                    key={`${extraCardInfo.startingSoon}_${cardInfo.endDate}_${serverTime?.offset}`}
                    date={
                      extraCardInfo.startingSoon
                        ? +cardInfo.startDate + (serverTime?.offset ?? 0)
                        : +cardInfo.endDate + (serverTime?.offset ?? 0)
                    }
                    renderer={renderer}
                    precision={50}
                    onComplete={() => {
                      if (
                        cardInfo?.lastBid?.bidder &&
                        address &&
                        address.toLowerCase() == cardInfo.lastBid.bidder.toLowerCase() &&
                        currentDate > +cardInfo.endDate + (serverTime?.offset ?? 0)
                      ) {
                        if (extraCardInfo.bidNow && cardInfo.status == 1) {
                          setTimeout(() => {
                            auctionEnd(cardInfo.id);
                          }, 500);
                        }
                      }

                      setChangingStatus(true);
                    }}
                  />
                )}
              </p>
            )}

            <p className="text-xs text-ellipsis flex pl-2">
              {cardInfo.status === 2 && cardInfo.biddersCount
                ? `Congratulations:`
                : `Current winner:`}
              {cardInfo?.lastBid?.username
                ? ` ${cardInfo?.lastBid?.username}`
                : cardInfo?.lastBid?.bidder
                ? ` ${cardInfo?.lastBid?.bidder.slice(0, 8)}`
                : ""}
            </p>
          </div>
        </div>
        {extraCardInfo?.bidNow ? (
          <button
            className={`py-2 text-center text-lg m-2 font-bold ${
              extraCardInfo?.canBid ? `bg-highlight-secondary` : `bg-[#CCCCCC] text-[#4C4C4C]`
            } `}
            onClick={() => {
              addBid(cardInfo.id);
              setBidding(true);
              resetBidding.current = setTimeout(() => {
                setBidding(false);
              }, 5000);
            }}
            disabled={bidding || !extraCardInfo.canBid}
          >
            {bidding ? "Bidding..." : "Bid Now"}
          </button>
        ) : extraCardInfo.startingSoon ? (
          <div
            className="py-2 text-center text-lg m-2 bg-custom-yellow font-bold "
            onClick={() => {}}
          >
            Starting soon
          </div>
        ) : extraCardInfo.sold ? (
          <div
            className="py-2 text-center text-lg m-2 bg-clair-gray font-bold text-[#4C4C4C]"
            onClick={() => {}}
          >
            SOLD
          </div>
        ) : (
          extraCardInfo.buyNow && (
            <div
              className="py-2 text-center text-lg m-2 bg-[#CCCCCC] font-bold text-[#4C4C4C]"
              onClick={() => {}}
            >
              Bid Now
            </div>
          )
        )}
        {/* {cardInfo.startingSoon && (
          <div className="bg-custom-yellow w-full text-center text-sm font-bold py-2">
            STARTING SOON
          </div>
        )} */}
        {/* {cardInfo.sold && (
          <div className=" w-full text-center text-sm font-bold py-2">SOLD</div>
        )} */}
        {}
      </div>
    </div>
  );
};
