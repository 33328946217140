import axios from "axios";
import { SentryService } from "common/services/SentryErrorCapture";

export const onGetCategoriesList = async () => {
  try {
    const categoriesResponse = await axios.get(
      `${process.env.REACT_APP_AWS_API_URL}/contracts/pa/categories`
    );
    // console.debug({ categoriesResponse });
    if (categoriesResponse.data.status === 200) {
      return categoriesResponse.data.data;
    }
    throw new Error(categoriesResponse.data.error);
  } catch (error) {
    SentryService("onGetCategoriesList", "onGetCategoriesList", error.message);
    throw error;
  }
};
