import axios from "axios";
import { Button } from "common/components";
import { Input } from "common/components";
import { TextArea } from "common/components/TextArea";
import AdminLayout from "layouts/AdminLayout";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

export const ViewProductPage = () => {
  const { id } = useParams();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const pinataGatewayKey = `?pinataGatewayToken=${process.env.REACT_APP_PINATA_GATEWAY_KEY}`;
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      await axios
        .get(`${process.env.REACT_APP_AWS_API_URL}/contracts/pa/item?id=${id}`)
        .then((response) => {
          setValue("name", response.data.data.info.name);
          setValue("description", response.data.data.info.description);
          setValue("category", response.data.data.info.category);
          setValue("maxBidders", response.data.data.maxBidders);
          setValue("autoBuyPrice", response.data.data.autoBuyPrice);
          setValue("startDate", new Date(response.data.data.startDate).toISOString().split("T")[0]);
          setValue("timeInterval", response.data.data.timeInterval);
          setValue("auctionPrice", response.data.data.auctionPrice);
          setValue("priceIncrements", response.data.data.priceIncrements);
          setValue("addTimeOnBid", response.data.data.addTimeOnBid);
          setImages(response.data.data.info.images);
          setLoading(false);
        });
    };
    fetchProducts();
  }, []);

  const onSubmit = (data) => {
    console.log(data);
  };
  return (
    <AdminLayout>
      <div className="p-4 w-full">
        <div className="mt-4 w-full">
          <h1 className="text-2xl font-semibold p-4">View Product</h1>
          <form className="grid grid-cols-2" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-span-2 md:col-span-1 px-4">
              <div className="mb-2">
                <label className="text-sm font-semibold" htmlFor="name">
                  Name
                </label>
                {loading ? (
                  <div className="h-5 bg-gray-200 rounded-full w-full mb-4"></div>
                ) : (
                  <div className="text-gray-500">{watch("name")}</div>
                )}
              </div>
              <div className="mb-2">
                <label className="text-sm font-semibold" htmlFor="description">
                  Description
                </label>
                {loading ? (
                  <div className="h-5 bg-gray-200 rounded-full w-full mb-4"></div>
                ) : (
                  <div className="text-gray-500">{watch("description")}</div>
                )}
              </div>
              <div className="mb-2">
                <label className="text-sm font-semibold" htmlFor="category">
                  Category
                </label>
                {loading ? (
                  <div className="h-5 bg-gray-200 rounded-full w-full mb-4"></div>
                ) : (
                  <div className="text-gray-500">{watch("category")}</div>
                )}
              </div>
              <div className="mb-2">
                <label className="text-sm font-semibold" htmlFor="maxBidders">
                  Max Bidders
                </label>
                {loading ? (
                  <div className="h-5 bg-gray-200 rounded-full w-full mb-4"></div>
                ) : (
                  <div className="text-gray-500">{watch("maxBidders")}</div>
                )}
              </div>
              <div className="mb-2">
                <label className="text-sm font-semibold" htmlFor="autoBuyPrice">
                  Auto Buy Price
                </label>
                {loading ? (
                  <div className="h-5 bg-gray-200 rounded-full w-full mb-4"></div>
                ) : (
                  <div className="text-gray-500">{watch("autoBuyPrice")}</div>
                )}
              </div>
              <div className="mb-2">
                <label className="text-sm font-semibold" htmlFor="startDate">
                  Start Date
                </label>
                {loading ? (
                  <div className="h-5 bg-gray-200 rounded-full w-full mb-4"></div>
                ) : (
                  <div className="text-gray-500">{watch("startDate")}</div>
                )}
              </div>
              <div className="mb-2">
                <label className="text-sm font-semibold" htmlFor="timeInterval">
                  Time interval
                </label>
                {loading ? (
                  <div className="h-5 bg-gray-200 rounded-full w-full mb-4"></div>
                ) : (
                  <div className="text-gray-500">{watch("timeInterval")}</div>
                )}
              </div>
              <div className="mb-2">
                <label className="text-sm font-semibold" htmlFor="auctionPrice">
                  Auction Price
                </label>
                {loading ? (
                  <div className="h-5 bg-gray-200 rounded-full w-full mb-4"></div>
                ) : (
                  <div className="text-gray-500">{watch("auctionPrice")}</div>
                )}
              </div>
              <div className="mb-2">
                <label className="text-sm font-semibold" htmlFor="priceIncrements">
                  Price increments
                </label>
                {loading ? (
                  <div className="h-5 bg-gray-200 rounded-full w-full mb-4"></div>
                ) : (
                  <div className="text-gray-500">{watch("priceIncrements")}</div>
                )}
              </div>
              <div className="mb-2">
                <label className="text-sm font-semibold" htmlFor="addTimeOnBid">
                  Add time on bid
                </label>
                {loading ? (
                  <div className="h-5 bg-gray-200 rounded-full w-full mb-4"></div>
                ) : (
                  <div className="text-gray-500">{watch("addTimeOnBid")}</div>
                )}
              </div>
            </div>
            <div className="col-span-2 md:col-span-1 px-4">
              <div className="mb-2">
                <label className="text-sm font-semibold" htmlFor="image">
                  Images
                </label>
                {loading ? (
                  <div className="h-[150px] bg-gray-200 w-[150px] mb-4"></div>
                ) : (
                  <>
                    {images.map((image, index) => (
                      <div
                        className="max-w-[200px] max-h-[200px] mb-4 flex"
                        key={`${image}_${index}`}
                      >
                        <img
                          key={index}
                          src={`${image}${pinataGatewayKey}`}
                          alt="product"
                          className="border border-gray-400 object-contain"
                        />
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </AdminLayout>
  );
};
