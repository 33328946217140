import { onReadContract } from "common/services";
import AuthABI from "common/contracts/Auth.json";

export const getAuthAccess = async (address, allowedRoles) => {
  const response = await onReadContract({
    contractAddress: process.env.REACT_APP_AUTH_CONTRACT_ADDRESS,
    contractABI: AuthABI,
    functionName: "getUserRoles",
    args: [address],
  });

//   console.debug("user roles", response);
  if (!response) return false;
  const roles = response.map((v) => Number(v));
  return roles.some((value) => allowedRoles.includes(value));
};
