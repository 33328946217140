import React from "react";
import { useParams } from "react-router-dom";
import { AuctionProvider } from "./auctionProvider";

export const AuctionProviderWrapper = ({ children }) => {
  const { id } = useParams();
  const queryParams = (new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  }));

  return (
    <AuctionProvider
      initialValues={{
        category: queryParams.category
          ? queryParams.category === "null"
            ? null
            : queryParams.category.replace("%26","&").replace(/_/g," ")
          : null,
        search: queryParams.search
          ? queryParams.search === "null"
            ? null
            : queryParams.search.replace(/_/g," ")
          : null,
        itemId: id || null,
        status: queryParams.status
          ? queryParams.status === "null"
            ? null
            : queryParams.status
          : null,
        page: queryParams.page ? (queryParams.page === "null" ? 1 : queryParams.page) : null,
        sortType: queryParams.sort
          ? queryParams.sort === "null"
            ? null
            : queryParams.sort
          : null,
      }}
    >
      {children}
    </AuctionProvider>
  );
};
