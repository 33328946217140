import axios from "axios";

const API = process.env.REACT_APP_AWS_API_URL;

export const onTurboFundsSummaryService = () => {
  return {
    async fetchTurboFundsSummary() {
      const response = await axios.get(`${API}/contracts/tf/summary`);
      return response.data;
    },
  };
};
