import { useQuery } from "@tanstack/react-query";
import { useWalletHistoryService } from "common/services/WalletHistory";

export const useGetWalletHistory = (queryKey = "walletHistory", userAddress) => {

    const { fetchWalletHistory } = useWalletHistoryService();

    return useQuery({
        queryKey: [queryKey],
        queryFn: () => fetchWalletHistory(userAddress),
        select: (data) => data.data,
    });
};