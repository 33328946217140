import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useWaitForTransactionReceipt } from "wagmi";
import { useRequestSignature } from "common/hooks/useRequestSignature";
import { onDeleteCalendarEvent } from "common/services/onDeleteCalendarEvent";

export const useDeleteCalendarEvent = () => {
  const requestSignature = useRequestSignature();
  const [isEnabled, setIsEnabled] = useState(false);
  const [calendarEventData, setCalendarEventData] = useState({});
  const [signature, setSignature] = useState("");

  const { isError, isSuccess, isFetching, status, data, error } = useQuery({
    queryKey: [`deleteCalendarEvent`],
    retry: 3,
    retryDelay: 3000,
    enabled: isEnabled,
    refetchOnWindowFocus: false,
    queryFn: () =>
      onDeleteCalendarEvent({
        signature,
        id: calendarEventData.id,
      }),
  });
  const {
    isError: isErrorTx,
    isSuccess: isSuccessTx,
    data: dataTx,
    isFetching: isFetchingTx,
    error: errorTx,
  } = useWaitForTransactionReceipt({
    queryKey: [`deleteCalendarEvent${data ? data.data : ""}`],
    hash: data ? data.data : "",
    retry: 3,
    retryDelay: 3000,
    refetchOnWindowFocus: false,
    enabled: isEnabled,
    confirmations: 2,
  });

  useEffect(() => {
    const onResult = async () => {
      if (!isFetching && !isFetchingTx && calendarEventData) {
        if (isError) {
          if (calendarEventData.onError) await calendarEventData.onError(data ? data.error : error);
          if (calendarEventData.onSettled) await calendarEventData.onSettled();
          setIsEnabled(false);
        } else if (isSuccess && data) {
          if (isErrorTx) {
            if (calendarEventData.onError)
              await calendarEventData.onError(dataTx ? dataTx.error : errorTx);
            if (calendarEventData.onSettled) await calendarEventData.onSettled();
            setIsEnabled(false);
          } else if (isSuccessTx && dataTx) {
            if (calendarEventData.onSuccess) await calendarEventData.onSuccess();
            if (calendarEventData.onSettled) await calendarEventData.onSettled();
            setIsEnabled(false);
          }
        }
      }
    };
    if (!isFetching) onResult();
  }, [
    isError,
    isSuccess,
    isFetching,
    status,
    data,
    isFetchingTx,
    calendarEventData,
    error,
    isErrorTx,
    isSuccessTx,
    dataTx,
    errorTx,
  ]);

  const requestDeleteCalendarEvent = async ({ calendarEventData }) => {
    if (!calendarEventData) throw new Error("missing payment request side data");
    const signature = await requestSignature();
    setSignature(signature);
    setCalendarEventData(calendarEventData);
    setIsEnabled(true);
  };

  return {
    requestDeleteCalendarEvent,
    isFetchingTx,
    isError,
    error,
    data,
    isErrorTx,
    isSuccessTx,
    dataTx,
    isFetchingTx,
  };
};
