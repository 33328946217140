import { useQuery } from "@tanstack/react-query";
import { useCommissionsService } from "modules/admin/services/Commissions";
import { useState } from "react";

export const useFetchCommissions = (queryKey = "commissions", initialParams = {}) => {
  const [params, setParams] = useState({
    ...initialParams,
  });
  /* 
    page: "",
    limit: "",
    search: "",
   */
  const { fetchCommissions } = useCommissionsService();

  const queryResults = useQuery({
    queryKey: [queryKey, params],
    queryFn: () => fetchCommissions(params),
    select: (data) => data.data,
  });

  return { ...queryResults, setParams, params };
};
