import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    petDataIpfsUrl: "",
};

const createPetSlice = createSlice({
    name: "createPet",
    initialState,
    reducers: {
        setCreatePetData(state, action) {
            state.petDataIpfsUrl = action.payload.petDataIpfsUrl;
        },
        clearCreatePetData(state) {
            state.petDataIpfsUrl = initialState.petDataIpfsUrl;
        },
    },
});

export const { setCreatePetData, clearCreatePetData } = createPetSlice.actions;
export default createPetSlice.reducer;
