import { useQuery } from "@tanstack/react-query";
import { CalculateUsersRankAndCommissions } from "common/services/rankAndCommissions";
import { SentryService } from "common/services/SentryErrorCapture";

export const useGetRankAndCommissions = ({ address, commissions }) => {
  const {
    isFetching: loading,
    data,
    error,
    refetch,
  } = useQuery({
    queryKey: [`getRankAndCommissions`, { address, commissions }],
    retry: 3,
    retryDelay: 3000,
    enabled: !!address,
    staleTime: 10 * (60 * 1000),
    cacheTime: 15 * (60 * 1000),
    refetchOnWindowFocus: false,
    queryFn: () => CalculateUsersRankAndCommissions(address, !!commissions),
  });
  if (error) {
    SentryService("useGetRankAndCommissions", "useGetRankAndCommissions", error);
  }
  return {
    loading,
    data,
    error,
    refetch,
  };
};
