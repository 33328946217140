import axios from "axios";

const API = process.env.REACT_APP_AWS_API_URL;

export const useTurboFundsService = () => {
  return {
    async fetchTurboFunds(params) {
      const response = await axios.get(`${API}/contracts/tf/products`, {
        params,
      });
      return response.data;
    },
  };
};
