export const handleAuctionEndResponse = ({ data, setItems, setItemDetails, itemDetails }) => {
  const itemsData = data;
  // console.debug("handleAuctionEndResponse", { itemsData });
  if (itemsData) {
    if (itemDetails) {
      setItemDetails(itemsData);
    }

    setItems((prev) => {
      const index = prev.findIndex((item) => item.id == itemsData.id);
      if (index == -1) return prev;
      const newItems = [...prev];
      newItems[index] = { ...itemsData, canBid: false, lastBidder: itemsData.bids.at(-1) };
      return newItems;
    });
  }
};

export const handleAuctionWinResponse = ({ data, setItems, setItemDetails, itemDetails }) => {
  const itemsData = data;
  // console.debug("handleAuctionWinResponse", { itemsData });

  if (itemsData) {
    if (itemDetails) {
      setItemDetails(itemsData);
    }
    if (itemsData.status == 1) {
      setItems((prev) => {
        const index = prev.findIndex((item) => item.id == itemsData.id);
        if (index == -1) return prev;
        const newItems = [...prev];
        newItems[index] = { ...itemsData, canBid: false, lastBidder: itemsData.bids.at(-1) };
        return newItems;
      });
    }
  }
};
