import { onReadContract } from "@common/services/onReadContract";
import { useCallback, useEffect, useState } from "react";
import { useAccount } from "wagmi";
import ABI from "../../../common/contracts/FortuneGoat.json";
import { parsePackagesResponse } from "@join-us/utils/parsePackagesResponse";
import { SentryService } from "common/services/SentryErrorCapture";

export const usePackagesInfo = () => {
    const { isConnected, address } = useAccount();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [packages, setPackages] = useState([]);
    const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;

    const getPackagesList = useCallback(async () => {
        try {
            setLoading(true);
            if (!isConnected) {
                throw new Error("User is not connected");
            }
            if (!address) {
                throw new Error("No address found");
            }
            const packages = (await onReadContract({
                contractAddress,
                contractABI: ABI,
                functionName: "getPackagesList",
                args: [0],
                // @ts-expect-error something is wrong with the types
                from: address,
            }));
            const decimals = (await onReadContract({
                contractAddress,
                contractABI: ABI,
                functionName: "getPackagesDecimals",
                args: [],
                // @ts-expect-error something is wrong with the types
                from: address,
            }));
            const parsedPackages = parsePackagesResponse({
                data: packages,
                decimals,
            });
            setPackages(parsedPackages);
        } catch (error) {
            SentryService("usePackagesInfo","getPackagesList", error)
            // console.log({ error });
            setError(error);
        } finally {
            setLoading(false);
        }
    }, [isConnected, address, contractAddress]);
    useEffect(() => {
        getPackagesList();
    }, [getPackagesList]);
    return { packages, loading, error, mutateAsync: getPackagesList };
};
