import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const OurWinnersCardSkeleton = () => {
  return (
    <div className="grid grid-cols-7 col-span-1 ">
      <div className="min-w-[10rem] relative col-span-4 flex bg-white justify-center items-center overflow-hidden">
        <Skeleton
          containerClassName="absolute t-0 l-0 w-full h-full mt-[-8px]"
          style={{ height: "100%" }}
        />
      </div>
      <div className="col-span-3 flex flex-col p-4 bg-white">
        <div className="flex flex-row">
          <div>
            <Skeleton borderRadius={"100%"} width={"2.75rem"} height={"2.75rem"} />
          </div>
          <div className="flex flex-col pl-1 flex-1">
            <span className="font-bold ">
              <Skeleton />
            </span>
            <span className="text-xs font-light mt-[-2px]">
              <Skeleton />
            </span>
          </div>
        </div>
        <div className="text-lg font-bold max-h-[45px] overflow-hidden pt-1 leading-5">
          <Skeleton />
        </div>
        <div className="text-sm mt-[-2px] mb-2">
          <Skeleton count={2} />
        </div>
        <div>
          <div className="py-1">
            <p className="text-xs">Final bid:</p>
            <p className="font-semibold text-sm">
              <Skeleton />
            </p>
          </div>
          <div className="py-1">
            <p className="text-xs">Estimated total cost:</p>
            <p className="font-semibold text-sm">
              <Skeleton />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
