// import { SentryService } from "./SentryErrorCapture";
import axios from "axios";

export const onDeleteCalendarCategory = async ({ id, signature }) => {
  try {
    const deletedCalendarCategoryResponse = await axios.delete(
      `${process.env.REACT_APP_AWS_API_URL}/contracts/calendar/category?id=${id}&signature=${signature}`
    );
    if (deletedCalendarCategoryResponse) {
      return deletedCalendarCategoryResponse.data;
    }
  } catch (error) {
    // SentryService("onDeleteCalendarCategory", "onDeleteCalendarCategory", error);
    // console.debug(error);
    throw new Error(
      error.response
        ? error.response.data.error.reason ||
          error.response.data.error.shortMessage ||
          error.response.data.error
        : error
    );
  }
};
