import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { onTurboFundsSummaryService } from "common/services";

export const useFetchTurboFundsSummary = (queryKey = "turboFundsSummary", initialParams = {}) => {
  const [params, setParams] = useState(initialParams);
  const { fetchTurboFundsSummary } = onTurboFundsSummaryService();

  const queryResults = useQuery({
    queryKey: [queryKey, params],
    queryFn: () => fetchTurboFundsSummary(params),
    select: (data) => data.data,
  });

  return { ...queryResults, setParams, params };
};
