import React, { useState } from "react";
import AdminLayout from "layouts/AdminLayout";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { Modal } from "@mui/material";
import { Button } from "common/components";
import { useFetchEvents } from "../hooks/Calendar/useFetchEvents";
import { CreateEventModal } from "../components/Calendar/CreateEventModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { DeleteEventModal } from "../components/Calendar/DeleteEventModal";
import { CategoriesModal } from "../components/Calendar/CategoriesModal";

const localizer = momentLocalizer(moment);

export const CalendarPage = () => {
  const [activeEvent, setActiveEvent] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCategoriesModalOpen, setIsCategoriesModalOpen] = useState(false);

  const { data: eventsData } = useFetchEvents();

  const handleClickEvent = (event) => {
    setActiveEvent(event);
    setIsViewModalOpen(true);
  };
  return (
    <AdminLayout>
      <div className="p-4 bg-white">
        <div className="w-full justify-between flex mb-4">
          <Button
            className="bg-electric-blue text-white rounded-none border-none"
            label="Categories"
            onClick={() => setIsCategoriesModalOpen(true)}
          />
          <Button
            className="bg-electric-blue text-white rounded-none border-none"
            label="Create new event"
            onClick={() => setIsCreateModalOpen(true)}
          />
        </div>
        <Calendar
          onSelectEvent={(event) => handleClickEvent(event)}
          localizer={localizer}
          events={
            eventsData?.events.map((event) => {
              return {
                id: event.id,
                title: event.name,
                description: event.description,
                start: new Date(`${event.day} ${event.start}`),
                end: new Date(`${event.day} ${event.end}`),
              };
            }) || []
          }
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
        />
      </div>
      <Modal open={isViewModalOpen} onClose={() => setIsViewModalOpen(false)}>
        <div className="bg-white p-4 w-1/4 mx-auto mt-40">
          <h1 className="text-xl font-semibold">{activeEvent?.title}</h1>
          {activeEvent?.start && (
            <>
              <h2 className="font-bold">Start date</h2>
              <p>{moment(activeEvent?.start).format("dddd, MMMM Do YYYY, h:mm:ss a")} </p>
            </>
          )}
          {activeEvent?.end && (
            <>
              <h2 className="font-bold">End date</h2>
              <p>{moment(activeEvent?.end).format("dddd, MMMM Do YYYY, h:mm:ss a")}</p>
            </>
          )}
          {activeEvent?.description && (
            <>
              <h2 className="font-bold">Description</h2>
              <p>{activeEvent?.description}</p>
            </>
          )}
          <div className="flex justify-between gap-x-2 mt-4">
            <button
              className="border bg-[#FF0000] text-white font-semibold px-4 py-2"
              onClick={() => {
                setIsDeleteModalOpen(true);
                setIsViewModalOpen(false);
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
            <button
              className="border bg-[#CCCCCC] text-[#051F40] font-semibold px-4 py-2"
              onClick={() => setIsViewModalOpen(false)}
            >
              Accept
            </button>
          </div>
        </div>
      </Modal>
      <DeleteEventModal
        activeEvent={activeEvent}
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      />
      <CategoriesModal
        isOpen={isCategoriesModalOpen}
        onClose={() => setIsCategoriesModalOpen(false)}
      />
      <CreateEventModal isOpen={isCreateModalOpen} onClose={() => setIsCreateModalOpen(false)} />
    </AdminLayout>
  );
};
