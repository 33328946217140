import { CONSTANTS } from "common/services/rankAndCommissions/constants/constants";

export const getUnilevelCommission = async (userRank) => {
	const result = {
		unilevelPercentage: CONSTANTS.UNILEVEL_PERCENTAGE,
		commissionBeforePercentage: 0,
		CommissionAfterPercentage: 0,
		total: 0,
		ucv: 0,
	};
	if (!userRank || !userRank.ucv || userRank.ucv == 0) return result;
	const unilevelPercentage = CONSTANTS.UNILEVEL_PERCENTAGE / 1e2;
	const commission = (userRank.ucv * unilevelPercentage) / 100;
	result.commissionBeforePercentage = userRank.ucv;
	result.ucv = userRank.ucv;
	result.commissionBeforePercentage = commission;
	result.total = commission;
	return result;
};
