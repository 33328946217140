// import { ProductCard } from "../components/ProductCard";
// import { RewardsProgres } from "../components/RewardsProgres";
// import ImageFront from "assets/images/image-grandfather.png";
import XXLSlide1 from "assets/images/slide/xxl/slide-1.jpg";
import XXLSlide2 from "assets/images/slide/xxl/slide-2.jpg";
import XXLSlide3 from "assets/images/slide/xxl/slide-3.jpg";
import XXLSlide4 from "assets/images/slide/xxl/slide-4.jpg";
import XLSlide1 from "assets/images/slide/xl/slide-1.jpg";
import XLSlide2 from "assets/images/slide/xl/slide-2.jpg";
import XLSlide3 from "assets/images/slide/xl/slide-3.jpg";
import XLSlide4 from "assets/images/slide/xl/slide-4.jpg";
import LGSlide1 from "assets/images/slide/lg/slide-1.jpg";
import LGSlide2 from "assets/images/slide/lg/slide-2.jpg";
import LGSlide3 from "assets/images/slide/lg/slide-3.jpg";
import LGSlide4 from "assets/images/slide/lg/slide-4.jpg";
import MDSlide1 from "assets/images/slide/md/slide-1.jpg";
import MDSlide2 from "assets/images/slide/md/slide-2.jpg";
import MDSlide3 from "assets/images/slide/md/slide-3.jpg";
import MDSlide4 from "assets/images/slide/md/slide-4.jpg";
import SMSlide1 from "assets/images/slide/sm/slide-1.jpg";
import SMSlide2 from "assets/images/slide/sm/slide-2.jpg";
import SMSlide3 from "assets/images/slide/sm/slide-3.jpg";
import SMSlide4 from "assets/images/slide/sm/slide-4.jpg";
import { SearchBar } from "../components/SearchBar";
// import { AppLayout } from "layouts/AppLayout";
import { useAuctionService } from "../context/auctionProvider";
import { useEffect, useState } from "react";
// import { Logo } from "common/components";
// import DropdownComponent from "common/components/Dropdown";
import { web3AuthInstance } from "App";
import { useGetUserInfo } from "common/hooks/useGetUserInfo";
import { ProductCard2 } from "../components/ProductCard2";
import { Navbar } from "modules/landing/components";
import { useAccount, useConnect } from "wagmi";
import { wagmiConfig } from "App";
import { Slide } from "react-slideshow-image";
import { Footer } from "common/components";
import { LoginModal } from "modules/auth/components/login-modal/LoginModal";
import { useLocation, useNavigate } from "react-router-dom";
import { onGetNavigateUrl } from "../utils/onGetNavigateUrl";
import { replaceWCQrCode } from "common/util/replaceWCQrCode";

const slidesObject = {
  xxl: [XXLSlide1, XXLSlide2, XXLSlide3, XXLSlide4],
  xl: [XLSlide1, XLSlide2, XLSlide3, XLSlide4],
  lg: [LGSlide1, LGSlide2, LGSlide3, LGSlide4],
  md: [MDSlide1, MDSlide2, MDSlide3, MDSlide4],
  sm: [SMSlide1, SMSlide2, SMSlide3, SMSlide4],
};

const getImageUrl = () => {
  if (window.matchMedia("(max-width: 600px)").matches) {
    return slidesObject.sm;
  } else if (window.matchMedia("(min-width: 601px) and (max-width: 1200px)").matches) {
    return slidesObject.md;
  } else if (window.matchMedia("(min-width: 1201px) and (max-width: 1600px)").matches) {
    return slidesObject.lg;
  } else if (window.matchMedia("(min-width: 1601px) and (max-width: 2400px)").matches) {
    return slidesObject.xl;
  } else {
    return slidesObject.xxl;
  }
};

export const ProductsPage = () => {
  const navigate = useNavigate();
  const {
    connectionStatus,
    categoriesList,
    isLoadingCategories,
    handleSearchOptions,
    search,
    category,
    status,
    items,
    autoBuy,
    addBid,
    handleFollowClick,
    pagination,
    user,
    SORT_TYPES,
    sortType,
    getUser,
    page,
    address,
    isConnected,
    auctionEnd,
    serverTime,
  } = useAuctionService();

  // const images = [
  //   "https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
  //   "https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80",
  //   "https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
  // ];

  // const { userInfo } = useGetUserInfo();

  // const options = [
  //   { title: "Dashboard", redirectionUrl: "/app/dashboard" },
  //   { title: "Store", redirectionUrl: "/app/store" },
  //   { title: "Profile", redirectionUrl: "/app/profile" },
  //   { title: "Logout", redirectionUrl: "/logout" },
  // ];

  const [profileImg, setProfileImg] = useState("");

  const [backgroundImages, setBackgroundImages] = useState(getImageUrl());

  useEffect(() => {
    const handleResize = () => setBackgroundImages(getImageUrl);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // useEffect(() => {
  //   if (connectionStatus && !items) handleSearchOptions({});
  // }, [handleSearchOptions, connectionStatus, items]);
  // console.debug({ pagination });
  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const response = await web3AuthInstance.getUserInfo();
        if (response?.profileImage) {
          setProfileImg(response.profileImage);
        }
        getUser();
      } catch (error) {
        console.log("error", error);
      }
    };
    if (web3AuthInstance.connected) getUserInfo();
  }, [web3AuthInstance.connected]);

  // console.debug({ currentDate, cards });
  // const cards = [
  //   ...cardItem,
  //   {
  //     id: 1,
  //     bidNow: true,
  //     sold: false,
  //     startingSoon: false,
  //     buyNow: true,
  //     isFav: false,
  //     sign: "no new biders",
  //   },
  // ];
  const { error, connectors, connect } = useConnect(wagmiConfig);
  // const handleJoin = async () => {
  //   // setShowModal(true);
  //   if (connect) connect({ connector: connectors[0] });
  // };

  const [showModal, setShowModal] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.state?.openModal) {
      // setShowModal(true);
      if (connect) {
        const observer = replaceWCQrCode({ web3AuthInstance });
        connect(
          { connector: connectors[0] },
          {
            onError: () => {
              observer.disconnect();
            },
          }
        );
      }
    }
  }, [location, connect, connectors]);
  // console.debug({ user });
  return (
    <>
      {/* <div className="w-full flex bg-[#F2F2EF] justify-between items-center py-2 px-5">
        <Logo variant="dark-letters" />
        <div className="flex items-center">
          <div className="px-4 font-semibold">Pricing</div>
          <div className="px-4 font-semibold">Company</div>
          <div className="px-4 font-semibold">Benefits</div>
          <div className="px-4 font-semibold">See our winners</div>
          <div className="px-4 font-semibold">Contact us</div>
          <hr className="h-6 border-r-2 border-clair-gray mr-4" />
          <div>
            <div className="flex items-center gap-x-2">
              {userInfo && profileImg ? (
                <>
                  {profileImg.length > 0 ? (
                    <img
                      className="h-12 w-12 cursor-pointer rounded-full object-cover hidden md:block"
                      src={profileImg}
                    />
                  ) : (
                    <div className="h-12 w-12 bg-clair-gray rounded-full"></div>
                  )}
                  <DropdownComponent title={userInfo && `${userInfo.info.name}`} items={options} />
                </>
              ) : (
                <button
                  onClick={handleJoin}
                  className="bg-highlight-secondary hover:bg-zinc-400 text-gray-800 font-bold py-2 px-10 text-lg"
                >
                  Join Now
                </button>
              )}
            </div>
          </div>
        </div>
      </div> */}
      <Navbar userBids={user ? user.currentBids : undefined} />
      {!isConnected && (
        <LoginModal
          setShowModal={setShowModal}
          connectors={connectors}
          showModal={showModal}
          connect={connect}
          error={error}
        />
      )}
      <div className="w-full flex flex-col items-center gap-4 px-12 bg-[#E3E3D7]">
        {/* <RewardsProgres progress={"60%"} /> */}
        <div className="w-full pt-4">
          <Slide>
            <div className="each-slide-effect">
              <div
                className="min-h-[200px] md:min-h-[400px]"
                style={{
                  backgroundImage: `url(${backgroundImages[0]})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              ></div>
            </div>
            <div className="each-slide-effect">
              <div
                className="min-h-[200px] md:min-h-[400px]"
                style={{
                  backgroundImage: `url(${backgroundImages[1]})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              ></div>
            </div>
            <div className="each-slide-effect">
              <div
                className="min-h-[200px] md:min-h-[400px]"
                style={{
                  backgroundImage: `url(${backgroundImages[2]})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              ></div>
            </div>
            <div className="each-slide-effect">
              <div
                className="min-h-[200px] md:min-h-[400px]"
                style={{
                  backgroundImage: `url(${backgroundImages[3]})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              ></div>
            </div>
          </Slide>
        </div>
        <SearchBar
          categories={categoriesList}
          loading={isLoadingCategories}
          handleSearchOptions={handleSearchOptions}
          category={category}
          search={search}
          status={status}
          sortTypes={SORT_TYPES}
          sortType={sortType}
          page={page}
        />
        <div className="mx-auto">
          {/* <p>current: {serverTime?.currentTime}</p>
        <p>server: {serverTime?.serverTime}</p>
        <p>offset: {serverTime?.offset}</p> */}
          <div
            key={`items_cards_${items && items.length > 0 ? items[0].id : "loading_items"}`}
            className="w-full grid grid-cols-1 place-items-center gap-4 md:grid-cols-3 lg:grid-cols-5 mb-4"
          >
            {items &&
              items.map((e) => {
                const followed = user ? user.likes.some((item) => item == e.id) : false;
                return (
                  <ProductCard2
                    key={e.id}
                    cardInfo={e}
                    favButtonHandler={handleFollowClick}
                    autoBuy={autoBuy}
                    addBid={addBid}
                    followed={followed}
                    address={address}
                    auctionEnd={auctionEnd}
                    serverTime={serverTime}
                  />
                );
              })}
          </div>
        </div>
        {pagination && (
          <div className="pb-52">
            <div className="flex">
              {pagination.prev_page && (
                <button
                  className="text-[16px] text-[#808080] font-bold mx-10"
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    handleSearchOptions({ newPage: pagination.page - 1 });
                    navigate(onGetNavigateUrl({ page: pagination.page - 1 }));
                  }}
                >{` PREVIOUS `}</button>
              )}
              {pagination.page - 1 > 0 && (
                <button
                  className="text-[16px] text-dark font-bold mx-10"
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    handleSearchOptions({ newPage: pagination.page - 1 });
                    navigate(onGetNavigateUrl({ page: pagination.page - 1 }));
                  }}
                >{` ${pagination.page - 1} `}</button>
              )}
              <span className="text-[16px] text-dark font-bold">{pagination.page}</span>
              {pagination.page + 1 <= pagination.total_pages && (
                <button
                  className="text-[16px] text-dark font-bold mx-10"
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    handleSearchOptions({ newPage: pagination.page + 1 });
                    navigate(onGetNavigateUrl({ page: pagination.page + 1 }));
                  }}
                >{` ${pagination.page + 1} `}</button>
              )}
              {pagination.next_page && (
                <button
                  className="text-[16px] text-[#808080] font-bold mx-10"
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    handleSearchOptions({ newPage: pagination.page + 1 });
                    navigate(onGetNavigateUrl({ page: pagination.page + 1 }));
                  }}
                >{` NEXT `}</button>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="bg-[#E3E3D7]">
        <Footer />
      </div>
    </>
  );
};
