import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useProductsService } from "modules/admin/services/Products";

export const useFetchProducts = (queryKey = "products", initialParams = {}) => {
  const [params, setParams] = useState(initialParams);
  /* 
    page: "",
    limit: "",
    search: "",
   */
  const { fetchProducts } = useProductsService();

  const queryResults = useQuery({
    queryKey: [queryKey, params],
    queryFn: () => fetchProducts(params),
    select: (data) => data.data,
  });

  return { ...queryResults, setParams, params };
};
