import { FC, useCallback, useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { Button, Input, ProgressBar } from "@common/components";
import { onReadContract } from "@common/services/onReadContract";
import USDTABI from "@common/contracts/USDT.json";
/* import {
    convertBigIntToNumber,
    getFiatWithFee,
    remove6Decimals,
} from "@common/helpers"; */
import { convertBigIntToNumber } from "@common/helpers/convertBigIntToNumber";
import { getFiatWithFee } from "@common/helpers/getFiatWithFee";
import { remove6Decimals } from "@common/helpers/remove6Decimals";
import { torusPlugin } from "@src/Web3AuthConnectorInstance";
import { SentryService } from "common/services/SentryErrorCapture";

export const JoinUsStep2 = ({ watch, setValue }) => {
    const { address, isConnected } = useAccount();
    const [availableInWallet, setAvailableInWallet] = useState(0);
    const [refresh, setRefresh] = useState(false);
    const minAmount = 1;

    const maxFiat =
        getFiatWithFee(Number(watch("amount"))) >= 500
            ? 500
            : getFiatWithFee(Number(watch("amount")));

    const torusRampOn = async () => {
        if (!address || !isConnected) {
            return;
        }
        if (!torusPlugin || !torusPlugin.torusWalletInstance.isInitialized) {
            return;
        }
        try {
            await torusPlugin.initiateTopup("transak", {
                selectedAddress: address,
                selectedCurrency: "USD", // Fiat currency
                fiatValue: !watch("amount") ? 500 : maxFiat, // Fiat Value
                selectedCryptoCurrency: "USDT", // Cryptocurreny `SOL`, `MATIC` etc.
                chainNetwork: "matic", // Blockchain network
            });
        } catch (error) {
            SentryService("JoinUsStep2","torusRampOn",error)
            // console.log({ error });
        }
    };
    const getBalance = useCallback(async () => {
        const data = await onReadContract({
            contractAddress: process.env.REACT_APP_USDT_CONTRACT_ADDRESS,
            contractABI: USDTABI,
            functionName: "balanceOf",
            args: [address],
        });
        if (typeof data === "bigint") {
            setAvailableInWallet(convertBigIntToNumber(data) || 0);
        }
    },[address]);

    useEffect(() => {
        getBalance();
    }, [address, getBalance, refresh]);

    const handleAmountInput = (e) => {
        const value = e.target.value;
        e.target.value = value.replace(/^-|[.,]/g, "");
    };

    const handleAmountChange = (e) => {
        if (Number(e.target.value) > 12000) {
            e.target.value = "12000";
        }
        if (e.target.value.startsWith("0")) {
            e.target.value = e.target.value.slice(1);
        }
        setValue("amount", Number(e.target.value));
    };

    const notEnoughFunds =
        watch("amount") >= minAmount &&
        watch("amount") > remove6Decimals(availableInWallet);

    return (
        <div className="p-6">
            <h2 className="text-2xl text-dark mb-6">Step 2, Make payment</h2>
            <div className="grid grid-cols-4 gap-4">
                <div className="col-span-4">
                    <ProgressBar value={watch("amount")} />
                </div>
                {notEnoughFunds && (
                    <div className="col-span-4">
                        <p className="text-red-500">
                            You don&apos;t have enough funds in your wallet. Please
                            get funds to continue.
                        </p>
                    </div>
                )}
                <div className="col-span-4 md:col-span-1 flex items-stretch">
                    <div className="flex flex-wrap items-center justify-center border border-slate-100 rounded w-full">
                        <div className="px-4 mx-4 text-2xl">
                            {remove6Decimals(availableInWallet)}
                        </div>
                        <div className="flex flex-col text-center">
                            <p>Available in wallet</p>
                            <p onClick={()=>setRefresh(!refresh)} className="text-electric-blue underline cursor-pointer">Update balance</p>
                        </div>
                    </div>
                </div>
                <div className="col-span-4 md:col-span-1 flex items-stretch">
                    <Button
                        label="Get funds"
                        onClick={torusRampOn}
                        type="button"
                        className="w-full"
                    />
                </div>
                <div className="col-span-4 md:col-span-1 flex items-center md:items-stretch">
                    <Input
                        label="Amount"
                        type="number"
                        className="w-full h-20"
                        value={watch("amount")}
                        onChange={handleAmountChange}
                        onInput={handleAmountInput}
                        placeholder="Enter amount"
                    />
                    {/* <p className="ml-2 text-dark font-bold">USDT</p> */}
                </div>
                <div className="col-span-4 md:col-span-1 flex items-stretch">
                    <Button
                        label="Make payment"
                        type="submit"
                        className="w-full"
                        disabled={
                            !watch("amount") ||
                            watch("amount") < minAmount ||
                            watch("amount") > remove6Decimals(availableInWallet)
                        }
                    />
                </div>
            </div>
        </div>
    );
};
