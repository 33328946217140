import axios from "axios";

const API = process.env.REACT_APP_AWS_API_URL;

export const useCalendarService = () => {
  return {
    async fetchEvents(params) {
      const response = await axios.get(`${API}/contracts/calendar/events`, {
        params,
      });
      return response.data;
    },
    async fetchCategories(params) {
      const response = await axios.get(`${API}/contracts/calendar/categories`, {
        params,
      });
      return response.data;
    },
  };
};
