import { web3AuthInstance } from "App";
import { wagmiConfig } from "App";
import { Footer } from "common/components";
import { useSendContactUs } from "common/hooks/useSendContactUs";
import { replaceWCQrCode } from "common/util/replaceWCQrCode";
import { Navbar } from "modules/landing/components";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useConnect } from "wagmi";

export const ContactUsPage = () => {
  const { sendContactUs } = useSendContactUs();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    const res = await sendContactUs(data);
    if (res?.status === 200) {
      toast.success("Message sent successfully");
      reset();
    } else {
      toast.error("Failed to send message");
    }
  };

  const { error, connectors, connect } = useConnect(wagmiConfig);

  const handleJoin = async () => {
    // setShowModal(true);
    if (connect) {
      const observer = replaceWCQrCode({ web3AuthInstance });
      connect(
        { connector: connectors[0] },
        {
          onSuccess: () => {
            window.location.reload();
          },
          onError: () => {
            observer.disconnect();
          },
        }
      );
    }
  };

  return (
    <div className="bg-[#E3E3D7] min-h-screen">
      <Navbar />
      <div className="container mx-auto">
        <div className="grid grid-cols-2 gap-20 my-10">
          <div
            className="bg-[url('@assets/images/touching-phone.png')] bg-no-repeat bg-cover bg-center col-span-2 md:col-span-1"
            style={{ width: "auto" }}
          />
          <div className="col-span-2 md:col-span-1 p-2">
            <h1 className="text-3xl font-semibold text-[#1840CE]">GET IN TOUCH</h1>
            <p>
              To ensure we give you the best answer, we require some information, please fill this
              form and we’ll get back to you as soon as we can.
            </p>
            <form className="mt-10" onSubmit={handleSubmit(onSubmit)}>
              <p className="text-xl font-bold">CONTACT INFO</p>
              <div className="flex flex-col my-3">
                <label className="font-semibold" htmlFor="name">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  className="border border-[#808080] p-2 bg-[#E3E3D7] rounded-sm"
                  {...register("name", { required: "This field is required" })}
                />
                <p className="text-red-500 text-sm">{errors.name?.message}</p>
              </div>
              <div className="flex justify-between gap-4">
                <div className="flex flex-col my-3 w-full">
                  <label className="font-semibold" htmlFor="topic">
                    Topic
                  </label>
                  <select
                    name="topic"
                    id="topic"
                    className="border border-[#808080] p-2 bg-[#E3E3D7] w-full rounded-sm"
                    defaultValue="default"
                    {...register("topic", { required: "This field is required" })}
                  >
                    <option value="default" disabled>
                      Select a topic
                    </option>
                    <option value="support">Support</option>
                    <option value="sales">Sales</option>
                    <option value="partnership">Partnership</option>
                  </select>
                  <p className="text-red-500 text-sm">{errors.topic?.message}</p>
                </div>
                <div className="flex flex-col my-3 w-full">
                  <label className="font-semibold" htmlFor="name">
                    Company Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="border border-[#808080] p-2 bg-[#E3E3D7] w-full rounded-sm"
                    {...register("companyName")}
                  />
                  <p className="text-red-500 text-sm">{errors.companyName?.message}</p>
                </div>
              </div>
              <div className="flex flex-col my-3">
                <label className="font-semibold" htmlFor="email">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  className="border border-[#808080] p-2 bg-[#E3E3D7] rounded-sm"
                  {...register("email", { required: "This field is required" })}
                />
                <p className="text-red-500 text-sm">{errors.email?.message}</p>
              </div>
              <div className="flex flex-col mt-10">
                <label className="font-semibold" htmlFor="message">
                  MESSAGE
                </label>
                <textarea
                  id="message"
                  className="border border-[#808080] p-2 bg-[#E3E3D7] rounded-sm"
                  {...register("message", { required: "This field is required" })}
                  rows={5}
                />
                <p className="text-red-500 text-sm">{errors.message?.message}</p>
              </div>
              <div className="flex justify-end mt-5">
                <button className="bg-[#1840CE] text-white px-4 py-2 rounded-sm">SEND</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="bg-gradient-to-b from-cream to-sky-700/50">
        <div className="flex flex-col py-20">
          <div className="w-full">
            <div className="flex justify-center py-10">
              <div className="container flex justify-center px-4">
                <div
                  className="flex justify-center items-center flex-col"
                  style={{ maxWidth: "800px" }}
                >
                  <h2 className="text-4xl text-gray-800 font-light mb-4 text-center">
                    More than a platform, a supportive family, dedicated to turning your dreams into
                    reality.
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="flex justify-center">
              <div className="container flex justify-center px-4">
                <div className="mx-4 flex items-center">
                  <button
                    onClick={handleJoin}
                    className="bg-electric-blue hover:bg-zinc-400 text-white font-bold py-2 px-20 rounded text-lg"
                  >
                    Join us
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
