import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    imageLink: "",
    address: null,
    accessLevel: 0,
    isRegistered: false,
    status: null,
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        login(state, action) {
            // console.log("login", { payload: action.payload });
            state.firstName = action.payload.firstName || "";
            state.lastName = action.payload.lastName || "";
            state.email = action.payload.email || "";
            state.phoneNumber = action.payload.phoneNumber || "";
            state.imageLink = action.payload.imageLink || "";
            state.address = action.payload.address;
            state.status = action.payload.status;
            state.accessLevel = action.payload.accessLevel;
            state.isRegistered = action.payload.isRegistered || false;
        },
        updateUser(state, action) {
            state.firstName = action.payload.firstName || "";
            state.lastName = action.payload.lastName || "";
            state.email = action.payload.email || "";
            state.phoneNumber = action.payload.phoneNumber || "";
            state.imageLink = action.payload.imageLink || "";
        },
        logout(state) {
            // console.log("logout", { payload: { ...initialState } });
            state.firstName = "";
            state.lastName = "";
            state.email = "";
            state.phoneNumber = "";
            state.imageLink = "";
            state.address = null;
            state.status = null;
            state.accessLevel = 0;
            state.isRegistered = false;
        },
    },
});

export const { login, logout, updateUser } = userSlice.actions;
export default userSlice.reducer;
