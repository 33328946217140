import { SentryService } from "./SentryErrorCapture";
import axios from "axios";

export const onCreateCalendarCategory = async ({ data, signature }) => {
  try {
    const createdCalendarCategoryResponse = await axios.post(
      `${process.env.REACT_APP_AWS_API_URL}/contracts/calendar/category`,
      {
        ...data,
        signature,
      },
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    if (createdCalendarCategoryResponse) {
      return createdCalendarCategoryResponse.data;
    }
  } catch (error) {
    // SentryService("onCreateCalendarCategory", "onCreateCalendarCategory", error);
    // console.debug(error);
    throw new Error(
      error.response
        ? error.response.data.error.reason ||
          error.response.data.error.shortMessage ||
          error.response.data.error
        : error
    );
  }
};
