import axios from "axios";
import { SentryService } from "common/services/SentryErrorCapture";

export const onGetWinnersList = async ({ page, limit }) => {
  try {
    const categoriesResponse = await axios.get(
      `${process.env.REACT_APP_AWS_API_URL}/contracts/pa/winners`,
      { params: { page, limit } }
    );
    // console.debug({ categoriesResponse });
    if (categoriesResponse.data.status === 200) {
      return categoriesResponse.data.data;
    }
    throw new Error(categoriesResponse.data.error);
  } catch (error) {
    SentryService("onGetWinnersList", "onGetWinnersList", error.message);
    throw error;
  }
};
