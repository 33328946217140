import axios from "axios";
import { SentryService } from "./SentryErrorCapture";

export const onRequestSetUserSelectedBranch = async ({ signature, userId, branch }) => {
  if (!signature) {
    throw new Error("signature is required");
  }
  if (!userId) {
    throw new Error("userId is required");
  }
  if (!branch) {
    throw new Error("branch is required");
  }
  try {
    const userSelectedBranchResponse = await axios.post(
      `${process.env.REACT_APP_AWS_API_URL}/contracts/fg/setBranch`,
      {
        signature,
        userId,
        branch,
      }
    );
    if (userSelectedBranchResponse) {
      return userSelectedBranchResponse.data;
    }
  } catch (error) {
    SentryService("onRequestSetUserSelectedBranch", "onRequestSetUserSelectedBranch", error)
    // console.debug(error);
    throw new Error(
      error.response
        ? error.response.data.error.reason ||
          error.response.data.error.shortMessage ||
          error.response.data.error
        : error
    );
  }
};
