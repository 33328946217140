import AdminLayout from "layouts/AdminLayout";
import React, { useCallback, useEffect, useState } from "react";
import { AddressField } from "../components/Funds/addressField";
import { useGetRankAndCommissions } from "common/hooks/useGetRankAndCommissions";
import { CommissionsTable } from "../components/Commissions/CommissionsTable";
import { OrganizationTree } from "modules/dashboard/components/Organigram";
import commissionsABI from "common/contracts/Commissions.json";
import { onReadContract } from "common/services";
import { onWriteContract } from "common/services";
import { toast } from "react-toastify";

export const AdminCommissions = () => {
  const [addressToSearch, setAddressToSearch] = useState(null);
  const [isBinaryTree, setIsBinaryTree] = useState(true);
  const [commissionsToPay, setCommissionsToPay] = useState([]);
  const [commissionsPaid, setCommissionsPaid] = useState([]);
  const [option, setOption] = useState("pay");
  const [page, setPage] = useState(0);
  const [dates, setDates] = useState([]);
  const [totalCommissions, setTotalCommissions] = useState(0);
  const [commissionsDate, setCommissionsDate] = useState("");
  const commissionsInfo = useGetRankAndCommissions({
    address: addressToSearch,
  });
  const getCommisionsList = useCallback(
    async (date) => {
      const totalCommissionsInDate = await onReadContract({
        contractAddress: "0xdFb9F6e8853744e3eD4CafCdAb2E6eC119cd6697",
        contractABI: commissionsABI,
        functionName: "datesCounterTotalCommissionsMap",
        args: [date],
      });
      setTotalCommissions(Number(totalCommissionsInDate));
      const response = await onReadContract({
        contractAddress: "0xdFb9F6e8853744e3eD4CafCdAb2E6eC119cd6697",
        contractABI: commissionsABI,
        functionName: "getCommissionsList",
        args: [date, page * 15, 100],
      });
      const getKind = (kind) => {
        switch (kind) {
          case 0:
            return "binary";
          case 1:
            return "unilevel";
          case 2:
            return "Matching binary";
          case 3:
            return "Matching unilevel";
          case 4:
            return "Leadership pool";
          case 5:
            return "Fast start";
        }
      };
      if (response) {
        const commissionsToPay = response.filter((rx) => Number(rx.status) == 1);
        const commissionsPaid = response.filter((rx) => Number(rx.status == 2));
        setCommissionsToPay(
          commissionsToPay.map((rx) => {
            return {
              ...rx,
              amount: Number(rx.amount) / 10 ** 6,
              id: parseInt(rx.id),
              type: getKind(rx.kind),
            };
          })
        );
        setCommissionsPaid(
          commissionsPaid.map((rx) => {
            return {
              ...rx,
              amount: Number(rx.amount) / 10 ** 6,
              id: parseInt(rx.id),
              type: getKind(rx.kind),
            };
          })
        );
      }
    },
    [page]
  );
  useEffect(() => {
    // const getDates = async () => {
    //   const date = await onReadContract({
    //     contractAddress: "0xdFb9F6e8853744e3eD4CafCdAb2E6eC119cd6697",
    //     contractABI: commissionsABI,
    //     functionName: "getCommissionDates",
    //     args: [],
    //   });
    //   console.debug(date);
    //   setDates(date.map((d) => Number(d)));
    //   setCommissionsDate(Number(date.at(-1)));
    //   getCommisionsList(date.at(-1));
    // };
    // getDates();
  }, [getCommisionsList]);

  const payCommission = async (comm) => {
    const args = [
      [
        BigInt(comm.id),
        comm.commissionId,
        comm.to,
        comm.date,
        comm.dateCounter,
        comm.amount * 10 ** 6,
        2,
        comm.kind,
      ],
    ];

    console.debug("args: ", args);
    const response = await onWriteContract({
      contractAddress: "0xdFb9F6e8853744e3eD4CafCdAb2E6eC119cd6697",
      contractABI: commissionsABI,
      functionName: "updateCommission",
      args,
      waitConfirmations: 1,
    });
    if (response) {
      toast.success("commission paid");
    }
  };

  const handleDateChange = async (date) => {};

  return (
    <AdminLayout>
      {false && <div className="w-[80%] mx-auto backgrount-white p-6 m-6 shadow">
        <div>
          <h1 className="text-xl text-bold">
            <select
              onChange={(e) => {
                getCommisionsList(e.target.value);
                setCommissionsDate(e.target.value);
              }}
            >
              {dates.map((date) => {
                return (
                  <option key={date} value={date}>
                    {date}
                  </option>
                );
              })}
            </select>{" "}
            -- {totalCommissions} Commissions
          </h1>
        </div>
        <div
          style={{
            borderBottom: "1px solid gray",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            fontSize: "26px",
            maxWidth: "90%",
            margin: "0 auto",
          }}
        >
          <div>
            <h2>SUMMARY</h2>
          </div>
          <div
            onClick={() => {
              setOption("pay");
            }}
            style={{ borderBottom: `${option == "pay" ? "3px solid blue" : "none"}` }}
          >
            COMMISSIONS TO PAY
          </div>
          <div
            onClick={() => {
              setOption("paid");
            }}
            style={{ borderBottom: `${option == "paid" ? "3px solid blue" : "none"}` }}
          >
            COMMISSIONS PAID
          </div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
            width: "100%",
            justifyContent: "space-around",
            alignItems: "center",
            fontSize: "20px",
            maxWidth: "90%",
            margin: "2rem auto 1rem auto",
          }}
        >
          <div className="mx-auto">Commission #Id</div>
          <div className="mx-auto">Address</div>
          <div className="mx-auto">Type</div>
          <div className="mx-auto">Amount</div>
          <div className="mx-auto">Action</div>
        </div>
        {((option == "pay" && commissionsToPay && commissionsToPay.length > 0) ||
          (option == "paid" && commissionsPaid && commissionsPaid.length > 0)) && (
          <div>
            {(option == "pay" ? commissionsToPay : commissionsPaid).map((comm, index) => {
              return (
                <div
                  key={comm.id}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
                    width: "100%",
                    justifyContent: "space-around",
                    alignItems: "center",
                    fontSize: "20px",
                    maxWidth: "90%",
                    margin: "0 auto",
                    padding: "1rem 0",
                    background: `${index % 2 == 0 ? "rgb(237,237,237)" : "white"}`,
                  }}
                >
                  <div className="mx-auto">{comm.id}</div>
                  <div className="mx-auto">{comm.to.substring(0, 8)}</div>
                  <div className="mx-auto">{comm.type}</div>
                  <div className="mx-auto">{comm.amount}</div>
                  <div
                    className="mx-auto"
                    onClick={() => {
                      payCommission(comm);
                    }}
                  >
                    Action
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>}
      {true && (
        <div className="w-full">
          <section className="flex flex-col max-w-[1200px] justify-center items-end gap-6 mx-auto py-24">
            <div className="mx-auto p-6 bg-white rounded-md shadow-md lg:min-w-[600px]">
              <div className="mx-auto w-min">
                <h2 className="text-2xl font-semibold mb-4 w-full">Search user commissions</h2>
              </div>
              <AddressField
                setAddress={setAddressToSearch}
                showLabel={false}
                inputPlaceholder="User to search"
                disableChanges={commissionsInfo ? commissionsInfo.loading : false}
              />
              {addressToSearch && <p>{addressToSearch}</p>}
              {addressToSearch && commissionsInfo && !commissionsInfo.loading && (
                <CommissionsTable commissionsInfo={commissionsInfo} />
              )}
              {commissionsInfo?.loading && <div>Loading...</div>}
              {commissionsInfo?.error && (
                <div>
                  Error: <p>{commissionsInfo.error}</p>
                </div>
              )}
            </div>
          </section>
          {addressToSearch && commissionsInfo && (
            <div className="w-full md:w-[80%] text-center mx-auto">
              <h2 className="text-bold text-xl">
                <strong>User Tree</strong>
              </h2>
              <p
                className="cursor:pointer"
                onClick={() => {
                  setIsBinaryTree((prev) => !prev);
                }}
              >
                {isBinaryTree ? (
                  <>
                    <strong>Binary</strong> | Unilevel
                  </>
                ) : (
                  <>
                    Binary | <strong>Unilevel</strong>
                  </>
                )}
              </p>
              <OrganizationTree
                key={isBinaryTree}
                treeType={isBinaryTree ? "binary" : "unilevel"}
                showEmpty={true}
                startingNode={addressToSearch}
                setOpenModal={() => {}}
                setNodeInfo={() => {}}
                setStartingNode={() => {}}
              />
            </div>
          )}
        </div>
      )}
    </AdminLayout>
  );
};
