import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useWaitForTransactionReceipt } from "wagmi";
import { onDeleteBid } from "../services";
import { useRequestSignature } from "common/hooks/useRequestSignature";

export const useDeleteBid = () => {
  const requestSignature = useRequestSignature();
  const [isEnabled, setIsEnabled] = useState(false);
  const [bidData, setBidData] = useState({});
  const [signature, setSignature] = useState("");

  const { isError, isSuccess, isFetching, status, data, error } = useQuery({
    queryKey: [`deleteBid`],
    retry: 3,
    retryDelay: 3000,
    enabled: isEnabled,
    refetchOnWindowFocus: false,
    queryFn: () =>
      onDeleteBid({
        signature,
        id: bidData.id,
      }),
  });
  const {
    isError: isErrorTx,
    isSuccess: isSuccessTx,
    data: dataTx,
    isFetching: isFetchingTx,
    error: errorTx,
  } = useWaitForTransactionReceipt({
    queryKey: [`createBid${data ? data.data : ""}`],
    hash: data ? data.data : "",
    retry: 3,
    retryDelay: 3000,
    refetchOnWindowFocus: false,
    enabled: isEnabled,
    confirmations: 2,
  });

  useEffect(() => {
    const onResult = async () => {
      if (!isFetching && !isFetchingTx && bidData) {
        if (isError) {
          if (bidData.onError) await bidData.onError(data ? data.error : error);
          if (bidData.onSettled) await bidData.onSettled();
          setIsEnabled(false);
        } else if (isSuccess && data) {
          if (isErrorTx) {
            if (bidData.onError) await bidData.onError(dataTx ? dataTx.error : errorTx);
            if (bidData.onSettled) await bidData.onSettled();
            setIsEnabled(false);
          } else if (isSuccessTx && dataTx) {
            if (bidData.onSuccess) await bidData.onSuccess();
            if (bidData.onSettled) await bidData.onSettled();
            setIsEnabled(false);
          }
        }
      }
    };
    if (!isFetching) onResult();
  }, [
    isError,
    isSuccess,
    isFetching,
    status,
    data,
    isFetchingTx,
    bidData,
    error,
    isErrorTx,
    isSuccessTx,
    dataTx,
    errorTx,
  ]);

  const requestDeleteBid = async ({ bidData }) => {
    if (!bidData) throw new Error("missing payment request side data");
    const signature = await requestSignature();
    setSignature(signature);
    setBidData(bidData);
    setIsEnabled(true);
  };

  return {
    requestDeleteBid,
    isFetchingTx,
    isError,
    error,
    data,
    isErrorTx,
    isSuccessTx,
    dataTx,
    isFetchingTx,
  };
};
