import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useWaitForTransactionReceipt } from "wagmi";
import { useRequestSignature } from "../../../../common/hooks/useRequestSignature";
import { onCreateExpenseProductTurboFunds } from "../../../admin/services/TurboFunds/onCreateExpenseProductTurboFunds";

export const useCreateExpenseProductTurboFunds = () => {
  const requestSignature = useRequestSignature();
  const [isEnabled, setIsEnabled] = useState(false);
//   const [bidData, setBidData] = useState({});
  const [expenseProductData, setExpenseProductData] = useState({});
  const [signature, setSignature] = useState("");

  const { isError, isSuccess, isFetching, status, data, error } = useQuery({
    queryKey: [`createExpenseProduct`],
    retry: 1,
    retryDelay: 3000,
    refetchOnWindowFocus: false,
    enabled: isEnabled,
    queryFn: () =>
      onCreateExpenseProductTurboFunds({
        signature,
        data: { ...expenseProductData },
      }),
  });
  const {
    isError: isErrorTx,
    isSuccess: isSuccessTx,
    data: dataTx,
    isFetching: isFetchingTx,
    error: errorTx,
  } = useWaitForTransactionReceipt({
    queryKey: [`createExpenseProduct${data ? data.data : ""}`],
    hash: data ? data.data : "",
    retry: 3,
    retryDelay: 3000,
    refetchOnWindowFocus: false,
    enabled: isEnabled,
    confirmations: 2,
  });

  useEffect(() => {
    const onResult = async () => {
      if (!isFetching && !isFetchingTx && expenseProductData) {
        if (isError) {
          if (expenseProductData.onError) await expenseProductData.onError(data ? data.error : error);
          if (expenseProductData.onSettled) await expenseProductData.onSettled();
          setIsEnabled(false);
        } else if (isSuccess && data) {
          if (isErrorTx) {
            if (expenseProductData.onError) await expenseProductData.onError(dataTx ? dataTx.error : errorTx);
            if (expenseProductData.onSettled) await expenseProductData.onSettled();
            setIsEnabled(false);
          } else if (isSuccessTx && dataTx) {
            if (expenseProductData.onSuccess) await expenseProductData.onSuccess();
            if (expenseProductData.onSettled) await expenseProductData.onSettled();
            setIsEnabled(false);
          }
        }
      }
    };
    if (!isFetching) onResult();
  }, [
    isError,
    isSuccess,
    isFetching,
    status,
    data,
    isFetchingTx,
    expenseProductData,
    error,
    isErrorTx,
    isSuccessTx,
    dataTx,
    errorTx,
  ]);

  const requestCreateExpenseProduct = async ({ expenseProductData }) => {
    if (!expenseProductData) throw new Error("missing payment request side data");
    const signature = await requestSignature();
    setSignature(signature);
    setExpenseProductData(expenseProductData);
    setIsEnabled(true);
  };

  return {
    requestCreateExpenseProduct,
    isFetchingTx,
    isError,
    error,
    data,
    isErrorTx,
    isSuccessTx,
    dataTx,
    isFetchingTx,
  };
};
