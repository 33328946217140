import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Logo } from "./Logo";
import {
  faFacebook,
  faInstagram,
  faTelegram,
  faTiktok,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

export const Footer = () => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-center items-center p-10">
      <div className="container md:px-4">
        <div className="grid grid-cols-4 gap-5 md:gap-4">
          <div className="md:col-span-1 sm:col-span-2 col-span-2 md:p-6 rounded">
            <div>
              <h3 className="font-bold uppercase text-gray-800 mb-2">FORTUNEGOAT</h3>
              <ul className="text-dark">
                <li
                  className="hover:bg-clair-gray w-fit cursor-pointer"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate("/app/store/winners");
                  }}
                >
                  See our winners
                </li>
                <li
                  className="hover:bg-clair-gray w-fit cursor-pointer"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate("/company");
                  }}
                >
                  Company
                </li>
                <li
                  className="hover:bg-clair-gray w-fit cursor-pointer"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate("/benefits");
                  }}
                >
                  Benefits
                </li>
                <li
                  className="hover:bg-clair-gray w-fit cursor-pointer"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate("/pricing");
                  }}
                >
                  Pricing
                </li>
              </ul>
            </div>
          </div>
          <div className="md:col-span-1 sm:col-span-2 col-span-2 md:p-6 rounded">
            <div>
              <h3 className="font-bold uppercase text-gray-800 mb-2">PLATFORM</h3>
              <ul className="text-dark">
                <li
                  className="hover:bg-clair-gray w-fit cursor-pointer"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate("/contact-us");
                  }}
                >
                  Contact Us
                </li>
                <li
                  className="hover:bg-clair-gray w-fit cursor-pointer"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate("/terms-and-conditions");
                  }}
                >
                  Terms and condition
                </li>
                <li
                  className="hover:bg-clair-gray w-fit cursor-pointer"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate("/members-agreement");
                  }}
                >
                  Members Agreement
                </li>
                <li>F.A.Q</li>
              </ul>
            </div>
          </div>
          <div className="md:col-span-1 sm:col-span-2 col-span-2 md:p-6 rounded">
            <div>
              <h3 className="font-bold uppercase text-gray-800 mb-2">Support</h3>
              <ul className="text-dark">
                <li
                  className="hover:bg-clair-gray w-fit cursor-pointer"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate("/terms-and-conditions");
                  }}
                >
                  Purchase Terms
                </li>
                <li
                  className="hover:bg-clair-gray w-fit cursor-pointer"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate("/privacy-policy");
                  }}
                >
                  Privacy Policy
                </li>
              </ul>
            </div>
          </div>
          <div className="md:col-span-1 sm:col-span-2 col-span-4 p-6 rounded flex justify-center items-center">
            <p className="text-5xl font-semibold text-gray-500 mb-4 flex justify-end">
              <Logo variant="dark-isotype" />
            </p>
            <p className="text-dark font-semibold md:hidden px-5">© 2021 FORTUNEGOAT</p>
          </div>
        </div>
        <hr className="my-10 border-gray-800" />
        <div className="flex flex-col md:flex-row justify-between">
          <div className="flex flex-row items-center justify-between gap-8 mb-5 md:mb-0">
            <p className="text-dark font-semibold hidden md:block">© 2021 FORTUNEGOAT</p>
            <p className="text-dark font-semibold">
              English <FontAwesomeIcon icon={faChevronDown} />{" "}
            </p>
            <p className="text-dark font-semibold md:px-4 md:hidden">Cookies Settings</p>
          </div>
          <div>
            <div className="flex flex-row items-center gap-2 w-full">
              <p className="text-dark font-semibold px-4 hidden md:block">Cookies Settings</p>
              <div className="mx-2">
                <a
                  href="https://www.facebook.com/profile.php?id=61555401401976&mibextid=LQQJ4do"
                  target="#blank"
                >
                  <FontAwesomeIcon
                    icon={faFacebook}
                    className="text-dark cursor-pointer"
                    size="xl"
                  />
                </a>
              </div>
              <div className="mx-2">
                <a href="https://www.tiktok.com/@fortune_goat?_t=8j4EoROwnh7&_r=1" target="#blank">
                  <FontAwesomeIcon icon={faTiktok} className="text-dark cursor-pointer" size="xl" />
                </a>
              </div>
              <div className="mx-2">
                <a href="https://youtube.com/@Fortune_GOAT?si=hLmOxQtvr8rPUBty" target="#blank">
                  <FontAwesomeIcon
                    icon={faYoutube}
                    className="text-dark cursor-pointer"
                    size="xl"
                  />
                </a>
              </div>
              <div className="mx-2">
                <a href="https://www.instagram.com/fortune.goat/?next=%2F" target="#blank">
                  <FontAwesomeIcon
                    icon={faInstagram}
                    className="text-dark cursor-pointer"
                    size="xl"
                  />
                </a>
              </div>
              <div className="mx-2">
                <a href="https://twitter.com/fortune_goat" target="#blank">
                  <FontAwesomeIcon
                    icon={faXTwitter}
                    className="text-dark cursor-pointer"
                    size="xl"
                  />
                </a>
              </div>
              <div className="mx-2">
                <a href="https://t.me/+HLzs2gEASH9hYmEx" target="#blank">
                  <FontAwesomeIcon
                    icon={faTelegram}
                    className="text-dark cursor-pointer"
                    size="xl"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
