import { useQuery } from "@tanstack/react-query";
import FGABI from "common/contracts/FortuneGoat.json";
import { onReadContract } from "common/services";
import { SentryService } from "common/services/SentryErrorCapture";

export const useBinaryTreeUsersCounter = ({ address, enabled = false }) => {
  const getUsersUnder = async () => {
    try {
      const leftBranchCountResponse = await onReadContract({
        contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS,
        contractABI: FGABI,
        functionName: "usersUnderBranch",
        args: [address, 1],
      });
      const rightBranchCountResponse = await onReadContract({
        contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS,
        contractABI: FGABI,
        functionName: "usersUnderBranch",
        args: [address, 2],
      });

      return {
        left: Number(leftBranchCountResponse),
        right: Number(rightBranchCountResponse),
        total: Number(leftBranchCountResponse) + Number(rightBranchCountResponse),
      };
    } catch (error) {
      SentryService("useBinaryTreeUsersCounter", "getUsersUnder", error.message);
      throw error;
    }
  };

  const { isFetching, status, data, error, refetch } = useQuery({
    queryKey: [`useBinaryTreeUsersCounter`],
    enabled,
    queryFn: async () => getUsersUnder(),
  });

  return {
    data,
    error,
    mutateAsync: refetch,
    loading: isFetching,
    status,
  };
};
