import { useQuery } from "@tanstack/react-query";
import { useUsersService } from "modules/admin/services/Users";
import { useState } from "react";

export const useFetchUserDetail = (queryKey = "userDetail", initialParams = {}) => {
  const [params, setParams] = useState(initialParams);
  const { fetchUserDetail } = useUsersService();

  const queryResults = useQuery({
    queryKey: [queryKey, params],
    refetchOnWindowFocus: false,
    staleTime: 10 * (60 * 1000),
    cacheTime: 15 * (60 * 1000),
    queryFn: () => fetchUserDetail(params),
    select: (data) => data.data,
  });

  return { ...queryResults, params, setParams };
};
