import { useQuery } from "@tanstack/react-query";
import { useCommissionsService } from "modules/admin/services/Commissions";
export const useFetchCommissionsSummary = (queryKey = "commissionsSummary") => {
  const { fetchCommissionsSummary } = useCommissionsService();

  const queryResults = useQuery({
    queryKey: [queryKey],
    queryFn: () => fetchCommissionsSummary(),
    select: (data) => data.data,
  });

  return { ...queryResults };
};
