import { Logo } from "@common/components";
import { Button } from "common/components";

export const JoinUsStep4 = ({isError, loading, lambdaError, localLoading}) => {
    return (
        <div
            className="p-6 w-full flex flex-col justify-center items-center"
            style={{ minHeight: "80vh" }}
        >
            <Logo variant="dark-isotype" />
            {
                loading || localLoading && <h2 className="text-2xl text-dark mb-6">Wait a few seconds...</h2>
            }
            {
                isError || lambdaError && 
                    <div className="flex flex-col items-center gap-4 pt-4"> 
                        <h3>Hubo un problema al registrarte.</h3>
                        <Button type='submit' label="Reintentar"/>
                    </div>
            }
        </div>
    );
};
