import founderBronze from "@assets/icons/bronze.png";
import founderSilver from "@assets/icons/silver.png";
import founderGold from "@assets/icons/gold.png";
import diamondFounder from "@assets/icons/diamond.png";
import platinumFounder from "@assets/icons/platinum.png";
import { useCallback, useEffect, useState } from "react";
import { usePackagesInfo } from "@src/modules/join-us/hooks/usePackagesInfo";
import ImageIcon from '@mui/icons-material/Image';

export const ImageLevel = ({ value }) => {
    // value deberia setearse con el saldo invertido
    const maxValue = 10000;
    const { loading, packages } = usePackagesInfo();
    const [percentValue, setPercentValue] = useState();
    const [prices, setPrices] = useState({});

    const [actualLevel, setActualLevel] = useState();
    const [remainingValue, setRemainingValue] = useState();

    const setPackages = useCallback(() => {
        const obj = {};
        for(const index in packages){
            obj[packages[index]['value']] = packages[index]['totalPrice']
        }
        setPrices(obj)
    },[packages])

    const setLevel = useCallback(() => {
        if(value < prices["founder-bronze"]){
            setActualLevel("no level")
        } else if (value >= prices["founder-bronze"] && value < prices["founder-silver"]) {
            setActualLevel("bronze");
        } else if (value >= prices["founder-silver"] && value < prices["founder-gold"]) {
            setActualLevel("silver");
        } else if (value >= prices["founder-gold"] && value < prices["founder-diamond"]) {
            setActualLevel("gold");
        } else if (value >= prices["founder-diamond"] && value < prices["founder-platinum"]) {
            setActualLevel("diamond");
        } else if (value >= prices["founder-diamond"]) {
            setActualLevel("platinum");
        } else {
            setActualLevel(undefined);
        }
    },[prices,value]);

    const calculateNextLevel = useCallback(() => {
        const nextLevel = Object.keys(prices).find(
            (level) => value < prices[level]
        );

        if (nextLevel) {
            setRemainingValue(prices[nextLevel] - value);
        } else {
            setRemainingValue(undefined);
        }
    },[prices, value]);

    useEffect(()=>{
        if(!loading){
            if(!prices || Object.keys(prices).length == 0){
                setPackages();
            }else{
                setLevel()
                setPercentValue(value / maxValue);
                calculateNextLevel();
            }
        }
    },[loading, setPackages, setLevel, packages, prices, value,maxValue, setPercentValue, calculateNextLevel])
    
    return (
        <div>
            { 
                actualLevel === "no level" && (
                    <>
                    <div className="flex flex-row justify-between">
                        <div className="pt-4 h-auto flex items-center justify-center">
                            <div className="flex justify-center">
                                <img src={founderSilver} className="opacity-5" />
                            </div>
                        </div>
                        <div className="flex justify-center flex-col">
                            <p className="text-dark text-2xl font-bold capitalize">{ actualLevel }</p>
                            <p className="text-gray-500 text-base">Current package</p>
                        </div>
                    </div>
                    </>
                )
            }
            { 
                actualLevel === "bronze" && (
                    <>
                    <div className="flex flex-row">
                        <div className="pt-4">
                            <img src={founderBronze} className="w-36 h-28" />
                        </div>
                        <div className="flex justify-center flex-col">
                            <p className="text-dark text-2xl font-bold capitalize">{ actualLevel }</p>
                            <p className="text-gray-500 text-base">Current package</p>
                        </div>
                    </div>
                    </>
                )
               
            }
            {
                actualLevel === "silver" && (
                    <>
                    <div className="flex flex-row">
                        <div className="pt-4">
                            <img src={founderSilver} className="w-36 h-28" />
                        </div>
                        <div className="flex justify-center flex-col">
                            <p className="text-dark text-2xl font-bold capitalize">{ actualLevel }</p>
                            <p className="text-gray-500 text-base">Current package</p>
                        </div>
                    </div>
                    </>

                )
            }
            {
                actualLevel === "gold" && (
                    <>
                    <div className="flex flex-row">
                        <div className="pt-4">
                            <img src={ founderGold } className="w-36 h-28" />
                        </div>
                        <div className="flex justify-center flex-col">
                            <p className="text-dark text-2xl font-bold capitalize">{ actualLevel }</p>
                            <p className="text-gray-500 text-base">Current package</p>
                        </div>
                    </div>
                    </>
                )
            }
            {
                actualLevel === "diamond" && (
                    <>
                    <div className="flex flex-row">
                        <div className="pt-4">
                            <img src={ diamondFounder } className="w-36 h-28" />
                        </div>
                        <div className="flex justify-center flex-col">
                            <p className="text-dark text-2xl font-bold capitalize ">{ actualLevel }</p>
                            <p className="text-gray-500 text-base">Current package</p>
                        </div>
                    </div>
                    </>
                )
            }
            {
                actualLevel === "platinum" && (
                    <>
                    <div className="flex flex-row">
                        <div className="pt-4">
                            <img src={ platinumFounder } className="w-36 h-28" />
                        </div>
                        <div className="flex justify-center flex-col">
                            <p className="text-dark text-2xl font-bold capitalize">{ actualLevel }</p>
                            <p className="text-gray-500 text-base">Current package</p>
                        </div>
                    </div>
                    </>
                )
            }
        </div>
    );
};
