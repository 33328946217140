import React, { useCallback, useEffect, useState } from "react";
import { useAccount, useBalance, useEstimateFeesPerGas, usePublicClient } from "wagmi";
import { useSendMatic } from "../../hooks/Funds/useSendMatic";
import { AddressField } from "./addressField";
import { SentryService } from "common/services/SentryErrorCapture";

export const FundsForm = () => {
  const { data: gasFeeData, refetch: refetchFeeData } = useEstimateFeesPerGas();
  const client = usePublicClient();
  const { address } = useAccount({});
  const { data: balance, refetch: refetchBalance } = useBalance({ address });
  const [addressTo, setAddress] = useState("");
  const [amount, setAmount] = useState("");
  const { success, error, loading, sendFunds, resetSendFundsState } = useSendMatic();
  const [estimatedGas, setEstimatedGas] = useState(0);
  // console.log("gasFeeData", gasFeeData);
  const refresh = useCallback(() => {
    refetchBalance();
    refetchFeeData();
  }, [refetchBalance, refetchFeeData]);

  useEffect(() => {
    refresh();
  }, [refresh, success, error]);
  useEffect(() => {
    const estimatedSendGas = async () => {
      if (!balance || !balance.value) return;
      try {
        const result = await client.estimateGas({
          account: address,
          to: addressTo || "0x0571B5baFaD35d6c92801F3e0C0A5ece0A46D9Ca",
          value: balance.value,
        });
        setEstimatedGas(
          Number(result) * Number(gasFeeData.gasPrice || gasFeeData.maxFeePerGas) * 1.35
        );
      } catch (error) {
        SentryService("FundsForm", "estimatedSendGas", error);
      }
    };
    if (balance && gasFeeData) {
      estimatedSendGas();
    }
  }, [address, client, gasFeeData, addressTo, amount, setEstimatedGas, balance]);
  const maxBalance = balance
    ? Number(balance.value) - estimatedGas < 0
      ? 0
      : (Number(balance.value) - estimatedGas) / 10 ** 18
    : 0;

  return (
    <div className="mx-auto p-6 bg-white rounded-md shadow-md lg:min-w-[600px]">
      <div className="mx-auto md:max-w-[50%] lg:max-w-[40%]">
        <h2 className="text-2xl font-semibold mb-4">
          Send MATIC
          <small
            className="ml-2 cursor-pointer text-sm text-gray-600"
            onClick={() => {
              refresh();
            }}
          >
            Refresh
          </small>
        </h2>
      </div>
      <AddressField setAddress={setAddress} />
      <div className="mb-4">
        <label
          htmlFor="matic"
          className={`block text-sm font-medium ${
            maxBalance <= 0 ? "text-rose-800" : "text-emerald-800"
          }`}
        >
          Your current MATIC balance: {balance ? Number(balance.value) / 10 ** 18 : 0}
        </label>
        <label htmlFor="matic" className="block text-sm font-medium text-gray-600">
          Estimated gas fees: {estimatedGas / 10 ** 18}
        </label>
        <label htmlFor="matic" className="block text-sm font-medium text-gray-600">
          MATIC amount
        </label>
        <input
          type="text"
          value={amount}
          disabled={maxBalance <= 0}
          onChange={(e) => {
            resetSendFundsState();
            if (maxBalance) {
              setAmount(() => (e.target.value > maxBalance ? maxBalance : e.target.value));
            }
          }}
          id="matic"
          name="matic"
          className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
          placeholder="Enter matic to send"
        />
      </div>
      <div className="flex w-full flex-col justify-end items-end">
        <button
          type="button"
          className="border border-dark text-dark font-bold py-3 px-8 rounded w-full md:w-fit"
          onClick={async () => {
            resetSendFundsState();
            await sendFunds({
              address: addressTo,
              amount: String(amount).substring(0, 13),
              confirmations: 2,
            });
          }}
          disabled={loading}
        >
          {loading ? "sending funds..." : "Send funds"}
        </button>
        <React.Fragment>
          {error && (
            <p>
              <small className="text-rose-800">{error}</small>
            </p>
          )}
          {success && (
            <p>
              <small className="text-emerald-800">{success}</small>
            </p>
          )}
        </React.Fragment>
      </div>
    </div>
  );
};
