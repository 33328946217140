import { getContractFunctions } from "./utils/contractFunctions";
import { calculateCommissions } from "./calculateCommissions";
// import packagesABI from "../../common/contracts/Packages.json"
const unilevelABI = require("../../contracts/Unilevel.json");
const binaryABI = require("../../contracts/Binary.json");
const fgABI = require("../../contracts/FortuneGoat.json");
const qualificationsABI = require("../../contracts/Qualifications.json");
const commissionsABI = require("../../contracts/Commissions.json");
const packagesABI = require("../../contracts/Packages.json");
const usersABI = require("../../contracts/Users.json");
const membershipABI = require("../../contracts/Membership.json");
const userPointsABI = require("../../contracts/UserPoints.json");
export const CalculateUsersRankAndCommissions = async (address, getCommissions = true) => {
  try {
    const addresses = Array.isArray(address) ? address : [address];
    const getFastStartParam = getCommissions;
    const getCommissionsParam = getCommissions;
    const getBinaryMatchingBonusParam = getCommissions;
    const getUnilevelMatchingBonusParam = getCommissions;
    const getLeadershipParam = getCommissions;
    const unilevelContract = process.env.REACT_APP_UNILEVEL_CONTRACT_ADDRESS;
    const binaryContract = process.env.REACT_APP_BINARY_CONTRACT_ADDRESS;
    const fgContract = process.env.REACT_APP_CONTRACT_ADDRESS;
    const qualificationsContract = process.env.REACT_APP_QUALIFICATIONS_CONTRACT_ADDRESS;
    const usersContract = process.env.REACT_APP_USERS_CONTRACT_ADDRESS;
    const commissionsContract = process.env.REACT_APP_COMMISSIONS_CONTRACT_ADDRESS;
    const userPointsContract = process.env.REACT_APP_USERS_POINTS_CONTRACT_ADDRESS;
    const membershipContract = process.env.REACT_APP_MEMBERSHIP_CONTRACT_ADDRESS;
    const packagesContract = process.env.REACT_APP_PACKAGES_CONTRACT_ADDRESS;
    console.debug({
      addresses,
      unilevelContract,
      binaryContract,
      fgContract,
      qualificationsContract,
      usersContract,
      commissionsContract,
      userPointsContract,
      membershipContract,
      packagesContract,
      unilevelABI,
      binaryABI,
      fgABI,
      qualificationsABI,
      commissionsABI,
      packagesABI,
      usersABI,
      membershipABI,
      userPointsABI,
    });

    console.debug("getContractFunctions");
    const contractFunctions = getContractFunctions({
      unilevelContract,
      unilevelABI,
      binaryContract,
      binaryABI,
      fgContract,
      fgABI,
      qualificationsContract,
      qualificationsABI,
      usersContract,
      usersABI,
      commissionsContract,
      commissionsABI,
      userPointsContract,
      userPointsABI,
      membershipABI,
      membershipContract,
      packagesABI,
      packagesContract,
    });
    console.debug("contractFunctions: ", contractFunctions);

    console.debug("calculate commissions");
    const commissionsArray = await calculateCommissions({
      contractFunctions,
      getCommissionsParam,
      getFastStartParam,
      getBinaryMatchingBonusParam,
      getUnilevelMatchingBonusParam,
      getLeadershipParam,
      addresses,
    });
    const commissions = commissionsArray[0];
    console.debug("commissions: ", commissions);
    // alert("commissions");
    const {
      userRank,
      commissions: userInfoCommissions,
      fastStart,
      matchingBonus,
      unilevelMatchingBonus,
      leadership,
    } = commissions;
    console.log("userRank: ", userRank);
    commissions.rank = userRank?.leadership
      ? userRank?.leadership.name
      : userRank?.userPackage
      ? userRank?.userPackage.name
      : null;
    commissions.paymentLevel = {
      actualRange: userRank?.leadership
        ? userRank?.leadership.name
        : userRank?.userPackage
        ? userRank?.userPackage.name
        : null,
      binaryPercent: userRank?.leadership
        ? userRank?.leadership.binaryPercent / 1e2
        : userRank?.userPackage
        ? userRank?.userPackage.binaryPercent / 1e2
        : 0,
      cap: userRank?.leadership
        ? userRank?.leadership.weeklyCap / 1e6
        : userRank?.userPackage
        ? userRank?.userPackage.binaryCap / 1e6
        : 0,
      unilevel: userRank?.activeChildren.level,
      goal: userRank?.activeChildren.nextLevel,
    };
    commissions.range = {
      volumeqv: {
        ...userRank?.personalQV,
        current: userRank?.personalQV.current / 1e6,
        nextLevel: userRank?.personalQV.nextLevel / 1e6,
      },
      binaryPercent: {
        ...userRank?.binaryLegsVolume,
        current: {
          left: userRank?.binaryLegsVolume.current.left / 1e6,
          right: userRank?.binaryLegsVolume.current.right / 1e6,
        },
        nextLevel: userRank?.binaryLegsVolume.nextLevel / 1e6,
      },
      personalVolume: {
        ...userRank?.personalQV,
        current: userRank?.personalQV.current / 1e6,
        nextLevel: userRank?.personalQV.nextLevel / 1e6,
      },
      qualifiedByLeg: {
        ...userRank?.sixtyForty,
        current: {
          sixtySum: userRank?.sixtyForty.current.sixtySum / 1e6,
          fortySum: userRank?.sixtyForty.current.fortySum / 1e6,
        },
        nextLevel: userRank?.sixtyForty.nextLevel / 1e6,
        total:
          (userRank?.sixtyForty.current.fortySum / 1e6) *
          (userRank?.leadership
            ? userRank?.leadership.binaryPercent / 1e4
            : userRank?.userPackage
            ? userRank?.userPackage.binaryPercent / 1e4
            : 0),
      },
    };
    if (!!getCommissions === true) {
      commissions.commissionsBreakdown = {
        binary: {
          total: userInfoCommissions.binaryCommission.total / 1e6,
          binaryCap: userInfoCommissions.binaryCommission.binaryCap / 1e6,
          percentage: userInfoCommissions.binaryCommission.binaryPercentage / 1e2,
          leftBranch: userInfoCommissions.binaryCommission.binaryCV.left / 1e6,
          rightBranch: userInfoCommissions.binaryCommission.binaryCV.right / 1e6,
          commissionBranch: userInfoCommissions.binaryCommission.binaryCV.lowest / 1e6,
        },
        unilevel: {
          total: userInfoCommissions.unilevelCommission.total / 1e6,
          ucv: userInfoCommissions.unilevelCommission.ucv / 1e6,
          commissionBeforePercentage:
            userInfoCommissions.unilevelCommission.commissionBeforePercentage / 1e6,
          percentage: userInfoCommissions.unilevelCommission.unilevelPercentage / 1e2,
        },
        fastStart: {
          total: fastStart.total / 1e6,
          levels:
            fastStart && fastStart.levelsData
              ? fastStart.levelsData.map((level, index) => {
                  const usersInLevel =
                    level && level.addressList && level.addressList.length !== 0
                      ? level.addressList[0].map((user) => ({
                          from: user.from,
                          packageId: user.packageId,
                          amount: user.amount / 1e6,
                          date: user.date * 1000,
                        }))
                      : [];

                  return {
                    affiliates: usersInLevel,
                    commission: level.sum / 1e6,
                    percentage: level.percentage / 1e2,
                    total: level.total / 1e6,
                    level: index + 1,
                  };
                })
              : [],
        },
        matchingBonus: {
          total: matchingBonus.total / 1e6,
          levels:
            matchingBonus && matchingBonus.levels
              ? matchingBonus.levels.map((level, index) => {
                  const affiliates =
                    level && level.binary && level.binary.usersCommissionsBeforePercentage
                      ? level.binary.usersCommissionsBeforePercentage.map((user) => ({
                          user: user.user,
                          commission: user.commission / 1e6,
                        }))
                      : [];
                  return {
                    affiliates,
                    percentage: level.binary.percentage / 1e2,
                    commission: level.binary.commissionsSum / 1e6,
                    total: level.binary.total / 1e6,
                    level: index + 1,
                  };
                })
              : [],
        },
        unilevelMatchingBonus: {
          total: unilevelMatchingBonus.total / 1e6,
          levels:
            unilevelMatchingBonus && unilevelMatchingBonus.levels
              ? unilevelMatchingBonus.levels.map((level, index) => {
                  const affiliates =
                    level && level.unilevel && level.unilevel.usersCommissionsBeforePercentage
                      ? level.unilevel.usersCommissionsBeforePercentage.map((user) => ({
                          user: user.user,
                          commission: user.commission / 1e6,
                        }))
                      : [];
                  return {
                    affiliates,
                    percentage: level.unilevel.percentage / 1e2,
                    commission: level.unilevel.commissionsSum / 1e6,
                    total: level.unilevel.total / 1e6,
                    level: index + 1,
                  };
                })
              : [],
        },
        leadership: {
          total: leadership.total / 1e6,
          leadershipPool: leadership.leadershipPool / 1e6,
          percentage: leadership.percentage / 1e2,
        },
      };
    }

    return commissions;
  } catch (err) {
    console.debug(err);
    if (err instanceof Error) {
      console.log({ err: JSON.stringify(err) });
      console.log({ message: JSON.stringify(err.message) });
      return null;
    } else {
      const errorMessage = "Unknown error occurred";
      console.log({ message: errorMessage });
      return null;
    }
  }
};
