import axios from "axios";

const API = process.env.REACT_APP_AWS_API_URL;

export const useBidPointsService = () => {
  return {
    async fetchBidPointsSummary() {
      const response = await axios.get(`${API}/contracts/fg/bidPoints/summary`);
      return response.data;
    },
  };
};
