// Web3Auth Libraries
import { Web3AuthConnector } from "@web3auth/web3auth-wagmi-connector";
import { Web3Auth } from "@web3auth/modal";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { OpenloginAdapter, OPENLOGIN_NETWORK } from "@web3auth/openlogin-adapter";
import { CHAIN_NAMESPACES, WALLET_ADAPTERS } from "@web3auth/base";
import { TorusWalletConnectorPlugin } from "@web3auth/torus-wallet-connector-plugin";
import logo from "@assets/logos/fortune-goat-dark-isotype.svg";

// Add Torus Wallet Plugin (optional)
export const torusPlugin = new TorusWalletConnectorPlugin({
  torusWalletOpts: {
    buttonPosition: "bottom-left",
  },
  walletInitOptions: {
    whiteLabel: {
      theme: {
        isDark: false,
        colors: {
          torusBrand1: "#282c34",
        },
      },
      logoDark: `${window.location.origin}/polygon-matic-icon.webp`,
      logoLight: `${window.location.origin}/polygon-matic-icon.webp`,
    },
    useWalletConnect: true,
    enableLogging: false,
  },
});
export default function Web3AuthConnectorInstance(chains, language) {
  // Create Web3Auth Instance
  const chainConfig = {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: "0x" + chains[0].id.toString(16),
    rpcTarget: chains[0].rpcUrls.default.http[0], // This is the public RPC we have added, please pass on your own endpoint while creating an app
    displayName: chains[0].name,
    tickerName: chains[0].nativeCurrency?.name,
    ticker: chains[0].nativeCurrency?.symbol,
    blockExplorer: chains[0].blockExplorers?.default.url,
  };

  const web3AuthInstance = new Web3Auth({
    clientId: process.env.REACT_APP_WEB3AUTH_KEY,
    chainConfig,
    // uiConfig refers to the whitelabeling options, which is available only on Growth Plan and above
    // Please remove this parameter if you're on the Base Plan
    uiConfig: {
      appName: "Fortune Goat",
      appLogo: logo, // Your App Logo Here
      theme: {
        primary: "#17c1e8",
      },
      mode: "light",
      logoLight: logo,
      logoDark: logo,
      defaultLanguage: language, // en, de, ja, ko, zh, es, fr, pt, nl
      loginGridCol: 3,
      primaryButton: "externalLogin", // "externalLogin" | "socialLogin" | "emailLogin"
      modalZIndex: "2147483647",
    },
    web3AuthNetwork:
      process.env.REACT_APP_ENVIRONMENT === "production"
        ? OPENLOGIN_NETWORK.SAPPHIRE_MAINNET
        : OPENLOGIN_NETWORK.SAPPHIRE_DEVNET,
    enableLogging: true,
  });

  // Add openlogin adapter for customisations
  const privateKeyProvider = new EthereumPrivateKeyProvider({ config: { chainConfig } });
  const openloginAdapterInstance = new OpenloginAdapter({
    privateKeyProvider,
    adapterSettings: {
      uxMode: "redirect",
      whiteLabel: {
        name: "Fortune Goat",
        logoLight: "logo",
        logoDark: "logo",
        defaultLanguage: language,
        dark: false, // whether to enable dark mode. defaultValue: false
      },
    },
  });
  web3AuthInstance.configureAdapter(openloginAdapterInstance);

  web3AuthInstance.addPlugin(torusPlugin);

  const web3authCon = new Web3AuthConnector({
    chains: chains,
    options: {
      web3AuthInstance,
      modalConfig: {
        [WALLET_ADAPTERS.WALLET_CONNECT_V2]: {
          label: "Wallet Connect",
          showOnModal: false,
        },
        [WALLET_ADAPTERS.TORUS_EVM]: {
          label: "Torus EVM",
          showOnModal: false,
        },
        [WALLET_ADAPTERS.METAMASK]: {
          label: "Metamask",
          showOnModal: false,
        },
        [WALLET_ADAPTERS.COINBASE]: {
          label: "Coinbase",
          showOnModal: false,
        },
      },
    },
  });
  web3authCon.name = "Iniciar con tus redes o teléfono";
  web3authCon.id = "Iniciar con tus redes o teléfono";
  return web3authCon;
}
