export const handleBidsResponse = ({
  data,
  setItems,
  setItemDetails,
  itemDetails,
  setServerTime,
}) => {
  const itemsData = data;
  const itemBid = itemsData.itemToNotify;
  const serverTime = itemsData.serverTime;
  // console.debug("handle bids response", { itemsData });
  if (itemBid) {
    // console.debug("handle bids response itemDetails", { itemDetails });
    if (itemDetails && itemBid.id === itemDetails.id) {
      // console.debug({itemDetails, itemBid})
      setItemDetails(itemBid);
    }

    setItems((prev) => {
      const index = prev.findIndex((item) => item.id == itemBid.id);
      // console.debug("bid", { index });
      if (index == -1) return prev;
      const newItems = [...prev];
      // console.log({ index });
      newItems[index] = {
        ...itemBid,
        canBid: true,
        lastBidder: itemBid.lastBid,
      };

      return newItems;
    });
  }
  if (serverTime) {
    const currentTime = new Date().getTime();
    const offset = currentTime- itemsData.serverTime;
    const newServerTime = { serverTime: itemsData.serverTime, currentTime, offset };
    // console.debug("### new server time bid response ###", newServerTime);

    setServerTime(newServerTime);
  }
};

export const handleUserBidsResponse = ({
  data,
  setItems,
  setItemDetails,
  itemDetails,
  setUser,
  setServerTime,
}) => {
  const itemsData = data.item;
  const userData = data.user;
  const serverTime = data.serverTime;
  // console.debug("handle userBids response", { itemsData, userData, serverTime });

  // console.debug("handle userBids response itemDetails", { itemDetails });
  if (itemsData) {
    // console.debug("handle userBids response itemDetails", { itemDetails });
    if (itemDetails) {
      setItemDetails(itemsData);
    }
    if (itemsData.status == 1) {
      setItems((prev) => {
        const index = prev.findIndex((item) => item.id == itemsData.id);
        if (index == -1) return prev;
        const newItems = [...prev];
        newItems[index] = { ...itemsData, canBid: true, lastBidder: itemsData.lastBid };
        return newItems;
      });
    }
  }
  if (userData) {
    setUser(userData);
  }
  if (serverTime) {
    const currentTime = new Date().getTime();
    const offset = currentTime -serverTime;
    const newServerTime = { serverTime, currentTime, offset };
    // console.debug("### new server time userbids ###", newServerTime);
    setServerTime(newServerTime);
  }
};
