import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useCalendarService } from "modules/admin/services/Calendar";

export const useFetchEvents = (queryKey = "calendarEvents", initialParams = {}) => {
  const [params, setParams] = useState({
    ...initialParams,
  });

  const { fetchEvents } = useCalendarService();

  const queryResults = useQuery({
    queryKey: [queryKey],
    queryFn: () => fetchEvents(params),
    select: (data) => data.data,
  });

  return { ...queryResults, setParams };
};
