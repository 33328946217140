import { onReadContract } from "../../../onReadContract";

export const usersFunctions = ({ usersContract, usersABI }) => {
  const readUsers = async ({ functionName, args }) => {
    return await onReadContract({
      contractAddress: usersContract,
      contractABI: usersABI,
      functionName,
      args,
    });
  };

  const usersByCounter = async (start, limit) => {
    const args = [start, limit];
    const UsersByCounterResponse = await readUsers({
      functionName: "getUsersByCounterMap",
      args,
    });
    console.debug("UsersByCounterResponse: ", UsersByCounterResponse);
    return UsersByCounterResponse;
  };
  const usersCount = async () => {
    const args = [];
    const usersCountResponse = await readUsers({
      functionName: "usersCount",
      args,
    });
    console.debug("usersCount: ", usersCountResponse);
    return Number(usersCountResponse);
  };

  return { usersByCounter, usersCount };
};
