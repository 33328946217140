import { onReadContract } from "../../../onReadContract";

export const fortuneGoatFunctions = ({ fgContract, fgABI }) => {
  const userInfoCache = {};
  const userPackageCache = {};
  const packagesListCache = [];
  const readFortuneGoat = async ({ functionName, args }) => {
    return await onReadContract({
      contractAddress: fgContract,
      contractABI: fgABI,
      functionName,
      args,
    });
  };

  const fortuneGoatUserPackage = async (address) => {
    console.debug("userPackage: ", address);
    if (userPackageCache && userPackageCache[address]) {
      return userPackageCache[address];
    }
    const userPackageObject = {
      packageId: 0,
      nextPackageId: 0,
      totalToPay: 0,
      totalPaid: 0,
      registerDate: 0,
      paidDate: 0,
      isPaid: false,
    };
    const args = [address];
    const userPackageResponse = await readFortuneGoat({
      functionName: "getUserPackage",
      args,
    });

    console.debug("userPackageResponse: ", userPackageResponse);
    if (userPackageResponse) {
      userPackageObject.packageId = Number(userPackageResponse.packageId);
      userPackageObject.nextPackageId = Number(userPackageResponse.nextPackageId);
      userPackageObject.totalToPay = Number(userPackageResponse.totalToPay);
      userPackageObject.totalPaid = Number(userPackageResponse.totalPaid);
      userPackageObject.registerDate = Number(userPackageResponse.registerDate);
      userPackageObject.paidDate = Number(userPackageResponse.paidDate);
      userPackageObject.isPaid = Boolean(Number(userPackageResponse.isPaid));
    }
    if (userPackageCache) {
      userPackageCache[address] = userPackageObject;
    }
    return userPackageObject;
  };

  const fortuneGoatPackagesList = async () => {
    if (packagesListCache && packagesListCache.length > 0) {
      return packagesListCache;
    }
    const args = [0];
    const packagesListResponse = await readFortuneGoat({
      functionName: "getPackagesList",
      args,
    });
    console.debug("packagesListResponse: ", packagesListResponse);
    if (packagesListResponse) {
      const packagesList = packagesListResponse.map((currentPackage) => {
        return {
          fullPackage: {
            id: Number(currentPackage.fullPackage.id),
            price: Number(currentPackage.fullPackage.price),
            vat: Number(currentPackage.fullPackage.vat),
            bidConsumable: Number(currentPackage.fullPackage.bidConsumable),
            turboFund: Number(currentPackage.fullPackage.turboFund),
            qv: Number(currentPackage.fullPackage.qv),
            ucv: Number(currentPackage.fullPackage.ucv),
            bcv: Number(currentPackage.fullPackage.bcv),
            name: currentPackage.fullPackage.name,
            consumablePercent: Number(currentPackage.fullPackage.consumablePercent),
            isUpgradePackage: currentPackage.fullPackage.isUpgradePackage,
          },
          fullPackageBinary: {
            packageId: Number(currentPackage.fullPackageBinary.packageId),
            binaryPercent: Number(currentPackage.fullPackageBinary.binaryPercent),
            binaryCap: Number(currentPackage.fullPackageBinary.binaryCap),
          },
          fullPackageBid: {
            packageId: Number(currentPackage.fullPackageBid.packageId),
            bidPrice: Number(currentPackage.fullPackageBid.bidPrice),
            totalBids: Number(currentPackage.fullPackageBid.totalBids),
          },
          fullPackageExpirations: {
            packageId: Number(currentPackage.fullPackageExpirations.packageId),
            bidExpirationDays: Number(currentPackage.fullPackageExpirations.bidExpirationDays),
            qualificationExpirationDays: Number(
              currentPackage.fullPackageExpirations.qualificationExpirationDays
            ),
            cashbackExpirationDays: Number(
              currentPackage.fullPackageExpirations.cashbackExpirationDays
            ),
          },
          fullPackageBonus: {
            packageId: Number(currentPackage.fullPackageBonus.packageId),
            startupBonus: Number(currentPackage.fullPackageBonus.startupBonus),
            matchingBonus: Number(currentPackage.fullPackageBonus.matchingBonus),
            unilevelBonus: Number(currentPackage.fullPackageBonus.unilevelBonus),
          },
          fullPackageCashback: {
            packageId: Number(currentPackage.fullPackageCashback.packageId),
            cashback: Number(currentPackage.fullPackageCashback.cashback),
          },
        };
      });
      packagesListCache.push(...packagesList);
      return packagesList;
    }
    return [];
  };
  const fortuneGoatPackagesDecimals = async () => {
    const args = [];
    const qualificationsMatchingBonusResponse = await readFortuneGoat({
      functionName: "getPackagesDecimals",
      args,
    });

    console.debug("qualificationsMatchingBonusResponse: ", qualificationsMatchingBonusResponse);
    return Number(qualificationsMatchingBonusResponse);
  };
  const fortuneGoatUserInfo = async (address) => {
    if (userInfoCache && userInfoCache[address]) {
      return userInfoCache[address];
    }
    const args = [address];
    const userInfoResponse = await readFortuneGoat({
      functionName: "getUserInfo",
      args,
    });

    console.debug("userInfoResponse: ", userInfoResponse);

    if (!userInfoCache[address]) {
      userInfoCache[address] = [
        {
          id: userInfoResponse[0].id,
          sponsor: userInfoResponse[0].sponsor,
          name: userInfoResponse[0].name,
          lastname: userInfoResponse[0].lastname,
          username: userInfoResponse[0].username,
          email: userInfoResponse[0].email,
          country: userInfoResponse[0].country,
          zipcode: Number(userInfoResponse[0].zipcode),
          phone: userInfoResponse[0].phone,
          registrationDate: Number(userInfoResponse[0].registrationDate),
          status: Number(userInfoResponse[2]),
        },
        {
          id: userInfoResponse[1].id,
          parentId: userInfoResponse[1].parentId,
          leftChild: userInfoResponse[1].leftChild,
          rightChild: userInfoResponse[1].rightChild,
          sponsorId: userInfoResponse[1].sponsorId,
          userName: userInfoResponse[1].userName,
          sponsorUsername: userInfoResponse[1].sponsorUsername,
          sponsorBranch: Number(userInfoResponse[1].sponsorBranch),
          userSelectedBranch: Number(userInfoResponse[1].userSelectedBranch),
          lastBranchUsed: Number(userInfoResponse[1].lastBranchUsed),
        },
      ];
    }

    return [
      {
        id: userInfoResponse[0].id,
        sponsor: userInfoResponse[0].sponsor,
        name: userInfoResponse[0].name,
        lastname: userInfoResponse[0].lastname,
        username: userInfoResponse[0].username,
        email: userInfoResponse[0].email,
        country: userInfoResponse[0].country,
        zipcode: Number(userInfoResponse[0].zipcode),
        phone: userInfoResponse[0].phone,
        registrationDate: Number(userInfoResponse[0].registrationDate),
        status: Number(userInfoResponse[2]),
      },
      {
        id: userInfoResponse[1].id,
        parentId: userInfoResponse[1].parentId,
        leftChild: userInfoResponse[1].leftChild,
        rightChild: userInfoResponse[1].rightChild,
        sponsorId: userInfoResponse[1].sponsorId,
        userName: userInfoResponse[1].userName,
        sponsorUsername: userInfoResponse[1].sponsorUsername,
        sponsorBranch: Number(userInfoResponse[1].sponsorBranch),
        userSelectedBranch: Number(userInfoResponse[1].userSelectedBranch),
        lastBranchUsed: Number(userInfoResponse[1].lastBranchUsed),
      },
    ];
  };

  return {
    fortuneGoatUserPackage,
    fortuneGoatUserInfo,
    fortuneGoatPackagesDecimals,
    fortuneGoatPackagesList,
  };
};
