import { LegalInfoLayout } from "@layouts/LegalInfoLayout";
import React from "react";

export const PrivacyPolicyPage = () => {
  const menu = [
    {
      title: "1. ¿Qué datos recopilamos?",
      id: "datos-recopilados",
      content: `
            "Datos personales" es la información que es recopilada por medio de formularios relacionados con nuestros
            productos, tal como nombre, identificación, dirección, número de teléfono, correo electrónico y otra información
            similar. 
            <br/>
            "Datos de uso" son datos personales que identifican al usuario, así como información sobre los servicios de
            fortunegoat.com que utiliza el usuario, por ejemplo, su nombre de usuario de fortunegoat.com junto con la
            contraseña asociada y su dirección IP cuando compra productos o inicia sesión en el sitio.
            <br/>
            Recopilamos estos datos para que usted pueda utilizar fortunegoat.com y para ayudarnos a personalizar y
            mejorar continuamente su experiencia en el Sitio. Mantenemos todos los datos en ____ nosotros mismos y con
            la ayuda de proveedores de servicios. fortunegoat.com está dirigido a un público general mayor de 18 años.
            `,
    },
    {
      title: "2. Sobre el uso de la información",
      id: "uso-de-informacion",
      content: `
            La información que Fortune Goat recopile es para brindarle una experiencia segura, fluida, eficiente y
            personalizada en fortunegoat.com. Usted acepta que dicha información obtenida conforme lo indicado en el
            punto anterior y la cual únicamente puede ser solicitada por el personal autorizado y debidamente capacitado en
            el manejo de información de los clientes que se relacione con sus transacciones, será utilizada únicamente con
            los siguientes propósitos:
            <ul>
                <li>Proporcionar los servicios y atención que el cliente que solicite;</li>
                <li>Resolver disputas y solucionar problemas;</li>
                <li>Prevenir actividades potencialmente prohibidas o ilegales y hacer cumplir nuestros Términos y
                Condiciones de uso;</li>
                <li>Personalizar, medir y mejorar nuestros servicios, contenidos y publicidad;</li>
                <li>Informar sobre nuestros servicios, marketing dirigido, actualizaciones del sitio y ofertas promocionales
                según sus preferencias de comunicación.</li>
            </ul>
            <br/>
            La información que es recopilada por Fortune Goat para los objetivos señalados, se mantendrá en control y
            confidencialidad bajo estrictas medidas de seguridad para salvaguardar su integridad, la Política de Privacidad y
            las Pautas para la Conducta de los Negocios, además, no será compartida por Fortune Goat, ni por los
            funcionarios que tienen acceso a dicha información, salvo en los casos aquí previstos o en los casos que la
            legislación y la normativa aplicable contemplen.
            `,
    },
    {
      title: "3. Información compartida",
      id: "informacion-compartida",
      content: `
            En cuanto a la información recopilada por parte de Fortune Goat, esta será de uso exclusivo por el personal
            interno. Sin embargo, es posible que algunos de estos datos recogidos también puedan ser usados por nuestros
            proveedores de servicios para la divulgación de productos, promociones, servicios, descuentos y demás
            información relacionada al comercio. Además, podrá ser que Fortune Goat divulgue datos de carácter personal
            por mandato legal o petición de las autoridades reguladores, con autoridades competentes que así lo soliciten
            en el ejercicio de sus facultades y cuando así lo ordene una autoridad judicial.
            `,
    },
    {
      title: "4. Enlaces a terceros",
      id: "enlaces-a-terceros",
      content: `
            Es posible que algunos de nuestros productos tengan enlaces a sitios o servicios de terceros. Dichos sitios
            están sujetos a sus respectivas políticas de privacidad por lo que recomendamos consultarlas para confirmar
            que usted está de acuerdo.
            `,
    },
    {
      title: "5. Seguridad de la información",
      id: "seguridad-de-la-informacion",
      content: `
            Fortune Goat garantizará la protección de los datos personales por medio de cifrado durante el uso de nuestros
            productos, estos datos se conservarán durante el tiempo necesario para cumplir los fines por los que fueron
            recogidos. Fortune Goat garantiza a sus usuarios que cuenta con protocolos de seguridad, protocolos de
            actuación y protocolos de transferencia de datos, de conformidad con la normativa vigente.
            <br/>
            Sin embargo, a pesar de nuestros esfuerzos, ninguna transmisión de datos por Internet es completamente
            segura, y no podemos garantizar la seguridad absoluta de su información.
            `,
    },
    {
      title: "6. Ver/cambiar datos personales",
      id: "datos-personales",
      content: `
            Puede ver la información en su cuenta de fortunegoat.com cualquier momento y actualizarla según sea
            necesario utilizando su nombre de usuario de fortunegoat.com y su contraseña. También puedes contactar en
            cualquier momento con nuestro Servicio de Atención al Cliente para actualizar tu información. Una vez que
            seamos informados, ajustaremos los datos incorrectos.
            <br/>
            Si lo solicita, le informaremos en cualquier momento si se han almacenado datos personales con respecto a su
            identidad y qué datos personales se han almacenado.
            `,
    },
    {
      title: "7. Eliminación de datos personales",
      id: "eliminacion-datos-personales",
      content: `
            En la medida en que lo requieran los estatutos aplicables, puede revocar su consentimiento para que
            almacenemos, recopilemos y procesemos sus datos personales en cualquier momento.
            <br/>
            Usted puede eliminar sus datos y desactivar su cuenta fortunegoat.com en cualquier momento. Para eliminar su
            cuenta, envíe un correo electrónico a ____ o navegue hasta Configuración > Eliminar cuenta en la aplicación
            ____. Fortune Goat puede retener datos según sea necesario para fines regulatorios o de servicio al cliente o
            para fines definidos en la Política de privacidad y los Términos de uso.
            `,
    },
    {
      title: "8. Cookies y tecnologías similares",
      id: "cookies-tecnologias-similares",
      content: `
            Las cookies son un pequeño archivo con datos que se guarda en el ordenador del usuario cuando visita una
            página. Este fichero almacena cierta información sobre el usuario, como por ejemplo, su comportamiento
            navegando por internet o las credenciales de usuario.
            <br/>
            Fortune Goat a través de su página web utiliza cookies y tecnologías similares para recopilar información sobre
            su interacción con nuestro sitio y mejorar su experiencia, sin embargo, puede configurar esta opción en el
            navegador de su preferencia. Nos gustaría señalar que desactivar la capacidad de su navegador para aceptar
            cookies puede resultar en una funcionalidad limitada.
            `,
    },
    {
      title: "9. Modificación de esta política",
      id: "modificacion-politica",
      content: `
            Fortune Goat se reserva el derecho a modificar esta Política de Privacidad con el propósito de adaptarla a la
            normativa vigente, lo cual será debidamente informado a cada cliente/usuario.
            `,
    },
    {
      title: "10. Responsabilidades en el uso de la plataforma tecnológica y del sitio web",
      id: "responsabilidades-de-uso",
      content: `
            El Cliente es responsable por mantener absoluta confidencialidad sobre su nombre de usuario, clave de acceso
            y cualquier otro mecanismo de seguridad proporcionado por Fortune Goat para efectos de utilizar el Sitio. El
            cliente acepta la responsabilidad por cualquier actividad ilegal o no permitida que se realice mediante la
            utilización de sus datos en la plataforma fortunegoat.com. En este sentido el Cliente se compromete, por su
            cuenta a no permitir la utilización de sus datos por parte de terceros no autorizados.
            <br/>
            La confidencialidad de la información que el cliente proporcionen a Fortune Goat le será asegurada a cualquier
            persona, independientemente de su nacionalidad residencia o domicilio, el respeto a sus derechos
            fundamentales, concretamente, su derecho a la autodeterminación informativa en relación con su vida o
            actividad privada y demás derechos de la personalidad, así como la defensa de su libertad e igualdad con
            respecto al tratamiento automatizado o manual de los datos correspondientes a su persona o bienes.
            <br/>
            El cliente se compromete a informar a Fortune Goat sobre cualquier uso no autorizado, por parte de un tercero,
            de su cuenta o contraseña, o mecanismos de seguridad y/o la de sus autorizados.
            <br/>
            El cliente y libera a Fortune Goat de toda responsabilidad por daños o perjuicios ocurridos y ocasionados como
            consecuencia de la utilización de los datos del Cliente por parte de terceros no autorizados. Asimismo, Fortune
            Goat se reserva el derecho de rechazar una solicitud de servicio, cerrar una cuenta o dar por finalizada la
            relación contractual, a su sola discreción y, en especial, ante la sospecha de cualquier actividad irregular, el uso
            de los datos del cliente por parte de terceros no autorizados.
            <br/>
            Fortune Goat podrá cerrar o restringir el acceso de un cliente a la plataforma tecnológica y/o terminar la relación
            contractual con el cliente, si se detecta algún uso indebido de la plataforma tecnológica o del sitio web,
            entendiendo como uso indebido de manera enunciativa, más no limitativa, los siguientes supuestos:
            <ol>
                <li>Utilizar la plataforma tecnológica o el sitio web para cualquier uso distinto de los autorizados.</li>
                <li>La utilización de mecanismos o herramientas automatizadas o tecnología similar cuya finalidad sea
                realizar la extracción, obtención o recopilación, directa o indirecta, de cualquier información contenida
                en el sitio web o en la plataforma tecnológica.</li>
                <li>Cualquier intento de modificación, adaptación, traducción, o conversión de los formatos o programas
                de cómputo del Sitio web o de la plataforma tecnológica o a los contenidos de estos.</li>
                <li>Utilizar o poner los códigos HTML a disposición de un tercero.</li>
                <li>Recopilar y utilizar las descripciones de los productos.</li>
                <li>Copiar, imitar, replicar para su uso en servidores espejo, reproducir, distribuir, publicar, descargar,
                mostrar o transmitir cualquier contenido del Sitio web o en la plataforma tecnológica (incluyendo
                marcas registradas) en cualquier forma o por cualquier medio; esta restricción incluye, pero no se limita
                a los siguientes medios: medios electrónicos, mecánicos, medios de fotocopiado, grabación o
                cualquier otro medio.</li>
                <li>Acceder a datos no destinados al usuario o iniciar sesión en un servidor o cuenta en la que el usuario no
                tenga acceso autorizado.</li>
                <li>Intentar interferir con el servicio a cualquier usuario, huésped o red, incluyendo, sin limitación, a través
                del envío de virus al Sitio o plataforma tecnológica, sobrecarga, inundación, spam, bombardeo de
                correo o fallas.</li>
                <li>Falsificar cualquier encabezado de TCP/IP o cualquier parte de la información del encabezado en
                cualquier correo electrónico o grupo de noticias.</li>
                <li>Intento o realización de actividades fraudulentas entre las que se encuentran sin limitar, la falsificación
                de identidades o formas de pago.</li>
                <li>Proporcionar cualquier información falsa o inexacta.</li>
            </ol>
            `,
    },
  ];
  return (
    <LegalInfoLayout title="Política de Privacidad" menu={menu}>
      <p className="mt-6 italic">Última actualización 08 de agosto de 2024</p>
      <h2 className="text-2xl font-bold my-4">POLÍTICA DE PRIVACIDAD</h2>
      {`            
            FORTUNE GOAT (en adelante identificada como “FORTUNE GOAT”) como una entidad comprometida
            con la seguridad de sus clientes y usuarios, en cumplimiento con la Ley 8968, Ley de Protección de la Persona
            frente al tratamiento de sus datos personales, pone a su disposición la Política de Privacidad y Tratamiento de
            Datos, la cual invitamos a leer detenidamente. Al ingresar a nuestros productos tales como el sitio web, aplicaciones
            móviles y demás herramientas, acepta de forma inmediata nuestra política.
            <br/>
            El objetivo de esta Política es garantizar a nuestros clientes y el compromiso y responsabilidad que mantiene
            Fortune Goat con la recopilación, el uso y tratamiento de los datos que se proveen a través de nuestros formularios
            o de nuestra página web. 
            `
        .split("\n")
        .map((paragraph, index) => (
          <p key={index} className="mb-2" dangerouslySetInnerHTML={{ __html: paragraph }} />
        ))}
      {menu.map((item, index) => (
        <div key={index} id={item.id} className="mt-6" style={{ scrollMarginTop: "100px" }}>
          <h2 className="text-2xl font-bold my-4">{item.title}</h2>
          {item.content.split("\n").map((paragraph, index) => (
            <p key={index} className="mb-2" dangerouslySetInnerHTML={{ __html: paragraph }} />
          ))}
        </div>
      ))}
    </LegalInfoLayout>
  );
};
