import axios from "axios";

const API = process.env.REACT_APP_AWS_API_URL;

export const useUsersService = () => {
  return {
    async fetchUserWalletHistory(params) {
      const response = await axios.get(`${API}/contracts/fg/user/wallet`, {
        params
      });
      return response.data;
    },
    async fetchPaymentHistory(params) {
      const response = await axios.get(`${API}/contracts/fg/paymentHistory`, {
        params
      });
      return response.data;
    },
    async fetchUserDetail(params) {
      const response = await axios.get(`${API}/contracts/fg/user`, {
        params
      });
      return response.data;
    },
    async fetchUsers(params) {
      const response = await axios.get(`${API}/contracts/fg/users`, {
        params
      });
      return response.data;
    },
    async updateUser(userData) {
      const response = await axios.post(`${API}/contracts/fg/updateUser`, userData);
      return response.data;
    },
    async deleteUser(userData) {
      const response = await axios.post(`${API}/contracts/fg/deleteUser`, userData);
      return response;
    },
  };
};
