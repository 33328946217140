import { useQuery } from "@tanstack/react-query";
import { useBidPointsService } from "modules/admin/services/useBidPointsService";

export const useFetchBidPointsSummary = (queryKey = "bidPointsSummary") => {
  const { fetchBidPointsSummary } = useBidPointsService();

  const queryResults = useQuery({
    queryKey: [queryKey],
    retry: 3,
    retryDelay: 3000,
    refetchOnWindowFocus: false,
    staleTime: 10 * (60 * 1000),
    cacheTime: 15 * (60 * 1000),
    queryFn: () => fetchBidPointsSummary(),
    select: (data) => data.data,
  });

  return { ...queryResults };
};
