import { useQuery } from "@tanstack/react-query";
import { useBidsService } from "modules/admin/services/Bids";
import { useState } from "react";

export const useFetchBids = (queryKey = "bids", initialParams = {}) => {
  const [params, setParams] = useState(initialParams);

  const { fetchBids } = useBidsService();

  const queryResults = useQuery({
    queryKey: [queryKey, params],
    queryFn: () => fetchBids(params),
    select: (data) => data.data,
  });

  return { ...queryResults, setParams, params };
};
