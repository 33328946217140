import axios from "axios";
import { SentryService } from "./SentryErrorCapture";

export const onRequestRegisterUser = async ({
  signature,
  sponsorUsername,
  userId,
  userSelectedBranch = 0,
  name,
  lastName,
  username,
  email,
  country,
  zip,
  phone,
  amountToPay = 0,
  underUsername = "0x0000000000000000000000000000000000000000",
  underUserBranch = 0,
}) => {
  if (!signature) {
    throw new Error("signature is required");
  }
  if (!sponsorUsername) {
    throw new Error("sponsorUsername is required");
  }
  if (!userId) {
    throw new Error("userId is required");
  }
  if (!username) {
    throw new Error("username is required");
  }
  try {
    const registerUserResponse = await axios.post(
      `${process.env.REACT_APP_AWS_API_URL}/contracts/fg/registerUser`,
      {
        sponsorUsername,
        userId,
        userSelectedBranch,
        name,
        lastName,
        username,
        email,
        country,
        zip,
        phone,
        amountToPay,
        underUsername,
        underUserBranch,
        signature,
      }
    );
    if (registerUserResponse) {
      return registerUserResponse.data;
    }
  } catch (error) {
    SentryService("onRequestRegisterUser", "onRequestRegisterUser", error)
    // console.debug(error);
    throw new Error(
      error.response
        ? error.response.data.error.reason ||
          error.response.data.error.shortMessage ||
          error.response.data.error
        : error
    );
  }
};
