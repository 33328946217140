import { onGetBinaryMatchBonus } from "./onGetBinaryMatchBonus";
import { getMatchingBonusPercentages as defaultDetMatchingBonusPercentages } from "./getMatchingBonusPercentages";
import { getLevelAddresses as defaultGetLevelAddresses } from "./getLevelAddresses";
import { getLevelSum as defaultGetLevelSum } from "../common/getLevelSum";

export const getBinaryMatchBonus = ({
  contractFunctions,
  getMatchingBonusPercentages = defaultDetMatchingBonusPercentages,
  getLevelAddresses = defaultGetLevelAddresses,
  getLevelSum = defaultGetLevelSum,
  getBinaryCommission,
}) => {
  return async ({ userRank, address }) =>
    onGetBinaryMatchBonus({
      userRank,
      address,
      contractFunctions,
      getMatchingBonusPercentages,
      getLevelAddresses,
      getLevelSum,
      getBinaryCommission,
    });
};
