export const onGetRank = async ({
  address,
  getUserInfo,
  getMaxLevel,
  getUsersTotalPaid,
  getUserRank,
  contractFunctions,
  userRanksCache,
}) => {
  const validatePersonalQV = (leadershipsList, userQV) => {
    let level = 0;
    if (!leadershipsList || leadershipsList.length == 0) throw new Error("Missing leadership list");
    if (!userQV || userQV === 0) {
      return {
        level,
        current: 0,
        nextLevel: leadershipsList[level].personalQV,
      };
    }
    for (let i = 0; i < leadershipsList.length; i++) {
      console.log("leadershipsList[level].personalQV: ", leadershipsList[i]);
      if (leadershipsList && leadershipsList[i] && leadershipsList[i].personalQV <= userQV) {
        level = i + 1;
      }
    }
    return {
      level,
      current: userQV,
      nextLevel: level >= leadershipsList.length ? null : leadershipsList[level].personalQV,
    };
  };
  const validateUnilevelQualifyingVolume = (leadershipsList, leadershipLevel = 0, uVolume) => {
    let level = 0;
    const totalUVolume = uVolume.reduce((startVol, volume) => {
      return (startVol += volume);
    }, 0);
    if (!leadershipsList || !uVolume || leadershipLevel == 0)
      return {
        level,
        current: totalUVolume || 0,
        nextLevel: leadershipsList[0].unilevelQVperMonth,
      };

    if (totalUVolume == 0 || totalUVolume < leadershipsList[0].unilevelQVperMonth)
      return {
        level,
        current: totalUVolume || 0,
        nextLevel: leadershipsList[0].unilevelQVperMonth,
      };
    for (let i = 0; i < leadershipsList.length; i++) {
      // console.log({ totalUVolume, target: leadershipsList[i].unilevelQVperMonth });
      if (leadershipsList[i].unilevelQVperMonth <= totalUVolume) {
        level = i + 1;
      } else {
        break;
      }
    }
    return {
      level,
      current: totalUVolume,
      nextLevel: level >= leadershipsList.length ? null : leadershipsList[level].unilevelQVperMonth,
    };
  };
  const validateSixtyFortyRule = (leadershipsList, leadershipLevel = 0, uVolume) => {
    // console.log({ leadershipLevel, uVolume });
    let level = 0;
    if (!uVolume || uVolume.length == 0)
      return {
        level,
        current: { sixtySum: 0, fortySum: 0 },
        nextLevel: leadershipsList[level].unilevelQVperMonth,
      };

    const uVolumeSorted = [...uVolume].sort((a, b) => a - b).filter((value) => value > 0);
    const max = uVolumeSorted.pop();
    let sixtySum = 0;
    let fortySum = 0;
    if (uVolume.length < 2 || leadershipLevel == 0) {
      const leadershipLevelSixty = leadershipsList[0].unilevelQVperMonth * 0.6;
      const leadershipLevelForty = leadershipsList[0].unilevelQVperMonth * 0.4;
      sixtySum = max ? (max >= leadershipLevelSixty ? leadershipLevelSixty : max) : 0;
      fortySum = uVolumeSorted.reduce(
        (a, b) => (b > leadershipLevelForty ? a + leadershipLevelForty : a + b),
        0
      );
      return {
        level,
        current: { sixtySum, fortySum },
        nextLevel: leadershipsList[level].unilevelQVperMonth,
      };
    }
    for (let i = 0; i < leadershipsList.length; i++) {
      const leadershipLevelSixty = leadershipsList[i].unilevelQVperMonth * 0.6;
      const leadershipLevelForty = leadershipsList[i].unilevelQVperMonth * 0.4;
      sixtySum = max ? (max >= leadershipLevelSixty ? leadershipLevelSixty : max) : 0;
      fortySum = uVolumeSorted.reduce(
        (a, b) => (b > leadershipLevelForty ? a + leadershipLevelForty : a + b),
        0
      );
      const totalSum = sixtySum + fortySum;
      // console.log({ leadershipLevelSixty, leadershipLevelForty, sixtySum, fortySum, totalSum, target:leadershipsList[i].unilevelQVperMonth });
      if (leadershipsList[i].unilevelQVperMonth <= totalSum) {
        level = i + 1;
      } else {
        break;
      }
    }
    return {
      level,
      current: { sixtySum, fortySum },
      nextLevel: level >= leadershipsList.length ? null : leadershipsList[level].unilevelQVperMonth,
    };
  };
  const validateBinaryLegsVolume = (leadershipsList, leadershipLevel = 0, bVolume) => {
    // console.log({ leadershipLevel, bVolume });
    let level = 0;
    if (!bVolume || leadershipLevel == 0 || bVolume.left == 0 || bVolume.right == 0) {
      return {
        level,
        current: bVolume,
        nextLevel:
          leadershipsList[level].unilevelQVperMonth * (leadershipsList[level].binaryPercent / 100),
      };
    }
    for (let i = 0; i < leadershipsList.length; i++) {
      const minPerLeg =
        leadershipsList[i].unilevelQVperMonth * (leadershipsList[i].binaryPercent / 100);
      // console.log({ minPerLeg, left: bVolume.left, right: bVolume.right });
      if (bVolume.left >= minPerLeg && bVolume.right >= minPerLeg) {
        level = i + 1;
      } else {
        break;
      }
    }
    return {
      level,
      current: bVolume,
      nextLevel:
        level >= leadershipsList.length
          ? null
          : leadershipsList[level].unilevelQVperMonth *
            (leadershipsList[level].binaryPercent / 100),
    };
  };
  const validateActiveChildren = async (
    leadershipsList,
    leadershipLevel = 0,
    hasSponsored,
    packagesList
  ) => {
    console.debug("VALIDATE ACTIVE CHILDREN",{ leadershipsList, hasSponsored });
    let level = 0;
    if (!packagesList) {
      return {
        level,
        current: {
          left: [],
          right: [],
          leftCount: 0,
          rightCount: 0,
        },
        nextLevel: level >= leadershipsList.length ? null : leadershipsList[level].binaryCQ,
      };
    }

    const sponsoredUsersPreFilter = await getUsersTotalPaid(
      hasSponsored,
      packagesList,
      contractFunctions
    );

    const sponsoredUsers = sponsoredUsersPreFilter.filter(
      (sponsored) => sponsored.packageId != undefined
    );
    const usersByBranch = {
      left: sponsoredUsers.filter((sponsored) => sponsored.sponsorBranch == 1),
      right: sponsoredUsers.filter((sponsor) => sponsor.sponsorBranch == 2),
      leftCount: 0,
      rightCount: 0,
    };
    usersByBranch.leftCount = usersByBranch.left.length || 0;
    usersByBranch.rightCount = usersByBranch.right.length || 0;
    // console.log({ sponsoredUsers });
    if (!sponsoredUsers || sponsoredUsers.length < 4)
      return {
        level,
        current: usersByBranch,
        nextLevel: leadershipsList[level].binaryCQ,
      };

    // console.log({ usersByBranch });
    if (usersByBranch.left.length < 2 || usersByBranch.right.length < 2)
      return {
        level,
        current: usersByBranch,
        nextLevel: leadershipsList[level].binaryCQ,
      };
    for (let i = 0; i < leadershipsList.length; i++) {
      const requiredUsers = leadershipsList[i].binaryCQ;
      // console.log({ requiredUsers, usersByBranch });
      if (usersByBranch.leftCount >= requiredUsers && usersByBranch.rightCount >= requiredUsers) {
        level = i + 1;
      } else {
        break;
      }
    }
    return {
      level,
      current: usersByBranch,
      nextLevel: level >= leadershipsList.length ? null : leadershipsList[level].binaryCQ,
    };
  };

  try {
    if (userRanksCache && userRanksCache[address] !== undefined) {
      return userRanksCache[address];
    }
    const userInfo = await getUserInfo(address, contractFunctions);
    if (!userInfo.userPackage) {
      console.log("user does not have a package");
    }
    const userPersonalQV = validatePersonalQV(userInfo.leadershipsList, userInfo.userQV);

    if (!userPersonalQV) throw new Error("missing user personal qv");
    console.log(
      "MISSING PERSONAL QV ----------------------------->: ",
      userPersonalQV,
      userInfo.address
    );

    const unilevelQV = validateUnilevelQualifyingVolume(
      userInfo.leadershipsList,
      getMaxLevel([userPersonalQV.level]),
      userInfo.uVolume
    );
    const sixtyForty = validateSixtyFortyRule(
      userInfo.leadershipsList,
      getMaxLevel([userPersonalQV.level, unilevelQV.level]),
      userInfo.uVolume
    );
    const binaryLegsVolume = validateBinaryLegsVolume(
      userInfo.leadershipsList,
      getMaxLevel([userPersonalQV.level, unilevelQV.level, sixtyForty.level]),
      userInfo.bVolume
    );
    const activeChildren = await validateActiveChildren(
      userInfo.leadershipsList,
      getMaxLevel([
        userPersonalQV.level,
        unilevelQV.level,
        sixtyForty.level,
        binaryLegsVolume.level,
      ]),
      userInfo.hasSponsored,
      userInfo.packagesList
    );

    const userRank = getUserRank([
      userPersonalQV.level || 0,
      unilevelQV.level || 0,
      sixtyForty.level || 0,
      binaryLegsVolume.level || 0,
      activeChildren.level || 0,
    ]);

    let leadership = null;
    if (userRank > 0) {
      leadership = userInfo.leadershipsList[userRank - 1];
    }
    delete userInfo.packagesList;

    if (userRanksCache) {
      userRanksCache[address] = {
        userRank,
        personalQV: userPersonalQV,
        unilevelQV,
        sixtyForty,
        binaryLegsVolume,
        activeChildren,
        userPackage: userInfo.userPackage,
        leadership,
        binaryCV: userInfo.bcv,
        ucv: userInfo.ucv,
        userInfo,
        address,
      };
    }

    return {
      userRank,
      personalQV: userPersonalQV,
      unilevelQV,
      sixtyForty,
      binaryLegsVolume,
      activeChildren,
      userPackage: userInfo.userPackage,
      leadership,
      binaryCV: userInfo.bcv,
      ucv: userInfo.ucv,
      userInfo,
      address,
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
};
