import { LegalInfoLayout } from '@layouts/LegalInfoLayout'
import React from 'react'

export const TermsAndConditionsPage = () => {
    const menu = [
        {
            title: "a. Políticas Generales.",
            id: "politicas-generales",
            content: `
            Las políticas de Fortune Goat accesibles a través del Sitio se incorporan expresamente por referencia a estos
            términos y condiciones, y usted debe revisarlas detenidamente. La Política de privacidad de Fortune Goat rige
            la   recopilación   y   el   uso   de   la   información   de   cada   usuario   en   el   Sitio.   Las   preguntas   frecuentes   brindan
            detalles adicionales sobre cómo funcionan los servicios y las Reglas internas establecen detalles importantes
            que rigen los derechos y obligaciones de cada usuario
            `
        },
        {
            title: "b. Licencia de usuario.",
            id: "licencia-de-usuario",
            content: `
            Se le otorga una licencia limitada, no exclusiva e intransferible para acceder al Sitio y a su contenido de
            acuerdo con estos Términos de uso. Si es menor de 18 años, sólo podrá utilizar el Sitio con la participación de
            un padre o tutor. El Sitio está alojado en Estados Unidos, pero contiene información general que es adecuada
            para el acceso y el uso global. 
            <br/>
            El Sitio puede contener referencias o referencias cruzadas a productos, programas o servicios que no han
            sido publicitados o que no están disponibles en su país, y dichas referencias no implican que Fortune Goat
            tenga planificado publicitar o poner a disposición dichos productos, programas o servicios en su país. 
            <br/>
            Aquellos que accedan a este Sitio desde otros lugares lo hacen por iniciativa propia y son responsables de
            cumplir   con   las   leyes   locales,   que   incluyen   las   leyes   relacionadas   con   la   transmisión   de   datos   técnicos
            exportados de Estados Unidos o del territorio en el que residen.
            <br/>
            Fortune Goat se reserva el derecho a rechazar el servicio a su absoluta discreción y sin previo aviso. Usted es
            el único responsable de su uso del Sitio y acepta compensar, exonerar y defender a Fortune Goat de todo
            reclamo, daño, pérdida, responsabilidad, costo y gasto, incluidos los honorarios de abogados, que resulten de
            su   uso   o   mal   uso   del   Sitio.   Está   estrictamente   prohibida   la   publicación   o   transmisión   de   material   ilegal,
            amenazador, calumnioso, difamatorio, obsceno, indecente, incendiario, pornográfico o profano, o de cualquier
            material que pudiera constituir o fomentar una conducta que pueda considerarse una ofensa criminal, dar
            lugar a responsabilidades civiles o de algún otro modo infringir alguna ley.
            `
        },
        {
            title: "c. Limitaciones en su uso.",
            id: "limitaciones-de-uso",
            content: `
            Ningún   material   del   Sitio   puede   ser   utilizado,   copiado,   reproducido,   republicado,   descargado,   publicado,
            mostrado, transmitido o distribuido de ninguna manera, sin la autorización previa por escrito de Fortune Goat,
            excepto que usted puede descargar una copia de los materiales en una única computadora sólo para su uso
            personal,   no   comercial   y   privado,   siempre   y   cuando   (i)   mantenga   intactos   todos   los   avisos   de   propiedad
            intelectual y otros avisos de privacidad, y (ii) no realice modificaciones a los materiales. No está permitido, sin
            el   permiso   previo   por   escrito   de   Fortune   Goat,   enmarcar   o   replicar   el   material   contenido   en   este   Sitio   en
            ningún   otro   servidor.   Está   expresamente   prohibido   el   uso   no   autorizado   de   dicho   material   en   ningún   otro
            entorno informático o sitio web.
            `
        },
        {
            title: "d. Privacidad e información personal.",
            id: "privacity-and-personal-info",
            content: `
            Su uso del Sitio también está sujeto a los términos de la Política de Privacidad. Usted reconoce que ha leído y
            que comprende la Política de privacidad, y acepta el uso de toda información personal que usted proporcione
            de acuerdo con los términos de la Política de privacidad y para los fines establecidos en ésta.
            `
        },
        {
            title: "e. Derechos de propiedad intelectual.",
            id: "derechos-propiedad-intelectual",
            content: `
            Incluye:
            <ul>
                <li>Derechos de autor   : el diseño, el código de fuente, la programación, la estructura de navegación,
                las   bases   de   datos,   el   texto,   el   contenido,   la   selección   y   la   disposición   de   los   elementos,   la
                organización, los gráficos, las fotografías, las imágenes, la compilación, la traducción magnética,
                la   conversión   digital   y   todos   los   demás   elementos   contenidos   en   el   Sitio   y   los   asuntos
                relacionados con el Sitio (en forma colectiva, los “Elementos”) están protegidos por las leyes de
                derecho de autor y los tratados internacionales correspondientes. La publicación de alguno de
                estos Elementos en el Sitio no constituye la renuncia a ningún derecho en dichos Elementos, y
                no confiere ninguna licencia ni transferencia de derechos de ningún tipo en relación con éstos.
                Salvo   que   se   exprese   lo   contrario   en   este   documento,   ningún   Elemento   puede   ser   utilizado,
                copiado, reproducido, descargado, publicado, mostrado, transmitido, modificado o distribuido de
                ninguna   forma   ni   por   ningún   medio,   entre   los   que   se   incluyen,   medios   electrónicos,   medios
                mecánicos, fotocopia, grabación u otro, sin el permiso previo por escrito de Fortune Goat.</li>
                <li>Marca comercial   : Fortune Goat, el logotipo, y todos los nombres de productos, los nombres de
                compañías   y   otras   marcas   comerciales   y   logotipos   incluidos   en   el   Sitio,   a   menos   que   se
                especifique lo contrario, son marcas comerciales o imágenes comerciales de Fortune Goat (en
                forma   colectiva,   las   “Marcas”)   y   están   protegidas   por   las   leyes   y   los   tratados   internacionales
                correspondientes.  Todos   los   demás   nombres   de   productos,   marcas   comerciales,   nombres   de
                compañías,   logotipos,   marcas   de   servicio   o   imágenes   comerciales   mencionados,   exhibidos,
                citados o de algún otro modo indicados en el Sitio son propiedad de sus respectivos dueños. La
                publicación   de   las   Marcas   en   el   Sitio   no   constituye   la   renuncia   a   ningún   derecho   en   dichas
                Marcas, y no confiere ninguna licencia ni transferencia de derechos de ningún tipo en relación
                con éstas.</li>
            </ul>
            <br/>
            Los Elementos y las Marcas son mencionados en este documento como el “material” o los “materiales”.
            Está expresamente prohibido el uso y/o el mal uso de cualquiera o todos de los Elementos, las Marcas y/o
            cualquier otro material contenido en el Sitio, sin el permiso previo por escrito de su propietario.
            `
        },
        {
            title: "f. Errores y correcciones.",
            id: "errores-correcciones",
            content: `
            Si bien Fortune Goat realiza esfuerzos razonables para incluir información precisa y actualizada en el Sitio,
            Fortune Goat no garantiza ni declara que el Sitio estará libre de errores. Los errores en el ingreso de datos y
            otros problemas técnicos en ocasiones pueden hacer que se muestre información inexacta. Fortune Goat se
            reserva el derecho a corregir cualquier inexactitud o error tipográfico en el Sitio, y no será responsable por
            dichos errores. Fortune Goat también puede realizar mejoras y/o cambios en las aplicaciones, la funcionalidad
            o el contenido del Sitio en cualquier momento. Si observa alguna información o descripción que considera
            incorrecta,   comuníquese   con   nosotros   según   se   describe   en   la   sección   “Contacto”,   y   Fortune   Goat   la
            verificará por usted.
            `
        },
        {
            title: "g. Enlaces a sitios web afiliados.",
            id: "enlaces-webs-afiliados",
            content: `
            El Sitio de Fortune Goat contiene enlaces a sitios web afiliados, los cuales le permiten recibir información
            específica en relación con la disponibilidad, los reclamos y las comparaciones de productos correspondientes
            al lugar donde usted vive. Los afiliados son responsables de que sus propios sitios web cumplan con las leyes
            vigentes en sus respectivas jurisdicciones, y se comprometen a revisarlos. Estos enlaces se proporcionan
            sólo para ofrecerle mayor conveniencia. Fortune Goat no aprueba ni acepta ninguna responsabilidad por el
            contenido de un sitio web afiliado o por la accesibilidad de los recursos disponibles en éstos. Fortune Goat le
            sugiere que revise cuidadosamente los términos de uso de cada sitio web afiliado al que elija acceder desde
            el Sitio.
            `
        },
        {
            title: "h. Presentaciones.",
            id: "presentaciones",
            content: `
            Fortune   Goat     agradece   sus   comentarios   con   respecto   a   los   contenidos   y   los   servicios   del   Sitio.   Toda
            información   que   no   sea   de   identificación   personal,   la   cual   se   trata   en   la   Política   de   privacidad   del   Sitio,
            cualquier sugerencia, idea, concepto, material u otra información que usted envíe a este Sitio o a través de
            éste (“Presentaciones”) se convertirá de forma permanente en propiedad exclusiva de Fortune Goat. Fortune
            Goat   no   tendrá   obligación   de   ningún   tipo   con   respecto   a   dichas   Presentaciones   y   será   libre   de   utilizar   y
            divulgar las Presentaciones a otros sin limitación.
            `
        },
        {
            title: "i. Cambios en el Sitio y en los Términos de uso.",
            id: "cambios-en-el-sitio",
            content: `
            Fortune Goat   se reserva el derecho, a su absoluta discreción, a cambiar el Sitio y/o su contenido y estos
            Términos y Condiciones de uso en cualquier momento, sin previo aviso. Las versiones actualizadas de los
            Términos y Condiciones de uso se publicarán en el Sitio y serán efectivas inmediatamente. La fecha de la
            última versión se indica a continuación. Contrólela con frecuencia, especialmente antes de utilizar el Sitio,
            para saber si estos Términos de uso han cambiado. El uso continuado del Sitio después de cualquier cambio
            en los Términos de uso constituye su consentimiento a dichos cambios.
            `
        },
        {
            title: "j. Liberación de Responsabilidad.",
            id: "liberacion-responsabilidad",
            content: `
            Usted   reconoce   y   acepta   que   el   uso   del   sitio   web   se   realiza   en   todo   momento   bajo   su   entero   riesgo   y
            responsabilidad. Fortune Goat no será responsable por el uso indebido que pueda realizarse del sitio web
            fortunegoat.com.
            <br/>
            Fortune   Goat   no   será   responsable   por   daños   y   perjuicios   de   cualquier   tipo   causados   en   los   equipos
            electrónicos   (cómputo,   teléfono,   tableta,   u   otros)   del   Cliente   por   virus,   gusanos   o   cualquier   otro   elemento
            dañino. Usted reconoce que el uso de la red Internet supone la asunción de un riesgo de que sus equipos
            informáticos puedan ser afectados por los elementos antes enunciados. A tal efecto, corresponde a usted, en
            todo   caso,   la   disponibilidad   de   herramientas   adecuadas   para   la   detección   y   eliminación   de   programas
            electrónicos dañinos que puedan suponer un riesgo para su equipo de cómputo.
            <br/>
            Fortune Goat tampoco será responsable por procesos o solicitudes de cualquier naturaleza que, no hayan
            podido completarse por fallos o desconexiones en las redes de telecomunicaciones, ya que estos servicios
            son brindados a proveedores de servicio por parte de terceros ajenos a Fortune Goat.
            `
        },
        {
            title: "k. Incumplimientos.",
            id: "incumplimientos",
            content: `
            El incumplimiento de cualquiera de las cláusulas u obligaciones establecidas en los Términos y Condiciones
            de   uso,   faculta   a   la   parte   afectada   a   solicitar   la   terminación   inmediata   del   contrato   y   el   pago   de   una
            compensación por los daños y perjuicios causados.
            <br/>
            La   omisión   por   cualquiera   de   las   partes   de   requerir   el   cumplimiento   estricto   en   algún   momento   de
            cualesquiera de los pactos o acuerdos o de ejercitar cualquier opción, derecho, poder o remedio contenido en
            este documento, no será nunca considerada como una renuncia implícita al mismo para el futuro.
            `
        },
        {
            title: "l. Terminación.",
            id: "terminacion",
            content: `
            Estos Términos y Condiciones de Uso continuarán hasta que cualquiera de las partes decida rescindirlos.
            Usted puede hacer esto en cualquier momento luego de: (i) que Fortune Goat reciba una notificación por
            escrito de su terminación; y, (ii) que deje de usar y acceder al Servicio de inmediato. Si accede o utiliza el
            Servicio después de notificar a Fortune Goat, acepta estos Términos y Condiciones de Uso nuevamente. La
            rescisión   de   estos   Términos   y   Condiciones   de   uso   por   parte   de   cualquiera   de   ellos   rescindirá   cualquier
            derecho   que   pueda   tener   para   utilizar   cualquier   contenido   o   Servicio   proporcionado.   Cualquier   licencia
            proporcionada a Fortune Goat para utilizar Material con licencia permanecerá vigente a menos que las partes
            acuerden lo contrario por escrito.
            <br/>
            A  su   entera   discreción,   Fortune   Goat   puede   rescindir   estos   Términos   y   Condiciones   de   uso   o   prohibir   o
            suspender su acceso a los Servicios en cualquier momento. La terminación o suspensión puede ocurrir por
            los motivos establecidos en estos Términos y Condiciones de uso, incluidos, entre otros, si: (a) su uso del
            Servicio viola estos términos o cualquier política o pauta aplicable, es potencialmente ilegal o perjudicial para
            Fortune Goat o cualquier otra parte, o podría someter a Fortune Goat a responsabilidad o gastos adicionales;
            (b)   la   prestación   del   Servicio   se   vuelve   técnica   o   comercialmente   inviable;   o   (c)   no   paga   ningún   cargo
            adeudado a Fortune Goat.
            `
        },
        {
            title: "m. Declaratoria de Nulidad o Invalidez.",
            id: "declaratoria-nulidad",
            content: `
            Si   cualquier   disposición   de   este   contrato   es   declarada   nula,   inválida   o   inaplicable,   tal   disposición   será
            considerada modificada hasta el alcance necesario para considerarla válida y aplicable. En cualquier caso, la
            declaratoria de ineficacia o invalidez de cualquier estipulación o cláusula no afectará la eficacia o validez de
            las restantes estipulaciones o cláusulas de este contrato y éste continuará vigente y válido y será interpretado
            y hecho cumplir como si la estipulación o cláusula declarada ineficaz o nula no hubiera sido incluida o hubiera
            sido modificada de la forma indicada anteriormente, según sea el caso.
            `
        },
        {
            title: "n. Legislación Aplicable.",
            id: "legislacion-aplicable",
            content: `
            Las   partes   se   someten   a   las   leyes   de   la   República   de   Costa   Rica   para   la   interpretación   y   ejecución   del
            presente   contrato.   Cualquier   controversia   o   disputa   que   surja   de   su   uso   del   Sitio   deberá   enviarse   a   la
            jurisdicción personal de cualquier tribunal ubicado en Costa Rica, lo cual usted acepta irrevocablemente. Si se
            determina que alguna disposición de estos Términos y Condiciones de uso es ilegal, nula o por algún motivo
            inexigible,   dicha   disposición   se   eliminará   y   no   afectará   la   validez   y   la   aplicabilidad   de   las   disposiciones
            restantes.
            `
        },
        {
            title: "o. Cláusula Arbitral.",
            id: "clausula-arbitral",
            content: `
            Fortune   Goat   y   usted   manifiestan   y   aceptan   que   en   caso   de   cualquier   diferencias,   conflictos   o   disputas
            relacionadas con los Términos y Condiciones de Uso o cualquier otro aspecto derivado de ellos, las partes
            seguirán   el   siguiente   procedimiento:   En   primero   término   se   realizarán   un   proceso   de   conciliación   que   se
            desarrollará ante el MEIC. En caso de no llegar a un acuerdo y habiéndose agotado dicha vía administrativa,
            las   partes   manifiestan   expresa   y   voluntariamente   que   de   conformidad   con   los   artículos   cuarenta   y   uno   y
            cuarenta y tres de la Constitución Política, renuncian en este acto expresamente a la jurisdicción ordinaria y
            acuerdan resolver el conflicto, el asunto o controversia mediante un arbitraje, cuyo laudo será definitivo e
            inapelable  de  conformidad  con  los  reglamentos  de  Conciliación  y Arbitraje  de  la  Cámara  de  Comercio  de
            Costa   Rica,   a   cuyas   normas   las   partes   se   someten   en   forma   incondicional.   El   arbitraje   será   de   derecho.
            Queda entendido que el arbitraje podrá ser solicitado por cualesquiera de las partes. En caso de que en el
            momento en que deba resolverse el conflicto, el Centro de Conciliación y Arbitraje de la Cámara de Comercio
            de Costa Rica no esté prestando los servicios anteriormente referidos, el conflicto se resolverá mediante un
            proceso   arbitral   que   se   tramitará   de   conformidad   con   la   Ley   sobre   Resolución  Alternativa   de   Conflictos   y
            Promoción   de   la   Paz   Social,   número   siete   mil   setecientos   veintisiete.   Conformes   con   lo   aquí   estipulado,
            suscriben esta cláusula.
            `
        },
        {
            title: "p. Fuerza mayor.",
            id: "fuerza-mayor",
            content: `
            Fortune   Goat   no   será   responsable   ante   usted,   ni   se   considerará   que   ha   incumplido   o   incumplido   estos
            Términos y Condiciones de Uso, por cualquier falla o retraso en su desempeño bajo ellos, cuando y en la
            medida en que dicha falla o demora sea causada o resulte de actos o circunstancias más allá de nuestro
            control   razonable,   incluidos,   entre   otros,   casos   fortuitos,   inundaciones,   incendios,   terremotos,   explosiones,
            acciones gubernamentales, guerras, invasiones u hostilidades (ya sea que se haya declarado la guerra o no),
            amenazas   o   actos   terroristas,   disturbios   u   otros   disturbios   civiles,   emergencia   nacional,   revolución,
            insurrección, epidemia, cierres patronales, huelgas u otros conflictos laborales (relacionados o no con nuestra
            fuerza   laboral),   o   restricciones   o   retrasos   que   afecten   a   los   transportistas   o   incapacidad   o   retraso   en   la
            obtención de suministros de materiales adecuados o apropiados, fallas de materiales o telecomunicaciones o
            corte de energía. 
            `
        },
        {
            title: "q. Avisos / Notificaciones.",
            id: "avisos",
            content: `
            Fortune Goat puede enviarle cualquier aviso en virtud de estos Términos y Condiciones de uso: (i) enviando
            un mensaje a la dirección de correo electrónico que usted proporcione, o (ii) publicando en el Servicio. Los
            avisos enviados por correo electrónico entrarán en vigencia cuando Fortune Goat envíe el correo electrónico y
            los   avisos   que   Fortune   Goat   proporcione   mediante   publicación   entrarán   en   vigencia   al   momento   de   su
            publicación. Es su responsabilidad mantener actualizada su dirección de correo electrónico.
            <br/>
            Para   comunicarse   con   Fortune   Goat,   deberá   hacerlo   de   la   siguiente   manera:   (i)   telefono   al   +52   998   147
            5050 ; o (ii) por correo electrónico a info@fortunegoat.com Fortune Goat puede actualizar sus direcciones
            para recibir avisos publicando un aviso en el Servicio. Los avisos proporcionados por mensajería o correo
            electrónico entrarán en vigor un día hábil después de su envío. Las notificaciones proporcionadas por correo
            registrado o certificado tendrán vigencia tres días hábiles después de su envío.
            `
        },
        {
            title: "r. Uso de Plurales y Singulares.",
            id: "bid-expiration",
            content: `
            Cuando el contexto lo requiera, el uso de la forma singular incluirá el plural, el uso del plural incluirá el singular
            y el uso de cualquier género incluirá todos los géneros.
            `
        },
    ]
    return (
        <LegalInfoLayout title="Terms & Conditions" menu={menu}>
            <p className="mt-6 italic">
                Last updated 28 of Feb 2024
            </p>
            <h2 className="text-2xl font-bold my-4">TÉRMINOS Y CONDICIONES DE USO <br/> DESARROLLO SOFTWARE – FORTUNEGOAT</h2>
            {`            
            Estos  Términos   de   uso   (los   "Términos")   se   aplican   a   su   uso   de   las   interfaces   y   propiedades   en   línea   de
            Fortune   Goat   (“Fortune   Goat”),   incluido   el   sitio   web   www.fortunegoat.com   y   las   aplicaciones   móviles   de
            FortuneGOAT (el “Sitio”), para la compra u oferta de bienes u otros servicios ofrecidos a través del Sitio. Todo
            contenido, información y servicio proporcionado en el Sitio y a través de éste puede utilizarse sólo de acuerdo
            con los siguientes términos y condiciones (los “Términos de uso”).
            <br/>
            EL  USO   DE   ESTE   SITIO   CONSTITUYE   SU  ACEPTACIÓN   DE   ESTAR   REGIDO   POR   LOS   SIGUIENTES
            TÉRMINOS   DE   USO,   CON   LAS   POSIBLES   MODIFICACIONES   QUE   REALICE   OCASIONALMENTE
            FORTUNE GOAT. LÉALOS ATENTAMENTE ANTES DE UTILIZAR EL SITIO. SI NO ESTÁ DE ACUERDO
            CON ALGUNA PARTE DE ESTOS TÉRMINOS DE USO, DEBERÁ ABSTENERSE DE UTILIZAR EL SITIO.
            `.split("\n").map((paragraph, index) => (
                <p key={index} className="mb-2" dangerouslySetInnerHTML={{ __html: paragraph }} />
            ))}
            {
                menu.map((item, index) => (
                    <div key={index} id={item.id} className="mt-6" style={{ scrollMarginTop: "100px" }}>
                        <h2 className="text-2xl font-bold my-4">{item.title}</h2>
                        {item?.content && item.content.split("\n").map((paragraph, index) => (
                            <p key={index} className="mb-2" dangerouslySetInnerHTML={{ __html: paragraph }} />
                        ))}
                    </div>
                ))
            }
        </LegalInfoLayout>
    )
}
