import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { formatNumber } from "common/util/formatNumber";

export const MeetTheWinnerCard = ({
  winner,
  country,
  productName,
  productDescription,
  finalBid,
  totalCost,
  bidsAmount,
  auctionEnd,
  image,
  winnerData,
}) => {
  const pinataGatewayKey = `?pinataGatewayToken=${process.env.REACT_APP_PINATA_GATEWAY_KEY}`;
  return (
    <div className="grid grid-cols-1 sm:grid-cols-8 w-full col-span-1 min-h-[400px] max-w-full">
      <div
        className="row-span-12 sm:row-span-1 col-span-4 flex justify-center items-center bg-center bg-no-repeat bg-cover"
        style={{
          backgroundImage: `url(${winnerData?.item?.info?.images[0]}${pinataGatewayKey})`,
        }}
      />
      <div className="col-span-3 flex flex-col justify-between p-4 bg-white">
        <div className="flex grow-1">
          <div>
            <img
              src="https://via.placeholder.com/50"
              className="rounded-full w-[3rem] h-[3rem] md:w-[5rem] md:h-[5rem]"
              alt="winner"
            />
          </div>
          {/* {JSON.stringify(winnerData?.item?.info?.images[0])} */}
          <div className="flex flex-col pl-2">
            <span className="text-sm md:text-2xl font-bold">{winner || "Winner Name"}</span>
            <span className="font-bold text-xs md:text-md">Level:</span>
            <span className="text-[#808080] text-xs md:text-md">{country || "Country"}</span>
          </div>
        </div>
        <div className="flex flex-col gap-6 grow-2">
          <div>
            <div className="text-xl md:text-3xl font-bold">
              {productName ? productName.slice(0, 40) : "Product Name"}
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-sm">Final bid:</p>
            <p className="font-semibold text-sm md:text-xl">
              {`$${formatNumber(finalBid, true)}` || "$"}
            </p>
            <p className="text-sm">Estimated total cost:</p>
            <p className="font-semibold text-sm md:text-xl">
              {`$${formatNumber(totalCost, true)}` || "$"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
