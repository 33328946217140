import { AppLayout } from "@layouts/AppLayout";
import StarIcon from "@assets/star.svg";
// import { useFetchRangeAndCommisions } from "modules/admin/hooks/RangeAndCommissions/useFetchRangeAndCommisions";
import { useAccount } from "wagmi";
import { useGetRankAndCommissions } from "common/hooks/useGetRankAndCommissions";
import Skeleton from "react-loading-skeleton";


export const RangePage = () => {

  const { address } = useAccount();

   // const {
   //   data ,
   // } = useFetchRangeAndCommisions("rangeCommissions", {
   //   userId: address ,
   //   commissions: false,
   // });

   const {data, loading: isLoading} = useGetRankAndCommissions({
      address
   });
   
  return (
    <AppLayout>
      {/* Payment Level */}
      <div className="flex flex-col w-full bg-white p-4 rounded">
        <p className="uppercase text-[#87BAD7] text-xl font-bold mb-4">Payment Level</p>
        {isLoading ? (
          <div className="my-3">
            <Skeleton width={"20rem"} height={"1.3rem"} />
          </div>
        ) : (
          <div className="w-full flex gap-20 flex-wrap mb-4">
            <div className="flex flex-col">
              <p className="font-semibold text-sm">Actual Range</p>
              <p className="font-bold">{data && data?.paymentLevel?.actualRange}</p>
              <div className="flex">
                <img src={StarIcon} alt="Founder Gold" />
                <img src={StarIcon} alt="Founder Gold" />
                <img src={StarIcon} alt="Founder Gold" />
              </div>
            </div>
            <div className="flex flex-col">
              <p className="font-semibold text-sm">Binary %</p>
              <p className="font-bold">{data && data?.paymentLevel?.binaryPercent}%</p>
            </div>
            <div className="flex flex-col">
              <p className="font-semibold text-sm">CAP</p>
              <p className="font-bold">{data && data?.paymentLevel?.cap} USDT</p>
            </div>
            <div className="flex flex-col">
              <p className="font-semibold text-sm">Unilevel</p>
              <p className="font-bold">{data && data?.paymentLevel?.unilevel}</p>
            </div>
            <div className="flex flex-col">
              <p className="font-semibold text-sm">Goal</p>
              <p className="font-bold">{data && data?.paymentLevel?.goal}</p>
            </div>
          </div>
        )}
        <p className="uppercase text-gray-500 text-sm">
          *This is currently highest active package in your account.
        </p>
      </div>

      {/* Range */}
      {isLoading ? (
        // <div className="my-3">
        //    <Skeleton width={"20rem"} height={"1.3rem"} />
        // </div>
        <div className="flex flex-col w-full bg-white p-4 rounded mt-4">
          <p className="uppercase text-[#87BAD7] text-xl font-bold mb-4">Range</p>
          <div className="flex my-4">
            <p className="text-xl font-semibold mx-4">1)</p>
            <div>
              <p className="text-sm font-semibold">
                VOLUME QV: <Skeleton width={"3rem"} height={"1rem"} />
              </p>
              <p className="text-lg font-semibold">UNILEVEL</p>
            </div>
          </div>
          <div className="flex my-4">
            <p className="text-xl font-semibold mx-4">2)</p>
            <div>
              <p className="text-sm font-semibold">
                BINARY <Skeleton width={"3rem"} height={"1rem"} />
              </p>
              <div className="flex gap-10">
                <p className="text-lg font-semibold">
                  LEFT: <Skeleton width={"3rem"} height={"1rem"} />
                </p>
                <p className="text-lg font-semibold">
                  RIGHT: <Skeleton width={"3rem"} height={"1rem"} />
                </p>
                <p className="text-lg font-semibold text-[#BCE210]">*MINIMUM: 80 EACH</p>
              </div>
            </div>
          </div>
          <div className="flex my-4">
            <p className="text-xl font-semibold mx-4">3)</p>
            <div>
              <p className="text-sm font-semibold">
                PERSONAL VOLUME: <Skeleton width={"3rem"} height={"1rem"} />
              </p>
              <p className="text-lg font-semibold">
                <Skeleton width={"3rem"} height={"1rem"} /> 
              </p>
            </div>
          </div>
          <div className="flex my-4">
            <p className="text-xl font-semibold mx-4">4)</p>
            <div>
              <p className="text-sm font-semibold">
                QUALIFIED BY LEG: <Skeleton width={"3rem"} height={"1rem"} />
              </p>
              <p className="text-lg font-semibold">
                <Skeleton width={"3rem"} height={"1rem"} />
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col w-full bg-white p-4 rounded mt-4">
          <p className="uppercase text-[#87BAD7] text-xl font-bold mb-4">Range</p>
          <div className="flex my-4">
            <p className="text-xl font-semibold mx-4">1)</p>
            <div>
              <p className="text-sm font-semibold">
                VOLUME QV: {data && data?.range?.volumeqv?.current}
              </p>
              <p className="text-lg font-semibold">UNILEVEL</p>
            </div>
          </div>
          <div className="flex my-4">
            <p className="text-xl font-semibold mx-4">2)</p>
            <div>
              <p className="text-sm font-semibold">
                BINARY {data && data.range.binaryPercent.level} %
              </p>
              <div className="flex gap-10">
                <p className="text-lg font-semibold">
                  LEFT: {data && data.range.binaryPercent.current.left}
                </p>
                <p className="text-lg font-semibold">
                  RIGHT: {data && data.range.binaryPercent.current.right}
                </p>
                <p className="text-lg font-semibold text-[#BCE210]">*MINIMUM: 80 EACH</p>
              </div>
            </div>
          </div>
          <div className="flex my-4">
            <p className="text-xl font-semibold mx-4">3)</p>
            <div>
              <p className="text-sm font-semibold">
                PERSONAL VOLUME: {data && data.range.personalVolume.current}
              </p>
              <p className="text-lg font-semibold">
                {data && data?.paymentLevel?.actualRange} (
                {data && data?.range?.personalVolume?.nextLevel})
              </p>
            </div>
          </div>
          <div className="flex my-4">
            <p className="text-xl font-semibold mx-4">4)</p>
            <div>
              <p className="text-sm font-semibold">
                QUALIFIED BY LEG: {data && data.range.qualifiedByLeg.level}
              </p>
              <p className="text-lg font-semibold">
                ${data && data.range.qualifiedByLeg.total} USDT{" "}
              </p>
            </div>
          </div>
        </div>
      )}
    </AppLayout>
  );
};
