import { SentryService } from "./SentryErrorCapture";
import axios from "axios";

export const onSendBids = async ({ data, signature }) => {
  try {
    const sendBidsResponse = await axios.post(
      `${process.env.REACT_APP_AWS_API_URL}/contracts/pa/bids/transfer`,
      {
        ...data,
        signature,
      }
    );
    if (sendBidsResponse) {
      return sendBidsResponse.data;
    }
  } catch (error) {
    // SentryService("onSendBids", "onSendBids", error);
    // console.debug(error);
    throw new Error(
      error.response
        ? error.response.data.error.reason ||
          error.response.data.error.shortMessage ||
          error.response.data.error
        : error
    );
  }
};
