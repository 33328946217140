import { onReadContract } from "common/services";
import { useCallback, useEffect, useState } from "react";
import { useBalance } from "wagmi";
import USDTABI from "common/contracts/USDT.json";
import { SentryService } from "common/services/SentryErrorCapture";

export const useGetAddressBalance = ({ address, usdt = true, matic = true }) => {
  const [USDTBalance, setUSDTBalance] = useState(0);
  const [USDTError, setUSDTError] = useState(null);
  const [USDTLoading, setUSDTLoading] = useState(false);
  const {
    data: maticBalance,
    error: maticError,
    isLoading: maticLoading,
    refetch,
  } = useBalance({
    address,
    enabled: matic,
  });
  const getUSDTBalance = useCallback(async (address) => {
    try {
      setUSDTLoading(true);
      setUSDTError(null);
      const usdtDecimals = await onReadContract({
        contractAddress: process.env.REACT_APP_USDT_CONTRACT_ADDRESS,
        contractABI: USDTABI,
        functionName: "decimals",
      });
      const usdtBalance = await onReadContract({
        contractAddress: process.env.REACT_APP_USDT_CONTRACT_ADDRESS,
        contractABI: USDTABI,
        functionName: "balanceOf",
        args: [address],
      });
      if (typeof usdtBalance === "bigint") {
        setUSDTBalance(Number(usdtBalance) / 10 ** usdtDecimals || 0);
      }
    } catch (error) {
      SentryService("useGetAddressBalance", "getUSDTBalance", error);
      setUSDTBalance(0);
      setUSDTError(error.message);
    } finally {
      setUSDTLoading(false);
    }
  }, []);

  useEffect(() => {
    if (usdt) getUSDTBalance(address);
  }, [getUSDTBalance, usdt, address]);

  return {
    USDTBalance,
    USDTError,
    USDTLoading,
    maticBalance: maticBalance ? maticBalance.formatted : 0,
    maticError,
    maticLoading,
    refetchUSDTbalance: getUSDTBalance,
    refetchMaticBalance: refetch,
  };
};
