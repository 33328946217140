import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useWaitForTransactionReceipt } from "wagmi";
import { useRequestSignature } from "./useRequestSignature";
import { onSetRoles } from "common/services/onSetRoles";

export const useSetRoles = () => {
  const requestSignature = useRequestSignature();
  const [isEnabled, setIsEnabled] = useState(false);
  const [rolesData, setRolesData] = useState({});
  const [signature, setSignature] = useState("");

  const { isError, isSuccess, isFetching, status, data, error } = useQuery({
    queryKey: [`setRoles`],
    retry: 1,
    retryDelay: 3000,
    refetchOnWindowFocus: false,
    enabled: isEnabled,
    queryFn: () =>
      onSetRoles({
        signature,
        data: { ...rolesData },
      }),
  });
  const {
    isError: isErrorTx,
    isSuccess: isSuccessTx,
    data: dataTx,
    isFetching: isFetchingTx,
    error: errorTx,
  } = useWaitForTransactionReceipt({
    queryKey: [`setRoles${data ? data.data : ""}`],
    hash: data ? data.data : "",
    retry: 3,
    retryDelay: 3000,
    refetchOnWindowFocus: false,
    enabled: isEnabled,
    confirmations: 2,
  });

  useEffect(() => {
    const onResult = async () => {
      if (!isFetching && !isFetchingTx && rolesData) {
        if (isError) {
          if (rolesData.onError) await rolesData.onError(data ? data.error : error);
          if (rolesData.onSettled) await rolesData.onSettled();
          setIsEnabled(false);
        } else if (isSuccess && data) {
          if (isErrorTx) {
            if (rolesData.onError) await rolesData.onError(dataTx ? dataTx.error : errorTx);
            if (rolesData.onSettled) await rolesData.onSettled();
            setIsEnabled(false);
          } else if (isSuccessTx && dataTx) {
            if (rolesData.onSuccess) await rolesData.onSuccess();
            if (rolesData.onSettled) await rolesData.onSettled();
            setIsEnabled(false);
          }
        }
      }
    };
    if (!isFetching) onResult();
  }, [
    isError,
    isSuccess,
    isFetching,
    status,
    data,
    isFetchingTx,
    rolesData,
    error,
    isErrorTx,
    isSuccessTx,
    dataTx,
    errorTx,
  ]);

  const requestSetRoles = async ({ rolesData }) => {
    if (!rolesData) throw new Error("missing payment request side data");
    const signature = await requestSignature();
    setSignature(signature);
    setRolesData(rolesData);
    setIsEnabled(true);
  };

  return {
    requestSetRoles,
    isFetchingTx,
    isError,
    error,
    data,
    isErrorTx,
    isSuccessTx,
    dataTx,
    isFetchingTx,
  };
};
