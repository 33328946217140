import BadHappyImg from "@assets/bag-happy.svg";
import HierarchyImg from "@assets/hierarchy.svg";
import PeopleImg from "@assets/people.svg";
import UserTickImg from "@assets/user-tick.svg";

export const ModelsForSuccess = () => {
    return (
        <div className="flex flex-col py-20">
            <div className="w-full">
                <div className="flex justify-center py-10">
                    <div className="container flex justify-center px-4">
                        <div
                            className="flex justify-center items-center flex-col"
                            style={{ maxWidth: "600px" }}
                        >
                            <h2 className="text-5xl text-gray-800 font-light mb-4 text-center">
                                FORTUNEGOAT follows four proven models for
                                success
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-center">
                <div className="container px-4">
                    <div className="grid grid-cols-4 gap-4">
                        <div className="md:col-span-1 sm:col-span-2 col-span-4 bg-highlight-primary p-6">
                            <div
                                style={{ maxWidth: "200px" }}
                                className="flex flex-col items-center mx-auto"
                            >
                                <img
                                    src={BadHappyImg}
                                    alt="Bad Happy"
                                    className="w-1/2 mb-3"
                                />
                                <p className="text-gray-600 text-xl text-center">
                                    Outlet Shopping (Penny Auctions)
                                </p>
                            </div>
                        </div>
                        <div className="bg-highlight-primary md:col-span-1 sm:col-span-2 col-span-4 p-6">
                            <div
                                style={{ maxWidth: "200px" }}
                                className="flex flex-col items-center mx-auto"
                            >
                                <img
                                    src={PeopleImg}
                                    alt="People"
                                    className="w-1/2 mb-3"
                                />
                                <p className="text-gray-600 text-xl text-center">
                                    Power of Word of Mouth
                                </p>
                            </div>
                        </div>
                        <div className="bg-highlight-primary md:col-span-1 sm:col-span-2 col-span-4 p-6">
                            <div
                                style={{ maxWidth: "200px" }}
                                className="flex flex-col items-center mx-auto"
                            >
                                <img
                                    src={HierarchyImg}
                                    alt="Hierarchy"
                                    className="w-1/2 mb-3"
                                />
                                <p className="text-gray-600 text-xl text-center">
                                    Blockchain Technology
                                </p>
                            </div>
                        </div>
                        <div className="bg-highlight-primary md:col-span-1 sm:col-span-2 col-span-4 p-6">
                            <div
                                style={{ maxWidth: "200px" }}
                                className="flex flex-col items-center mx-auto"
                            >
                                <img
                                    src={UserTickImg}
                                    alt="User Tick"
                                    className="w-1/2 mb-3"
                                />
                                <p className="text-gray-600 text-xl">
                                    Leadership
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
