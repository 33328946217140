import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { usePackagesService } from "modules/admin/services/Packages";

export const useFetchPackages = (queryKey = "packages", initialParams = {}) => {
  const [params, setParams] = useState(initialParams);
  /* 
    page: "",
    limit: "",
    search: "",
   */
  const { fetchPackages } = usePackagesService();

  const queryResults = useQuery({
    queryKey: [queryKey, params],
    queryFn: () => fetchPackages(params),
    select: (data) => data.data,
  });

  return { ...queryResults, setParams, params };
};
