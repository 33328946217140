import { useLocation } from "react-router-dom";
/* import {
    Continuously,
    Header,
    JoinUs,
    ModelsForSuccess,
    MoreThanCTA,
    Navbar,
    PennyAuctions,
    TheFeatures,
} from "@landing/components"; */
import { Continuously } from "@landing/components/Continuously";
import { Header } from "@landing/components/Header";
import { JoinUs } from "@landing/components/JoinUs";
import { ModelsForSuccess } from "@landing/components/ModelsForSuccess";
import { MoreThanCTA } from "@landing/components/MoreThanCTA";
import { Navbar } from "@landing/components/Navbar";
import { PennyAuctions } from "@landing/components/PennyAuctions";
import { TheFeatures } from "@landing/components/TheFeatures";
import { LoginModal } from "@auth/components/login-modal/LoginModal";
import { useEffect, useState } from "react";
import { useAccount, useConnect } from "wagmi";
import { useLoginRedirect } from "common/hooks/useLoginRedirect";
import { wagmiConfig } from "App";
import { Footer } from "common/components";
import { web3AuthInstance } from "App";
import { replaceWCQrCode } from "common/util/replaceWCQrCode";

export const LandingPage = () => {
  const { isConnected, address } = useAccount();
  useLoginRedirect({ isConnected, address });
  const [showModal, setShowModal] = useState(false);
  const { error, connectors, connect } = useConnect(wagmiConfig);

  const location = useLocation();

  const handleJoin = async () => {
    // setShowModal(true);

    if (connect) {
      const observer = replaceWCQrCode({ web3AuthInstance });
      connect(
        { connector: connectors[0] },
        {
          onError: () => {
            observer.disconnect();
          },
        }
      );
    }
  };

  useEffect(() => {
    if (location.state?.openModal) {
      // setShowModal(true);
      if (connect) {
        const observer = replaceWCQrCode({ web3AuthInstance });
        connect(
          { connector: connectors[0] },
          {
            onError: () => {
              observer.disconnect();
            },
          }
        );
      }
    }
  }, [location, connect, connectors]);

  return (
    <>
      <Navbar />
      {!isConnected && (
        <LoginModal
          setShowModal={setShowModal}
          connectors={connectors}
          showModal={showModal}
          connect={connect}
          error={error}
        />
      )}
      <Header handleJoin={handleJoin} />
      <div className="bg-[#E3E3D7] pt-32 pb-36">
        <PennyAuctions />
      </div>
      <div className="bg-gradient-to-b from-cream to-sky-700/50">
        <JoinUs handleJoin={handleJoin} />
        <TheFeatures />
        <ModelsForSuccess />
        <MoreThanCTA handleJoin={handleJoin} />
        <Footer />
      </div>
    </>
  );
};
