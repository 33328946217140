import { usersCommission } from "./usersCommissions";

export const getLevelSum = async ({
	addresses,
	percentage,
	contractFunctions,
	getBinaryCommission,
	getUnilevelCommission,
}) => {
	console.debug("getLevelSum", { addresses, percentage });
	const result = {
		unilevel: {
			usersCommissionsBeforePercentage: [],
			commissionsSum: 0,
			total: 0,
			percentage,
		},
		binary: {
			usersCommissionsBeforePercentage: [],
			commissionsSum: 0,
			total: 0,
			percentage,
		},
	};
	if (!addresses || addresses.length == 0) return result;
	// console.log("getLevelSum", addresses.length);

	const usersBinaryCommissionsArray= [];
	const usersUnilevelCommissionsArray = [];
	for (const currentAddress of addresses) {
		const usersCommissions = await usersCommission({
			addressToGet: currentAddress,
			contractFunctions,
			getBinaryCommission,
			getUnilevelCommission,
		});
		usersBinaryCommissionsArray.push({user: currentAddress, commission: usersCommissions.binary});
		usersUnilevelCommissionsArray.push({user: currentAddress, commission: usersCommissions.unilevel});
	}

	// console.debug({ usersCommissionsArray });
	const binarySum = usersBinaryCommissionsArray.reduce((prev, curr) => prev + Number(curr.commission), 0);
	const unilevelSum = usersUnilevelCommissionsArray.reduce((prev, curr) => prev + Number(curr.commission), 0);
	// console.debug({ sum, percentage });
	result.unilevel = {
		usersCommissionsBeforePercentage: usersUnilevelCommissionsArray,
		commissionsSum: usersUnilevelCommissionsArray.reduce((a, b) => {
			return a + b.commission;
		}, 0),
		total: (unilevelSum * (percentage / 1e2)) / 100,
		percentage,
	};
	result.binary = {
		usersCommissionsBeforePercentage: usersBinaryCommissionsArray,
		commissionsSum: usersBinaryCommissionsArray.reduce((a, b) => {
			return a + b.commission;
		}, 0),
		total: (binarySum * (percentage / 1e2)) / 100,
		percentage,
	};
	return result;
};
