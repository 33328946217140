import { getBinaryCommission as defaultGetBinaryCommission } from "../onGetCommissions/getBinaryCommission";
import { getUnilevelCommission as defaultGetUnilevelCommission } from "../onGetCommissions/getUnilevelCommission";

export const onGetBinaryMatchBonus = async ({
  userRank,
  address,
  contractFunctions,
  getMatchingBonusPercentages,
  getLevelAddresses,
  getLevelSum,
  getBinaryCommission = defaultGetBinaryCommission,
  getUnilevelCommission = defaultGetUnilevelCommission,
}) => {
  console.log("onGetBinaryMatchBonus", { userRank, address });
  const levels = [];
  let total = 0;
  if (!userRank || !address) return { levels, total };
  if (!userRank.leadership && !userRank.userPackage) return { levels, total };

  const matchingBonus = userRank.leadership
    ? userRank.leadership.binaryMatchingBonus
    : userRank.userPackage
    ? userRank.userPackage.matchingBonus
    : 0;
  const matchingBonusPercentages = await getMatchingBonusPercentages({
    matchingBonus,
    contractFunctions,
  });

  let addresses = [address];
  // console.debug({ matchingBonusPercentages });
  for (const bonus of matchingBonusPercentages) {
    const levelAddresses = await getLevelAddresses({
      addresses,
      contractFunctions,
    });
    const levelSum = await getLevelSum({
      addresses: levelAddresses,
      percentage: bonus,
      contractFunctions,
      getBinaryCommission,
      getUnilevelCommission,
    });
    levels.push(levelSum);
    addresses = levelAddresses;
  }
  // console.debug({ levels });
  total = levels.reduce((prev, current) => {
    return prev + current.binary.total;
  }, 0);
  return { levels, total };
};
