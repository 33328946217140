import axios from "axios";
import { SentryService } from "common/services/SentryErrorCapture";

export const onGetFeaturedWinners = async () => {
  try {
    const winnersResponse = await axios.get(
      `${process.env.REACT_APP_AWS_API_URL}/contracts/pa/winners/meet`
    );
    // console.debug({ winnersResponse });
    if (winnersResponse.data.status === 200) {
      return winnersResponse.data.data.lastDayWinners;
    }
    throw new Error(winnersResponse.data.error);
  } catch (error) {
    SentryService("onGetFeaturedWinners", "onGetFeaturedWinners", error.message);
    throw error;
  }
};
