import { binaryFunctions } from "./binary";
import { commissionsFunctions } from "./commissions";
import { fortuneGoatFunctions } from "./fortuneGoat";
import { membershipFunctions } from "./membership";
import { packagesFunctions } from "./packages";
import { qualificationsFunctions } from "./qualifications";
import { unilevelFunctions } from "./unilevel";
import { userPointsFunctions } from "./userPoints";
import { usersFunctions } from "./users";

export const getContractFunctions = ({
	unilevelContract,
	unilevelABI,
	binaryContract,
	binaryABI,
	fgContract,
	fgABI,
	qualificationsContract,
	qualificationsABI,
	usersContract,
	usersABI,
	commissionsContract,
	commissionsABI,
	userPointsContract,
	userPointsABI,
	membershipABI,
	membershipContract,
	packagesContract,
	packagesABI,
}) => {
	const unilevel = unilevelFunctions({
		unilevelContract,
		unilevelABI,
	});
	const binary = binaryFunctions({
		binaryContract,
		binaryABI,
	});
	const users = usersFunctions({
		usersContract,
		usersABI,
	});
	const fortuneGoat = fortuneGoatFunctions({
		fgContract,
		fgABI,
	});
	const qualifications = qualificationsFunctions({
		qualificationsContract,
		qualificationsABI,
	});
	const commissions = commissionsFunctions({
		commissionsContract,
		commissionsABI,
	});
	const membership = membershipFunctions({
		membershipContract,
		membershipABI,
	});

	const userPoints = userPointsFunctions({
		userPointsContract,
		userPointsABI,
	});
	const packages = packagesFunctions({
		packagesContract,
		packagesABI,
	});
	return {
		unilevel,
		binary,
		qualifications,
		fortuneGoat,
		users,
		commissions,
		userPoints,
		membership,
		packages,
	};
};
