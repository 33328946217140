import { GenericList } from "common/components";
import AdminLayout from "layouts/AdminLayout";
import React from "react";
import { useState } from "react";
import { useFetchTurboFunds } from "../hooks/TurboFunds/useFetchTurboFunds";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "@mui/material";
import { useAccount } from "wagmi";
import { toast } from "react-toastify";
import { useDeleteExpenseProductTurboFunds } from "../hooks/TurboFunds/useDeleteExpenseProductTurboFunds";

export const TurboFundsPage = () => {

  const { address } = useAccount();
  const { requestDeleteExpenseProduct } = useDeleteExpenseProductTurboFunds();
  const navigate = useNavigate();
  const activeClass = "border-b-2 border-b-[#1840CE]";
  const [activeTab, setActiveTab] = useState("expenses");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const [turboFunds, setTurboFunds] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState({});

  const {
    data: turboFundsData,
    params,
    setParams,
    isPending,
  } = useFetchTurboFunds("turboFunds", { status: [1], search: "", page: 1, limit: 10 });

  const handleDelete = async () => {

    await requestDeleteExpenseProduct({
      expenseProductData: {
        userId: address,
        id: productToDelete.id,
        onSuccess: async () => {
          toast.success("Expense product deleted successfully.");
        },
        onError: async () => {
          toast.error("Failed to delete expense product.");
        },
      },
    });
    toast.success("Expense product deleted successfully.");
    setIsModalOpen(false);
    setTurboFunds(turboFunds.filter((product) => product.id !== productToDelete.id));
  };

  useEffect(() => {
    if (turboFundsData) {
      console.log({ turboFundsData });
      setTurboFunds(
        turboFundsData.products.map((item) => ({
          id: item.id,
          name: item.name,
          price: item.amount ? `${item.amount / 1000000} USDT` : item.amount,
          category: item.category,
          actions: (
            <div className="flex gap-x-2">
              <button
                className="border bg-[#87BAD7] text-[#051F40] font-semibold px-4 py-2"
                onClick={() => navigate(`/admin/turbo-funds/edit-expense/${item.id}`)}
              >
                Edit
              </button>
              <button
                className="border bg-[#CCCCCC] text-[#051F40] font-semibold px-4 py-2"
                onClick={() => {
                  setIsModalOpen(true);
                  setProductToDelete(item);
                }}
              >
                Delete
              </button>
            </div>
          ),
        }))
      );
    }
  }, [turboFundsData, navigate]);

  const expensesColumns = {
    name: { header: "Product Name" },
    price: { header: "Price" },
    category: { header: "Category" },
    actions: {
      header: "Actions",
    },
  };

  const Tabs = (
    <div className="grid grid-cols-3">
      <div
        className={`flex border-b-2 justify-center p-4 cursor-pointer ${
          activeTab === "expenses" ? activeClass : ""
        }`}
        onClick={() => handleTabChange("expenses")}
      >
        EXPENSES
      </div>
      <div
        className={`flex border-b-2 justify-center p-4 cursor-pointer ${
          activeTab === "summary" ? activeClass : ""
        }`}
        onClick={() => handleTabChange("summary")}
      >
        SUMMARY
      </div>
    </div>
  );

  const turboFundsHeaderOptions = (
    <div className="flex w-full justify-between">
      <div className="flex-1 border border-[#161C24]">
        <input
          type="text"
          placeholder="Search"
          className="p-2 w-3/4"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              setParams({ ...params, search: searchInput });
            }
          }}
        />
        <button
          className="bg-[#1840CE] text-white px-4 py-2 w-1/4"
          onClick={() => {
            setParams({ ...params, search: searchInput });
          }}
        >
          Search
        </button>
      </div>
      <button
        className="bg-[#BCE210] text-[#051F40] font-semibold px-4 py-2 ml-2"
        onClick={() => navigate("/admin/turbo-funds/create-expense")}
      >
        Create New Expense
      </button>
    </div>
  );

  const SummaryTable = (
    <div className="w-full mt-4">
      <div className="w-full">
        <p className="text-2xl pb-4 font-semibold">Summary</p>
      </div>
      <div className="w-full border-b border-b-dark border-dashed pb-4 flex gap-4">
        <div className="flex flex-col">
          <p className="text-baby-blue text-md font-semibold">Initial balance</p>
          <p className="text-dark text-lg font-semibold">N/A</p>
        </div>
        <div className="flex flex-col">
          <p className="text-baby-blue text-md font-semibold">Final balance</p>
          <p className="text-dark text-lg font-semibold">N/A</p>
        </div>
      </div>
      <table className="w-full mt-4">
        <thead>
          <tr>
            <th className="text-left">Product Name</th>
            <th className="text-left">Date</th>
            <th className="text-left">Status</th>
            <th className="text-left">Amount</th>
            <th className="text-left">Balance</th>
          </tr>
        </thead>
        <tbody>
          {/* <tr className="bg-gray-100">
            <td className="p-4">112316263</td>
            <td>June 6th, 2024</td>
            <td>Binary</td>
            <td>Complete</td>
            <td className="text-electric-blue font-semibold">$0.00</td>
          </tr>
          <tr className="bg-white">
            <td className="p-4">112316263</td>
            <td>June 6th, 2024</td>
            <td>Binary</td>
            <td>Complete</td>
            <td className="text-electric-blue font-semibold">$0.00</td>
          </tr>
          <tr className="bg-gray-100">
            <td className="p-4">112316263</td>
            <td>June 6th, 2024</td>
            <td>Binary</td>
            <td>Complete</td>
            <td className="text-electric-blue font-semibold">$0.00</td>
          </tr>
          <tr className="bg-white">
            <td className="p-4">112316263</td>
            <td>June 6th, 2024</td>
            <td>Binary</td>
            <td>Complete</td>
            <td className="text-electric-blue font-semibold">$0.00</td>
          </tr>
          <tr className="bg-gray-100">
            <td className="p-4">112316263</td>
            <td>June 6th, 2024</td>
            <td>Binary</td>
            <td>Complete</td>
            <td className="text-electric-blue font-semibold">$0.00</td>
          </tr>
          <tr className="bg-white">
            <td className="p-4">112316263</td>
            <td>June 6th, 2024</td>
            <td>Binary</td>
            <td>Complete</td>
            <td className="text-electric-blue font-semibold">$0.00</td>
          </tr> */}
        </tbody>
      </table>
    </div>
  );

  return (
    <AdminLayout>
      <div className="w-full bg-white p-4">
        <h1 className="text-xl font-semibold text-gray-800">Turbo Funds</h1>
        {Tabs}
        {activeTab === "expenses" && (
          <GenericList
            data={turboFunds}
            columns={expensesColumns}
            headerOptions={turboFundsHeaderOptions}
            isLoading={isPending}
          />
        )}
        {activeTab === "summary" && SummaryTable}
      </div>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="w-[400px] mx-auto bg-white rounded-md mt-20">
          <h2 className="text-2xl p-4 text-red-500">Delete expense product</h2>
          <p className="p-4 text-justify">Are you sure you want to delete this expense product?</p>
          <p className="p-4 font-bold uppercase text-center">
            {productToDelete.name} 
          </p>
          <div className="flex justify-end gap-x-2 p-4">
            <button
              className="border border-dark text-dark font-bold px-4 py-2 rounded"
              onClick={() => setIsModalOpen(false)}
            >
              Cancel
            </button>
            <button
              className="border border-red-500 text-red-500 font-bold px-4 py-2 rounded"
              onClick={() => handleDelete()}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </AdminLayout>
  );
};
