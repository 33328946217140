import AdminLayout from "layouts/AdminLayout";
import React from "react";
import { useState } from "react";
import { Button } from "common/components";
import { useFetchUserDetail } from "../hooks/Users/useFetchUserDetail";
import { useParams } from "react-router-dom";
import { SkeletonTable } from "../components/ui/SkeletonTable";
// import { useFetchRangeAndCommisions } from "modules/admin/hooks/RangeAndCommissions/useFetchRangeAndCommisions";
import { useFetchUserWalletHistory } from "../hooks/Users/useFetchUserWalletHistory";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { remove6Decimals } from "common/helpers";
import { useGetRankAndCommissions } from "common/hooks/useGetRankAndCommissions";

export const UserSeeDetailsPage = () => {
  const activeClass = "border-b-2 border-b-[#1840CE] text-[#1840CE] font-semibold";
  const [activeTab, setActiveTab] = useState("user-details");
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  const { id } = useParams();
  const { data: userDetail, isPending} = useFetchUserDetail("userDetail",{ userId: `${id}` })
  const { data: dataWalletHistory, isPending: isPendingWalletHistory} = useFetchUserWalletHistory("userWalletHistory",{ userId: `${id}` })

  const { data: dataCommissions, loading: isPendingRangeAndCommissions } =
    useGetRankAndCommissions({
      address: `${id}`,
      commissions: true,
    });

  const Tabs = (
    <div className="grid grid-cols-3">
      <div
        className={`flex border-b-2 justify-center p-4 cursor-pointer ${
          activeTab === "user-details" ? activeClass : ""
        }`}
        onClick={() => handleTabChange("user-details")}
      >
        User details
      </div>
      <div
        className={`flex border-b-2 justify-center p-4 cursor-pointer ${
          activeTab === "shoping-history" ? activeClass : ""
        }`}
        onClick={() => handleTabChange("shoping-history")}
      >
        Shopping history
      </div>
      <div
        className={`flex border-b-2 justify-center p-4 cursor-pointer ${
          activeTab === "commissions" ? activeClass : ""
        }`}
        onClick={() => handleTabChange("commissions")}
      >
        Commissions
      </div>
    </div>
  );

  return (
    <AdminLayout>
      <div className="max-w-screen-lg bg-white p-8">
        {isPending ? (
          <div>
            <Skeleton width={"9rem"} height={"1.2rem"} />
          </div>
        ) : (
          <h1 className="text-xl font-semibold text-gray-800">
            {userDetail && userDetail?.binary?.userName}{" "}
          </h1>
        )}
        {Tabs}

        {activeTab === "user-details" && (
          <>
            {isPending ? (
              <>
                <p className="mt-6">Wallet</p>
                <div>
                  <Skeleton width={"9rem"} height={"1.2rem"} />
                </div>
                <p className="mt-6">Sponsor</p>
                <div>
                  <Skeleton width={"9rem"} height={"1.2rem"} />
                </div>
                <p className="mt-6">Rank</p>
                <div>
                  <Skeleton width={"9rem"} height={"1.2rem"} />
                </div>
                <p className="mt-6">Package Acquired</p>
                <div>
                  <Skeleton width={"9rem"} height={"1.2rem"} />
                </div>
              </>
            ) : (
              <>
                <p className="mt-6">Wallet</p>
                <h2 className="font-bold">{userDetail && userDetail?.info?.id}</h2>

                <p className="mt-6">Sponsor</p>
                <h2 className="font-bold">{userDetail && userDetail?.binary?.sponsorUsername}</h2>

                <p className="mt-6">Rank</p>
                {isPendingRangeAndCommissions ? (
                  <div>
                    <Skeleton width={"2rem"} height={"1.2rem"} />
                  </div>
                ) : (
                  <h2 className="font-bold">
                    {dataCommissions && dataCommissions?.userRank?.userRank}
                  </h2>
                )}
                <p className="mt-6">Package Acquired</p>
                <h2 className="font-bold">{userDetail && userDetail?.package?.packageName}</h2>
              </>
            )}
          </>
        )}

        {activeTab === "shoping-history" && (
          <div className="col-span-3 relative overflow-x-auto">
            {isPendingWalletHistory ? (
              <div className="p-4 w-full col-span-5">
                <SkeletonTable />
              </div>
            ) : (
              <div className="col-span-3 relative max-h-96 overflow-x-auto overflow-y-scroll">
                <table className="mt-3 w-full divide-y divide-gray-200 dark:divide-gray-300">
                  <thead className="sticky top-0 bg-white">
                    <tr className="text-left border-b border-[#808080]">
                      <th className="pl-4 p-4 font-bold">Product Name</th>
                      <th className="text-left p-4 font-bold">Price</th>
                      <th className="text-left p-4 font-bold">Category</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataWalletHistory &&
                      dataWalletHistory?.expenses?.detailedData.map((item, index) => (
                        <tr className="border-b border-[#808080]" key={index}>
                          <td className="p-4">{item?.description}</td>
                          <td className="text-left pl-4">{item?.amount}</td>
                          <td className="p-4">{item?.category}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}

        {activeTab === "commissions" && (
          <>
            {isPendingRangeAndCommissions ? (
              <>
                <div className="flex gap-10 font-bold mt-8">
                  <p className="whitespace-break-spaces ...">1) FAST START:</p>
                  <p className="border-b-4 border-b-[#BCE210]">
                    <div>
                      <Skeleton width={"6rem"} height={"1.2rem"} />
                    </div>
                  </p>
                </div>
                <div className="flex gap-10 font-bold my-8">
                  <p className="whitespace-break-spaces ...">2) BINARY:</p>
                  <p className="border-b-4 border-b-[#BCE210]">
                    <div>
                      <Skeleton width={"6rem"} height={"1.2rem"} />
                    </div>
                  </p>
                </div>
                <div className="flex gap-10 font-bold my-8">
                  <p className="whitespace-break-spaces ...">3) BINARY MATCHING BONUS:</p>
                  <p className="border-b-4 border-b-[#BCE210]">
                    <div>
                      <Skeleton width={"6rem"} height={"1.2rem"} />
                    </div>
                  </p>
                </div>
                <div className="flex gap-10 font-bold my-8">
                  <p className="whitespace-break-spaces ...">3) UNILEVEL:</p>
                  <p className="border-b-4 border-b-[#BCE210]">
                    <div>
                      <Skeleton width={"6rem"} height={"1.2rem"} />
                    </div>
                  </p>
                </div>
              </>
            ) : (
              <>
                <div className="flex gap-10 font-bold mt-8">
                  <p className="whitespace-break-spaces ...">1) FAST START:</p>
                  <p className="border-b-4 border-b-[#BCE210]">
                    $ {dataCommissions && remove6Decimals(dataCommissions?.fastStart?.total)} USDT
                  </p>
                  {/* data.commissionsBreakdown.fastStart */}
                </div>
                <div className="flex gap-10 font-bold my-8">
                  <p className="whitespace-break-spaces ...">2) BINARY:</p>
                  <p className="border-b-4 border-b-[#BCE210]">
                    ${" "}
                    {dataCommissions &&
                      remove6Decimals(dataCommissions?.commissions?.binaryCommission?.total)}{" "}
                    USDT
                  </p>
                  {/* data.commissionsBreakdown.fastStart */}
                </div>
                <div className="flex gap-10 font-bold my-8">
                  <p className="whitespace-break-spaces ...">3) BINARY MATCHING BONUS:</p>
                  <p className="border-b-4 border-b-[#BCE210]">
                    $ {dataCommissions && remove6Decimals(dataCommissions?.matchingBonus?.total)}{" "}
                    USDT
                  </p>
                  {/* data.commissionsBreakdown.fastStart */}
                </div>
                <div className="flex gap-10 font-bold my-8">
                  <p className="whitespace-break-spaces ...">3) UNILEVEL:</p>
                  <p className="border-b-4 border-b-[#BCE210]">
                    ${" "}
                    {dataCommissions &&
                      remove6Decimals(dataCommissions?.commissions?.unilevelCommission?.total)}{" "}
                    USDT
                  </p>
                  {/* data.commissionsBreakdown.fastStart */}
                </div>
              </>
            )}
          </>
        )}

        <div className="col-span-3 md:col-span-2 px-4">
          <div className="my-4">
            <Button
              className="mr-2 mt-8"
              label="Back"
              type="button"
              onClick={() => window.history.back()}
            />
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};
