import founderBronze from "@assets/icons/bronze.png";
import founderSilver from "@assets/icons/silver.png";
import founderGold from "@assets/icons/gold.png";
import diamondFounder from "@assets/icons/diamond.png";

export const JoinUs = ({ handleJoin }) => {
  return (
    <div className="flex flex-col py-40 bg-white ">
      <div className="w-full">
        <div className="flex justify-center py-10">
          <div className="flex-col container px-4">
            <div className="text-center">
              <h2 className="text-5xl text-dark font-light mb-4">
                Join Us for free to know more about our Founder Packs&apos; benefits
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="container px-4">
          <div className="grid grid-cols-7">
            <div className="md:col-span-3 col-span-7 p-6 rounded">
              <div>
                <div className="w-full flex justify-center">
                  <img src={founderBronze} className="w-60" />
                </div>
                <p className="text-xl text-center font-semibold text-dark mb-4">
                  Founder Packs Starting From:
                </p>
                <p className="text-g text-center" style={{ color: "#BCE210", fontSize: "7rem" }}>
                  <sup
                    className="font-semibold"
                    style={{ color: "#BCE210", fontSize: "3rem", verticalAlign: "15px" }}
                  >
                    $
                  </sup>
                  900
                </p>
                <h3 className="text-3xl text-dark  mb-4 text-center px-9">Bronze Founder</h3>
              </div>
            </div>
            <div className="col-span-1">
              <div className=" mx-4 md:flex py-1 justify-center hidden  ">
                <div className="min-h-96 w-0.5  bg-gray-400"></div>
              </div>
            </div>
            <div className="md:col-span-3 col-span-7 p-6 rounded">
              <div className="w-full flex justify-center pb-5">
                <p className="text-[#1840CE] font-semibold text-xl justify-center">
                  Other packages
                </p>
              </div>
              <div className="flex items-center">
                <img src={founderSilver} />
                <div className="flex flex-col">
                  <p className="text-xl text-center font-semibold text-dark mb-4">Founder Silver</p>
                  <p className="text-g text-center">$1,500.00</p>
                </div>
              </div>
              <div className="flex items-center">
                <img src={founderGold} />
                <div className="flex flex-col">
                  <p className="text-xl text-center font-semibold text-dark mb-4">Founder Gold</p>
                  <p className="text-g text-center">$3,500.00</p>
                </div>
              </div>
              <div className="flex items-center">
                <img src={diamondFounder} />
                <div className="flex flex-col">
                  <p className="text-xl text-center font-semibold text-dark mb-4">
                    Founder Diamond
                  </p>
                  <p className="text-g text-center">$6,500.00</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col py-20">
        <div className="flex justify-center">
          <div className="container flex justify-center px-4">
            <div className="mx-4 flex items-center">
              <button
                onClick={handleJoin}
                className="bg-highlight-secondary hover:bg-zinc-400  font-bold py-2 px-20 rounded text-lg"
              >
                Join us
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
