import { onGetFastStart } from "./onGetFastStart";
import { getLevelAddresses as defaultGetLevelAddresses } from "./getLevelAddresses";
import { getLevelSum as defaultGetLevelSum } from "./getLevelSum";

export const getFastStart = ({
  contractFunctions,
  getLevelAddresses = defaultGetLevelAddresses,
  getLevelSum = defaultGetLevelSum,
}) => {
  return async ({ address }) =>
    onGetFastStart({
      address,
      contractFunctions,
      getLevelAddresses,
      getLevelSum,
    });
};
