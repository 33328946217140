import { Navbar } from "modules/landing/components";
import React, { useState } from "react";
import CheckIcon from "assets/icons/check.svg";
import GrayCheckIcon from "assets/icons/gray-checkbox.svg";
// import FunShopper1 from "assets/packs/fun-shopper-1.png";
// import FunShopper2 from "assets/packs/fun-shopper-2.png";
// import FunShopper3 from "assets/packs/fun-shopper-3.png";
// import FunShopper4 from "assets/packs/fun-shopper-4.png";
// import FunShopper5 from "assets/packs/fun-shopper-5.png";
// import FunShopper6 from "assets/packs/fun-shopper-6.png";
// import DiamondShopper from "assets/packs/diamond-shopper.png";
// import PlatinumShopper from "assets/packs/platinum-shopper.png";
import { Footer } from "common/components";
import { useFetchPackages } from "modules/admin/hooks/Packages/useFetchPackages";
import { Modal } from "@mui/material";
import { useAccount, useBalance, useConnect, useEstimateFeesPerGas } from "wagmi";
import { useBuyPackage } from "common/hooks/useBuyPackage";
import { toast } from "react-toastify";
import { useRequestFunds } from "common/hooks/useRequestFunds";
import { onReadContract } from "common/services";
import { onEstimateWriteContractGas } from "common/services/onEstimateGas";
import { onWriteContract } from "common/services";
import USDTABI from "@common/contracts/USDT.json";
import { wagmiConfig } from "App";
import { formatNumber } from "../utils/currencyFormatter";
import { web3AuthInstance } from "App";
import { replaceWCQrCode } from "common/util/replaceWCQrCode";
const GAS_MULTIPLIER = 1.15;

export const PricingPage = () => {
  const { data: packagesData } = useFetchPackages("packages", { page: 1, limit: 10, search: "" });
  const [activePackage, setActivePackage] = useState(null);
  const { address } = useAccount();
  const { requestBuyPackage } = useBuyPackage();

  const [openModal, setOpenModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const BuyPackageModal = () => {
    const [loading, setLoading] = useState(false);
    const { address, isConnected } = useAccount();
    const { data: balanceData } = useBalance({ address: isConnected ? address : undefined });
    const { requestFunds } = useRequestFunds();
    const { data: gasFeeData } = useEstimateFeesPerGas();

    const onMakePayment = async () => {
      let counter = 0;
      while (!gasFeeData || counter < 10000000) {
        counter++;
      }
      console.debug("gasFeeData", gasFeeData);
      // alert(gasFeeData);
      setLoading(true);
      const hasApproval = await onReadContract({
        contractAddress: process.env.REACT_APP_USDT_CONTRACT_ADDRESS,
        contractABI: USDTABI,
        functionName: "allowance",
        args: [address, process.env.REACT_APP_CONTRACT_ADDRESS],
        from: address,
      });
      let approveResponse = hasApproval ? Number(hasApproval) > 0 : false;
      if (!approveResponse) {
        const estimateApprovalGas = await onEstimateWriteContractGas({
          contractAddress: process.env.REACT_APP_USDT_CONTRACT_ADDRESS,
          contractABI: USDTABI,
          functionName: "approve",
          args: [process.env.REACT_APP_CONTRACT_ADDRESS, BigInt(9999999999999999n)],
          from: address,
        });
        console.log({ estimateApprovalGas });
        console.log({
          total:
            Number(estimateApprovalGas) *
            Number(
              process.env.REACT_APP_ENVIRONMENT === "production"
                ? gasFeeData.gasPrice || gasFeeData.maxFeePerGas
                : 10000000000
            ) *
            1.35,
        });
        const gas = parseInt(
          Number(estimateApprovalGas) *
            Number(
              process.env.REACT_APP_ENVIRONMENT === "production"
                ? gasFeeData.gasPrice || gasFeeData.maxFeePerGas
                : 10000000000
            ) *
            2
        );
        console.log({ gas });
        if (balanceData) {
          console.log({ value: balanceData.value });
          if (Number(balanceData.value) < gas) {
            const userData = {
              address,
              onError: async () => {
                return toast.error(
                  "Something went wrong funding account, please try again. FUND ERROR."
                );
              },
              onSuccess: async () => {
                console.log({ gas });
                approveResponse = await onWriteContract({
                  contractAddress: process.env.REACT_APP_USDT_CONTRACT_ADDRESS,
                  contractABI: USDTABI,
                  functionName: "approve",
                  args: [process.env.REACT_APP_CONTRACT_ADDRESS, BigInt(9999999999999999n)],
                  from: address,
                  confirmations: 2,
                  maxFeePerGas: parseInt(
                    Number(
                      Math.max([Number(gasFeeData.gasPrice ?? 0), Number(gasFeeData.maxFeePerGas)])
                    ) * GAS_MULTIPLIER
                  ),
                  maxPriorityFeePerGas: parseInt(
                    Number(
                      Math.max([Number(gasFeeData.gasPrice ?? 0), Number(gasFeeData.maxFeePerGas)])
                    ) * GAS_MULTIPLIER
                  ),
                });
                if (approveResponse) {
                  toast.success("Successfully approved");
                } else {
                  setLoading(false);
                  return toast.error("Something went wrong, please try again. APPROVE ERROR 1.");
                }
                console.log({ userData });
                setIsSubmitting(true);
                await requestBuyPackage({
                  packageData: {
                    userId: address,
                    packageId: activePackage?.package.id,
                    onSuccess: async () => {
                      setIsSubmitting(false);
                      toast.success("Package bought successfully.");
                      setOpenModal(false);
                    },
                    onError: async (error) => {
                      setIsSubmitting(false);
                      toast.error(`Failed to buy package.${error}`);
                    },
                  },
                });
              },
            };
            await requestFunds({ userData });
          } else {
            console.log({ gas });
            approveResponse = await onWriteContract({
              contractAddress: process.env.REACT_APP_USDT_CONTRACT_ADDRESS,
              contractABI: USDTABI,
              functionName: "approve",
              args: [process.env.REACT_APP_CONTRACT_ADDRESS, BigInt(9999999999999999n)],
              from: address,
              confirmations: 2,
              maxFeePerGas: parseInt(
                Number(
                  Math.max([Number(gasFeeData.gasPrice ?? 0), Number(gasFeeData.maxFeePerGas)])
                ) * GAS_MULTIPLIER
              ),
              maxPriorityFeePerGas: parseInt(
                Number(
                  Math.max([Number(gasFeeData.gasPrice ?? 0), Number(gasFeeData.maxFeePerGas)])
                ) * GAS_MULTIPLIER
              ),
            });
            if (approveResponse) {
              toast.success("Successfully approved");
            } else {
              setLoading(false);
              return toast.error(
                `Something went wrong, please try again. APPROVE ERROR 2.${approveResponse}`
              );
            }
            setIsSubmitting(true);
            await requestBuyPackage({
              packageData: {
                userId: address,
                packageId: activePackage?.package.id,
                onSuccess: async () => {
                  setIsSubmitting(false);
                  toast.success("Package bought successfully.");
                  setOpenModal(false);
                },
                onError: async (error) => {
                  setIsSubmitting(false);
                  toast.error(`Failed to buy package.${error}`);
                },
              },
            });
          }
        } else {
          setLoading(false);
        }
      } else {
        setIsSubmitting(true);
        await requestBuyPackage({
          packageData: {
            userId: address,
            packageId: activePackage?.package.id,
            onSuccess: async () => {
              setIsSubmitting(false);
              toast.success("Package bought successfully.");
              setOpenModal(false);
            },
            onError: async (error) => {
              setIsSubmitting(false);
              toast.error(`Failed to buy package.${error}`);
            },
          },
        });
      }
    };

    return (
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <div className="w-[400px] mx-auto bg-white rounded-md mt-20">
          <h2 className="text-2xl p-4 text-dark">Do you want to buy this package?</h2>
          <div className="p-4">
            <p>Package: {activePackage?.package.name}</p>
            <p>
              Price:{" "}
              {activePackage?.package.price
                ? `${activePackage?.package.price / 1e6} USDT`
                : activePackage?.package.price}
            </p>

            <button
              disabled={loading || isSubmitting}
              className="bg-[#1840CE] text-white font-bold py-2 px-4 w-fit m-5"
              onClick={onMakePayment}
              /* onClick={async () => {
                setIsSubmitting(true);
                await requestBuyPackage({
                  packageData: {
                    userId: address,
                    packageId: activePackage?.package.id,
                    onSuccess: async () => {
                      setIsSubmitting(false);
                      toast.success("Package buyed successfully.");
                      setOpenModal(false);
                    },
                    onError: async () => {
                      setIsSubmitting(false);
                      toast.error("Failed to buy package.");
                    },
                  },
                });
              }} */
            >
              {loading || isSubmitting ? "BUYING..." : "BUY PACKAGE"}
            </button>

            <button
              className="border border-dark text-dark font-bold px-4 py-2 rounded"
              onClick={() => setOpenModal(false)}
            >
              CANCEL
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  const { error, connectors, connect } = useConnect(wagmiConfig);

  const handleJoin = async () => {
    // setShowModal(true);
    if (connect) {
      const observer = replaceWCQrCode({ web3AuthInstance });
      connect(
        { connector: connectors[0] },
        {
          onSuccess: () => {
            window.location.reload();
          },
          onError: () => {
            observer.disconnect();
          },
        }
      );
    }
  };
  return (
    <div className="bg-[#E3E3D7] min-h-screen">
      <Navbar />
      <div className="flex justify-between p-4 mt-4 container mx-auto">
        <h1 className="font-bold text-3xl">BID PACKS</h1>
        <div className="flex flex-col">
          <p>Choose a pack that&apos;s right for you.</p>
          <p>Change or improve your pack anytime.</p>
        </div>
      </div>
      <div className="bg-gradient-to-b from-cream to-sky-700/50">
        <div className="grid grid-cols-3 gap-4 container mx-auto p-2">
          {/* {packsData.map((pack, index) => (
            <div className="col-span-3 md:col-span-1 bg-white mx-2 md:mx-0" key={index}>
              <div className="flex flex-col">
                <div className="flex p-5 flex-wrap">
                  <img src={pack.image} alt="placeholder" />
                  <div className="p-5">
                    <h2 className="text-xl font-semibold">{pack.title}</h2>
                    <p className="text-3xl font-semibold">{pack.price}</p>
                  </div>
                </div>
                <hr className="w-[90%] mx-auto" />
                <ul className="p-5">
                  {pack.features.map((feature, key) => (
                    <li key={key} className="flex">
                      <img src={CheckIcon} /> <p className="px-2">{feature}</p>
                    </li>
                  ))}
                </ul>
                <button className="bg-[#1840CE] text-white font-bold py-2 px-4 w-fit m-5">
                  GET STARTED
                </button>
              </div>
            </div>
          ))} */}
          {packagesData?.packages &&
            packagesData?.packages.map((pack, index) => (
              <div className="col-span-3 md:col-span-1 bg-white mx-2 md:mx-0" key={index}>
                <div className="flex flex-col">
                  <div className="flex p-5 flex-wrap">
                    <img
                      src={`${pack.extraInfo.images[0]}?pinataGatewayToken=${process.env.REACT_APP_PINATA_GATEWAY_KEY}`}
                      alt="placeholder"
                      className="max-w-[50%]"
                    />
                    <div className="p-5">
                      <h2 className="text-xl font-semibold">{pack.package.name}</h2>
                      <p className="text-3xl font-semibold">
                        {pack.package.price
                          ? `${pack.package.price / 1000000} USDT`
                          : pack.package.price}
                      </p>
                    </div>
                  </div>
                  <p className="p-5 break-words">{pack.extraInfo.description}</p>
                  <hr className="w-[90%] mx-auto" />
                  <ul className="p-5">
                    <li className="flex">
                      {pack.bonus.startupBonus > 0 ? (
                        <img src={CheckIcon} />
                      ) : (
                        <img src={GrayCheckIcon} />
                      )}
                      <p className="px-2">
                        Startup Bonus: {formatNumber(pack.bonus.startupBonus / 1e6, "currency", 2)}
                      </p>
                    </li>
                    <li className="flex">
                      {pack.package.qv > 0 ? <img src={CheckIcon} /> : <img src={GrayCheckIcon} />}
                      <p className="px-2">QV: {pack.package.qv}</p>
                    </li>
                    <li className="flex">
                      {pack.package.ucv > 0 ? <img src={CheckIcon} /> : <img src={GrayCheckIcon} />}
                      <p className="px-2">UCV: {pack.package.ucv}</p>
                    </li>
                    <li className="flex">
                      {pack.package.bcv > 0 ? <img src={CheckIcon} /> : <img src={GrayCheckIcon} />}
                      <p className="px-2">BCV: {pack.package.bcv}</p>
                    </li>
                    <li className="flex">
                      {pack.binary.binaryPercent > 0 ? (
                        <img src={CheckIcon} />
                      ) : (
                        <img src={GrayCheckIcon} />
                      )}
                      <p className="px-2">IDT percentage of {pack.binary.binaryPercent / 1e2}%</p>
                    </li>
                    <li className="flex">
                      {pack.binary.binaryCap > 0 ? (
                        <img src={CheckIcon} />
                      ) : (
                        <img src={GrayCheckIcon} />
                      )}
                      <p className="px-2">
                        IDT Cap:{" "}
                        {formatNumber(
                          pack.binary.binaryCap ? pack.binary.binaryCap / 1e6 : 0,
                          "currency",
                          2
                        )}
                      </p>
                    </li>
                    <li className="flex">
                      {pack.expirations.qualificationExpirationDays > 0 ? (
                        <img src={CheckIcon} />
                      ) : (
                        <img src={GrayCheckIcon} />
                      )}
                      <p className="px-2">
                        Qualification time: {pack.expirations.qualificationExpirationDays} days
                      </p>
                    </li>
                    <li className="flex">
                      {pack.bid.price > 0 ? <img src={CheckIcon} /> : <img src={GrayCheckIcon} />}
                      <p className="px-2">
                        Bid price: {formatNumber(pack.bid.bidPrice / 1e6, "currency", 2)}
                      </p>
                    </li>
                    <li className="flex">
                      {pack.bid.totalBids > 0 ? (
                        <img src={CheckIcon} />
                      ) : (
                        <img src={GrayCheckIcon} />
                      )}
                      <p className="px-2">Bids amount: {formatNumber(pack.bid.totalBids)}</p>
                    </li>
                    <li className="flex">
                      {pack.cashback.cashback > 0 ? (
                        <img src={CheckIcon} />
                      ) : (
                        <img src={GrayCheckIcon} />
                      )}
                      <p className="px-2">
                        Cashback: {formatNumber(pack.cashback.cashback / 1e6, "currency", 2)}
                      </p>
                    </li>
                    <li className="flex">
                      {pack.expirations.cashbackExpirationDays > 0 ? (
                        <img src={CheckIcon} />
                      ) : (
                        <img src={GrayCheckIcon} />
                      )}
                      <p className="px-2">
                        Time for Cashback: {pack.expirations.cashbackExpirationDays} days
                      </p>
                    </li>
                    <li className="flex">
                      {pack.expirations.bidExpirationDays > 0 ? (
                        <img src={CheckIcon} />
                      ) : (
                        <img src={GrayCheckIcon} />
                      )}
                      <p className="px-2">
                        Bid expiration: {pack.expirations.bidExpirationDays} days
                      </p>
                    </li>
                    <li className="flex">
                      {pack.bonus.matchingBonus > 0 ? (
                        <img src={CheckIcon} />
                      ) : (
                        <img src={GrayCheckIcon} />
                      )}
                      <p className="px-2">Matching Bonus: {pack.bonus.matchingBonus}</p>
                    </li>
                    <li className="flex">
                      {pack.bonus.unilevelBonus > 0 ? (
                        <img src={CheckIcon} />
                      ) : (
                        <img src={GrayCheckIcon} />
                      )}
                      <p className="px-2">Family Tree Bonus: {pack.bonus.unilevelBonus}</p>
                    </li>
                    {/* {pack.features.map((feature, key) => (
                      <li key={key} className="flex">
                        <img src={CheckIcon} /> <p className="px-2">{feature}</p>
                      </li>
                    ))} */}
                  </ul>
                  {address ? (
                    <button
                      className="bg-[#1840CE] text-white font-bold py-2 px-4 w-fit m-5"
                      onClick={() => {
                        setOpenModal(true);
                        setActivePackage(pack);
                      }}
                    >
                      BUY PACKAGE
                    </button>
                  ) : (
                    <button
                      className="bg-[#1840CE] text-white font-bold py-2 px-4 w-fit m-5"
                      onClick={handleJoin}
                    >
                      GET STARTED
                    </button>
                  )}
                </div>
              </div>
            ))}
        </div>
        <BuyPackageModal />
        <Footer />
      </div>
    </div>
  );
};
