export const handleAutobuyResponse = ({
  data,
  setItems,
  setItemDetails,
  itemDetails,
  address,
  updateUser,
}) => {
  const itemsData = data;
  // console.debug("handleAuctionEndResponse", { itemsData });
  if (itemsData) {
    if (itemDetails) {
      setItemDetails(itemsData);
    }

    setItems((prev) => {
      const index = prev.findIndex((item) => item.id == itemsData.id);
      if (index == -1) return prev;
      const newItems = [...prev];
      newItems[index] = { ...itemsData, canBid: false, lastBidder: itemsData.bids.at(-1) };
      return newItems;
    });

    if (itemsData && itemsData.bidders && itemsData.bidders.includes(address)) {
      updateUser();
    }
  }
};

export const handleUserAutobuyResponse = ({
  data,
  setItems,
  setItemDetails,
  itemDetails,
}) => {
  const itemsData = data.item;
  // console.debug("handleUserAutobuyResponse", { itemsData });

  if (itemsData) {
    if (itemDetails) {
      setItemDetails(itemsData);
    }
    setItems((prev) => {
      const index = prev.findIndex((item) => item.id == itemsData.id);
      if (index == -1) return prev;
      const newItems = [...prev];
      newItems[index] = { ...itemsData, canBid: false, lastBidder: itemsData.bids.at(-1) };
      return newItems;
    });
  }
};
