// import { SentryService } from "./SentryErrorCapture";
import axios from "axios";

export const onEditBid = async ({ data, signature }) => {
  try {
    const updatedBidResponse = await axios.put(
      `${process.env.REACT_APP_AWS_API_URL}/contracts/pa/item`,
      {
        ...data,
        signature,
      },
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    if (updatedBidResponse) {
      return updatedBidResponse.data;
    }
  } catch (error) {
    // SentryService("onEditBid", "onEditBid", error);
    // console.debug(error);
    throw new Error(
      error.response
        ? error.response.data.error.reason ||
          error.response.data.error.shortMessage ||
          error.response.data.error
        : error
    );
  }
};
