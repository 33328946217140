export const Header = ({ handleJoin }) => {
  return (
    <>
      <div
        className="flex  flex-col justify-center items-center bg-[url('@assets/images/image-family.png')] bg-center bg-no-repeat bg-cover min-h-[300px] md:min-h-[800px]"
        style={{
          width: "auto",
        }}
      >
        <h1 className="text-5xl text-white flex items-center mb-4">Welcome to FortuneGOAT</h1>
        <p className="text-2xl text-white max-w-[60%] text-center">
          Your gateway to a life changing experience! In a world where prices are always going up,
          incomes feel not enough, and dreams often seem out of reach, we&apos;re here to introduce
          a fresh opportunity that not only boosts your income but also puts your dreams within
          arm&apos;s reach.
        </p>
        <button
          onClick={handleJoin}
          className="bg-electric-blue hover:bg-zinc-400 text-white font-bold py-2 px-20 rounded text-lg mt-20"
        >
          Join Now
        </button>
      </div>
      <div className="bg-highlight-primary flex items-center justify-center py-10">
        <div className="container flex md:justify-between justify-center flex-wrap px-4">
          <div className="flex flex-row mb-4 xs:mb-0">
            <div className="border-l-2 border-dark opacity-50 pl-4" />
            <div className="text-5xl font-semibold text-dark flex items-center mr-4">A</div>
            <p className="text-gray-600 text-lg" style={{ maxWidth: "300px" }}>
              Penny auctions for a thrilling experience while saving big
            </p>
          </div>
          <div className="flex flex-row mb-4 xs:mb-0">
            <div className="border-l-2 border-dark opacity-50 pl-4" />
            <div className="text-5xl font-semibold text-dark flex items-center mr-4">B</div>
            <p className="text-gray-600 text-lg" style={{ maxWidth: "300px" }}>
              Share outstanding products through network marketing
            </p>
          </div>
          <div className="flex flex-row mb-4 xs:mb-0">
            <div className="border-l-2 border-dark opacity-50 pl-4" />
            <div className="text-5xl font-semibold text-dark flex items-center mr-4">C</div>
            <p className="text-gray-600 text-lg" style={{ maxWidth: "300px" }}>
              Propel your goals through leadership programs
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
