import { onReadContract } from "../../../onReadContract";

export const unilevelFunctions = ({ unilevelContract, unilevelABI }) => {
  const unilevelUsersSponsoredByCache = {};
  const readUnilevel = async ({ functionName, args }) => {
    return await onReadContract({
      contractAddress: unilevelContract,
      contractABI: unilevelABI,
      functionName,
      args,
    });
  };
  const unilevelUVolume = async (address) => {
    const args = [address, 0];
    const unilevelUnder = await readUnilevel({
      functionName: "getVolumeUnder",
      args,
    });
    console.debug("unilevelUnder: ", unilevelUnder);
    if (unilevelUnder) {
      return unilevelUnder.map((value) => {
        return Number(value);
      });
    }
    return [0];
  };
  const unilevelUCV = async (address) => {
    const args = [address, 1];
    const unilevelUnder = await readUnilevel({
      functionName: "getVolumeUnder",
      args,
    });
    console.debug("unilevelUnder: ", unilevelUnder);
    if (unilevelUnder) {
      return unilevelUnder.map((value) => {
        return Number(value);
      });
    }
    return [0];
  };
  const unilevelUsersSponsoredBy = async (address) => {
    console.debug("unilevelUsersSponsoredBy: ", address);
    if (unilevelUsersSponsoredByCache && unilevelUsersSponsoredByCache[address]) {
      return unilevelUsersSponsoredByCache[address];
    }
    const args = [address];
    const UsersSponsoredBy = await readUnilevel({
      functionName: "getUsersSponsoredBy",
      args,
    });

    console.debug("unilevelUsersSponsoredBy: ", UsersSponsoredBy);
    if (UsersSponsoredBy) {
      const addressesResult = UsersSponsoredBy.map((value) => {
        return {
          id: value.id,
          parentId: value.parentId,
          userName: value.userName,
          status: Number(value.status),
        };
      });
      if (unilevelUsersSponsoredByCache) {
        unilevelUsersSponsoredByCache[address] = addressesResult;
      }
      return addressesResult;
    }
    if (unilevelUsersSponsoredByCache) {
      unilevelUsersSponsoredByCache[address] = [];
    }
    return [];
  };
  return { unilevelUVolume, unilevelUCV, unilevelUsersSponsoredBy };
};
