import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { useAccount } from "wagmi";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";

import { Button } from "common/components";
import { Select } from "common/components";
import { Input } from "common/components";
import { useCreateExpenseProductTurboFunds } from "../../admin/hooks/TurboFunds/useCreateExpenseProductTurboFunds";
import AdminLayout from "layouts/AdminLayout";
import { onGetCategoriesList } from "modules/penny-auction/services/onGetCategoriesList";

export const CreateExpensePage = () => {
  const navigate = useNavigate();
  const { address } = useAccount();
  const { requestCreateExpenseProduct } = useCreateExpenseProductTurboFunds();

  const { isFetching: isLoadingCategories, data: categoriesList } = useQuery({
    queryKey: [`categoriesList`],
    queryFn: async () => await onGetCategoriesList(),
    staleTime: 360000,
    refetchOnWindowFocus: false,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [image, setImage] = useState([]);
  const [images, setImages] = useState([]);
  const onSubmit = async (data) => {
    setIsSubmitting(true);

    let parsedInputs = {
      name: data.name || "",
      category: data.category || "",
      amount: data.amount || "",
      image: [...Array.from(data.image), ...Array.from(data.images)],
    };

    console.log({ parsedInputs });

    await requestCreateExpenseProduct({
      expenseProductData: {
        userId: address,
        ...parsedInputs,
        onSuccess: async () => {
          toast.success("Expense product created successfully.");
          navigate("/admin/turbo-funds");
        },
        onError: async () => {
          isSubmitting(false);
          toast.error("Failed to create expense product.");
        },
      },
    });
  };

  const imageFileList = watch("image");

  useEffect(() => {
    if (imageFileList && imageFileList.length > 0) {
      const newImages = [];
      Array.from(imageFileList).forEach((file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          newImages.push(event.target.result);
          if (newImages.length === imageFileList.length) {
            setImage(newImages);
          }
        };
        reader.readAsDataURL(file);
      });
    } else {
      setImage([]);
    }
  }, [imageFileList]);

  const imagesFileList = watch("images");

  useEffect(() => {
    if (imagesFileList && imagesFileList.length > 0) {
      const newImages = [];
      Array.from(imagesFileList).forEach((file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          newImages.push(event.target.result);
          if (newImages.length === imagesFileList.length) {
            setImages(newImages);
          }
        };
        reader.readAsDataURL(file);
      });
    } else {
      setImages([]);
    }
  }, [imagesFileList]);

  const categoriesOptions = categoriesList ? categoriesList.map((cat) => cat.name) : [];

  return (
    <AdminLayout>
      <div className="max-w-screen-lg bg-white">
        <div className="w-full px-8">
          <h1 className="text-2xl px-4 pt-8">New Expense</h1>
          <form className="grid grid-cols-3 mt-4" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-span-3 md:col-span-3 px-4">
              <div className="mb-2">
                <div className="flex gap-4 items-center">
                  <label className="text-lg font-semibold" htmlFor="name">
                    Product´s Name
                  </label>
                  <div className="flex" title="Name of the product">
                    <FontAwesomeIcon icon={faCircleInfo} color="#4C4C4C" />
                  </div>
                </div>
                <Input
                  disabled={isSubmitting}
                  type="text"
                  {...register("name", { required: "This field is required." })}
                  id="name"
                />
                {errors.name && <span className="text-red-500">{errors.name.message}</span>}
              </div>

              <div className="mb-2">
                <div className="flex gap-4 items-center">
                  <label className="text-lg font-semibold" htmlFor="category">
                    Category
                  </label>
                  <div className="flex" title="Category of the product">
                    <FontAwesomeIcon icon={faCircleInfo} color="#4C4C4C" />
                  </div>
                </div>
                {isLoadingCategories ? (
                  <p>Loading categories list...</p>
                ) : (
                  Array.isArray(categoriesList) && (
                    <Select
                      disabled={isSubmitting}
                      id="category"
                      options={categoriesOptions}
                      {...register("category", { required: "This field is required." })}
                    />
                  )
                )}
                {errors.category && <span className="text-red-500">{errors.category.message}</span>}
              </div>
              <div className="mb-2">
                <div className="flex gap-4 items-center">
                  <label className="text-lg font-semibold" htmlFor="amount">
                    Cost
                  </label>
                  <div className="flex" title="Cost">
                    <FontAwesomeIcon icon={faCircleInfo} color="#4C4C4C" />
                  </div>
                </div>
                <Input
                  disabled={isSubmitting}
                  type="number"
                  {...register("amount", { required: "This field is required." })}
                  id="amount"
                  step="0.01"
                />
                {errors.amount && <span className="text-red-500">{errors.amount.message}</span>}
              </div>
            </div>
            <div className="col-span-3 md:col-span-3 px-4">
              <div className="mb-2">
                <h2 className="text-lg font-semibold">Image</h2>
                <label className="text-lg font-semibold" htmlFor="image">
                  Add Main Image
                </label>
                <div className="flex w-full">
                  {image.length > 0 && (
                    <img
                      src={image[0]}
                      alt={`Imagen cargada 1`}
                      style={{ width: "150px", margin: "10px" }}
                    />
                  )}
                </div>
                <div className="flex w-full">
                  {image.length === 0 && (
                    <img
                      src="https://via.placeholder.com/150"
                      alt="product"
                      style={{ width: "150px" }}
                    />
                  )}
                </div>
                <Input
                  disabled={isSubmitting}
                  type="file"
                  {...register("image", { required: "This field is required." })}
                  id="image"
                />
                {errors.image && <span className="text-red-500">{errors.image.message}</span>}

                <h2 className="text-lg font-semibold">Image</h2>
                <label className="text-lg font-semibold" htmlFor="image">
                  Add Secondary Images
                </label>
                <div className="flex w-full">
                  {images.length > 0 &&
                    images.map((image, index) => (
                      <img
                        key={index}
                        src={image}
                        alt={`Imagen cargada ${index + 1}`}
                        style={{ width: "150px", margin: "10px" }}
                      />
                    ))}
                </div>
                <div className="flex w-full">
                  {images.length === 0 && (
                    <img
                      src="https://via.placeholder.com/150"
                      alt="product"
                      style={{ width: "150px" }}
                    />
                  )}
                </div>
                <Input
                  disabled={isSubmitting}
                  type="file"
                  multiple
                  {...register("images", { required: "This field is required." })}
                  id="image"
                />
                {errors.images && <span className="text-red-500">{errors.images.message}</span>}
              </div>
            </div>
            <div className="col-span-3 md:col-span-3 px-4 flex justify-end gap-x-2 mt-4">
              <div className="my-4">
                <Button
                  disabled={isSubmitting}
                  label={isSubmitting ? "Creating expense..." : "Create new expense"}
                  className="border-0 bg-[#BCE210] text-[#051F40] font-semibold px-4 py-2 mb-4"
                />
              </div>
            </div>
            {isSubmitting && (
              <div className="w-full col-span-3 md:col-span-3">
                <div className="h-1.5 w-full overflow-hidden">
                  <div className="animate-progress w-full h-full bg-[#BCE210] origin-left-right"></div>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </AdminLayout>
  );
  
};




