import { SentryService } from "./SentryErrorCapture";
import { onWriteContract } from "./onWriteContract";
import USDTABI from "common/contracts/USDT.json";

export const onSendUSDTs = async ({ address, amount, confirmations }) => {
  if (!address) {
    throw new Error("Address is required");
  }
  if (!amount) {
    throw new Error("Amount in matic is required");
  }
  try {
    const hash = await onWriteContract({
      contractAddress: process.env.REACT_APP_USDT_CONTRACT_ADDRESS,
      contractABI: USDTABI,
      functionName: "transfer",
      args: [address, amount],
      confirmations,
    });
    return hash;
  } catch (error) {
    SentryService("onSendUSDTs", "onSendUSDTs", error);
    throw new Error(error ? error : "Error sending funds");
  }
};
