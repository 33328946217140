import axios from "axios";

const API = process.env.REACT_APP_AWS_API_URL;

export const useOrganizationUsersService = () => {
  return {
    async fetchOrganizationUsers(params) {
      const response = await axios.get(`${API}/contracts/fg/organizationUsers`, {
        params
      });
      return response.data;
    },
  };
};
