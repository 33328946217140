const QRCode = require("qrcode");

const generateQRCodeLocally = async (barcode) => {
  try {
    const qrCode = await QRCode.toDataURL(barcode);
    return qrCode;
  } catch (err) {
    console.error(err);
  }
};
export const replaceWCQrCode = ({ web3AuthInstance }) => {
  const replaceCanvasWithContent = async () => {
    const qrElement = document.querySelector(".w3a-wallet-connect-qr");
    if (qrElement && qrElement.firstChild && qrElement.firstChild.tagName === "CANVAS") {
      try {
        const newContent = document.createElement("img");
        const uri = web3AuthInstance.walletAdapters["wallet-connect-v2"].adapterData.uri;
        const src = await generateQRCodeLocally(uri);

        if (!src) {
          console.error("Failed to generate QR code image source.");
          observer.disconnect();
          return; // Exit if there's no src generated
        }

        newContent.src = src;
        newContent.style.padding = "20px";
        newContent.style.margin = "20px auto";
        newContent.style.borderRadius = "10px";
        newContent.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.2)";
        newContent.style.maxWidth = "100%";

        // Replace the first child only if it still exists and is a canvas
        if (qrElement.firstChild && qrElement.firstChild.tagName === "CANVAS") {
          qrElement.replaceChild(newContent, qrElement.firstChild);
          observer.disconnect(); // Disconnect the observer after replacement
        }
      } catch (error) {
        observer.disconnect();
        console.error("Error replacing QR code canvas with image:", error);
      }
    }
  };

  // MutationObserver to watch for the QR element being added to the DOM
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      //   console.debug({ mutation });
      if (mutation.addedNodes.length > 0 && web3AuthInstance) {
        replaceCanvasWithContent();
      }
    });
  });
  const container = document.querySelector(".w3a-parent-container");
  if (container) {
    observer.observe(container, { childList: true, subtree: true });
  } else {
    observer.observe(document.body, { childList: true, subtree: true });
  }
  
  return observer;
};
