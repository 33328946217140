import { useQuery } from "@tanstack/react-query";
import { onRequestTransactionFunds } from "common/services";
import { useEffect, useState } from "react";
import { useWaitForTransactionReceipt } from "wagmi";

export const useRequestFunds = () => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [userData, setUserData] = useState({});

  const { isError, isSuccess, isFetching, status, data, error } = useQuery({
    queryKey: [`requestFunds`],
    retry: 5,
    retryDelay: 3000,
    refetchOnWindowFocus: false,
    enabled: isEnabled,
    queryFn: async () =>
      onRequestTransactionFunds({
        address: userData.address,
      }),
  });

  const {
    isError: isErrorTx,
    isSuccess: isSuccessTx,
    data: dataTx,
    isFetching: isFetchingTx,
    error: errorTx,
  } = useWaitForTransactionReceipt({
    queryKey: [`requestFundsTx${data ? data.response : ""}`],
    hash: data ? data.response : "",
    retry: 2,
    retryDelay: 2000,
    refetchOnWindowFocus: false,
    enabled: isEnabled,
    confirmations: 3,
  });

  useEffect(() => {
    const onResult = async () => {
      if (!isFetching && !isFetchingTx && userData) {
        if (isError) {
          if (userData.onError) await userData.onError(data ? data.error : error);
          if (userData.onSettled) await userData.onSettled();
          setIsEnabled(false);
        } else if (isSuccess && data) {
          if (isErrorTx) {
            if (userData.onError) await userData.onError(dataTx ? dataTx.error : errorTx);
            if (userData.onSettled) await userData.onSettled();
            setIsEnabled(false);
          } else if (isSuccessTx && dataTx) {
            if (userData.onSuccess) await userData.onSuccess();
            if (userData.onSettled) await userData.onSettled();
            setIsEnabled(false);
          }
        }
      }
    };
    if (!isFetching) onResult();
  }, [
    isError,
    isSuccess,
    isFetching,
    status,
    data,
    isFetchingTx,
    userData,
    error,
    isErrorTx,
    isSuccessTx,
    dataTx,
    errorTx,
  ]);

  const requestFunds = ({ userData }) => {
    if (!userData) throw new Error("missing request branch side data");
    setUserData(userData);
    setIsEnabled(true);
  };

  return {
    requestFunds,
    isFetchingTx,
    isError,
    error,
    data,
    isErrorTx,
    isSuccessTx,
    dataTx,
    isFetchingTx,
  };
};
