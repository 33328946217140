import { LegalInfoLayout } from '@layouts/LegalInfoLayout'
import React from 'react'

export const MembersAgreementPage = () => {
    const menu = [
        {
            title: "1. Authorization",
            id: "authorization",
            content: `
            By signing this Agreement, the individual officially becomes a Member of FortuneGoat Group. The Member acknowledges that prior to executing this Agreement, have received, read, and fully understood the Binary Compensation Plan, in accordance with Schedule A. To qualify for commissions, the Member must remain active and in compliance with the terms outlined in the Agreement and the Binary Compensation Plan.
            `
        },
        {
            title: "2. Identification documents",
            id: "identification-documents",
            content: `
            FortuneGoat, at its sole direction, reserves the right to request the Member to undergo through a detailed third-party Know Your Client (KYC) or Anti-Money Laundering (AML)verification process at any stage. FortuneGoat further reserves the right to withhold commission payments from any Member who fails to provide such information or provides false information.
            `
        },
        {
            title: "3. Expiration, renewal and termination",
            id: "expiration-renewal-termination",
            content: `
            1. This Agreement shall remain in effect until: (i) the Member voluntarily cancel the Agreement, (ii) the Member's account becomes inactive and/or the Member fail to renew.
            2. If the Member fails to renew the membership, or if it is cancelled or terminated for any reason, the Member will permanently lose all rights in FortuneGoat. The Member shall not be eligible to promote or market FortuneGoat's products and services nor shall be eligible to receive royalties, bonuses, or other income resulting from the activities of former downline sales organization from the date when such suspension, termination or cancellation becomes effective.
            3. In the event of cancellation, termination or non renewal, the Member immediately waive all rights, including but not limited to property rights, former downline organization and any bonuses, commissions or other remuneration derived through the sales and other activities of the Member's former downline organization.
            4. FortuneGoat reserves the right to terminate any Member upon thirty calendar days' notice if the Company elects to:
            <ol>
                <li>1. cease business operations;</li>
                <li>2. dissolve as a business entity; or</li>
                <li>3. terminate distribution of its products and/or services via direct selling channels.</li>
            </ol>
            5. Member may cancel this Agreement at any time, and for any reason, upon giving an advance written notice toFortuneGoat of at least thirty calendar days. However, the Member shall be bound by all the restrictive covenants.
            6. FortuneGoat can terminate theAgreement without any advance notice if Member is in material breach of thisAgreement, or if FortuneGoat has reasonable grounds to believe the same.FortuneGoat shall not be liable for any damages or compensation for exercising its rights under this clause.

            7. In the event that a referral cancels or terminates their membership for any reason, the Member shall permanently forfeit all rights to royalties, bonuses, or any other income derived from that specific referral. This forfeiture takes effect from the date on which the referral's membership is suspended, terminated, or canceled.
            `
        },
        {
            title: "4. Delays",
            id: "delays",
            content: `
            FortuneGoat shall not be responsible for delays or failures in the performance of its obligations when such failure is due to circumstances beyond its reasonable control. This includes, without limitation, strikes, labour difficulties, transportation difficulties, riot, war, fire, and/or weather, pandemic, scheduled maintenance, curtailment of a source of supply, or government decrees or orders.
            `
        },
        {
            title: "5. Independent contractor status",
            id: "independent-contractor-status",
            content: `
            1.    The Parties acknowledge and agree that the Member is not an employee, agent, partner, or legal representative of FortuneGoat.
            2.    The relationship establish in this Agreement is that of an independent contractor, and nothing herein shall be construed to create a partnership, joint venture, or employer-employee relationship.
            3.    The Member has complete freedom in determining its own goals and methods of operation. The Member shall establish the number of hours that will devote to the business and will have the sole discretion of scheduling such hours.
            4.    Member's success relies on independent efforts.
            5.    The Member shall not be treated as an employee of FortuneGoat for any purposes, including, without limitation, tax purposes. The Member is responsible for paying taxes due from all commissions and bonus earned as a Member of FortuneGoat.
            `
        },
        {
            title: "6. Ownership of information, trade secrets and confidentiality",
            id: "ownership-of-information-trade-secrets-confidentiality",
            content: `
            1.    The Member recognize and agree that information compiled by or maintained by FortuneGoat, constitutes a commercially advantageous, unique and proprietary trade secret ofFortuneGoat, which it keeps as proprietary and confidential and treats as a trade secret.
            2.    During the term of the Agreement, FortuneGoat grant the Member a personal, non-exclusive, non-transferable, and revocable right to use trade secret, confidential, and proprietary business information (Proprietary Information), which includes, without limitation, line of sight information, business reports, manufacturing and product developments, and sales, earnings and other financial reports.
            3.    Any information, video, report, ideas, intellectual property, chart, data that is share with any customer or with the Member is also confidential information which is meant for the personal consumption of that specific customer or thatMember for the duration of their subscription is active.
            4.    During the term of this Agreement and for a period of 5 years after the termination or expiration of the Agreement, the Member shall not (i) use the information from the reports to compete with FortuneGoat or for any purpose other promoting FortuneGoat's business; or (ii) use or disclose to any person or entity any confidential information contained in the reports in any other marketing company.
            `
        },
        {
            title: "7. Non-Solicitation and Non- Compete Agreement",
            id: "non-solicitation-non-compete-agreement",
            content: `
            1.    The Member may participate in other direct sales, multilevel, network marketing or relationship marketing business ventures or marketing opportunities (“NetworkMarketing”), provided that such business is not in direct or indirect competition with FortuneGoat. However, during the term of the Agreement and for one (1) year thereafter, the Member may not signup or attempt to be a member ora customer for any other Network Marketing business.
            2.    The Member, at all levels, is obligated to notify FortuneGoat if they are signed up as a member for another Network Marketing company by sending an email toFortuneGoat.
            3.    The Member may not offer any non-FortuneGoat opportunity, products or services at anyFortuneGoat related meeting, seminar, or convention, or immediately following aFortuneGoat event.
            `
        },
        {
            title: "8. Images/Records/Consents",
            id: "images-records-consents",
            content: `
            The Member hereby grants FortuneGoat permission to obtain photographs, videos, and other recorded media of the Member or its likeness. The Member acknowledges and agree to authorize FortuneGoat to use any such recorded media for any lawful purpose without compensation.

            Furthermore, the Member consents to FortuneGoat utilizing their testimonial, image, and likeness in corporate sales materials, including but not limited to print media, electronic media, audio, and video.In consideration of being a Member, the Member waives any right to compensation for the use of their testimonial, image, and likeness, even if FortuneGoat receives payment for items or sales materials containing such representations.It is acknowledged that, in certain instances, the Member's testimonial may be featured in material involving other members.
            `
        },
        {
            title: "9. Changes, amendments and modifications",
            id: "changes-amendments-modifications",
            content: `
            Because of business environment, FortuneGoat reserves the right to amend this Agreement, the Binary Compensation Plan and the price packages at its sole and absolute discretion. Notifications of amendments shall appear on the officialFortuneGoat website. Any such amendment, change or modification shall be effective immediately upon notice by one of the following methods:
            1.    Posting on theFortuneGoat website; or
            2.    Notification via email; or
            3.    Notification thought any FortuneGoat communications channels.
            `
        },
        {
            title: "10. Indemnification",
            id: "indemnification",
            content: `
            Member agrees to indemnify, defend, and hold harmless FortuneGoat (together with its related parties, agents, stockholders, members, employees, directors, officers, and attorneys, collectively “Indemnified Parties”) from and against all losses or liabilities (including attorneys' fees) they may suffer or incur as a result ofMember's breach or alleged breach of the Members Agreement. This provision shall survive the termination of the Agreement.
            `
        },
        {
            title: "11. Applicable Law",
            id: "applicable-law",
            content: `
            This Agreement shall be governed by and construed in accordance with the laws of England and Wales. If any provision contained herein is found to be invalid, illegal, or unenforceable in any respect, such provision shall be ineffective, but shall not in any way invalidate or otherwise affect any other provision.
            `
        },
        {
            title: "12. Mandatory Arbitration",
            id: "mandatory-arbitration",
            content: `
            Any dispute, controversy, or claim arising out of or in connection with this Agreement, or the breach, termination, or validity thereof, shall be submitted to the [___] ArbitrationCentre (the “Centre”) and settled by final and binding arbitration in accordance with the Centre Arbitration Rules. Judgment on any award issued under this clause may be entered by any court of competent jurisdiction.

            Parties agree that, by entering into this Agreement, both Parties are each waiving the right to a trial by jury or to participate in a class action. Parties agree that each shall bring claims against the other only in an individual capacity and not asa plaintiff or class member in any purported class or representative proceeding.

            If the competent court or arbitration tribunal decides an issue or part of an issue is not arbitrable then such anon-arbitrable issue shall be referred to the court of Centre for final adjudication.
            `
        },
        {
            title: "13. Miscellaneous",
            id: "miscellaneous",
            content: `
            The provisions of this Agreement, including all documents incorporated herein by reference, embody the whole agreement between the Parties and supersede any prior agreements, understandings, and obligations between them concerning the subject matter of the Agreement.
            In witness whereof, intending to be legally bound, the Parties have executed this Agreement on the date indicated above.
            `
        }
    ]
    return (
        <LegalInfoLayout title="Members Agreement" menu={menu}>
            <p className="mt-6 italic">
                Last updated 19 of Feb 2024
            </p>
            <h2 className="text-2xl font-bold my-4">Members&apos;s Agreement</h2>
            <p className="mb-2">
                THIS MEMBERSAGREEMENT (the “Agreement”) is entered into on this [Date], by and betweenFortuneGoat Lounge Group, LTD, a company organized and existing under the lawsof England and Wales, (“FortuneGoat”) and [Name], [ID number] (“Member”).
                Authorization.
            </p>
            {
                menu.map((item, index) => (
                    <div key={index} id={item.id} className="mt-6" style={{ scrollMarginTop: "100px" }}>
                        <h2 className="text-2xl font-bold my-4">{item.title}</h2>
                        {item?.content && item.content.split("\n").map((paragraph, index) => (
                            <p key={index} className="mb-2" dangerouslySetInnerHTML={{ __html: paragraph }} />
                        ))}
                    </div>
                ))
            }
        </LegalInfoLayout>
    )
}
