import { Modal } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useCreateCalendarCategory } from "common/hooks/useCreateCalendarCategory";
import { useDeleteCalendarCategory } from "modules/admin/hooks/Calendar/useDeleteCalendarCategory";
import { useFetchCategories } from "modules/admin/hooks/Calendar/useFetchCategories";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

export const CategoriesModal = ({ isOpen, onClose }) => {
  const { data } = useFetchCategories();
  const { requestCreateCalendarCategory } = useCreateCalendarCategory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);

  const { register, handleSubmit, reset } = useForm();

  const queryClient = useQueryClient();

  const { requestDeleteCalendarCategory } = useDeleteCalendarCategory();

  const submit = async (data) => {
    setIsSubmitting(true);
    await requestCreateCalendarCategory({
      calendarCategoryData: {
        ...data,
        onSuccess: async () => {
          toast.success("Category created successfully.");
          queryClient.invalidateQueries("calendarCategories");
          setIsSubmitting(false);
          reset();
        },
        onError: async () => {
          toast.error("Failed to create category.");
          setIsSubmitting(false);
        },
      },
    });
  };

  const deleteCategory = async (id) => {
    setCategoryToDelete(id);
    setIsDeleting(true);
    await requestDeleteCalendarCategory({
      calendarCategoryData: {
        id,
        onSuccess: async () => {
          toast.success("Category deleted successfully.");
          queryClient.invalidateQueries("calendarCategories");
          setIsDeleting(false);
          setCategoryToDelete(null);
        },
        onError: async () => {
          toast.error("Failed to delete category.");
          setIsDeleting(false);
          setCategoryToDelete(null);
        },
      },
    });
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className="bg-white p-4 w-full max-w-[900px] mx-auto mt-40">
        <h1 className="text-2xl font-bold mb-4">Categories</h1>
        <form className="flex flex-col gap-2 mb-4" onSubmit={handleSubmit(submit)}>
          <input
            type="text"
            className="border border-[#161C24] rounded-sm w-full px-2 py-1"
            placeholder="Name..."
            {...register("name", { required: true })}
          />
          <input
            type="text"
            className="border border-[#161C24] rounded-sm w-full px-2 py-1"
            placeholder="Description..."
            {...register("description")}
          />
          <button
            className="bg-electric-blue text-white rounded-sm px-4 py-2 mt-2"
            disabled={isSubmitting}
            type="submit"
          >
            {isSubmitting ? "Creating..." : "Create"}
          </button>
        </form>
        {/* [{"id":1,"name":"circuit","info":"https://aqua-voluntary-felidae-839.mypinata.cloud/ipfs/Qmb6m7ZgkfYGD1vgWHms9YUJuGmuPu5TX24ykj5j2c3r2W","status":true,"images":["https://aqua-voluntary-felidae-839.mypinata.cloud/ipfs/QmSj1Rz37CYdiWzPfbZgRaWfhWUfHwp2TWDXAShJ4YrkSN"],"description":"Organized"},{"id":2,"name":"monitor","info":"https://aqua-voluntary-felidae-839.mypinata.cloud/ipfs/QmXpSx22mDe9rWU4tny6vAwpHi1uCQgKojZSZ2QQRb2PZA","status":true,"images":["https://aqua-voluntary-felidae-839.mypinata.cloud/ipfs/QmSj1Rz37CYdiWzPfbZgRaWfhWUfHwp2TWDXAShJ4YrkSN"],"description":"Synergistic"}] */}
        <div className="grid grid-cols-3 gap-4 overflow-auto max-h-[600px]">
          {data?.categories.map((category) => (
            <div key={category.id} className="bg-gray-100 p-4 flex justify-between">
              <div>
                <h2 className="font-bold capitalize text-lg">{category.name}</h2>
                <p>{category.description}</p>
              </div>
              <div className="flex">
                <button
                  className="bg-[#FF0000] text-white rounded-sm px-4 py-2"
                  onClick={() => deleteCategory(category.id)}
                  disabled={isDeleting}
                >
                  {isDeleting && categoryToDelete === category.id ? "Deleting..." : "Delete"}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};
