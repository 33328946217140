import { userCommissions } from "./utils/userCommissions/index";
import { getBinaryMatchBonus } from "./utils/userCommissions/onGetBinaryMatchBonus";
import { getCommissions } from "./utils/userCommissions/onGetCommissions";
import { getFastStart } from "./utils/userCommissions/onGetFastStart";
import { getLeadership } from "./utils/userCommissions/onGetLeadership";
import { getRank } from "./utils/userCommissions/onGetRank";
import { getUnilevelMatchingBonus } from "./utils/userCommissions/onGetUnilevelMatchBonus";

const batch_users_commission_size = parseInt(process.env.BATCH_USERS_COMMISSION_SIZE || "5");

export const calculateCommissions = async ({
  contractFunctions,
  getCommissionsParam,
  getBinaryMatchingBonusParam,
  getFastStartParam,
  getUnilevelMatchingBonusParam,
  getLeadershipParam,
  addresses,
}) => {
  console.log("Get total users count");
  const totalUsersCount = addresses ? addresses.length : await contractFunctions.users.usersCount();
  console.log("totalUsersCount: ", totalUsersCount);
  console.log("batches count: ", totalUsersCount / batch_users_commission_size);
  const batches = Array(Math.ceil(totalUsersCount / batch_users_commission_size));
  let index = 0;
  const commissions = [];
  const time = new Date().getTime();
  console.log("time: ", time);
  const userRanksCache = {};
  const userInfoCache = {};
  // alert(`addresses: ${addresses}`);
  // alert(`batches: ${batches}`);
  for (const batch of batches) {
    console.log("batch: ", index);
    console.log("get batch addresses");
    await new Promise((resolve) => setTimeout(resolve, 50));
    const batchAddresses =
      addresses ??
      (await contractFunctions.users.usersByCounter(index, batch_users_commission_size));
    console.log("batchAddresses: ", batchAddresses);

    if (!!batchAddresses) {
      const newCommissions = batchAddresses.map((address) => {
        const onGetRank = getRank({ contractFunctions, userRanksCache });
        const onGetCommissions = getCommissions({});
        const onGetFastStart = getFastStart({ contractFunctions });
        const onGetBinaryMatchBonus = getBinaryMatchBonus({
          contractFunctions,
        });
        const onGetUnilevelMatchBonus = getUnilevelMatchingBonus({ contractFunctions });
        const onGetLeadership = getLeadership({ contractFunctions });
        return userCommissions({
          contractFunctions,
          address,
          onGetRank,
          onGetCommissions,
          onGetFastStart,
          onGetBinaryMatchBonus,
          getCommissionsParam,
          getBinaryMatchingBonusParam,
          getFastStartParam,
          getUnilevelMatchingBonusParam,
          onGetUnilevelMatchBonus,
          onGetLeadership,
          getLeadershipParam,
        });
      });
      console.log("newCommissions: ", newCommissions);
      const result = await Promise.all(newCommissions);
      console.log("result: ", result);
      commissions.push(...result);
    }

    index += batch_users_commission_size;
    console.log("new time: ", (new Date().getTime() - time) / 100);
  }

  return commissions;
};
