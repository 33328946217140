export const MoreThanCTA = ({ handleJoin }) => {
    return (
        <div className="flex flex-col py-20">
            <div className="w-full">
                <div className="flex justify-center py-10">
                    <div className="container flex justify-center px-4">
                        <div
                            className="flex justify-center items-center flex-col"
                            style={{ maxWidth: "800px" }}
                        >
                            <h2 className="text-4xl text-gray-800 font-light mb-4 text-center">
                                More than a platform, a supportive family,
                                dedicated to turning your dreams into reality.
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-center">
                <div className="container flex justify-center px-4">
                    <div className="mx-4 flex items-center">
                        <button
                            onClick={handleJoin}
                            className="bg-electric-blue hover:bg-zinc-400 text-white font-bold py-2 px-20 rounded text-lg"
                        >
                            Join us
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
