import { onGetCommissions } from "./onGetCommissions";
import { getBinaryCommission as defaultGetBinaryCommission } from "./getBinaryCommission";
import { getUnilevelCommission as defaultGetUnilevelCommission } from "./getUnilevelCommission";

export const getCommissions = ({
  getBinaryCommission = defaultGetBinaryCommission,
  getUnilevelCommission = defaultGetUnilevelCommission,
}) => {
  return async ({ userRank }) =>
    onGetCommissions({
      userRank,
      getBinaryCommission,
      getUnilevelCommission,
    });
};
