import Recepit2Img from "@assets/receipt-2.svg";
import MedalStarImg from "@assets/medal-star.svg";
import HomeExampleImg from "@assets/images/image-home-example.png";

export const PennyAuctions = () => {
  return (
    <div className="flex flex-col py-">
      <div className="w-full">
        <div className="flex justify-center py-10">
          <div className="container px-4">
            <h2 className="text-5xl text-dark font-light mb-4" style={{ maxWidth: "600px" }}>
              Penny Auctions
            </h2>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="container px-4">
          <div className="grid grid-cols-3 gap-4">
            <div className="col-span-3 bg-white p-6 rounded">
              <div style={{ maxWidth: "700px" }}>
                <div className="flex items-center">
                  <img src={Recepit2Img} alt="Receipt" className="w-10 mx-2 my-4" />
                  <h3 className="text-4xl text-dark font-light mb-4" style={{ maxWidth: "600px" }}>
                    How does it work?
                  </h3>
                </div>
                <p className="text-gray-600 text-lg">
                  Purchase a Fun Shopper Pack (starting at $50) or a special Founder Pack (starting
                  at $900), and start having fun bidding. Each bid raises the price by $0.01 or
                  less. Once your bid is placed, the auction clock restarts from 10-15 seconds
                  (depending on the item) every time someone bids. If no new bids are placed when
                  the clock reaches zero, the last bidder wins.
                </p>
              </div>
            </div>
            <div className="bg-white md:col-start-1 md:col-end-4 col-span-3 p-6 rounded">
              <div className="grid grid-cols-2 gap-4">
                <div className="col-span-2 md:col-span-1 flex flex-col justify-end">
                  <h3 className="text-2xl font-semibold text-dark mb-4">Joining</h3>
                  <p className="text-gray-600 text-lg">
                    You can join for free through a member&apos;s invitation and start purchasing Fun
                    Shopper Packs or one of our special Founder Packs to enjoy this amazing shopping
                    and income generating experience.
                  </p>
                </div>
                <div className="col-span-2 md:col-span-1 flex justify-center">
                  <img
                    src={HomeExampleImg}
                    alt="Laptop Coffee"
                    className="w-full md:w-1/2 mx-2 my-4"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
