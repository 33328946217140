import { web3AuthInstance } from "App";
import { Logo } from "common/components";
import DropdownComponent from "common/components/Dropdown";
import { useGetUserInfo } from "common/hooks/useGetUserInfo";
import { Navbar } from "modules/landing/components";
import { useEffect, useState } from "react";
import VideoImg from "@assets/test/video.png";
import { Footer } from "common/components";
import { useAuctionService } from "../context/auctionProvider";
import { FeaturedWinners } from "../components/OurWinnersPage/FeaturedWinners";
import { MeetTheWinners } from "../components/OurWinnersPage/MeetTheWinners";
import { WinnersList } from "../components/OurWinnersPage/WinnersList";

export const OurWinners = () => {
  const {
    connectionStatus,
    handleSearchOptions,
    items,
    autoBuy,
    itemId,
    addBid,
    handleFollowClick,
    user,
    itemDetails,
    address,
    auctionEnd,
  } = useAuctionService();

  const { userInfo } = useGetUserInfo();

  const options = [
    { title: "Dashboard", redirectionUrl: "/app/dashboard" },
    { title: "Store", redirectionUrl: "/app/store" },
    { title: "Profile", redirectionUrl: "/app/profile" },
    { title: "Logout", redirectionUrl: "/logout" },
  ];

  const [profileImg, setProfileImg] = useState("");

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const response = await web3AuthInstance.getUserInfo();
        if (response?.profileImage) {
          setProfileImg(response.profileImage);
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    if (web3AuthInstance.connected) getUserInfo();
  }, [web3AuthInstance.connected]);
  return (
    <div className="bg-[#E3E3D7]">
      <Navbar userBids={user ? user.currentBids : undefined} />
      <div className="container mx-auto">
        <div className="w-full flex flex-col items-center gap-4 px-4 md:px-12">
          <FeaturedWinners />
          <MeetTheWinners />
          <WinnersList />
        </div>
      </div>
      <Footer />
    </div>
  );
};
