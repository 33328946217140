import { SkeletonTable } from "modules/admin/components/ui/SkeletonTable";

export const GenericList = ({ data, columns, headerOptions, isLoading }) => {
  return (
    <div className="container mx-auto">
      <div className="grid grid-cols-5">
        <div className="col-span-5 flex items-center p-4 border-b border-b-gray-200">
          {headerOptions}
        </div>
        {isLoading ? (
          <div className="p-4 w-full col-span-5">
            <SkeletonTable />
          </div>
        ) : (
          <div className="col-span-5 relative overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="text-left border-b border-[#808080]">
                  {Object.keys(columns).map((column, index) => (
                    <th key={index} className="p-4 font-bold">
                      {columns[column].header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((item, itemIndex) => (
                  <tr key={itemIndex} className="border-b border-[#808080]">
                    {Object.keys(columns).map((column, columnKey) => (
                      <td key={columnKey} className="p-4">
                        {columns[column].render
                          ? columns[column].render(item[column])
                          : item[column]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};
