import walletConnectIcon from "@assets/icons/walletconnect.webp";
import browserWalletIcon from "@assets/icons/browserwallet.webp";
import wwwIcon from "@assets/icons/www-icon.png";
const imgsArray = [walletConnectIcon, browserWalletIcon, wwwIcon];

// @ts-expect-error something is wrong with the types
export const LoginModalBody = ({ connectors, connect }) => {
    // @ts-expect-error something is wrong with the types
    const handleConnect = async (connector) => {
        if (connect) await connect({ connector });
    };

    return connectors ? (
        <div style={{ display: "grid", gap: "0.5rem", marginTop: 1 }}>
            {/* @ts-expect-error something is wrong with the types */}
            {connectors.map((connector, index) => {
                return connector?.ready ? (
                    <div
                        key={connector.id}
                        style={{
                            background: "#ffffff05",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            padding: "4px 6px",
                            gap: "1rem",
                        }}
                        onClick={() => handleConnect(connector)}
                    >
                        {/* <SoftAvatar
              variant="rounded"
              sx={{ border: "1px solid #ffffff21" }}
              src={imgsArray[index]}
            ></SoftAvatar> */}
                        <img
                            src={imgsArray[index]}
                            alt="icon"
                            style={{ width: 30, height: 30 }}
                        />
                        <h6>{connector.name}</h6>
                    </div>
                ) : null;
            })}
        </div>
    ) : null;
};
