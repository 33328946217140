import { ProductCard2 } from "modules/penny-auction/components/ProductCard2";

const { ProductCard } = require("modules/penny-auction/components/ProductCard");

export const ItemDetailsProductsCards = ({
  items = [],
  itemId,
  autoBuy = () => {},
  addBid = () => {},
  likes = [],
  favButtonHandler = () => {},
  auctionEnd = () => {},
}) => {
  const filteredItems = items ? items.filter((item) => item.id != itemId) : [];
  if (filteredItems.length > 2) {
    filteredItems.pop();
  }
  const scrollerClass = `flex px-8 gap-4`;
  const scrollerStyle =
    filteredItems && filteredItems.length > 0
      ? { width: `${filteredItems.length * 300}px`, minWidth: `${filteredItems.length * 300}px` }
      : {};

  return (
    <div className="col-span-5 flex w-full px-8 overflow-scroll pb-8">
      <div className={scrollerClass} style={scrollerStyle}>
        {/* Array map of 6 elements */}
        {filteredItems &&
          filteredItems.map((item) => {
            const followed =
              likes && likes.length > 0 ? likes.some((like) => like == item.id) : false;
            return (
              <ProductCard2
                key={item.id}
                cardInfo={item}
                autoBuy={autoBuy}
                addBid={addBid}
                followed={followed}
                favButtonHandler={favButtonHandler}
                auctionEnd={auctionEnd}
              />
            );
          })}
      </div>
    </div>
  );
};
