export function convertBigIntToNumber(bigintValue: bigint) {
    if (
        bigintValue <= BigInt(Number.MAX_SAFE_INTEGER) &&
        bigintValue >= BigInt(Number.MIN_SAFE_INTEGER)
    ) {
        return Number(bigintValue);
    } else {
        console.warn(
            "The value is too large to be represented as a number without losing precision."
        );
        return null;
    }
}
