import axios from "axios";
import { SentryService } from "common/services/SentryErrorCapture";

export const onGetMeetTheWinners = async () => {
  try {
    const getMeetTheWinnersResponse = await axios.get(
      `${process.env.REACT_APP_AWS_API_URL}/contracts/pa/winners/meet`
    );
    // console.debug({ getMeetTheWinnersResponse });
    if (getMeetTheWinnersResponse.data.status === 200) {
      return getMeetTheWinnersResponse.data.data.randomWinners;
    }
    throw new Error(getMeetTheWinnersResponse.data.error);
  } catch (error) {
    SentryService("onGetMeetTheWinners", "onGetMeetTheWinners", error.message);
    throw error;
  }
};
