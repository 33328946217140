// import { SentryService } from "./SentryErrorCapture";
import axios from "axios";

export const onDeleteBid = async ({ id, signature }) => {
  try {
    const deletedBidResponse = await axios.delete(
      `${process.env.REACT_APP_AWS_API_URL}/contracts/pa/item?id=${id}&signature=${signature}`
    );
    if (deletedBidResponse) {
      return deletedBidResponse.data;
    }
  } catch (error) {
    // SentryService("onDeleteBid", "onDeleteBid", error);
    // console.debug(error);
    throw new Error(
      error.response
        ? error.response.data.error.reason ||
          error.response.data.error.shortMessage ||
          error.response.data.error
        : error
    );
  }
};
