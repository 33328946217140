import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  useAccount,
  //  useBalance,
  // useTransaction
} from "wagmi";
import { toast } from "react-toastify";
import { JoinUsStep1 } from "@join-us/components/JoinUsStep1";
import { JoinUsStep2 } from "@join-us/components/JoinUsStep2";
import { JoinUsStep3 } from "@join-us/components/JoinUsStep3";
import { JoinUsStep4 } from "@join-us/components/JoinUsStep4";
import { JoinUsStep5 } from "@join-us/components/JoinUsStep5";
import { JoinUsLayout } from "@layouts/JoinUsLayout";
import { Button } from "@common/components";
// import { useNavigate, useSearchParams } from "react-router-dom";
import { ProtectedRoute } from "@config/router/ProtectedRoute";
// import { onWriteContract } from "@src/common/services/onWriteContract";
// import { onReadContract } from "@src/common/services/onReadContract";
// import FortuneGoatABI from "@src/common/contracts/FortuneGoat.json";
import * as yup from "yup";
import { useYupValidationResolver } from "@src/common/hooks/useYupValidationResolver";
// import { onRequestTransactionFunds } from "common/services/onRequestTransactionFunds";
// import { useFeeData } from "wagmi";
// import { onEstimateWriteContractGas } from "common/services/onEstimateGas";
import { useLoginRedirect } from "common/hooks/useLoginRedirect";
import { useRegisterUser } from "common/hooks/useRegisterUser";
import { useSearchParams } from "react-router-dom";
import { SentryService } from "common/services/SentryErrorCapture";
// import { onRequestRegisterUser } from "common/services";
/* import axios from "axios"; */

export const JoinUsPage = () => {
  // const { data: gasFeeData } = useFeeData();
  const { isLoading, isError, registerUser } = useRegisterUser();
  const [step, setStep] = useState(1);
  const [searchParams] = useSearchParams();
  const sponsorParam = searchParams.get("sponsor");
  const { address, isConnected } = useAccount();
  // const [hash, setHash] = useState("");
  const [lambdaError, setLambdaError] = useState();
  const [localLoading, setLocalLoading] = useState(false);
  // const [buttonDisabled, setButtonDisabled] = useState(true);
  // const [recheck, setRecheck] = useState(false);
  // const [fundsLoading, setFundsLoading] = useState(false);
  // const [displayError, setDisplayError] = useState();
  // const { isError, isLoading } = useTransaction({
  //   hash,
  // });
  useLoginRedirect({ isConnected, address });
  // const balance = useBalance({ address, watch: true });
  // const navigate = useNavigate();

  // const sendFunds = async () => {
  //   setFundsLoading(true)
  //   const requestFundsResponse = await onRequestTransactionFunds({ address });
  //   if(requestFundsResponse.status){
  //     setHash(requestFundsResponse.address);
  //   } else {
  //     setDisplayError("Something went wrong when sending funds, please wait a few minutes and try again")
  //   }
  //   const waitTime = parseInt((Number(gasFeeData.gasPrice) * 3) / 10 ** 7);
  //   await new Promise((resolve) => setTimeout(resolve, waitTime));
  //   setRecheck(!recheck)
  //   setFundsLoading(false)
  // }

  // const registerFunction = async (data) => {
  //   const registerResponse = await onWriteContract({
  //     contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS,
  //     contractABI: FortuneGoatABI,
  //     functionName: "registerUsers",
  //     args: [
  //       [
  //         address,
  //         "0x0000000000000000000000000000000000000000",
  //         data.fullName,
  //         data.fullName,
  //         data.username,
  //         data.email,
  //         data.country,
  //         data.zip,
  //         "",
  //         0
  //       ],
  //       data.sponsor,
  //       0,
  //       BigInt(0),
  //       "0x0000000000000000000000000000000000000000",
  //       BigInt(0),
  //     ],
  //     // @ts-expect-error something is wrong with the types
  //     from: address,
  //   });
  //   return registerResponse
  // }

  const validationSchema = yup.object({
    fullName: yup.string().required("Full name is required").min(3),
    username: yup
      .string()
      .required("Username is required")
      .min(3)
      .matches(
        /^[a-z]+(-[a-z]+)*$/,
        "Username is invalid. Only lowercase letters and hyphens are allowed."
      ),
    email: yup.string().email().required("Email is required"),
    sponsor: yup.string().required("Sponsor is required").min(3),
    country: yup.string().required("Country is required"),
    amount: yup.number(),
    zip: yup.string().min(4).max(5).required("Zip is required"),
  });

  const resolver = useYupValidationResolver(validationSchema);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    trigger,
    setError,
    clearErrors,
  } = useForm({ resolver });

  useEffect(() => {
    if (sponsorParam && sponsorParam.length > 0) {
      let parsedSponsor = sponsorParam.replace(/_/g, " ").trim();
      setValue("sponsor", parsedSponsor);
      window.localStorage.setItem("sponsor", JSON.stringify(parsedSponsor));
    } else {
      try {
        const stringifiedSponsor = window.localStorage.getItem("sponsor") || null;
        const sponsor = stringifiedSponsor ? JSON.parse(stringifiedSponsor) : null;
        if (sponsor) setValue("sponsor", sponsor);
      } catch (error) {
        SentryService("JoinUsPage", "useEffect", error);
        // console.log({ error });
      }
    }
  }, [sponsorParam, setValue]);
  // useEffect(()=>{
  //   const hasDashboardAccess = async () => {
  //     const response = await onReadContract({
  //       contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS,
  //       contractABI: FortuneGoatABI,
  //       functionName: "getUserInfo",
  //       args: [address],
  //     });
  //     if (response && response[0]?.username && response[0]?.username !== "") {
  //       navigate("/app/dashboard");
  //     } else {
  //       checkFunds()
  //     }
  //   }
  //   const checkFunds = async () => {
  //     if(!gasFeeData) return;
  //     const estimateApprovalGas = await onEstimateWriteContractGas({
  //       contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS,
  //       contractABI: FortuneGoatABI,
  //       functionName: "registerUsers",
  //       args: [
  //         [
  //           address,
  //           "0x0000000000000000000000000000000000000000",
  //           'Juan Carlos Perez Sanchez',
  //           'Juan Carlos Perez Sanchez',
  //           'juancarlosperezsanchez',
  //           'juancarlosperezsancherz912@yahoo.com.ar',
  //           'Costa Rica',
  //           '12345',
  //           "",
  //           0
  //         ],
  //         'global master 1',
  //         0,
  //         BigInt(0),
  //         "0x0000000000000000000000000000000000000000",
  //         BigInt(0),
  //       ],
  //       from: address,
  //       confirmations: 6,
  //     });
  //     const totalGas = parseInt(estimateApprovalGas) * parseInt(gasFeeData.gasPrice)
  //     console.log(`balance : ${parseInt(balance?.data?.value)} > gasfee: ${totalGas}`)
  //     if(parseInt(balance?.data?.value) > totalGas){
  //       console.log('no necesita fondos')
  //       toast.success("Ready for register");
  //       setDisplayError("");
  //       setButtonDisabled(false)
  //     } else {
  //       if(totalGas > 1000000000000000000n){
  //         setDisplayError("Gas fees too high, please wait few minutes and try again")
  //       }
  //       if(!isLoading && !fundsLoading){
  //         await sendFunds()
  //       }
  //     }
  //   }

  //   if(step === 1 && address && isConnected){
  //     hasDashboardAccess()
  //   }
  // },[recheck])

  const onSubmit = async (data) => {
    setStep(4);
    setLocalLoading(true);
    // setLambdaError(undefined);
    // const registerResponse = await registerFunction(data)
    const userData = {
      sponsorUsername: data.sponsor,
      userId: address,
      userSelectedBranch: 0,
      name: data.fullName,
      lastName: data.fullName,
      username: data.username,
      email: data.email,
      country: data.country,
      zip: data.zip,
      phone: "",
      amountToPay: 0,
      underUsername: "0x0000000000000000000000000000000000000000",
      underUserBranch: 0,
      onSuccess: () => {
        setStep(5);
        toast.success("You have successfully registered!");
      },
      onSettled: () => {
        setLocalLoading(false);
      },
      onError: () => {
        setStep(1);
        setLambdaError("Register error");
      },
    };
    registerUser({ userData });
  };

  const showStep = () => {
    switch (step) {
      case 1:
        return (
          <JoinUsStep1
            register={register}
            errors={errors}
            setValue={setValue}
            trigger={trigger}
            watch={watch}
            setError={setError}
            clearErrors={clearErrors}
          />
        );
      case 2:
        return (
          <JoinUsStep2 register={register} setValue={setValue} watch={watch} errors={errors} />
        );
      case 3:
        return <JoinUsStep3 watch={watch} register={register} errors={errors} trigger={trigger} />;
      case 4:
        return (
          <JoinUsStep4
            isError={isError}
            loading={isLoading}
            localLoading={localLoading}
            lambdaError={lambdaError}
            onSubmit={onSubmit}
            register={register}
          />
        );
      case 5:
        return <JoinUsStep5 watch={watch} />;
      default:
        return <p>Something went wrong, please reload the page and try again.</p>;
    }
  };

  return (
    <ProtectedRoute requireWallet state={{ openModal: true }}>
      <JoinUsLayout layoutLoading={localLoading && true}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {showStep()}
          <div className="flex flex-col items-end mt-6">
            {/* {displayError && <p className="text-red-400">{displayError}</p>} */}
            <div className="m-4">
              {step === 1 && (
                <Button
                  type="submit"
                  label={isLoading ? "Registering..." : "Register"}
                  disabled={Object.keys(errors).length || isLoading}
                />
              )}
            </div>
          </div>
        </form>
      </JoinUsLayout>
    </ProtectedRoute>
  );
};
