import React, { useState } from "react";
import { useAccount } from "wagmi";
import { AddressField } from "./addressField";
// import { SentryService } from "common/services/SentryErrorCapture";
import { useFetchUserDetail } from "modules/admin/hooks/Users/useFetchUserDetail";
import { useSendBids } from "modules/admin/hooks/Funds/useSendBids";
import { toast } from "react-toastify";

export const FundsBidsForm = () => {
  const { address } = useAccount({});
  const [addressTo, setAddress] = useState("");
  const [amount, setAmount] = useState("");

  const { data: userData, refetch: userDetailRefetch } = useFetchUserDetail("currentUserDetail", {
    userId: address,
  });
  const { requestSendBids, isError, error } = useSendBids();
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <div className="mx-auto p-6 bg-white rounded-md shadow-md lg:min-w-[600px] h-full">
      <div className="mx-auto md:max-w-[50%] lg:max-w-[40%]">
        <h2 className="text-2xl font-semibold mb-4">
          Send Bids
          <small
            className="ml-2 cursor-pointer text-sm text-gray-600"
            onClick={() => {
              userDetailRefetch();
            }}
          >
            Refresh
          </small>
        </h2>
      </div>
      <AddressField setAddress={setAddress} />
      <div className="mb-4">
        <label
          htmlFor="usdt"
          className={`block text-sm font-medium ${
            userData?.package.bids <= 0 ? "text-rose-800" : "text-emerald-800"
          }`}
        >
          Your current Bids balance: {userData?.package.bids}
        </label>
        <label htmlFor="usdt" className="block text-sm font-medium text-gray-600">
          Bids amount
        </label>
        <input
          type="text"
          value={amount}
          disabled={userData?.package.bids <= 0}
          onChange={(e) => {
            if (userData?.package.bids) {
              setAmount(() =>
                e.target.value > userData?.package.bids ? userData?.package.bids : e.target.value
              );
            }
          }}
          id="usdt"
          name="usdt"
          className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
          placeholder="Enter bids to send"
        />
      </div>
      <div className="flex w-full flex-col justify-end items-end">
        <button
          type="button"
          className="border border-dark text-dark font-bold py-3 px-8 rounded w-full md:w-fit"
          onClick={async () => {
            setIsSubmitting(true);
            const parsedInputs = {
              recieverId: addressTo,
              amount: amount,
            };
            await requestSendBids({
              bidsData: {
                userId: address,
                ...parsedInputs,
                onSuccess: async () => {
                  toast.success("Bids sent successfully");
                  userDetailRefetch();
                  setIsSubmitting(false);
                },
                onError: async (error) => {
                  setIsSubmitting(false);
                  toast.error(`Failed to send bids: ${error}`);
                },
              },
            });
          }}
          disabled={isSubmitting}
        >
          {isSubmitting ? "sending Bids..." : "Send Bids"}
        </button>
        <React.Fragment>
          {isError && (
            <p>
              <small className="text-rose-800">{JSON.stringify(error)}</small>
            </p>
          )}
        </React.Fragment>
      </div>
    </div>
  );
};
