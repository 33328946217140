import { useState } from 'react';
import { Dropdown } from '@mui/base/Dropdown';
import { MenuButton } from '@mui/base/MenuButton';
import { Menu } from '@mui/base/Menu';
import { MenuItem } from '@mui/base/MenuItem';
import { useNavigate } from "react-router-dom";


const DropdownComponent = ({ title, items }) => {

   const [isOpen, setIsOpen] = useState(false);
   const navigate = useNavigate();

   const toggleDropdown = () => {
      setIsOpen(!isOpen);
   };

  return (
    <Dropdown>
      <MenuButton className="flex items-center gap-3" onClick={toggleDropdown}>
        {title}
        <svg
          width="14"
          height="8"
          viewBox="0 0 14 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={`transform transition-transform duration-300 ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
        >
          <path
            d="M1 1L7 7L13 1"
            stroke="#161C24"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </MenuButton>
      {isOpen && (
        <Menu className="bg-light-gray mt-2 z-50">
          {items?.map((e) => {
            return (
              <MenuItem
                onClick={() => navigate(`${e.redirectionUrl}`)}
                key={e.title}
                className="p-2 cursor-pointer pr-8 hover:bg-clair-gray"
              >
                {e.title}
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </Dropdown>
  );
};

export default DropdownComponent;