import founderBronze from "@assets/icons/bronze.png";
import founderSilver from "@assets/icons/silver.png";
import founderGold from "@assets/icons/gold.png";
import diamondFounder from "@assets/icons/diamond.png";
import platinumFounder from "@assets/icons/platinum.png";
import ArrowIcon from "@assets/icons/arrow.svg";
import { useCallback, useEffect, useState } from "react";
import { usePackagesInfo } from "@src/modules/join-us/hooks/usePackagesInfo";

export const ProgressBar = ({ value }) => {
    // value deberia setearse con el saldo invertido
    const maxValue = 10000;
    const { loading, packages } = usePackagesInfo();
    const [percentValue, setPercentValue] = useState();
    const [prices, setPrices] = useState({});

    const [actualLevel, setActualLevel] = useState();
    const [remainingValue, setRemainingValue] = useState();

    const setPackages = useCallback(() => {
        const obj = {};
        for(const index in packages){
            obj[packages[index]['value']] = packages[index]['totalPrice']
        }
        setPrices(obj)
    },[packages])

    const setLevel = useCallback(() => {
        if(value < prices["founder-bronze"]){
            setActualLevel("no level")
        } else if (value >= prices["founder-bronze"] && value < prices["founder-silver"]) {
            setActualLevel("bronze");
        } else if (value >= prices["founder-silver"] && value < prices["founder-gold"]) {
            setActualLevel("silver");
        } else if (value >= prices["founder-gold"] && value < prices["founder-diamond"]) {
            setActualLevel("gold");
        } else if (value >= prices["founder-diamond"] && value < prices["founder-platinum"]) {
            setActualLevel("diamond");
        } else if (value >= prices["founder-diamond"]) {
            setActualLevel("platinum");
        } else {
            setActualLevel(undefined);
        }
    },[prices,value]);

    const calculateNextLevel = useCallback(() => {
        const nextLevel = Object.keys(prices).find(
            (level) => value < prices[level]
        );

        if (nextLevel) {
            setRemainingValue(prices[nextLevel] - value);
        } else {
            setRemainingValue(undefined);
        }
    },[prices, value]);

    console.log(`ACTUAL LEVEL ${actualLevel}`)

    useEffect(()=>{
        if(!loading){
            if(!prices || Object.keys(prices).length == 0){
                setPackages();
            }else{
                setLevel()
                setPercentValue(value / maxValue);
                calculateNextLevel();
            }
        }
    },[loading, setPackages, setLevel, packages, prices, value,maxValue, setPercentValue, calculateNextLevel])
    
    return (
        <div className="flex flex-col mt-5 p-4">
            <div className="flex w-full mb-1">
                <div className="[font-size:_clamp(.5em,1vw,2em)] w-[9%] flex flex-col items-end">
                    <p>
                        FOUNDER
                        <br />
                        BRONZE
                    </p>
                    <p>${prices["founder-bronze"]}</p>
                </div>
                <div className="[font-size:_clamp(.5em,1vw,2em)] w-[12%] flex flex-col items-center">
                    <p>
                        FOUNDER
                        <br />
                        SILVER
                    </p>
                    <p>${prices["founder-silver"]}</p>
                </div>
                <div className="[font-size:_clamp(.5em,1vw,2em)] w-[14%] flex flex-col items-end">
                    <p>
                        FOUNDER
                        <br />
                        GOLD
                    </p>
                    <p>${prices["founder-gold"]}</p>
                </div>
                <div className="[font-size:_clamp(.5em,1vw,2em)] w-[15%] flex flex-col items-end">
                    <p>
                        FOUNDER
                        <br />
                        DIAMOND
                    </p>
                    <p>${prices["founder-diamond"]}</p>
                </div>
                <div className="[font-size:_clamp(.5em,1vw,2em)] w-[50%] flex flex-col items-end">
                    <p>
                        FOUNDER
                        <br />
                        PLATINUM
                    </p>
                    <p>${prices["founder-platinum"]}</p>
                </div>
            </div>
            <progress value={percentValue} style={{ width: "100%" }} />
            <div className="flex w-full mt-1">
                <div className="w-[9%] flex flex-col items-end">
                    <img src={ArrowIcon} className="h-4 w-4 rotate-12" />
                    <img src={founderBronze} className="w-10 h-10" />
                </div>
                <div className="w-[6%] flex flex-col items-end">
                    <img src={ArrowIcon} className="h-4 w-4 rotate-12" />
                    <img src={founderSilver} className="w-10 h-10" />
                </div>
                <div className="w-[20%] flex flex-col items-end">
                    <img src={ArrowIcon} className="h-4 w-4 rotate-12" />
                    <img src={founderGold} className="w-10 h-10" />
                </div>
                <div className="w-[15%] flex flex-col items-end">
                    <img src={ArrowIcon} className="h-4 w-4 rotate-12" />
                    <img src={diamondFounder} className="w-10 h-10" />
                </div>
                <div className="w-[50%] flex flex-col items-end">
                    <img src={ArrowIcon} className="h-4 w-4 rotate-12" />
                    <img src={platinumFounder} className="w-10 h-10" />
                </div>
            </div>
        </div>
    );
};
