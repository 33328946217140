import { GenericList } from "common/components";
import AdminLayout from "layouts/AdminLayout";
import React, { useEffect } from "react";
import { useState } from "react";
import { useFetchPackages } from "../hooks/Packages/useFetchPackages";
import { useNavigate } from "react-router-dom";
import { Modal } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useDeletePackage } from "../hooks/Packages/useDeletePackage";
import { useAccount } from "wagmi";
import { toast } from "react-toastify";
import { useFetchBidPointsSummary } from "../../admin/hooks/useFetchBidPointsSummary";
import { onGetWinnersList } from "modules/penny-auction/services/onGetWinnersList";
import { Pagination } from "modules/penny-auction/components/OurWinnersPage/WinnersList/pagination";
import { useFetchBids } from "../hooks/Bids/useFetchBids";

export const BidsPage = () => {
  const activeClass = "border-b-2 border-b-[#1840CE]";
  const [searchInput, setSearchInput] = useState("");
  const [activeTab, setActiveTab] = useState("summary");
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  const navigate = useNavigate();
  const { address } = useAccount();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [packageToDelete, setPackageToDelete] = useState({});
  const queryClient = useQueryClient();
  const { requestDeletePackage } = useDeletePackage();
  const { data: dataBidPointsSummary, isPending: isPendingDataBidPointSummary } =
    useFetchBidPointsSummary();

  const {
    data: bidsHistoryData,
    isPending: loadingBidsHistory,
    params: bidsHistoryParams,
    setParams: setBidsHistoryParams,
  } = useFetchBids("bids", {
    page: 1,
    limit: 10,
  });

  const handleDelete = async () => {
    await requestDeletePackage({
      packageData: {
        userId: address,
        id: packageToDelete.id,
        onSuccess: async () => {
          toast.success("Package deleted successfully.");
        },
        onError: async () => {
          toast.error("Failed to delete package.");
        },
      },
    });
    queryClient.invalidateQueries("packages");
    setIsModalOpen(false);
  };

  const {
    data: packagesData,
    isPending,
    setParams,
    params,
  } = useFetchPackages("packages", { page: 1, limit: 10, search: "", packageStatus: "all" });

  const [bidsData, setBidsData] = useState([]);

  useEffect(() => {
    if (packagesData) {
      setBidsData(
        packagesData.packages.map((item) => {
          return {
            id: item.package.id,
            package: item.package.name,
            qvpoints: item.package.qv,
            bidsQuantity: item.bid.totalBids,
            actions: (
              <div className="flex gap-x-2">
                <button
                  className="border bg-[#87BAD7] text-[#051F40] font-semibold px-4 py-2"
                  onClick={() => navigate(`/admin/bids/edit/${item.package.id}`)}
                >
                  Edit
                </button>
                <button
                  className="border bg-[#CCCCCC] text-[#051F40] font-semibold px-4 py-2"
                  onClick={() => {
                    setIsModalOpen(true);
                    setPackageToDelete(item.package);
                  }}
                >
                  Delete
                </button>
              </div>
            ),
          };
        })
      );
    }
  }, [packagesData]);

  const bidsColumns = {
    package: { header: "Package Name" },
    qvpoints: { header: "QV Points" },
    bidsQuantity: { header: "Bids Quantity" },
    actions: {
      header: "Actions",
    },
  };

  const [historyData, setHistoryData] = useState([]);
  const [formattedHistoryData, setFormattedHistoryData] = useState([]);
  const [winnersPage, setWinnersPage] = useState(1);

  const {
    isFetching: loadingWinnersList,
    data: winnersListData,
    error: error,
  } = useQuery({
    queryKey: [`winnersList_${winnersPage}`],
    queryFn: async () => await onGetWinnersList({ page: winnersPage, limit: 10 }),
    staleTime: 360000,
    refetchOnWindowFocus: false,
  });

  const handlePageChange = (page) => {
    setWinnersPage(page);
  };

  const winnersColumns = {
    productName: { header: "Product Name" },
    winner: { header: "Winner" },
    country: { header: "Country" },
    finalBid: { header: "Final Bid" },
    totalCost: { header: "Total Cost" },
    bidsAmount: { header: "Bids Amount" },
    auctionEnd: { header: "Auction End" },
  };

  /* {
        "id": 7,
        "amount": 200,
        "remaining": 100,
        "createdAt": 1717530432,
        "cashbackDays": 0,
        "expirationDays": 1095,
        "cashback": 0,
        "packageId": 3,
        "owners": [
          {
            "id": 1.1461985066523594e+48,
            "sponsor": 3.703217048322232e+47,
            "name": "boxoro2784@avastu.com",
            "lastname": "boxoro2784@avastu.com",
            "username": "boxoro",
            "email": "boxoro2784@avastu.com",
            "country": "Costa Rica",
            "zipcode": 10000,
            "phone": null,
            "registrationDate": 1717529700,
            "status": 0,
            "statusText": "Active"
          }
        ],
        "origin": {
          "kind": "Package Purchase",
          "package": {
            "id": 3,
            "price": 3500000000,
            "vat": 0,
            "bidConsumable": 1500000000,
            "turboFund": 2000000000,
            "qv": 3500,
            "ucv": 1350,
            "bcv": 900,
            "name": "FOUNDER GOLD",
            "consumablePercent": 4000,
            "isUpgradePackage": false
          }
        }
      } */

  const bidsHistoryColumns = {
    amount: { header: "Amount" },
    remaining: { header: "Remaining" },
    createdAt: { header: "Created Date" },
    cashbackDays: { header: "Cashback Days" },
    expirationDays: { header: "Expiration Days" },
    cashback: { header: "Cashback" },
    owners: { header: "Owners" },
    origin: { header: "Origin Package" },
  };

  useEffect(() => {
    if (bidsHistoryData) {
      setFormattedHistoryData(
        bidsHistoryData.bids.map((bid) => {
          return {
            amount: bid.amount,
            remaining: bid.remaining,
            createdAt: new Date(bid.createdAt*1000).toLocaleDateString("en-US"),
            cashbackDays: bid.cashbackDays,
            expirationDays: bid.expirationDays || "N/A",
            cashback: bid.cashback,
            packageId: bid.packageId,
            owners: bid.owners.map((owner) => owner.username).join(", "),
            origin: bid.origin.package?.name || bid.origin.kind || "N/A",
          };
        })
      );
    }
  }, [bidsHistoryData]);

  useEffect(() => {
    if (winnersListData) {
      setHistoryData(
        winnersListData.winners.map((winner) => {
          return {
            productName: winner.item.info.name,
            productDescription: winner.item.info.description,
            winner: winner.user.username || winner.user.id,
            country: winner.user.country,
            finalBid: winner.item.finalPrice,
            totalCost: winner.item.autobuyPrice,
            bidsAmount: winner.item.totalBids,
            /* format auctionEnd, 1724184780000 transform to 2024-06-01 */
            auctionEnd: new Date(winner.item.endDate).toLocaleDateString("en-US"),
          };
        })
      );
    }
  }, [winnersListData]);

  const Tabs = (
    <div className="grid grid-cols-4">
      <div
        className={`flex border-b-2 justify-center p-4 cursor-pointer ${
          activeTab === "summary" ? activeClass : ""
        }`}
        onClick={() => handleTabChange("summary")}
      >
        SUMMARY
      </div>
      <div
        className={`flex border-b-2 justify-center p-4 cursor-pointer ${
          activeTab === "bids-creation" ? activeClass : ""
        }`}
        onClick={() => handleTabChange("bids-creation")}
      >
        EXPENSES
      </div>
      <div
        className={`flex border-b-2 justify-center p-4 cursor-pointer ${
          activeTab === "winners-history" ? activeClass : ""
        }`}
        onClick={() => handleTabChange("winners-history")}
      >
        WINNERS HISTORY
      </div>
      <div
        className={`flex border-b-2 justify-center p-4 cursor-pointer ${
          activeTab === "bids-history" ? activeClass : ""
        }`}
        onClick={() => handleTabChange("bids-history")}
      >
        BIDS HISTORY
      </div>
    </div>
  );

  const expensesHeaderOptions = (
    <div className="flex w-full justify-between">
      <div className="flex-1 border border-[#161C24]">
        <input
          type="text"
          placeholder="Search"
          className="p-2 w-3/4"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              setParams({ ...params, search: searchInput });
            }
          }}
        />
        <button
          className="bg-[#1840CE] text-white px-4 py-2 w-1/4"
          onClick={() => {
            setParams({ ...params, search: searchInput });
          }}
        >
          Search
        </button>
      </div>
      <button
        className="bg-[#BCE210] text-[#051F40] font-semibold px-4 py-2 ml-2"
        onClick={() => navigate("/admin/bids/create")}
      >
        Create New Bid Package
      </button>
    </div>
  );

  return (
    <AdminLayout>
      <div className="w-full bg-white p-4">
        <h1 className="text-xl font-semibold text-gray-800">Bids</h1>
        {Tabs}
        {activeTab === "bids-creation" && (
          <>
            <GenericList
              data={bidsData}
              columns={bidsColumns}
              isLoading={isPending}
              headerOptions={expensesHeaderOptions}
            />
            <div className="w-full justify-center flex p-5">
              {packagesData?.pagination && (
                <div className="pb-52">
                  <div className="flex">
                    {packagesData?.pagination.prev_page && (
                      <button
                        className="text-[16px] text-[#808080] font-bold mx-10"
                        onClick={() => {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                          setParams({ ...params, page: packagesData?.pagination.page - 1 });
                        }}
                      >{` PREVIOUS `}</button>
                    )}
                    {packagesData?.pagination.page - 1 > 0 && (
                      <button
                        className="text-[16px] text-dark font-bold mx-10"
                        onClick={() => {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                          setParams({ ...params, page: packagesData?.pagination.page - 1 });
                        }}
                      >{` ${packagesData?.pagination.page - 1} `}</button>
                    )}
                    <span className="text-[16px] text-dark font-bold">
                      {packagesData?.pagination.page}
                    </span>
                    {packagesData?.pagination.page + 1 <= packagesData?.pagination.total_pages && (
                      <button
                        className="text-[16px] text-dark font-bold mx-10"
                        onClick={() => {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                          setParams({ ...params, page: packagesData?.pagination.page + 1 });
                        }}
                      >{` ${packagesData?.pagination.page + 1} `}</button>
                    )}
                    {packagesData?.pagination.next_page && (
                      <button
                        className="text-[16px] text-[#808080] font-bold mx-10"
                        onClick={() => {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                          setParams({ ...params, page: packagesData?.pagination.page + 1 });
                        }}
                      >{` NEXT `}</button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        {activeTab === "summary" && (
          <div className="w-full grid grid-cols-3 gap-4 mt-4 min-h-screen">
            <div className="col-span-3 md:col-span-1">
              <div>
                <div className="bg-[#132440] h-52 rounded p-4 flex flex-col">
                  <div>
                    <p className="text-white text-sm">Available bids</p>
                  </div>
                  <div className="mt-4 h-full flex justify-center items-center">
                    <p className="text-white font-bold text-3xl">
                      {dataBidPointsSummary && dataBidPointsSummary?.total}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-3 md:col-span-1">
              <div>
                <div className="bg-[#132440] h-52 rounded p-4 flex flex-col">
                  <div>
                    <p className="text-white text-sm">Bids to Expire</p>
                    {/* <p className="text-baby-blue text-sm">June, 2024</p> */}
                  </div>
                  <div className="mt-4 h-full flex justify-center items-center">
                    <p className="text-white font-bold text-3xl">
                      {" "}
                      {dataBidPointsSummary && dataBidPointsSummary?.toExpire}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeTab === "winners-history" && (
          <>
            <GenericList
              data={historyData}
              columns={winnersColumns}
              isLoading={loadingWinnersList}
            />
            {winnersListData?.pagination && (
              <div className="col-span-1 lg:col-span-2 my-4 text-2xl flex justify-center w-full">
                <Pagination
                  pagination={winnersListData.pagination}
                  onPreviousClick={() => {
                    handlePageChange(winnersPage - 1);
                  }}
                  onNextClick={() => {
                    handlePageChange(winnersPage + 1);
                  }}
                />
              </div>
            )}
          </>
        )}
        {activeTab === "bids-history" && (
          <>
            <GenericList
              data={formattedHistoryData}
              columns={bidsHistoryColumns}
              isLoading={loadingBidsHistory}
            />
            <div className="w-full justify-center flex p-5">
              {bidsHistoryData?.pagination && (
                <div className="pb-52">
                  <div className="flex">
                    {bidsHistoryData?.pagination.prev_page && (
                      <button
                        className="text-[16px] text-[#808080] font-bold mx-10"
                        onClick={() => {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                          setBidsHistoryParams({
                            ...bidsHistoryParams,
                            page: bidsHistoryData?.pagination.page - 1,
                          });
                        }}
                      >{` PREVIOUS `}</button>
                    )}
                    {bidsHistoryData?.pagination.page - 1 > 0 && (
                      <button
                        className="text-[16px] text-dark font-bold mx-10"
                        onClick={() => {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                          setBidsHistoryParams({
                            ...bidsHistoryParams,
                            page: bidsHistoryData?.pagination.page - 1,
                          });
                        }}
                      >{` ${bidsHistoryData?.pagination.page - 1} `}</button>
                    )}
                    <span className="text-[16px] text-dark font-bold">
                      {bidsHistoryData?.pagination.page}
                    </span>
                    {bidsHistoryData?.pagination.page + 1 <=
                      bidsHistoryData?.pagination.total_pages && (
                      <button
                        className="text-[16px] text-dark font-bold mx-10"
                        onClick={() => {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                          setBidsHistoryParams({
                            ...bidsHistoryParams,
                            page: bidsHistoryData?.pagination.page + 1,
                          });
                        }}
                      >{` ${bidsHistoryData?.pagination.page + 1} `}</button>
                    )}
                    {bidsHistoryData?.pagination.next_page && (
                      <button
                        className="text-[16px] text-[#808080] font-bold mx-10"
                        onClick={() => {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                          setBidsHistoryParams({
                            ...bidsHistoryParams,
                            page: bidsHistoryData?.pagination.page + 1,
                          });
                        }}
                      >{` NEXT `}</button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="w-[400px] mx-auto bg-white rounded-md mt-20">
          <h2 className="text-2xl p-4 text-red-500">Delete package</h2>
          <p className="p-4">Are you sure you want to delete this package?</p>
          <div className="flex justify-end gap-x-2 p-4">
            <button
              className="border border-dark text-dark font-bold px-4 py-2 rounded"
              onClick={() => setIsModalOpen(false)}
            >
              Cancel
            </button>
            <button
              className="border border-red-500 text-red-500 font-bold px-4 py-2 rounded"
              onClick={() => handleDelete()}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </AdminLayout>
  );
};
