import { onReadContract } from "../../../onReadContract";

export const qualificationsFunctions = ({ qualificationsContract, qualificationsABI }) => {
  const qualificationsCache = {
    leadership: [],
    matchingBonus: {},
    fastStart: [],
  };
  const readQualifications = async ({ functionName, args }) => {
    return await onReadContract({
      contractAddress: qualificationsContract,
      contractABI: qualificationsABI,
      functionName,
      args,
    });
  };

  const qualificationsLeadership = async () => {
    if (
      qualificationsCache &&
      qualificationsCache.leadership &&
      qualificationsCache.leadership.length > 0
    ) {
      return qualificationsCache.leadership;
    }
    const args = [0];
    const leadershipResponse = await readQualifications({
      functionName: "getLeadership",
      args,
    });
    console.debug("leadershipResponse: ", leadershipResponse);
    if (leadershipResponse) {
      const leadershipResult = leadershipResponse.map((leadership) => {
        return {
          name: leadership.name,
          personalQV: Number(leadership.personalQV),
          unilevelQVperMonth: Number(leadership.unilevelQVperMonth),
          minUnilevelQVperBinaryLegPercentage: Number(
            leadership.minUnilevelQVperBinaryLegPercentage
          ),
          mainLegPercentage: Number(leadership.mainLegPercentage),
          binaryPercent: Number(leadership.binaryPercent),
          binaryMatchingBonus: Number(leadership.binaryMatchingBonus),
          unilevelMatchingBonus: Number(leadership.unilevelMatchingBonus),
          weeklyCap: Number(leadership.weeklyCap),
          binaryCQ: Number(leadership.binaryCQ),
          globalPoolPercentage: Number(leadership.globalPoolPercentage),
        };
      });

      if (
        qualificationsCache &&
        qualificationsCache.leadership &&
        qualificationsCache.leadership.length == 0
      ) {
        qualificationsCache.leadership = leadershipResult;
      }
      return leadershipResult;
    }
    return [];
  };

  const qualificationsMatchingBonus = async (index) => {
    if (
      qualificationsCache &&
      qualificationsCache.matchingBonus &&
      qualificationsCache.matchingBonus[index]
    ) {
      return qualificationsCache.matchingBonus[index];
    }
    const args = [index];
    const qualificationsMatchingBonusResponse = await readQualifications({
      functionName: "matchingBonusPercentageMap",
      args,
    });
    console.debug("qualificationsMatchingBonusResponse: ", qualificationsMatchingBonusResponse);
    const result = Number(qualificationsMatchingBonusResponse);

    if (qualificationsCache && qualificationsCache.matchingBonus) {
      qualificationsCache.matchingBonus[index] = result;
    }
    return result;
  };

  const getFastStartLevels = async () => {
    if (
      qualificationsCache &&
      qualificationsCache.fastStart &&
      qualificationsCache.fastStart.length > 0
    ) {
      return qualificationsCache.fastStart;
    }
    const args = [];
    const functionName = "getFastStartLevels";

    const FastStartLevelsResponse = await readQualifications({
      functionName,
      args,
    });
    console.debug("FastStartLevelsResponse: ", FastStartLevelsResponse);
    const result = FastStartLevelsResponse.map((level) => Number(level));

    if (qualificationsCache && qualificationsCache.fastStart) {
      qualificationsCache.fastStart = result;
    }
    return result;
  };

  return { qualificationsLeadership, qualificationsMatchingBonus, getFastStartLevels };
};
