import axios from "axios";
import { SentryService } from "./SentryErrorCapture";

export const onRequestAddPackagePayment = async ({ signature, userId, amount }) => {
  if (!signature) {
    throw new Error("signature is required");
  }
  if (!userId) {
    throw new Error("userId is required");
  }
  if (!amount) {
    throw new Error("amount is required");
  }
  try {
    const packagePaymentResponse = await axios.post(
      `${process.env.REACT_APP_AWS_API_URL}/contracts/fg/payPackage`,
      {
        signature,
        userId,
        amount,
      }
    );
    // console.debug(packagePaymentResponse);
    if (packagePaymentResponse) {
      return packagePaymentResponse.data;
    }
  } catch (error) {
    SentryService("onRequestAddPackagePayment", "onRequestAddPackagePayment", error)
    // console.debug(error);
    throw new Error(
      error.response
        ? error.response.data.error.reason ||
          error.response.data.error.shortMessage ||
          error.response.data.error
        : error
    );
  }
};
