import { Select } from "common/components";
import { Input } from "common/components";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useYupValidationResolver } from "@src/common/hooks/useYupValidationResolver";
import { useEffect } from "react";
import { Button } from "common/components";
import { useGetUserInfo } from "common/hooks/useGetUserInfo";
import ABI from "../../../common/contracts/FortuneGoat.json";
import { toast } from "react-toastify";
import { useAccount } from "wagmi";
import { onReadContract } from "common/services";
// import { onWriteContract } from "common/services";
import { useRegisterUser } from "common/hooks/useRegisterUser";

export const RegisterAffiliate = ({ setOpenModal, nodeInfo }) => {
  const { address } = useAccount();
  const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
  const { userInfo, mutateAsync, loading } = useGetUserInfo();
  const { registerUser } = useRegisterUser();
  const validationSchema = yup.object({
    fullName: yup.string().required("Full name is required").min(3),
    username: yup
      .string()
      .required("Username is required")
      .min(3)
      .matches(
        /^[a-z]+(-[a-z]+)*$/,
        "Username is invalid. Only lowercase letters and hyphens are allowed."
      ),
    email: yup.string().email().required("Email is required"),
    sponsor: yup.string().required("Sponsor is required").min(3),
    country: yup.string().required("Country is required"),
    amount: yup.number(),
    zip: yup.string().min(4).max(5).required("Zip is required"),
    position: yup.string(),
    wallet: yup.string().required("Wallet is required"),
  });

  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    trigger,
    setError,
    clearErrors,
  } = useForm({ resolver });
  const checkUserAvailability = async () => {
    if (!address) {
      throw new Error("No address found");
    }
    const userNameResponse = await onReadContract({
      contractAddress,
      contractABI: ABI,
      functionName: "isUsernameAvailable",
      args: [watch("username")],
      from: address,
    });
    if (!userNameResponse) {
      setError("username", { type: "custom", message: "Username already registered" });
      toast.error("Username already registered");
    }
  };
  const onFocusClear = (e) => {
    clearErrors(e.target.id);
  };
  const onSubmit = async (data) => {
    // const decimals = 6;
    const userData = {
      sponsorUsername: data.sponsor,
      userId: data.wallet,
      userSelectedBranch: 0,
      name: data.fullName,
      lastName: data.fullName,
      username: data.username,
      email: data.email,
      country: data.country,
      zip: data.zip,
      phone: "",
      amountToPay: 0,
      underUsername: nodeInfo.fatherUsername,
      underUserBranch: nodeInfo.branch,
      onSuccess: async () => {
        await mutateAsync();
        toast.success("You have successfully registered!");
        setOpenModal(false);
        window.location.reload();
      },
      onError: async () => {
        toast.error("Something went wrong, please try again. REGISTER ERROR.");
      },
    };
    await registerUser({ userData });
    // const registerResponse = await onWriteContract({
    //   contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS,
    //   contractABI: ABI,
    //   functionName: "registerUsers",
    //   args: [
    //     [
    //       data.wallet,
    //       "0x0000000000000000000000000000000000000000",
    //       data.fullName,
    //       data.fullName,
    //       data.username,
    //       data.email,
    //       data.country,
    //       data.zip,
    //       "",
    //       0
    //     ],
    //     data.sponsor,
    //     0,
    //     BigInt(0 * 10 ** decimals),
    //     nodeInfo.fatherUsername,
    //     nodeInfo.branch,
    //   ],
    //   // @ts-expect-error something is wrong with the types
    //   from: address,
    // });
    // if (registerResponse) {
    //   toast.success("You have successfully registered!");
    //   mutateAsync();
    //   setOpenModal(false);
    // } else {
    //   toast.error("Something went wrong, please try again. REGISTER ERROR.");
    // }
  };
  useEffect(() => {
    if (userInfo) {
      setValue("sponsor", userInfo.info.username);
    }
  }, [userInfo]);
  return (
    <div className="p-6">
      <h2 className="text-2xl text-dark mb-6">Register affiliate</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2">
            <Input
              label="Full name"
              id="first_name"
              type="text"
              placeholder="Full name"
              {...register("fullName")}
              onBlur={() => trigger("fullName")}
            />
            {errors.fullName && <span className="text-red-500">{errors.fullName.message}</span>}
          </div>
          <div className="col-span-2">
            <Input
              label="Username"
              id="username"
              type="text"
              placeholder="Username"
              {...register("username")}
              onBlur={() => checkUserAvailability()}
              onFocus={(e) => onFocusClear(e)}
            />
            {errors.username && <span className="text-red-500">{errors.username.message}</span>}
          </div>
          <div className="col-span-2">
            <Input
              label="Email"
              id="email"
              type="email"
              placeholder="Email"
              {...register("email")}
              onBlur={() => trigger("email")}
            />
            {errors.email && <span className="text-red-500">{errors.email.message}</span>}
          </div>
          <div className="col-span-2">
            <Input
              label="Sponsor"
              id="sponsor"
              type="text"
              placeholder="Sponsor"
              {...register("sponsor")}
              onBlur={() => trigger("sponsor")}
              disabled
            />
            {errors.sponsor && <span className="text-red-500">{errors.sponsor.message}</span>}
          </div>
          <div className="col-span-2">
            <Input
              label="Wallet"
              id="wallet"
              type="text"
              placeholder="Wallet"
              {...register("wallet")}
              onBlur={() => trigger("wallet")}
            />
            {errors.wallet && <span className="text-red-500">{errors.wallet.message}</span>}
          </div>
          <div className="col-span-2 md:col-span-1">
            <Select
              label="Country"
              id="country"
              options={["Costa Rica", "México"]}
              {...register("country")}
              onBlur={() => trigger("country")}
            />
            {errors.country && <span className="text-red-500">{errors.country.message}</span>}
          </div>
          <div className="col-span-2 md:col-span-1">
            <Input
              label="Zip code"
              id="zip_code"
              type="text"
              placeholder="Zip code"
              {...register("zip")}
              onBlur={() => trigger("zip")}
            />
            {errors.zip && <span className="text-red-500">{errors.zip.message}</span>}
          </div>

          <Button
            type="button"
            label="Cancelar"
            onClick={() => {
              setOpenModal(false);
            }}
          />
          <Button type="submit" label="Registrar" />
        </div>
      </form>
    </div>
  );
};
