import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import "./global.css";
import "react-image-crop/dist/ReactCrop.css";
import "react-tagsinput/react-tagsinput.css";
import "react-slideshow-image/dist/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

import { WalletConnectModal } from "@walletconnect/modal";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// react-router components
import { RouterProvider } from "react-router-dom";

//! DO NOTE REMOVE if removed the page will fail
import {
  walletConnectProvider,
  //  EIP6963Connector
} from "@web3modal/wagmi";

import {
  createConfig,
  http,
  WagmiProvider,
  //  useAccount
} from "wagmi";

import { polygon, sepolia } from "wagmi/chains";

import PersistProvider from "redux/providers/persist-provider";
import { CHAIN_NAMESPACES, WALLET_ADAPTERS } from "@web3auth/base";
import { OpenloginAdapter, OPENLOGIN_NETWORK } from "@web3auth/openlogin-adapter";
import { WalletConnectV2Adapter } from "@web3auth/wallet-connect-v2-adapter";
import { MetamaskAdapter } from "@web3auth/metamask-adapter";
import logo from "@assets/logos/fortune-goat-dark-isotype.svg";
import { router } from "@config/router";
import { Web3Auth } from "@web3auth/modal";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { torusPlugin } from "Web3AuthConnectorInstance";
import { Web3AuthConnector } from "@web3auth/web3auth-wagmi-connector";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useEffect } from "react";
// 1. Get projectId
const projectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID;
const infuraApiKey = process.env.REACT_APP_INFURA_API_KEY;
const prodChain = polygon;
const devChain = sepolia;
const rpcTarget =
  process.env.REACT_APP_ENVIRONMENT === "production"
    ? `https://polygon-mainnet.infura.io/v3/${infuraApiKey}`
    : `https://sepolia.infura.io/v3/${infuraApiKey}`;

const chains = [process.env.REACT_APP_ENVIRONMENT === "production" ? prodChain : devChain];
const chainConfig = {
  chainNamespace: CHAIN_NAMESPACES.EIP155,
  chainId: "0x" + chains[0].id.toString(16),
  rpcTarget,
  displayName: chains[0].name,
  tickerName: chains[0].nativeCurrency?.name,
  ticker: chains[0].nativeCurrency?.symbol,
  blockExplorer: chains[0].blockExplorers?.default.url,
  blockExplorerUrl: chains[0].blockExplorers?.default.url,
  logo,
  isTestnet: process.env.REACT_APP_ENVIRONMENT !== "production",
};

// Add openlogin adapter for customisations
const privateKeyProvider = new EthereumPrivateKeyProvider({ config: { chainConfig } });
const openloginAdapterInstance = new OpenloginAdapter({
  privateKeyProvider,
  adapterSettings: {
    clientId: process.env.REACT_APP_WEB3AUTH_KEY,
    network:
      process.env.REACT_APP_ENVIRONMENT === "production"
        ? OPENLOGIN_NETWORK.SAPPHIRE_MAINNET
        : OPENLOGIN_NETWORK.SAPPHIRE_DEVNET,
    uxMode: "redirect",
    whiteLabel: {
      name: "Fortune Goat",
      logoLight: "logo",
      logoDark: "logo",
      defaultLanguage: "en",
      mode: "dark",
      dark: true, // whether to enable dark mode. defaultValue: false
    },
  },
});
const defaultWcSettings = {
  adapterSettings: {
    walletConnectInitOptions: {
      projectId,
      relayUrl: "wss://relay.walletconnect.com",
      metadata: {
        name: "Fortune Goat",
        description: "Fortune Goat",
        url: `${window.location.href}`,
        icons: [`${window.location.href}/favicon.svg`],
      },
    },
  },
  loginSettings: {
    requiredNamespaces: {
      eip155: {
        methods: ["eth_sendTransaction", "eth_sign", "personal_sign", "eth_signTypedData"],
        chains: [
          `eip155:${
            process.env.REACT_APP_ENVIRONMENT === "production"
              ? String(prodChain.id).replace("_", "").trim()
              : String(devChain.id).replace("_", "").trim()
          }`,
        ],
        events: ["chainChanged", "accountsChanged"],
      },
    },
  },
};
// const walletConnectModal = new WalletConnectModal({ projectId });
const walletConnectV2Instance = new WalletConnectV2Adapter({
  adapterSettings: { ...defaultWcSettings.adapterSettings },
  loginSettings: { ...defaultWcSettings.loginSettings },
});

const metamaskInstance = new MetamaskAdapter();

export const web3AuthInstance = new Web3Auth({
  autoconnect: false,
  privateKeyProvider,
  clientId: process.env.REACT_APP_WEB3AUTH_KEY,
  // chainConfig,
  // uiConfig refers to the whitelabeling options, which is available only on Growth Plan and above
  // Please remove this parameter if you're on the Base Plan
  chainConfig,
  uiConfig: {
    appName: "Fortune Goat",
    appLogo: logo, // Your App Logo Here
    theme: {
      primary: "#17c1e8",
    },
    mode: "light",
    logoLight: logo,
    logoDark: logo,
    defaultLanguage: "en", // en, de, ja, ko, zh, es, fr, pt, nl
    loginGridCol: 3,
    primaryButton: "externalLogin", // "externalLogin" | "socialLogin" | "emailLogin"
    modalZIndex: "2147483647",
  },
  web3AuthNetwork:
    process.env.REACT_APP_ENVIRONMENT === "production"
      ? OPENLOGIN_NETWORK.SAPPHIRE_MAINNET
      : OPENLOGIN_NETWORK.SAPPHIRE_DEVNET,
  enableLogging: true,
});

web3AuthInstance.configureAdapter(walletConnectV2Instance);
web3AuthInstance.configureAdapter(metamaskInstance);
web3AuthInstance.configureAdapter(openloginAdapterInstance);
web3AuthInstance.addPlugin(torusPlugin);

// if (
//   web3AuthInstance.walletAdapters["wallet-connect-v2"] &&
//   web3AuthInstance.walletAdapters["wallet-connect-v2"].adapterData &&
//   web3AuthInstance.walletAdapters["wallet-connect-v2"].adapterData.extensionAdapters
// ) {
//   web3AuthInstance.walletAdapters["wallet-connect-v2"].adapterData.extensionAdapters.push(
//     {
//       chains: ["eip155"],
//       desktop: { native: "", universal: "" },
//       mobile: {
//         native: "binance://",
//         universal: "https://www.binance.com",
//       },
//       name: "Binance",
//       logo: "https://explorer-api.walletconnect.com/v3/logo/md/ebac7b39-688c-41e3-7912-a4fefba74600?projectId=2f05ae7f1116030fde2d36508f472bfb",
//     },
//     {
//       chains: ["eip155"],
//       desktop: { native: "", universal: "" },
//       mobile: {
//         native: "exodus://",
//         universal: "https://www.exodus.com",
//       },
//       name: "Exodus",
//       logo: "https://explorer-api.walletconnect.com/v3/logo/md/4c16cad4-cac9-4643-6726-c696efaf5200?projectId=2f05ae7f1116030fde2d36508f472bfb",
//     }
//   );
// }
export const modalConfig = {
  [WALLET_ADAPTERS.OPENLOGIN]: {
    label: "openlogin",
    showOnModal: true,
  },
  [WALLET_ADAPTERS.WALLET_CONNECT_V2]: {
    label: "wallet_connect",
    showOnModal: true,
  },
  [WALLET_ADAPTERS.TORUS_EVM]: {
    label: "Torus EVM",
    showOnModal: true,
  },
  [WALLET_ADAPTERS.METAMASK]: {
    label: "metamask",
    showOnModal: true,
  },
  [WALLET_ADAPTERS.COINBASE]: {
    label: "Coinbase",
    showOnModal: false,
  },
};
console.debug({ web3AuthInstance });
const web3authCon = new Web3AuthConnector({
  web3AuthInstance,
  modalConfig,
});
console.debug({ web3authCon });
// web3authCon.name = "Iniciar con tus redes o teléfono";
// web3authCon.id = "Iniciar con tus redes o teléfono";
/* --------------------- */

export const wagmiConfig = createConfig({
  chains,
  transports:
    process.env.REACT_APP_ENVIRONMENT === "production"
      ? {
          [prodChain.id]: http(rpcTarget),
        }
      : {
          [devChain.id]: http(rpcTarget),
        },
  connectors: [web3authCon],
});

const queryClient = new QueryClient();

export default function App() {
  useEffect(() => {
    const handleGlobalError = (event) => {
      const errorMessage = event?.reason?.message || event?.message;
      // console.log(event);
      if (errorMessage && errorMessage.includes("The provider is disconnected from all chains")) {
        // console.error("Caught provider disconnection error:", event);
        alert("Connection to the blockchain has been lost. Please reconnect.");
        window.location.reload();
      }
      if (errorMessage && errorMessage.includes("No matching key. expirer: topic")) {
        window.location.reload();
      }
    };

    window.addEventListener("unhandledrejection", handleGlobalError);
    window.addEventListener("error", handleGlobalError);

    return () => {
      window.removeEventListener("unhandledrejection", handleGlobalError);
      window.removeEventListener("error", handleGlobalError);
    };
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <WagmiProvider config={wagmiConfig}>
        <PersistProvider>
          <ToastContainer />
          {/* <AppRouter /> */}
          <RouterProvider router={router} />
        </PersistProvider>
      </WagmiProvider>
    </QueryClientProvider>
  );
}
