export const TheFeatures = () => {
    return (
        <div className="flex flex-col py-40">
            <div className="flex justify-center">
                <div className="container px-4">
                    <div className="grid grid-cols-8 md:gap-y-44 gap-4  bg-white">
                        <div className=" bg-white col-span-8 rounded">
                            <h2 className="text-5xl text-dark font-light md:mt-32 md:mx-24 mt-5  text-center">
                                We are Fortune GOAT
                            </h2>
                        </div>
                        <div className="md:col-start-2 md:col-end-5 col-span-8  rounded bg-[url('@assets/images/image-work-money.png')] bg-center bg-no-repeat bg-cover ">
                        </div>
                        <div className=" bg-white md:col-start-5 md:col-end-8  col-span-8  rounded">
                            <div className="m-10">
                                <h3 className="flex text-1xl w-full font-normal uppercase text-gray-400 mb-4">
                                    Our History
                                </h3>
                                <p className="text-2xl font-semibold text-dark mb-4">
                                    Innovative Dreams Platform
                                </p>
                                <p className="text-xl font-normal text-gray-400 mb-4">
                                    Founded in 2024 by visionaries Alejandro Murillo and Jorge Solis, FortuneGoat introduces an innovative way to shop, earn, and have fun. We are dedicated to helping people achieve their dreams by supporting others to do the same.
                                </p>
                            </div>
                        </div>
                        <div className=" bg-white md:col-start-2 md:col-end-5  col-span-8  rounded">
                            <div className="m-10">
                                <h3 className="flex text-1xl w-full font-normal uppercase text-gray-400 mb-4">
                                    Our Products
                                </h3>
                                <p className="text-2xl font-semibold text-dark mb-4">
                                    Dreams, Share, Win
                                </p>
                                <p className="text-xl font-normal text-gray-400 mb-4">
                                    Participate and share in this remarkable shopping experience, and inviteothers to join you. Engage, share, earn, and win with an unparalleledopportunity to achieve your dreams while taking care of and enjoying time withyour loved ones.
                                </p>
                            </div>
                        </div>
                        <div className="md:col-start-5 md:col-end-8 col-span-8  rounded bg-[url('@assets/images/image-group-work.png')] bg-center bg-no-repeat bg-cover ">
                        </div>
                        <div className="md:col-start-2 md:col-end-5 col-span-8  rounded bg-[url('@assets/images/image-hi.png')] bg-center bg-no-repeat bg-cover ">
                        </div>
                        <div className=" bg-white md:col-start-5 md:col-end-8  col-span-8  rounded">
                            <div className="m-10">
                                <h3 className="flex text-1xl w-full font-normal uppercase text-gray-400 mb-4">
                                    Our Team
                                </h3>
                                <p className="text-2xl font-semibold text-dark mb-4">
                                    Teamwork for Superior Service
                                </p>
                                <p className="text-xl font-normal text-gray-400 mb-4">
                                    At Fortune Goat, we uphold the principles of teamwork and market-oriented decisions. Each collaborator, team leader, and member is dedicated to delivering superior service to our customers.
                                </p>
                            </div>
                        </div>
                        <div className=" bg-white md:col-start-2 md:col-end-5  col-span-8  rounded">
                            <div className="m-10">
                                <h3 className="flex text-1xl w-full font-normal uppercase text-gray-400 mb-4">
                                    Our Planet
                                </h3>
                                <p className="text-2xl font-semibold text-dark mb-4">
                                    Tropical Forest Conservation
                                </p>
                                <p className="text-xl font-normal text-gray-400 mb-4">
                                    25% of our membership income is directly allocated to the development of secondary tropical forests in Costa Rica. We have successfully established our first forest in Santiago de Puriscal.
                                </p>
                            </div>
                        </div>
                        <div className="md:col-start-5 md:col-end-8 col-span-8  rounded bg-[url('@assets/images/image-reading-work.png')] bg-center bg-no-repeat bg-cover ">
                        </div>
                        <div className=" bg-transparent col-span-8 rounded">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
