import { Suspense, lazy } from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";
// import { LandingPage } from "@landing/pages";
import { JoinUsPage } from "@join-us/pages";
import { AffiliatesDashboardPage, DashboardPage } from "@dashboard/pages";
import { Logout } from "common/components/Logout";
import { ProtectedRoute } from "./ProtectedRoute";
import { PrivacyPolicyPage } from "@landing/pages/PrivacyPolicyPage";
import { TermsAndConditionsPage } from "@landing/pages/TermsAndConditionsPage";
import { MembersAgreementPage } from "@landing/pages/MembersAgreementPage";
import { ProfileDashboardPage } from "modules/dashboard/pages/ProfileDashboardPage";
import {
  CreateProductPage,
  CreateExpensePage,
  EditExpensePage,
  EditProductPage,
  CommissionsPage as AdminCommissionsPage,
} from "modules/admin/pages";
/* import AuctionTest from "layouts/auctionTest"; */
import { ProductsPage } from "modules/penny-auction/pages/ProductsPage";
import { ProductListPage } from "modules/admin/pages";
import { ViewProductPage } from "modules/admin/pages";
import { OurWinners } from "modules/penny-auction/pages/OurWinners";
import { AdminCommissions } from "modules/admin/pages/AdminCommissions";
import { TurboFundPage } from "modules/dashboard/pages";
import { RangePage } from "modules/dashboard/pages";
import { CommissionsPage } from "modules/dashboard/pages";
import { LandingPage } from "modules/landing/pages";
import { PricingPage } from "modules/penny-auction/pages/PricingPage";
import { BenefitsPage } from "modules/penny-auction/pages/BenefitsPage";
import { ContactUsPage } from "modules/penny-auction/pages/ContactUsPage";
import { UsersPage } from "modules/admin/pages";
import { UserSeeDetailsPage } from "modules/admin/pages";
import { OrganizationUsersPage } from "modules/admin/pages";
import { TurboFundsPage } from "modules/admin/pages";
import { BidsPage } from "modules/admin/pages";
import { ProductPage } from "modules/penny-auction/pages/ProductPage";
import { AuctionProviderWrapper } from "modules/penny-auction/context/auctionProviderWrapper";
import { PastProductListPage } from "modules/admin/pages";
import { CalendarPage } from "modules/admin/pages";
import { CreatePackagePage } from "modules/admin/pages";
import { EditPackagePage } from "modules/admin/pages";
import { CreateOrganizationUserPage } from "modules/admin/pages";
const Funds = lazy(() => import("modules/admin/pages/funds"));
const Balance = lazy(() => import("modules/admin/pages/balances"));
export const router = createBrowserRouter([
  {
    path: "/",
    // element: <LandingPage />,
    element: <Navigate to="/app/store/products" />,
  },
  {
    path: "/company",
    element: <LandingPage />,
  },
  {
    path: "/join",
    element: <JoinUsPage />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicyPage />,
  },
  {
    path: "/terms-and-conditions",
    element: <TermsAndConditionsPage />,
  },
  {
    path: "/members-agreement",
    element: <MembersAgreementPage />,
  },
  /* {
    path: "/auction",
    element: <AuctionTest />,
  }, */
  {
    path: "/app/dashboard",
    element: (
      <ProtectedRoute requireDashboardAccess requireWallet>
        <DashboardPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/app/turbo-fund",
    element: (
      <ProtectedRoute requireDashboardAccess requireWallet>
        <TurboFundPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/app/range",
    element: (
      <ProtectedRoute requireDashboardAccess requireWallet>
        <RangePage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/app/commissions",
    element: (
      <ProtectedRoute requireDashboardAccess requireWallet>
        <CommissionsPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/app/store/product/:id",
    element: (
      // <ProtectedRoute requireDashboardAccess requireWallet>
      <AuctionProviderWrapper>
        <ProductPage />
      </AuctionProviderWrapper>
      // </ProtectedRoute>
    ),
  },
  {
    path: "/app/store/products/:page?/:category?/:search?/:sortType?/:status?",
    element: (
      // <ProtectedRoute requireDashboardAccess requireWallet>
      <AuctionProviderWrapper>
        <ProductsPage />
      </AuctionProviderWrapper>
      // </ProtectedRoute>
    ),
  },
  {
    path: "/pricing",
    element: (
      // <ProtectedRoute requireDashboardAccess requireWallet>
      <AuctionProviderWrapper>
        <PricingPage />
      </AuctionProviderWrapper>
      // </ProtectedRoute>
    ),
  },
  {
    path: "/benefits",
    element: (
      // <ProtectedRoute requireDashboardAccess requireWallet>
      <AuctionProviderWrapper>
        <BenefitsPage />
      </AuctionProviderWrapper>
      // </ProtectedRoute>
    ),
  },
  {
    path: "/contact-us",
    element: (
      // <ProtectedRoute requireDashboardAccess requireWallet>
      <AuctionProviderWrapper>
        <ContactUsPage />
      </AuctionProviderWrapper>
      // </ProtectedRoute>
    ),
  },
  {
    path: "/app/store/winners",
    element: (
      // <ProtectedRoute requireDashboardAccess requireWallet>
      <AuctionProviderWrapper>
        <OurWinners />
      </AuctionProviderWrapper>
      // </ProtectedRoute>
    ),
  },
  {
    path: "/app/store",
    element: <Navigate to="/app/store/products" />,
  },
  /* {
    path: "/get-money",
    element: <GetMoneyPage />,
  },
  {
    path: "/approve",
    element: <ApprovePage />,
  }, */
  {
    path: "/admin",
    element: <Navigate to="/admin/products" />,
  },
  {
    path: "/admin/products",
    element: (
      <Suspense fallback={<>Loading</>}>
        <ProtectedRoute requireWallet requireAuth allowedAuth={[1, 3]}>
          <ProductListPage />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/admin/past-products",
    element: (
      <Suspense fallback={<>Loading</>}>
        <ProtectedRoute requireWallet>
          <PastProductListPage />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/admin/calendar",
    element: (
      <Suspense fallback={<>Loading</>}>
        <ProtectedRoute requireWallet>
          <CalendarPage />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/admin/users",
    element: (
      <Suspense fallback={<>Loading</>}>
        <ProtectedRoute requireWallet>
          <UsersPage />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/admin/users/detail/:id",
    element: (
      <Suspense fallback={<>Loading</>}>
        <ProtectedRoute requireWallet>
          <UserSeeDetailsPage />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/admin/organization-users",
    element: (
      <Suspense fallback={<>Loading</>}>
        <ProtectedRoute requireWallet>
          <OrganizationUsersPage />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/admin/organization-users/create",
    element: (
      <Suspense fallback={<>Loading</>}>
        <ProtectedRoute requireWallet>
          <CreateOrganizationUserPage />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/admin/products/create",
    element: (
      <Suspense fallback={<>Loading</>}>
        <ProtectedRoute requireWallet>
          <CreateProductPage />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/admin/products/view/:id",
    element: (
      <Suspense fallback={<>Loading</>}>
        <ProtectedRoute requireWallet>
          <ViewProductPage />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/admin/products/edit/:id",
    element: (
      <Suspense fallback={<>Loading</>}>
        <ProtectedRoute requireWallet>
          <EditProductPage />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/admin/funds",
    element: (
      <Suspense fallback={<>Loading</>}>
        <ProtectedRoute requireWallet>
          <Funds />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/admin/balances",
    element: (
      <Suspense fallback={<>Loading</>}>
        <ProtectedRoute requireWallet>
          <Balance />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/admin/commissions",
    element: (
      <Suspense fallback={<>Loading</>}>
        <ProtectedRoute requireWallet>
          {/* <AdminCommissions /> */}
          <AdminCommissionsPage />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/admin/turbo-funds",
    element: (
      <Suspense fallback={<>Loading</>}>
        <ProtectedRoute requireWallet>
          {/* <AdminCommissions /> */}
          <TurboFundsPage />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/admin/turbo-funds/create-expense",
    element: (
      <Suspense fallback={<>Loading</>}>
        <ProtectedRoute requireWallet>
          <CreateExpensePage />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/admin/turbo-funds/edit-expense/:id",
    element: (
      <Suspense fallback={<>Loading</>}>
        <ProtectedRoute requireWallet>
          <EditExpensePage />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/admin/bids",
    element: (
      <Suspense fallback={<>Loading</>}>
        <ProtectedRoute requireWallet>
          {/* <AdminCommissions /> */}
          <BidsPage />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/admin/bids/create",
    element: (
      <Suspense fallback={<>Loading</>}>
        <ProtectedRoute requireWallet>
          <CreatePackagePage />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/admin/bids/edit/:id",
    element: (
      <Suspense fallback={<>Loading</>}>
        <ProtectedRoute requireWallet>
          <EditPackagePage />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/app/affiliates",
    element: (
      <ProtectedRoute requireDashboardAccess requireWallet>
        <AffiliatesDashboardPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/app/profile",
    element: (
      <ProtectedRoute requireDashboardAccess requireWallet>
        <ProfileDashboardPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/logout",
    element: <Logout />,
  },
  {
    path: "*",
    element: <Navigate to="/" />,
  },
]);
