import { useState } from "react";
import { onSendFunds } from "common/services/onSendFunds";

export const useSendMatic = () => {
  const [success, setSuccess] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const resetSendFundsState = () => {
    setSuccess();
    setError();
  };
  const sendFunds = async ({ address, amount, confirmations }) => {
    if (!address) {
      setError("missing address");
      return;
    }
    if (!amount) {
      setError("missing amount");
      return;
    }
    setLoading(true);
    try {
      const transactionFunds = await onSendFunds({ address, amount, confirmations });
      let success = "";
      let error = "";
      if (!transactionFunds || transactionFunds.error) {
        error = transactionFunds ? transactionFunds.error : "Error sending matic";
      } else {
        success = "Sent matic succesfully";
      }
      setSuccess(success);
      setError(error);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return { success, error, loading, sendFunds, resetSendFundsState };
};
