import { useQuery } from "@tanstack/react-query";
import { useRolesService } from "modules/admin/services/Roles";

export const useFetchRoles = (queryKey = "roles") => {
  const { fetchRoles } = useRolesService();

  const queryResults = useQuery({
    queryKey: [queryKey],
    queryFn: () => fetchRoles(),
    select: (data) => data.data,
  });

  return { ...queryResults };
};
