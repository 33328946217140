import { useQuery } from "@tanstack/react-query";
import { onRequestRegisterUser } from "common/services";
import { useEffect, useState } from "react";
import { useWaitForTransactionReceipt } from "wagmi";
import { useRequestSignature } from "./useRequestSignature";

export const useRegisterUser = () => {
  const requestSignature = useRequestSignature();
  const [isEnabled, setIsEnabled] = useState(false);
  const [userData, setUserData] = useState({});
  const [signature, setSignature] = useState("");

  const { isError, isSuccess, isFetching, status, data, error } = useQuery({
    queryKey: [`registerUser`],
    retry: 5,
    retryDelay: 3000,
    refetchOnWindowFocus: false,
    enabled: isEnabled,
    queryFn: async () =>
      onRequestRegisterUser({
        signature,
        sponsorUsername: userData.sponsorUsername,
        userId: userData.userId,
        userSelectedBranch: userData.userSelectedBranch,
        name: userData.name,
        lastName: userData.lastName,
        username: userData.username,
        email: userData.email,
        country: userData.country,
        zip: userData.zip,
        phone: userData.phone,
        amountToPay: userData.amountToPay,
        underUsername: userData.underUsername,
        underUserBranch: userData.underUserBranch,
      }),
  });
  const {
    isError: isErrorTx,
    isSuccess: isSuccessTx,
    data: dataTx,
    isFetching: isFetchingTx,
    error: errorTx,
  } = useWaitForTransactionReceipt({
    queryKey: [`requestRegisterUserTx${data ? data.data : ""}`],
    hash: data ? data.data : "",
    retry: 2,
    retryDelay: 2000,
    enabled: isEnabled,
    confirmations: 2,
  });

  useEffect(() => {
    const onResult = async () => {
      if (!isFetching && !isFetchingTx && userData) {
        if (isError) {
          if (userData.onError) await userData.onError(data ? data.error : error);
          if (userData.onSettled) await userData.onSettled();
          setIsEnabled(false);
        } else if (isSuccess && data) {
          if (isErrorTx) {
            if (userData.onError) await userData.onError(dataTx ? dataTx.error : errorTx);
            if (userData.onSettled) await userData.onSettled();
            setIsEnabled(false);
          } else if (isSuccessTx && dataTx) {
            if (userData.onSuccess) await userData.onSuccess();
            if (userData.onSettled) await userData.onSettled();
            setIsEnabled(false);
          }
        }
      }
    };
    if (!isFetching) onResult();
  }, [
    isError,
    isSuccess,
    isFetching,
    status,
    data,
    isFetchingTx,
    userData,
    error,
    isErrorTx,
    isSuccessTx,
    dataTx,
    errorTx,
  ]);

  const registerUser = async ({ userData }) => {
    if (!userData) throw new Error("missing register user data");
    const signature = await requestSignature();
    setSignature(signature);
    setUserData(userData);
    setIsEnabled(true);
  };

  return {
    registerUser,
    isFetchingTx,
    isError,
    error,
    data,
    isErrorTx,
    isSuccessTx,
    dataTx,
    isFetchingTx,
  };
};
