import { SentryService } from "common/services/SentryErrorCapture";
import { useSignMessage } from "wagmi";

export const useRequestSignature = () => {
  const { signMessageAsync } = useSignMessage();
  const requestSignature = async () => {
    try {
      const [hours, minutes] = new Date().toUTCString().split(" ")[4].split(":");
      const signMessage = `${process.env.REACT_APP_SIGN_MESSAGE} ${hours}:${minutes}`;
      const signature = await signMessageAsync({ message: signMessage });
      return signature;
    } catch (error) {
      SentryService("useRequestSignature", "requestSignature", error);
      // console.log(error);
      return null;
    }
  };
  return requestSignature;
};
