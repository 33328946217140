import { zeroAddress } from "viem";

export const onGetFastStart = async ({
  address,
  contractFunctions,
  getLevelAddresses,
  getLevelSum,
}) => {
  await new Promise((resolve) => setTimeout(resolve, 50));
  const userInfo = await contractFunctions.fortuneGoat.fortuneGoatUserInfo(address);
  if (!userInfo || userInfo[0].id == zeroAddress) {
    return {
      levelsData: [],
      total: 0,
    };
  }
  console.debug("userInfo: ", userInfo);
  //check if user was registered a month ago
  // const isElegible = IsUserFastStartElegible({ registrationDate: userInfo[0].registrationDate });
  // if (!isElegible.status) {
  // 	return {
  // 		userRegistrationDate: isElegible.registrationDate,
  // 		fastStartEndDate: isElegible.fastStartEnd,
  // 		levelsData: [],
  // 		total: 0,
  // 	};
  // }

  await new Promise((resolve) => setTimeout(resolve, 50));
  const levelsPercentagesPromise = contractFunctions.qualifications.getFastStartLevels();
  const packagesListPromise = contractFunctions.fortuneGoat.fortuneGoatPackagesList();

  const [levelsPercentages, packagesList] = await Promise.all([
    levelsPercentagesPromise,
    packagesListPromise,
  ]);

  console.log("levelsPercentages: ", levelsPercentages);
  let addressArray = [address];
  const levelsData = [];
  for (const levelPercentage of levelsPercentages) {
    console.log("addressArray: ", addressArray);
    await new Promise((resolve) => setTimeout(resolve, 50));
    const { sum: levelSum, affiliates } = await getLevelSum({
      addresses: addressArray,
      contractFunctions,
      packagesList,
    });
    console.log("levelSum: ", levelSum);
    levelsData.push({
      addressList: affiliates,
      sum: levelSum,
      percentage: levelPercentage,
      total: (levelSum * (levelPercentage / 1e2)) / 100,
    });
    addressArray = await getLevelAddresses({ addresses: addressArray, contractFunctions });
    console.log("levelAddresses: ", addressArray);
  }

  // const firstLevelAddresses = await getLevelAddresses({
  // 	addresses: [address],
  // 	contractFunctions,
  // });

  // // console.debug("firstLevelAddresses:", firstLevelAddresses);
  // const firstLevelSum = await getLevelSum({
  // 	addresses: firstLevelAddresses,
  // 	contractFunctions,
  // 	packagesList,
  // });
  // // console.debug("firstLevelSum:", firstLevelSum);
  // const secondLevelAddresses = await getLevelAddresses({
  // 	addresses: firstLevelAddresses,
  // 	contractFunctions,
  // });
  // const secondLevelSum = await getLevelSum({
  // 	addresses: secondLevelAddresses,
  // 	contractFunctions,
  // 	packagesList,
  // });
  // const thirdLevelAddresses = await getLevelAddresses({
  // 	addresses: secondLevelAddresses,
  // 	contractFunctions,
  // });
  // const thirdLevelSum = await getLevelSum({
  // 	addresses: thirdLevelAddresses,
  // 	contractFunctions,
  // 	packagesList,
  // });
  // const firstLevelPercentage = 7;
  // const secondLevelPercentage = 4;
  // const thirdLevelPercentage = 4;
  const levelsTotal = levelsData.reduce((prev, curr) => {
    return (prev += curr.total);
  }, 0);
  console.log("levelsTotal: ", levelsTotal);
  const fastStartResult = {
    levelsData,
    total: levelsTotal,
  };
  // console.debug({ fastStartResult });
  return fastStartResult;
};
