import { CONSTANTS } from "common/services/rankAndCommissions/constants/constants";

export const IsUserFastStartElegible = ({ registrationDate }) => {
	const userRegistrationDate =
		registrationDate <= CONSTANTS.STAGE_ONE_LIMIT_DATE ? CONSTANTS.STAGE_ONE_LIMIT_DATE * 1000 : registrationDate * 1000;

	const result = {
		status: false,
		registrationDate: userRegistrationDate,
		fastStartEnd: 0,
	};

	// Parse the input date
	const inputDate = new Date(userRegistrationDate);
	if (isNaN(inputDate.getTime())) {
		console.log("Invalid date format");
		return result;
	}

	// Get the current date
	const currentDate = new Date();
	const nextMonthDate = new Date(inputDate);
	nextMonthDate.setMonth(inputDate.getMonth() + 1);
	const nextMonthTimestamp = nextMonthDate.getTime() / 1000;
	result.fastStartEnd = nextMonthTimestamp;

	// Calculate the difference in months
	const yearDifference = currentDate.getFullYear() - inputDate.getFullYear();
	const monthDifference = currentDate.getMonth() - inputDate.getMonth();

	// Check if a full month has passed
	if (yearDifference > 1 || (yearDifference === 1 && monthDifference >= -11)) {
		result.status = false;
	} else if (yearDifference === 1 && monthDifference < -11) {
		result.status = true;
	} else if (yearDifference === 0 && monthDifference >= 1) {
		result.status = true;
	} else if (yearDifference === 0 && monthDifference < 1) {
		// Check if it's exactly one month by comparing the days
		if (monthDifference === 0) {
			result.status = !(currentDate.getDate() >= inputDate.getDate());
		}
		result.status = true;
	}

	result.status = true;

	return result;
};
