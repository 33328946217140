import Select from 'react-select';
import { Input } from "./Input";
import countriesJSON from "../../assets/json/countryCode.json";

export const PhoneInput = ({setValue, errors, clearErrors, placeholder}) => {
    const handleChange = (e) =>{
        clearErrors()
        setValue("phoneInput", e.target.value)
    }
    const handleSelectChange = (e) => {
        clearErrors()
        setValue("prefix", e.value)
    }
    const objectCountryCode = countriesJSON.map((c)=>{
        return {
            value: c.PHONE_CODE,
            label: `${c.ESPAÑOL} - ${c.PHONE_CODE}`,
        }
    })
  return (
    <>
    <div className="flex flex-col items-center md:flex-row px-4 gap-x-2">
        <Select name='prefix' placeholder={"Area code"} onChange={(e)=>{handleSelectChange(e)}} options={objectCountryCode} className="w-full bg-light-gray rounded md:w-1/3"/>
        <Input name='phoneInput' onChange={(e)=>{handleChange(e)}} className="" type="text" placeholder={placeholder}/>
    </div>
    {errors.prefix && <span className="text-red-500">{errors.prefix.message}</span>}
    {errors.phoneInput && <span className="text-red-500">{errors.phoneInput.message}</span>}
    </>
  );
};
