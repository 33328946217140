import {
  faInstagram,
  faLinkedin,
  faFacebook,
  faTiktok,
  faYoutube,
  faXTwitter,
  faTelegram,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Logo } from "@common/components";
import { Link } from "react-router-dom";

export const FooterMin = () => {
  return (
    <div className="flex justify-center items-center p-10">
      <div className="container px-4">
        <hr className="my-10 border-gray-100" />
        <div className="flex justify-between">
          <div className="items-center w-24 hidden md:flex">
            <Logo variant="dark-isotype" />
          </div>
          <div className="flex flex-wrap w-full justify-center md:justify-end md:items-center">
            <ul className="text-dark flex flex-col md:flex-row items-center">
              <li className="px-2 font-semibold cursor-pointer">
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li className="px-2 font-semibold cursor-pointer">
                <Link to="/terms-and-conditions">Terms & Conditions</Link>
              </li>
              <li className="px-2 font-semibold cursor-pointer">
                <Link to="/members-agreement">Members agreement</Link>
              </li>
              <li className="px-2 font-semibold cursor-pointer">Cookie Settings</li>
            </ul>
            <div className="flex justify-center p-6">
              <div className="mx-2">
                <a
                  href="https://www.facebook.com/profile.php?id=61555401401976&mibextid=LQQJ4do"
                  target="#blank"
                >
                  <FontAwesomeIcon
                    icon={faFacebook}
                    className="text-dark cursor-pointer"
                    size="xl"
                  />
                </a>
              </div>
              <div className="mx-2">
                <a href="https://www.tiktok.com/@fortune_goat?_t=8j4EoROwnh7&_r=1" target="#blank">
                  <FontAwesomeIcon icon={faTiktok} className="text-dark cursor-pointer" size="xl" />
                </a>
              </div>
              <div className="mx-2">
                <a href="https://youtube.com/@Fortune_GOAT?si=hLmOxQtvr8rPUBty" target="#blank">
                  <FontAwesomeIcon
                    icon={faYoutube}
                    className="text-dark cursor-pointer"
                    size="xl"
                  />
                </a>
              </div>
              <div className="mx-2">
                <a href="https://www.instagram.com/fortune.goat/?next=%2F" target="#blank">
                  <FontAwesomeIcon
                    icon={faInstagram}
                    className="text-dark cursor-pointer"
                    size="xl"
                  />
                </a>
              </div>
              <div className="mx-2">
                <a href="https://twitter.com/fortune_goat" target="#blank">
                  <FontAwesomeIcon
                    icon={faXTwitter}
                    className="text-dark cursor-pointer"
                    size="xl"
                  />
                </a>
              </div>
              <div className="mx-2">
                <a href="https://t.me/+HLzs2gEASH9hYmEx" target="#blank">
                  <FontAwesomeIcon
                    icon={faTelegram}
                    className="text-dark cursor-pointer"
                    size="xl"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
