import { useQuery } from "@tanstack/react-query";
import { useOrganizationUsersService } from "modules/admin/services/OrganizationUsers";
import { useState } from "react";

export const useFetchOrganizationUsers = (queryKey = "organizationUsers", initialParams = {}) => {
  const [params, setParams] = useState(initialParams);
  /* 
    page: "",
    limit: "",
    search: "",
   */
  const { fetchOrganizationUsers } = useOrganizationUsersService();

  const queryResults = useQuery({
    queryKey: [queryKey, params],
    queryFn: () => fetchOrganizationUsers(params),
    select: (data) => data.data,
  });

  return { ...queryResults, setParams, params };
};
