import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import { Button } from "common/components";
import { Select } from "common/components";
import { Input } from "common/components";
import CustomEditor from "common/components/CustomEditor";
import { TextArea } from "common/components/TextArea";
import { dateToTimestamp } from "common/helpers/dateToTimestamp";
import { useCreateProduct } from "common/hooks/useCreateProduct";
import { convertFromRaw, convertToRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import AdminLayout from "layouts/AdminLayout";
import { onGetCategoriesList } from "modules/penny-auction/services/onGetCategoriesList";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAccount } from "wagmi";

export const CreateProductPage = () => {
  const navigate = useNavigate();
  const { address } = useAccount();
  const { requestCreateProduct } = useCreateProduct();
  const { isFetching: isLoadingCategories, data: categoriesList } = useQuery({
    queryKey: [`categoriesList`],
    queryFn: async () => await onGetCategoriesList(),
    staleTime: 360000,
    refetchOnWindowFocus: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm();
  const [image, setImage] = useState([]);
  const [images, setImages] = useState([]);
  const onSubmit = async (data) => {
    const rawContentState = convertToRaw(data.editorContent.getCurrentContent());
    const contentState = convertFromRaw(rawContentState);
    const htmlContent = stateToHTML(contentState);
    setIsSubmitting(true);
    const timeInterval =
      +(data.secondsDuration || 0) +
      +(data.minutesDuration * 60 || 0) +
      +(data.hoursDuration * 3600 || 0) +
      +(data.daysDuration * 24 * 3600 || 0);

    let parsedInputs = {
      name: data.name || "",
      description: htmlContent || "",
      category: data.category || "",
      maxBidders: data.maxBidders || "",
      autoBuyPrice: data.autoBuyPrice || "",
      startDate: dateToTimestamp(data.startDate) || "",
      timeInterval: timeInterval || "",
      auctionPrice: data.auctionPrice || "",
      priceIncrements: data.priceIncrements || "",
      addTimeOnBid: data.addTimeOnBid || "",
      bidsPerClick: data.bidsPerClick || 1,
      discount: data.discount || 0,
      image: [...Array.from(data.image), ...Array.from(data.images)],

      // rPrice: data.rPrice || "",
      message: data.message || "",
      timeZone: data.timeZone || "",
    };

    console.log({ parsedInputs });

    await requestCreateProduct({
      productData: {
        userId: address,
        ...parsedInputs,
        onSuccess: async () => {
          toast.success("Product created successfully.");
          navigate("/admin/products");
        },
        onError: async (error) => {
          setIsSubmitting(false);
          toast.error(`Failed to create product. ${error}`);
        },
      },
    });
  };

  const imageFileList = watch("image");

  useEffect(() => {
    if (imageFileList && imageFileList.length > 0) {
      const newImages = [];
      Array.from(imageFileList).forEach((file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          newImages.push(event.target.result);
          if (newImages.length === imageFileList.length) {
            setImage(newImages);
          }
        };
        reader.readAsDataURL(file);
      });
    } else {
      setImage([]);
    }
  }, [imageFileList]);

  const imagesFileList = watch("images");

  useEffect(() => {
    if (imagesFileList && imagesFileList.length > 0) {
      const newImages = [];
      Array.from(imagesFileList).forEach((file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          newImages.push(event.target.result);
          if (newImages.length === imagesFileList.length) {
            setImages(newImages);
          }
        };
        reader.readAsDataURL(file);
      });
    } else {
      setImages([]);
    }
  }, [imagesFileList]);

  const categoriesOptions = categoriesList ? categoriesList.map((cat) => cat.name) : [];

  return (
    <AdminLayout>
      <div className="w-full bg-white">
        <div className="w-full px-8">
          <h1 className="text-2xl px-4 pt-8">New Product</h1>
          <form className="grid grid-cols-3 mt-4" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-span-3 md:col-span-2 px-4">
              <div className="mb-2">
                <div className="flex gap-4 items-center">
                  <label className="text-lg font-semibold" htmlFor="name">
                    Name
                  </label>
                  <div className="flex" title="Name of the product">
                    <FontAwesomeIcon icon={faCircleInfo} color="#4C4C4C" />
                  </div>
                </div>
                <Input
                  type="text"
                  {...register("name", { required: "This field is required." })}
                  id="name"
                />
                {errors.name && <span className="text-red-500">{errors.name.message}</span>}
              </div>
              <div className="mb-2">
                <div className="flex gap-4 items-center">
                  <label className="text-lg font-semibold" htmlFor="description">
                    Description
                  </label>
                  <div className="flex" title="Description of the product">
                    <FontAwesomeIcon icon={faCircleInfo} color="#4C4C4C" />
                  </div>
                </div>
                <div>
                  <Controller
                    name="editorContent"
                    control={control}
                    render={({ field }) => <CustomEditor {...field} value={field.value} />}
                  />
                </div>
                {/* <TextArea {...register("description", { required: "This field is required." })} /> */}
                {errors.description && (
                  <span className="text-red-500">{errors.description.message}</span>
                )}
              </div>
              <div className="mb-2">
                <div className="flex gap-4 items-center">
                  <label className="text-lg font-semibold" htmlFor="category">
                    Category
                  </label>
                  <div className="flex" title="Category of the product">
                    <FontAwesomeIcon icon={faCircleInfo} color="#4C4C4C" />
                  </div>
                </div>
                {isLoadingCategories ? (
                  <p>Loading categories list...</p>
                ) : (
                  Array.isArray(categoriesList) && (
                    <Select
                      id="category"
                      options={categoriesOptions}
                      {...register("category", { required: "This field is required." })}
                    />
                  )
                )}
                {errors.category && <span className="text-red-500">{errors.category.message}</span>}
              </div>
              <div className="mb-2">
                <div className="flex gap-4 items-center">
                  <label className="text-lg font-semibold" htmlFor="maxBidders">
                    No new bidders price
                  </label>
                  <div
                    className="flex"
                    title="Price at which no new bidders are accepted, only current bidders continue."
                  >
                    <FontAwesomeIcon icon={faCircleInfo} color="#4C4C4C" />
                  </div>
                </div>
                <Input
                  type="number"
                  {...register("maxBidders", { required: "This field is required." })}
                  id="maxBidders"
                  step="0.01"
                />
              </div>
              <div className="mb-2">
                <div className="flex gap-4 items-center">
                  <label className="text-lg font-semibold" htmlFor="autoBuyPrice">
                    Retail Price
                  </label>
                  <div
                    className="flex"
                    title="Price at which anyone can buy the item even if they did not participate in the bids. The person who buys the product will get their bids refunded. This price is the reference when awarding the prize.
"
                  >
                    <FontAwesomeIcon icon={faCircleInfo} color="#4C4C4C" />
                  </div>
                </div>
                <Input
                  type="number"
                  {...register("autoBuyPrice", { required: "This field is required." })}
                  id="autoBuyPrice"
                  step="0.01"
                />
                {errors.autoBuyPrice && (
                  <span className="text-red-500">{errors.autoBuyPrice.message}</span>
                )}
              </div>
              <div className="mb-2">
                <div className="flex gap-4 items-center">
                  <label className="text-lg font-semibold" htmlFor="startDate">
                    Cooming soon time
                  </label>
                  <div className="flex" title="The day the penny auction starts.">
                    <FontAwesomeIcon icon={faCircleInfo} color="#4C4C4C" />
                  </div>
                </div>
                <Input
                  type="datetime-local"
                  {...register("startDate", { required: "This field is required." })}
                  id="startDate"
                />
                {errors.startDate && (
                  <span className="text-red-500">{errors.startDate.message}</span>
                )}
              </div>
              <div className="mb-2">
                <div className="flex gap-4 items-center">
                  <label className="text-lg font-semibold" htmlFor="timeZone">
                    Time Zone
                  </label>
                  <div className="flex" title="Time Zone, initially it will be Costa Rica's.">
                    <FontAwesomeIcon icon={faCircleInfo} color="#4C4C4C" />
                  </div>
                </div>
                <Select
                  id="timeZone"
                  options={["UTC", "GMT", "CET", "EST", "PST"]}
                  {...register("timeZone")}
                ></Select>
                {errors.timeZone && <span className="text-red-500">{errors.timeZone.message}</span>}
              </div>
              <div className="mb-2">
                <div className="flex gap-4 items-center">
                  <label className="text-lg font-semibold" htmlFor="timeInterval">
                    Starting date and time
                  </label>
                  <div className="flex" title="The date and time the penny auction starts.">
                    <FontAwesomeIcon icon={faCircleInfo} color="#4C4C4C" />
                  </div>
                </div>
                <div className="grid grid-cols-4 gap-1">
                  <Input
                    label="days"
                    type="number"
                    {...register("daysDuration", { required: "This field is required." })}
                    id="daysDuration"
                    defaultValue={0}
                    style={{ flex: "1 1 25%" }}
                  />
                  <Input
                    label="hours"
                    type="number"
                    {...register("hoursDuration", { required: "This field is required." })}
                    id="hoursDuration"
                    defaultValue={0}
                    style={{ flex: "1 1 25%" }}
                  />
                  <Input
                    label="minutes"
                    type="number"
                    {...register("minutesDuration", { required: "This field is required." })}
                    id="minutesDuration"
                    defaultValue={0}
                    style={{ flex: "1 1 25%" }}
                  />
                  <Input
                    label="seconds"
                    type="number"
                    {...register("secondsDuration", { required: "This field is required." })}
                    id="secondsDuration"
                    defaultValue={0}
                    style={{ flex: "1 1 25%" }}
                  />
                </div>
                {errors.timeInterval && (
                  <>
                    <span className="text-red-500">{errors.daysDuration.message}</span>
                    <span className="text-red-500">{errors.hoursDuration.message}</span>
                    <span className="text-red-500">{errors.minutesDuration.message}</span>
                    <span className="text-red-500">{errors.secondsDuration.message}</span>
                  </>
                )}
              </div>
              <div className="mb-2">
                <div className="flex gap-4 items-center">
                  <label className="text-lg font-semibold" htmlFor="message">
                    Add Message
                  </label>
                  <div
                    className="flex"
                    title="Also add the option for No New Bidders (it must appear automatically) when that price is reached, this message should automatically appear. Other messages saying Founder Only and VIP Shoppers Only."
                  >
                    <FontAwesomeIcon icon={faCircleInfo} color="#4C4C4C" />
                  </div>
                </div>
                <Input type="text" {...register("message")} id="message" />
                {errors.message && <span className="text-red-500">{errors.message.message}</span>}
              </div>
              <div className="mb-2">
                <div className="flex gap-4 items-center">
                  <label className="text-lg font-semibold" htmlFor="auctionPrice">
                    Auction Price
                  </label>
                  <div
                    className="flex"
                    title="This is the price that accumulates all the increments from each bid. It should always start at 0."
                  >
                    <FontAwesomeIcon icon={faCircleInfo} color="#4C4C4C" />
                  </div>
                </div>
                <Input
                  type="number"
                  {...register("auctionPrice", { required: "This field is required." })}
                  id="auctionPrice"
                  step="0.01"
                />
                {errors.auctionPrice && (
                  <span className="text-red-500">{errors.auctionPrice.message}</span>
                )}
              </div>
              <div className="mb-2">
                <div className="flex gap-4 items-center">
                  <label className="text-lg font-semibold" htmlFor="priceIncrements">
                    Price Increments
                  </label>
                  <div
                    className="flex"
                    title="The increment of the bidding price each time a person places a bid."
                  >
                    <FontAwesomeIcon icon={faCircleInfo} color="#4C4C4C" />
                  </div>
                </div>
                <Input
                  type="number"
                  {...register("priceIncrements", { required: "This field is required." })}
                  id="priceIncrements"
                  step="0.001"
                />
                {errors.priceIncrements && (
                  <span className="text-red-500">{errors.priceIncrements.message}</span>
                )}
              </div>
              <div className="mb-2">
                <div className="flex gap-4 items-center">
                  <label className="text-lg font-semibold" htmlFor="addTimeOnBid">
                    Reset time after bid <small>(seconds)</small>
                  </label>
                  <div
                    className="flex"
                    title="The seconds to which the clock resets after someone places a bid (click)."
                  >
                    <FontAwesomeIcon icon={faCircleInfo} color="#4C4C4C" />
                  </div>
                </div>
                <Input
                  type="number"
                  {...register("addTimeOnBid", { required: "This field is required." })}
                  id="addTimeOnBid"
                />
                {errors.addTimeOnBid && (
                  <span className="text-red-500">{errors.addTimeOnBid.message}</span>
                )}
              </div>
              <div className="mb-2">
                <div className="flex gap-4 items-center">
                  <label className="text-lg font-semibold" htmlFor="bidsPerClick">
                    Bids per click
                  </label>
                  <div
                    className="flex"
                    title="How many bids are deducted from the person who clicked in the auction, initially it will always be 1."
                  >
                    <FontAwesomeIcon icon={faCircleInfo} color="#4C4C4C" />
                  </div>
                </div>
                <Input
                  type="number"
                  {...register("bidsPerClick", { required: "This field is required." })}
                  id="bidsPerClick"
                  step="1"
                />
                {errors.bidsPerClick && (
                  <span className="text-red-500">{errors.bidsPerClick.message}</span>
                )}
              </div>
              <div className="mb-2">
                <div className="flex gap-4 items-center">
                  <label className="text-lg font-semibold" htmlFor="bidsPerClick">
                    Discount percentage
                  </label>
                  <div className="flex" title="Discount percentage on price when auction ends">
                    <FontAwesomeIcon icon={faCircleInfo} color="#4C4C4C" />
                  </div>
                </div>
                <Input
                  type="number"
                  {...register("discount", { required: "This field is required." })}
                  id="discount"
                  step="0.01"
                />
                {errors.discount && <span className="text-red-500">{errors.discount.message}</span>}
              </div>
            </div>
            <div className="col-span-3 md:col-span-2 px-4">
              <div className="mb-2">
                <h2 className="text-lg font-semibold">Images</h2>
                <label className="text-lg font-semibold" htmlFor="image">
                  Add Main Image
                </label>
                <div className="flex w-full">
                  {image.length > 0 && (
                    <img
                      src={image[0]}
                      alt={`Imagen cargada 1`}
                      style={{ width: "150px", margin: "10px" }}
                    />
                  )}
                </div>
                <div className="flex w-full">
                  {image.length === 0 && (
                    <img
                      src="https://via.placeholder.com/150"
                      alt="product"
                      style={{ width: "150px" }}
                    />
                  )}
                </div>
                <Input
                  type="file"
                  {...register("image", { required: "This field is required." })}
                  id="image"
                />
                {errors.image && <span className="text-red-500">{errors.image.message}</span>}
                <label className="text-lg font-semibold" htmlFor="image">
                  Add Secondary Images
                </label>
                <div className="flex w-full">
                  {images.length > 0 &&
                    images.map((image, index) => (
                      <img
                        key={index}
                        src={image}
                        alt={`Imagen cargada ${index + 1}`}
                        style={{ width: "150px", margin: "10px" }}
                      />
                    ))}
                </div>
                <div className="flex w-full">
                  {images.length === 0 && (
                    <img
                      src="https://via.placeholder.com/150"
                      alt="product"
                      style={{ width: "150px" }}
                    />
                  )}
                </div>
                <Input
                  type="file"
                  multiple
                  {...register("images", { required: "This field is required." })}
                  id="image"
                />
                {errors.images && <span className="text-red-500">{errors.images.message}</span>}
              </div>
            </div>
            <div className="col-span-3 md:col-span-2 px-4">
              <div className="my-4">
                <Button
                  className="mr-2"
                  label="Back"
                  type="button"
                  onClick={() => window.history.back()}
                />
                <Button
                  disabled={isSubmitting}
                  label={isSubmitting ? "Creating product..." : "Create new product"}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </AdminLayout>
  );
};
