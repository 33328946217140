import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useWaitForTransactionReceipt } from "wagmi";
import { useRequestSignature } from "./useRequestSignature";
import { onCreateCalendarCategory } from "common/services/onCreateCalendarCategory";

export const useCreateCalendarCategory = () => {
  const requestSignature = useRequestSignature();
  const [isEnabled, setIsEnabled] = useState(false);
  const [calendarCategoryData, setCalendarCategoryData] = useState({});
  const [signature, setSignature] = useState("");

  const { isError, isSuccess, isFetching, status, data, error } = useQuery({
    queryKey: [`createCalendarCategory`],
    retry: 1,
    retryDelay: 3000,
    refetchOnWindowFocus: false,
    enabled: isEnabled,
    queryFn: () =>
      onCreateCalendarCategory({
        signature,
        data: { ...calendarCategoryData },
      }),
  });
  const {
    isError: isErrorTx,
    isSuccess: isSuccessTx,
    data: dataTx,
    isFetching: isFetchingTx,
    error: errorTx,
  } = useWaitForTransactionReceipt({
    queryKey: [`createCalendarCategory${data ? data.data : ""}`],
    hash: data ? data.data : "",
    retry: 3,
    retryDelay: 3000,
    refetchOnWindowFocus: false,
    enabled: isEnabled,
    confirmations: 2,
  });

  useEffect(() => {
    const onResult = async () => {
      if (!isFetching && !isFetchingTx && calendarCategoryData) {
        if (isError) {
          if (calendarCategoryData.onError)
            await calendarCategoryData.onError(data ? data.error : error);
          if (calendarCategoryData.onSettled) await calendarCategoryData.onSettled();
          setIsEnabled(false);
        } else if (isSuccess && data) {
          if (isErrorTx) {
            if (calendarCategoryData.onError)
              await calendarCategoryData.onError(dataTx ? dataTx.error : errorTx);
            if (calendarCategoryData.onSettled) await calendarCategoryData.onSettled();
            setIsEnabled(false);
          } else if (isSuccessTx && dataTx) {
            if (calendarCategoryData.onSuccess) await calendarCategoryData.onSuccess();
            if (calendarCategoryData.onSettled) await calendarCategoryData.onSettled();
            setIsEnabled(false);
          }
        }
      }
    };
    if (!isFetching) onResult();
  }, [
    isError,
    isSuccess,
    isFetching,
    status,
    data,
    isFetchingTx,
    calendarCategoryData,
    error,
    isErrorTx,
    isSuccessTx,
    dataTx,
    errorTx,
  ]);

  const requestCreateCalendarCategory = async ({ calendarCategoryData }) => {
    if (!calendarCategoryData) throw new Error("missing payment request side data");
    const signature = await requestSignature();
    setSignature(signature);
    setCalendarCategoryData(calendarCategoryData);
    setIsEnabled(true);
  };

  return {
    requestCreateCalendarCategory,
    isFetchingTx,
    isError,
    error,
    data,
    isErrorTx,
    isSuccessTx,
    dataTx,
    isFetchingTx,
  };
};
