import { getCommissions } from "../onGetCommissions";
import { getRank } from "../onGetRank";

export const usersCommission = async ({
	addressToGet,
	contractFunctions,
	getBinaryCommission,
	getUnilevelCommission,
}) => {
	const result = {
		binary: 0,
		unilevel: 0,
	};
	if (!addressToGet) return result;
	let addressRank;
	try {
		const onGetRank = getRank({ contractFunctions });
		addressRank = await onGetRank({
			address: addressToGet,
		});
	} catch (error) {
		return result;
	}
	if (!addressRank) return result;
	const onGetCommissions = getCommissions({
		getBinaryCommission,
		getUnilevelCommission,
	});
	const addressCommissions = await onGetCommissions({
		userRank: addressRank,
	});
	console.debug(addressToGet, addressRank, addressCommissions);
	result.binary = addressCommissions && addressCommissions.binaryCommission ? addressCommissions.binaryCommission.total : 0;
	result.unilevel = addressCommissions && addressCommissions.unilevelCommission ? addressCommissions.unilevelCommission.total : 0;

	return result;
};
