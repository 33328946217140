import { useAccount, useBalance } from "wagmi";

export const useHasEnoughFunds = (amountNeeded) => {
    const { isConnected, address } = useAccount();
    const { data } = useBalance({ address: isConnected ? address : undefined });

    const hasEnoughFunds = data && Number(data?.value) > amountNeeded;

    return {
        hasEnoughFunds,
        founds: data?.value,
    };
};
