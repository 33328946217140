import { useAccount } from "wagmi";
import { torusPlugin } from "../../../Web3AuthConnectorInstance";
import { useHasEnoughFunds } from "@common/hooks/useHasEnoughFunds";
import { SentryService } from "common/services/SentryErrorCapture";

export const JoinUsStep3 = () => {
    const { isConnected, address } = useAccount();
    const torusRampOn = async () => {
        if (!address || !isConnected) {
            return;
        }
        if (!torusPlugin || !torusPlugin.torusWalletInstance.isInitialized) {
            return;
        }
        try {
            await torusPlugin.initiateTopup("transak", {
                selectedAddress: address,
                selectedCurrency: "USD", // Fiat currency
                fiatValue: 100, // Fiat Value
                selectedCryptoCurrency: "USDT", // Cryptocurreny `SOL`, `MATIC` etc.
                chainNetwork: "matic", // Blockchain network
            });
        } catch (error) {
            SentryService("JoinUsStep3","torusRampOn",error)
            // console.log({ error });
        }
    };

    const { hasEnoughFunds } = useHasEnoughFunds(0.0015);

    return (
        <div className="p-6">
            <h2 className="text-2xl text-dark mb-6">
                Step 3, complete payment.
            </h2>
            <h3 className="text-lg text-dark font-semibold uppercase pb-2">
                Summary
            </h3>
            {/* <div>
                <p className="text-dark">
                    <span className="font-semibold">Selected package:</span>{" "}
                    {
                        packages.find((pack) => pack.value === watch("package"))
                            ?.title
                    }
                </p>
                <p className="text-dark">
                    <span className="font-semibold">Price:</span>{" "}
                    {
                        packages.find((pack) => pack.value === watch("package"))
                            ?.price
                    }
                </p>
                <img
                    src={
                        packages.find((pack) => pack.value === watch("package"))
                            ?.image
                    }
                    className="m-4"
                    alt="package"
                />
            </div> */}
            {!hasEnoughFunds && (
                <div className="my-2">
                    <p className="text-red-500 font-semibold pb-2">
                        Your account has insufficient funds to complete the
                        transaction.
                    </p>
                    <div>
                        <p>Please add funds to your account.</p>
                    </div>
                </div>
            )}
            {torusPlugin && (
                <button
                    type="button"
                    className="text-white font-semibold py-2 px-10 text-lg bg-dark-blue"
                    onClick={() => {
                        torusRampOn();
                    }}
                >
                    Get funds
                </button>
            )}
            {/* <div className="grid grid-cols-2 gap-4">
                <div className="col-span-2">
                    <Input
                        label="Cardholder name"
                        id="cardholder_name"
                        type="text"
                        placeholder="Cardholder name"
                        {...register("cardholderName", {
                            required: "This field is required",
                        })}
                        onBlur={() => trigger("cardholderName")}
                    />
                    {errors.cardholderName && (
                        <span className="text-red-500">
                            {errors.cardholderName.message}
                        </span>
                    )}
                </div>
                <div className="col-span-2">
                    <Input
                        label="Card number"
                        id="card_number"
                        type="text"
                        placeholder="Card number"
                        {...register("cardNumber", {
                            required: "This field is required",
                        })}
                        onBlur={() => trigger("cardNumber")}
                    />
                    {errors.cardNumber && (
                        <span className="text-red-500">
                            {errors.cardNumber.message}
                        </span>
                    )}
                </div>
                <div className="col-span-2 md:col-span-1">
                    <Input
                        label="Expiration date"
                        id="expiration_date"
                        type="text"
                        placeholder="Expiration date"
                        {...register("expirationDate", {
                            required: "This field is required",
                        })}
                        onBlur={() => trigger("expirationDate")}
                    />
                    {errors.expirationDate && (
                        <span className="text-red-500">
                            {errors.expirationDate.message}
                        </span>
                    )}
                </div>
                <div className="col-span-2 md:col-span-1">
                    <Input
                        label="Security code"
                        id="security_code"
                        type="password"
                        placeholder="Security code"
                        {...register("securityCode", {
                            required: "This field is required",
                        })}
                        onBlur={() => trigger("securityCode")}
                    />
                    {errors.securityCode && (
                        <span className="text-red-500">
                            {errors.securityCode.message}
                        </span>
                    )}
                </div>
                <hr className="col-span-2" />
            </div>
            <div className="grid grid-cols-5 gap-4 mt-4">
                <h2 className="col-span-5 text-dark font-semibold uppercase pb-2">
                    Billing information
                </h2>
                <div className="col-span-5 md:col-span-1">
                    <Select
                        label="Title"
                        id="billing_title"
                        options={["Mr.", "Mrs.", "Ms."]}
                        {...register("billingTitle", {
                            required: "This field is required",
                        })}
                        onBlur={() => trigger("billingTitle")}
                    />
                    {errors.billingTitle && (
                        <span className="text-red-500">
                            {errors.billingTitle.message}
                        </span>
                    )}
                </div>
                <div className="col-span-5 md:col-span-2">
                    <Input
                        label="Name"
                        id="billing_name"
                        type="text"
                        placeholder="Name"
                        {...register("billingName", {
                            required: "This field is required",
                        })}
                        onBlur={() => trigger("billingName")}
                    />
                    {errors.billingName && (
                        <span className="text-red-500">
                            {errors.billingName.message}
                        </span>
                    )}
                </div>
                <div className="col-span-5 md:col-span-2">
                    <Input
                        label="Last name"
                        id="billing_last_name"
                        type="text"
                        placeholder="Last name"
                        {...register("billingLastName", {
                            required: "This field is required",
                        })}
                        onBlur={() => trigger("billingLastName")}
                    />
                    {errors.billingLastName && (
                        <span className="text-red-500">
                            {errors.billingLastName.message}
                        </span>
                    )}
                </div>
                <div className="col-span-5">
                    <Input
                        label="Billing address"
                        id="billing_address"
                        type="text"
                        placeholder="Billing address"
                        {...register("billingAddress", {
                            required: "This field is required",
                        })}
                        onBlur={() => trigger("billingAddress")}
                    />
                    {errors.billingAddress && (
                        <span className="text-red-500">
                            {errors.billingAddress.message}
                        </span>
                    )}
                </div>
                <div className="col-span-5 md:col-span-3">
                    <Select
                        label="Country"
                        id="billing_country"
                        options={["United States", "Canada"]}
                        {...register("billingCountry", {
                            required: "This field is required",
                        })}
                        onBlur={() => trigger("billingCountry")}
                    />
                    {errors.billingCountry && (
                        <span className="text-red-500">
                            {errors.billingCountry.message}
                        </span>
                    )}
                </div>
                <div className="col-span-5 md:col-span-2">
                    <Input
                        label="Zip code"
                        id="billing_zip_code"
                        type="text"
                        placeholder="Zip code"
                        {...register("billingZip", {
                            required: "This field is required",
                        })}
                        onBlur={() => trigger("billingZip")}
                    />
                    {errors.billingZip && (
                        <span className="text-red-500">
                            {errors.billingZip.message}
                        </span>
                    )}
                </div>
            </div> */}
        </div>
    );
};
