export const handleItemsResponse = ({ data, setItems, setPagination, setServerTime }) => {
  const itemsData = JSON.parse(data);
  // console.debug("### handleItemsResponse ###", { itemsData });
  if (itemsData) {
    const currentTime = new Date().getTime();
    const offset = currentTime- itemsData.serverTime;
    setItems(
      itemsData.items.map((item) => ({
        ...item,
        isActive:
          item.status == 1 &&
          Boolean(currentTime + offset > item.startDate && currentTime + offset <= item.endDate),
      }))
    );
    setPagination(itemsData.pagination);
    setServerTime({ serverTime: itemsData.serverTime, currentTime, offset });
  }
};

export const handleItemResponse = ({ data, setItem }) => {
  const itemsData = JSON.parse(data);
  const item = itemsData.items[0];

  item.lastBid = item && item.bids.length > 0 ? item.bids.at(-1) : null;
  // console.debug("handleItemResponse", { itemsData });
  if (itemsData && itemsData.items) {
    setItem(item);
  }
};
