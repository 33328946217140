import axios from "axios";
import { SentryService } from "./SentryErrorCapture";

export const onRequestTransactionFunds = async ({ address }) => {
  if (!address) {
    throw new Error("Address is required");
  }
  try {
    const depositMaticResponse = await axios.post(
      `${process.env.REACT_APP_AWS_API_URL}/fund`,
      {
        address,
      }
    );
    if (depositMaticResponse) {
      return depositMaticResponse.data;
    }
  } catch (error) {
    SentryService("onRequestTransactionFunds", "onRequestTransactionFunds", error)
    throw new Error(error.response ? error.response.data.error : error);
  }
};
