// import { fortuneGoatAPI } from "config/apis/FortuneGoatAPIs";
import { SentryService } from "./SentryErrorCapture";
import axios from "axios";

export const onUpdateUserInfo = async ({ data, signature }) => {
  try {
    const updateUserResponse = await axios.post(`${process.env.REACT_APP_AWS_API_URL}/contracts/fg/updateUser`, {
      ...data,
      signature,
    });
    if (updateUserResponse) {
      return updateUserResponse.data;
    }
  } catch (error) {
    SentryService("onUpdateUserInfo", "onUpdateUserInfo", error);
    // console.debug(error);
    throw new Error(
      error.response
        ? error.response.data.error.reason ||
          error.response.data.error.shortMessage ||
          error.response.data.error
        : error
    );
  }
};
