import { forwardRef } from "react";
import cs from "classnames";

export const Input = forwardRef(
    (
        { label, id, className, ...rest },
        ref
    ) => {
        return (
            <div className="flex flex-col w-full">
                {id && (
                    <label
                        htmlFor={id}
                        className="text-dark font-semibold pb-2"
                    >
                        {label}
                    </label>
                )}
                <input
                    id={id}
                    ref={ref}
                    {...rest}
                    className={cs("px-4 py-3 bg-light-gray rounded", className)}
                />
            </div>
        );
    }
);
