export const onGetCommissions = async ({
  userRank,
  getBinaryCommission,
  getUnilevelCommission,
}) => {
  const binaryCommission = await getBinaryCommission(userRank);
  console.log("binaryCommission: ", binaryCommission);
  const unilevelCommission = await getUnilevelCommission(userRank);
  console.log("unilevelCommission: ", unilevelCommission);
  return {
    binaryCommission,
    unilevelCommission,
  };
};
