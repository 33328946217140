export const pennyAuctionConstants = {
  SORT_TYPES: {
    "Best sellers": 0,
    "A-Z": 1,
    "Z-A": 2,
    "Price-Low-High": 3,
    "Price-High-Low": 4,
    "Oldest-Newest": 5,
    "Newest-oldest": 6,
    "Bids-Low-High": 7,
    "Bids-High-Low": 8,
  },
};
