import { CONSTANTS } from "common/services/rankAndCommissions/constants/constants";

export const getUserInfo = async (address, contractFunctions) => {
  try {
    const uVolume = await contractFunctions.unilevel.unilevelUVolume(address);

    const bVolumeLeft = await contractFunctions.binary.binaryVolume(address, 1);
    await new Promise((resolve) => setTimeout(resolve, 50));
    const bVolumeRight = await contractFunctions.binary.binaryVolume(address, 2);
    const cvs = await contractFunctions.userPoints.getUserTotalPoints({ address });
    await new Promise((resolve) => setTimeout(resolve, 50));
    const userHasSponsored = await contractFunctions.binary.binaryUsersSponsoredBy(address);

    const userPackage = await contractFunctions.fortuneGoat.fortuneGoatUserPackage(address);
    await new Promise((resolve) => setTimeout(resolve, 50));
    const packagesList = await contractFunctions.fortuneGoat.fortuneGoatPackagesList();
    const packagesDecimals = await contractFunctions.fortuneGoat.fortuneGoatPackagesDecimals();

    await new Promise((resolve) => setTimeout(resolve, 50));
    const leadershipsListResponse =
      await contractFunctions.qualifications.qualificationsLeadership();

    const totalUserPoints = await contractFunctions.userPoints.getUserTotalPoints({ address });
    await new Promise((resolve) => setTimeout(resolve, 50));
    const membersipInfo = await contractFunctions.membership.membershipMap({ id: address });
    const userQV = await contractFunctions.userPoints.getUserQVPoints({ address });
    await new Promise((resolve) => setTimeout(resolve, 50));
    const userInfo = await contractFunctions.fortuneGoat.fortuneGoatUserInfo(address);

    // const uVolumePromise = contractFunctions.unilevel.unilevelUVolume(address);

    // const bVolumeLeftPromise = contractFunctions.binary.binaryVolume(address, 1);
    // const bVolumeRightPromise = contractFunctions.binary.binaryVolume(address, 2);
    // const cvsPromise = contractFunctions.userPoints.getUserTotalPoints({ address });
    // const userHasSponsoredPromise = contractFunctions.binary.binaryUsersSponsoredBy(address);

    // const userPackagePromise = contractFunctions.fortuneGoat.fortuneGoatUserPackage(address);
    // const packagesListPromise = contractFunctions.fortuneGoat.fortuneGoatPackagesList();
    // const packagesDecimalsPromise = contractFunctions.fortuneGoat.fortuneGoatPackagesDecimals();

    // const leadershipsListPromise = contractFunctions.qualifications.qualificationsLeadership();

    // const totalUserPointsPromise = contractFunctions.userPoints.getUserTotalPoints({ address });
    // const membershipInfoPromise = contractFunctions.membership.membershipMap({ id: address });
    // const userQVPromise = contractFunctions.userPoints.getUserQVPoints({ address });
    // const userInfoPromise = contractFunctions.fortuneGoat.fortuneGoatUserInfo(address);
    // const [
    // 	uVolume,
    // 	bVolumeLeft,
    // 	bVolumeRight,
    // 	userPackage,
    // 	packagesList,
    // 	leadershipsListResponse,
    // 	packagesDecimals,
    // 	userHasSponsored,
    // 	totalUserPoints,
    // 	membersipInfo,
    // 	cvs,
    // 	userQV,
    // 	userInfo,
    // ] = await Promise.all([
    // 	uVolumePromise,
    // 	bVolumeLeftPromise,
    // 	bVolumeRightPromise,
    // 	userPackagePromise,
    // 	packagesListPromise,
    // 	leadershipsListPromise,
    // 	packagesDecimalsPromise,
    // 	userHasSponsoredPromise,
    // 	totalUserPointsPromise,
    // 	membershipInfoPromise,
    // 	cvsPromise,
    // 	userQVPromise,
    // 	userInfoPromise,
    // ]);
    // console.log("VOLUMES: ", {
    // 	address,
    // 	uVolume,
    // 	ucv,
    // 	bVolumeLeft,
    // 	bVolumeRight,
    // 	bcvLeft,
    // 	bcvRight,
    // 	userHasSponsored,
    // });
    const userHasSponsoredParsed = userHasSponsored.map((sponsored) => ({
      id: sponsored.id,
      sponsorBranch: Number(sponsored.sponsorBranch),
    }));

    const userPackageFiltered = packagesList.filter((packagex) => {
      return userPackage ? packagex.fullPackage.id == userPackage.packageId : false;
    });

    const userPackageInfo =
      userPackageFiltered && userPackageFiltered.length > 0
        ? {
            packageId: Number(userPackageFiltered[0].fullPackage.id),
            name: userPackageFiltered[0].fullPackage.name,
            qv: Number(userPackageFiltered[0].fullPackage.qv) * 10 ** Number(packagesDecimals),
            ucv: Number(userPackageFiltered[0].fullPackage.ucv) * 10 ** Number(packagesDecimals),
            bcv: Number(userPackageFiltered[0].fullPackage.bcv) * 10 ** Number(packagesDecimals),
            binaryPercent: Number(userPackageFiltered[0].fullPackageBinary.binaryPercent),
            binaryCap: Number(userPackageFiltered[0].fullPackageBinary.binaryCap),
            startupBonus: Number(userPackageFiltered[0].fullPackageBonus.startupBonus),
            matchingBonus: Number(userPackageFiltered[0].fullPackageBonus.matchingBonus),
            unilevelBonus: Number(userPackageFiltered[0].fullPackageBonus.unilevelBonus),
            expirationDays: Number(
              userPackageFiltered[0].fullPackageExpirations.qualificationExpirationDays
            ),
          }
        : null;

    let userPackageParsed = null;
    if (userPackageInfo && userInfo[0].status == 0) {
      if (CONSTANTS.CURRENT_DATE_TIME_IN_SECONDS > CONSTANTS.STAGE_ONE_LIMIT_DATE) {
        // check if we are in stage two
        if (userPackage && userPackage.paidDate < CONSTANTS.STAGE_ONE_LIMIT_DATE) {
          // check if founder package
          if (userPackageInfo.packageId < 6) {
            if (
              CONSTANTS.CURRENT_DATE_TIME_IN_SECONDS <
              userPackageInfo.expirationDays * 24 * 60 * 60 + CONSTANTS.STAGE_ONE_LIMIT_DATE
            ) {
              userPackageParsed = {
                ...userPackageInfo,
                nextPackageId: userPackage ? userPackage.nextPackageId : 0,
                totalToPay: userPackage ? userPackage.totalToPay : 0,
                totalPaid: userPackage ? userPackage.totalPaid : 0,
                registerDate: userPackage ? userPackage.registerDate : undefined,
                paidDate: userPackage ? userPackage.paidDate : 0,
                isPaid: userPackage ? userPackage.isPaid : false,
              };
            }
          } else {
            if (
              CONSTANTS.CURRENT_DATE_TIME_IN_SECONDS <
              userPackageInfo.expirationDays * 24 * 60 * 60 + CONSTANTS.STAGE_ONE_LIMIT_DATE
            ) {
              userPackageParsed = {
                ...userPackageInfo,
                nextPackageId: userPackage ? userPackage.nextPackageId : 0,
                totalToPay: userPackage ? userPackage.totalToPay : 0,
                totalPaid: userPackage ? userPackage.totalPaid : 0,
                registerDate: userPackage ? userPackage.registerDate : undefined,
                paidDate: userPackage ? userPackage.paidDate : 0,
                isPaid: userPackage ? userPackage.isPaid : false,
              };
            }
          }
        } else {
          // check if active membership
          if (
            membersipInfo &&
            CONSTANTS.CURRENT_DATE_TIME_IN_SECONDS < membersipInfo.date + 365 * 24 * 60 * 60
          ) {
            // calculate expiration of package
            if (
              CONSTANTS.CURRENT_DATE_TIME_IN_SECONDS <
              userPackageInfo.expirationDays * 24 * 60 * 60 + CONSTANTS.STAGE_ONE_LIMIT_DATE
            ) {
              userPackageParsed = {
                ...userPackageInfo,
                nextPackageId: userPackage ? userPackage.nextPackageId : 0,
                totalToPay: userPackage ? userPackage.totalToPay : 0,
                totalPaid: userPackage ? userPackage.totalPaid : 0,
                registerDate: userPackage ? userPackage.registerDate : undefined,
                paidDate: userPackage ? userPackage.paidDate : 0,
                isPaid: userPackage ? userPackage.isPaid : false,
              };
            }
          }
        }
      } else {
        // check if we are in stage one
        userPackageParsed = {
          ...userPackageInfo,
          nextPackageId: userPackage ? userPackage.nextPackageId : 0,
          totalToPay: userPackage ? userPackage.totalToPay : 0,
          totalPaid: userPackage ? userPackage.totalPaid : 0,
          registerDate: userPackage ? userPackage.registerDate : undefined,
          paidDate: userPackage ? userPackage.paidDate : 0,
          isPaid: userPackage ? userPackage.isPaid : false,
        };
      }
    }

    const leadershipsList = leadershipsListResponse.map((leadership) => {
      console.log("LEADERSHIP: ", leadership);
      return {
        name: leadership.name,
        personalQV: Number(leadership.personalQV),
        unilevelQVperMonth: Number(leadership.unilevelQVperMonth),
        minUnilevelQVperBinaryLegPercentage: Number(leadership.minUnilevelQVperBinaryLegPercentage),
        mainLegPercentage: Number(leadership.mainLegPercentage),
        binaryPercent: Number(leadership.binaryPercent),
        binaryMatchingBonus: Number(leadership.binaryMatchingBonus),
        unilevelMatchingBonus: Number(leadership.unilevelMatchingBonus),
        weeklyCap: Number(leadership.weeklyCap),
        binaryCQ: Number(leadership.binaryCQ),
        globalPoolPercentage: Number(leadership.globalPoolPercentage),
      };
    });

    return {
      uVolume: uVolume && uVolume.length > 0 ? uVolume.map((value) => Number(value)) : [0],
      ucv:
        // ucv && ucv.length > 0 ? ucv.map((value) => Number(value)) : [0],
        cvs && cvs.unilevel ? cvs.unilevel * 10 ** Number(packagesDecimals) : 0,
      bVolume: {
        left: isNaN(Number(bVolumeLeft)) ? 0 : Number(bVolumeLeft),
        right: isNaN(Number(bVolumeRight)) ? 0 : Number(bVolumeRight),
      },
      bcv: {
        // left: Number(bcvLeft) * 10 ** Number(packagesDecimals),
        // right: Number(bcvRight) * 10 ** Number(packagesDecimals),
        left: cvs && cvs.leftBranch * 10 ** Number(packagesDecimals),
        right: cvs && cvs.rightBranch * 10 ** Number(packagesDecimals),
        lowest:
          cvs && cvs.leftBranch && cvs.rightBranch
            ? Math.min(
                cvs.leftBranch * 10 ** Number(packagesDecimals),
                cvs.rightBranch * 10 ** Number(packagesDecimals)
              )
            : 0,
      },
      userPackage: userPackageParsed,
      membersipInfo,
      totalUserPoints,
      leadershipsList,
      hasSponsored: userHasSponsoredParsed,
      packagesList,
      address,
      userQV,
    };
  } catch (error) {
    console.error("getUserInfo Error: ", error);
    throw error;
  }
};
