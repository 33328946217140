import { formatNumber } from "common/util/formatNumber";

export const OurWinnersCard = ({
  winner,
  country,
  productName,
  productDescription,
  finalBid,
  totalCost,
  bidsAmount,
  auctionEnd,
  image,
}) => {
  const currentDate = new Date().getTime();
  const endTime = Math.round((currentDate - auctionEnd) / 1000 / 60);

  const pinataGatewayKey = `?pinataGatewayToken=${process.env.REACT_APP_PINATA_GATEWAY_KEY}`;
  return (
    <div className="grid grid-cols-9 col-span-1 text-left">
      <div className="relative col-span-4 lg:col-span-5 flex bg-white justify-center items-center pl-2">
        <img
          className="object-contain w-full h-full absolute p-4"
          src={`${image}${pinataGatewayKey}` || "https://via.placeholder.com/500"}
        />
        <div className="flex flex-col justify-center absolute bottom-[1rem] left-[1rem] font-bold items-center text-[1.35rem] p-[5px] leading-[13px] h-[3rem] w-[3rem] z-10 bg-[#87bad7] rounded-full text-nowrap">
          <small className="text-[6.5px] font-normal tracking-tight mt-[-2px]">BIDS PLACED</small>
          {bidsAmount}
        </div>
      </div>
      <div className="col-span-5 lg:col-span-4 flex flex-col py-4 bg-white">
        <div className="flex justify-start">
          <div className="w-[2.75rem] h-[2.75rem]">
            <img src="https://via.placeholder.com/50" className="rounded-full" alt="winner" />
          </div>
          <div className="flex flex-col pl-2">
            <span className="font-bold ">{winner || "Winner Name"}</span>
            <span className="text-xs font-light mt-[-2px] text-nowrap">{country || "Country"}</span>
          </div>
          {auctionEnd ? (
            <p className="hidden text-[8px] max-w-[45%] text-right md:text-[10px] xl:text-[12px] md:block font-light ml-[auto] pr-4">
              Auction ended{" "}
              {endTime > 60 ? `${Math.floor(endTime / 60)} hours` : `${endTime} minutes`} ago
            </p>
          ) : (
            <p></p>
          )}
        </div>
        <div className="text-lg font-bold max-h-[45px] overflow-hidden pt-1 pr-2 leading-5">
          {productName || "Product Name"}
        </div>
        <div>
          <div className="py-1">
            <p className="text-xs">Final bid:</p>
            <p className="font-semibold text-sm">{`$${formatNumber(finalBid, true)}` || "$"}</p>
          </div>
          <div className="py-1">
            <p className="text-xs">Estimated total cost:</p>
            <p className="font-semibold text-sm">{`$${formatNumber(totalCost, true)}` || "$"}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
