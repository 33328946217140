export const parseTreeFromContractResponse = (text, treeType, showEmpty = true) => {
  // Split CSV data into rows
  console.log(treeType);
  const rows = text.trim().split("*");
  //remove last empty row
  rows.pop();

  // Get headers (first row)
  const headers = rows[0].split(",");

  // Initialize an array to store JSON objects
  const jsonData = [];
  const userIds = [];
  const parentIds = [];
  const parentsWithEmptyNodes = {};
  // Iterate over rows (excluding the header)
  for (let i = 1; i < rows.length; i++) {
    const row = rows[i].split(",");
    const jsonRow = {
      Id: "",
      parentId: "",
      username: "",
    };

    // Iterate over each column and add to JSON object
    for (let j = 0; j < headers.length; j++) {
      jsonRow[headers[j].trim()] = row[j].toLowerCase().trim();
    }

    // Add the JSON object to the array
    userIds.push(jsonRow.Id);
    parentIds.push(jsonRow.parentId);
    jsonData.push(jsonRow);
    if (treeType == "binary") {
      if (
        !parentsWithEmptyNodes[jsonRow.parentId] &&
        jsonRow.parentId != "0x0000000000000000000000000000000000000000"
      ) {
        parentsWithEmptyNodes[jsonRow.parentId] = {
          emptyNode: jsonRow.parentBranch == 1 ? 2 : 1,
        };
      } else {
        delete parentsWithEmptyNodes[jsonRow.parentId];
      }
    }
  }

  // Use filter to keep only the elements not included in userIds
  const filteredParentIds = parentIds.filter((parentId) => !userIds.includes(parentId))[0];
  // Find the index where parentId matches filteredParentIds[0]
  const indexToUpdate = jsonData.findIndex((item) => item.parentId === filteredParentIds);
  // Update the corresponding element to set parentId to null
  if (indexToUpdate !== -1) {
    jsonData[indexToUpdate].parentId = null;
  }

  if (parentsWithEmptyNodes[filteredParentIds]) {
    delete parentsWithEmptyNodes[filteredParentIds];
  }

  const emptyNodesToTheLeft = [];
  const emptyNodesToTheRight = [];
  if (showEmpty) {
    Object.entries(parentsWithEmptyNodes).forEach(([key, value], index) => {
      const nodeData = jsonData.find((value) => value.Id == key);
      if (value.emptyNode == 1) {
        emptyNodesToTheRight.push({
          parentId: key,
          username: "Empty",
          parentBranch: value.emptyNode,
          Id: `${nodeData.userName || nodeData.username}-left`,
        });
      } else {
        emptyNodesToTheLeft.push({
          parentId: key,
          username: "Empty",
          parentBranch: value.emptyNode,
          Id: `${nodeData.userName || nodeData.username}-right`,
        });
      }
    });
    const lastNodesWithoutChildren = userIds.filter((userId) => !parentIds.includes(userId));
    lastNodesWithoutChildren.forEach((node, index) => {
      if (treeType == "binary") {
        const nodeData = jsonData.find((value) => value.Id == node);
        emptyNodesToTheRight.push(
          {
            parentId: node,
            username:
              nodeData.hasChildren && nodeData.hasChildren == "true" ? "loading..." : "Empty",
            parentBranch: 1,
            Id: `${nodeData.userName || nodeData.username}-left`,
          },
          {
            parentId: node,
            username:
              nodeData.hasChildren && nodeData.hasChildren == "true" ? "loading..." : "Empty",
            parentBranch: 2,
            Id: `${nodeData.userName || nodeData.username}-right`
          }
        );
      } else {
        const nodeData = jsonData.find((value) => value.Id == node);
        emptyNodesToTheLeft.push({
          parentId: node,
          username: nodeData.hasChildren && nodeData.hasChildren == "true" ? "loading..." : "Empty",
          parentBranch: 1,
          Id: `${nodeData.userName || nodeData.username}-${index}`,
        });
      }
    });
  }
  // console.debug({ filteredParentIds, indexToUpdate, emptyNodesToTheRight });
  // Log or use the jsonData as needed
  return [...emptyNodesToTheRight, ...jsonData, ...emptyNodesToTheLeft];
};
