import { useNavigate } from "react-router-dom";
import FortuneGoatABI from "@common/contracts/FortuneGoat.json";
import { onReadContract } from "@common/services";
import { useEffect } from "react";

export const useLoginRedirect = ({ address = null, isConnected = false }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const hasDashboardAccess = async () => {
      const response = await onReadContract({
        contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS,
        contractABI: FortuneGoatABI,
        functionName: "getUserInfo",
        args: [address],
      });

      if (response && response[0]?.username && response[0]?.username !== "") {
        // navigate("/app/store");
      } else {
        navigate("/join");
      }
    };
    if (isConnected && address) hasDashboardAccess();
  }, [isConnected, address, navigate]);
};
