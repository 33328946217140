import { onGetMeetTheWinners } from "modules/penny-auction/services/onGetMeetTheWinners";
import { useQuery } from "@tanstack/react-query";
import { MeetTheWinnerCard } from "./MeetTheWinnerCard";
import Carousel from "react-multi-carousel";
import { MeetTheWinnerCardSkeleton } from "./MeetTheWinnerCardSkeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useRef } from "react";

const responsive = {
  mobile: {
    breakpoint: { max: 4000, min: 365 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

export const MeetTheWinners = () => {
  const carouselRef = useRef(null);
  const {
    isFetching: loading,
    data: winners,
    error: error,
  } = useQuery({
    queryKey: [`meetTheWinners`],
    queryFn: async () => await onGetMeetTheWinners(),
    staleTime: 360000,
    refetchOnWindowFocus: false,
  });

  return (
    <div className="w-full p-4 relative mtw">
      <style>{`
        .mtw .react-multi-carousel-list{display: flex; flex-direction: row; overflow: hidden;}
        .mtw .react-multi-carousel-track{display: flex; flex-direction: row;}
      `}</style>
      <h2 className="text-2xl font-semibold w-full pt-4 pb-2">Check out what they think!</h2>
      {loading ? (
        <MeetTheWinnerCardSkeleton />
      ) : error ? (
        <h2>error loading winners</h2>
      ) : (
        <div className="grid grid-cols-12">
          <div className="col-span-12 sm:col-span-11 min-h-full">
            <Carousel
              ref={carouselRef}
              responsive={responsive}
              autoPlay={true}
              swipeable={true}
              draggable={true}
              showDots={false}
              renderDotsOutside={false}
              infinite={true}
              partialVisible={false}
              autoPlaySpeed={8000}
            >
              {winners.map((winner) => {
                return (
                  <MeetTheWinnerCard
                    key={`meet_the_winner_${winner.item.id}`}
                    productName={winner.item.info.name}
                    productDescription={winner.item.info.description}
                    winner={winner.user.username || winner.user.id}
                    country={winner.user.country}
                    finalBid={winner.item.finalPrice}
                    totalCost={winner.item.autobuyPrice}
                    winnerData={winner}
                  />
                );
              })}
            </Carousel>
          </div>
          <div
            className="hidden sm:flex col-span-1 flex justify-center items-center cursor-pointer"
            onClick={() => {
              if (carouselRef.current) {
                carouselRef.current.next();
              }
            }}
          >
            <FontAwesomeIcon icon={faChevronRight} className="text-gray text-4xl" />
          </div>
        </div>
      )}
    </div>
  );
};
