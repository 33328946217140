import { Modal } from "@mui/material";
import AdminLayout from "layouts/AdminLayout";
import React, { useEffect, useState } from "react";
import { useFetchCommissions } from "../hooks/Commissions/useFetchCommissions";
import { useFetchCommissionsSummary } from "../hooks/Commissions/useFetchCommissionsSummary";
import { Input } from "common/components";
import { useForm } from "react-hook-form";
import { usePayCommission } from "common/hooks/usePayCommission";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";

export const CommissionsPage = () => {
  const activeClass = "border-b-2 border-b-[#1840CE]";
  const [activeTab, setActiveTab] = useState("summary");
  const {
    data: commissionsToPayData,
    setParams: setToPayCommissionsParams,
    params: toPayCommissionsParams,
  } = useFetchCommissions("commissions-to-pay", {
    paidStatus: 1,
  });
  const { data: commissionsPaidData } = useFetchCommissions("commissions-paid", {
    paidStatus: 2,
  });
  const [commissionsToPay, setCommissionsToPay] = useState([]);
  const [commissionToPay, setCommissionToPay] = useState({});
  const [commissionsPaid, setCommissionsPaid] = useState([]);
  const [isPaying, setIsPaying] = useState(false);

  const { requestPayCommission } = usePayCommission();
  const queryClient = useQueryClient();

  const handlePayCommission = async () => {
    setIsPaying(true);
    try {
      const parsedInputs = {
        commissionId: commissionToPay.id,
      };
      await requestPayCommission({
        commissionData: {
          ...parsedInputs,
          onSuccess: async () => {
            toast.success("Commission paid successfully.");
            queryClient.invalidateQueries("commissions-to-pay");
            setIsPaying(false);
            setCommissionToPay({});
          },
          onError: async (error) => {
            toast.error(`Failed to pay commission. ${error}`);
            setIsPaying(false);
          },
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!commissionsToPayData) return;
    setCommissionsToPay(
      commissionsToPayData?.commissions.map((commission) => {
        return {
          id: commission.id,
          to: commission.to,
          date: new Date(commission?.date * 1000).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          }),
          type: commission.kindText,
          amount: commission.amount,
          action: (
            <button
              className="bg-baby-blue text-dark py-2 font-semibold px-5"
              onClick={() => setCommissionToPay(commission)}
            >
              Pay
            </button>
          ),
        };
      })
    );
  }, [commissionsToPayData]);

  useEffect(() => {
    if (!commissionsPaidData) return;
    setCommissionsPaid(
      commissionsPaidData?.commissions.map((commission) => {
        return {
          id: commission.id,
          to: commission.to,
          type: commission.kindText,
          amount: commission.amount,
          status: commission.status,
          date: new Date(commission?.date * 1000).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          }),
          action: <button className="bg-baby-blue text-dark py-2 font-semibold px-5">Pay</button>,
        };
      })
    );
  }, [commissionsPaidData]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  const Tabs = (
    <div className="grid grid-cols-3">
      <div
        className={`flex border-b-2 justify-center p-4 cursor-pointer uppercase ${
          activeTab === "summary" ? activeClass : ""
        }`}
        onClick={() => handleTabChange("summary")}
      >
        Summary
      </div>
      <div
        className={`flex border-b-2 justify-center p-4 cursor-pointer uppercase ${
          activeTab === "commissions-to-pay" ? activeClass : ""
        }`}
        onClick={() => handleTabChange("commissions-to-pay")}
      >
        Commissions to pay
      </div>
      <div
        className={`flex border-b-2 justify-center p-4 cursor-pointer uppercase ${
          activeTab === "commissions-paid" ? activeClass : ""
        }`}
        onClick={() => handleTabChange("commissions-paid")}
      >
        Commissions paid
      </div>
    </div>
  );

  const { data } = useFetchCommissionsSummary();

  const CommissionsSummary = (
    <div className="w-full grid grid-cols-3 gap-4 mt-4">
      <div className="col-span-3 md:col-span-1">
        <div>
          <div className="bg-[#132440] h-52 rounded p-4 flex flex-col">
            <div>
              <p className="text-white text-sm">Total Binary Commissions</p>
              {/* <p className="text-baby-blue text-sm">*Weekly: June 6th - June 13th, 2024</p> */}
            </div>
            <div className="mt-4 h-full flex justify-center items-center">
              <p className="text-white font-bold text-3xl">
                {(data?.pending?.binaryTotal + data?.paid?.binaryTotal) / 1e6} USDT
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-3 md:col-span-1">
        <div>
          <div className="bg-[#132440] h-52 rounded p-4 flex flex-col">
            <div>
              <p className="text-white text-sm">Total Unilevel Commissions</p>
              {/* <p className="text-baby-blue text-sm">*Monthly: June, 2024</p> */}
            </div>
            <div className="mt-4 h-full flex justify-center items-center">
              <p className="text-white font-bold text-3xl">
                {(data?.pending?.unilevelTotal + data?.paid?.unilevelTotal) / 1e6} USDT
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-3 md:col-span-1 shadow-md">
        <div className="bg-white">
          <div className="bg-[#132440] h-52 rounded p-4 flex flex-col">
            <div className="mt-4 h-full flex flex-col justify-center items-center">
              <p className="text-white font-bold text-3xl">Leadership Pool</p>
              <p className="text-baby-blue text-sm">*Last 3 month</p>
            </div>
          </div>
          {/* <div className="px-4 py-2">
            <Avatar className="mt-4" />
            <p className="text-gray-800 text-sm font-semibold">john-doe</p>
            <p className="text-gray-800 text-xl font-bold">$0.00 USDT</p>
          </div>
          <div className="px-4 py-2">
            <Avatar className="mt-4" />
            <p className="text-gray-800 text-sm font-semibold">john-doe</p>
            <p className="text-gray-800 text-xl font-bold">$0.00 USDT</p>
          </div>
          <div className="px-4 py-2">
            <Avatar className="mt-4" />
            <p className="text-gray-800 text-sm font-semibold">john-doe</p>
            <p className="text-gray-800 text-xl font-bold">$0.00 USDT</p>
          </div> */}
        </div>
      </div>
    </div>
  );

  const CommissionsToPayTable = () => {
    const { register, handleSubmit } = useForm();

    const onSubmit = (data) => {
      setToPayCommissionsParams({
        ...toPayCommissionsParams,
        // startDate: data.startDate,
        /* to unix */
        start_date: isNaN(new Date(data.startDate).getTime() / 1000)
          ? ""
          : new Date(data.startDate).getTime() / 1000,
        // endDate: data.endDate,
        /* to unix */
        end_date: isNaN(new Date(data.endDate).getTime() / 1000)
          ? ""
          : new Date(data.endDate).getTime() / 1000,
      });
    };
    return (
      <div className="w-full mt-4">
        <form className="flex gap-2 my-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col justify-between">
            <p className="text-baby-blue text-md font-semibold">Start Date</p>
            {/* if param date is active dont show input */}
            {toPayCommissionsParams.start_date ? (
              <p className="text-dark text-lg font-semibold p-4">
                {new Date(toPayCommissionsParams.start_date * 1000).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </p>
            ) : (
              <Input
                type="date"
                className="text-dark text-lg font-semibold w-fit"
                {...register("startDate")}
              />
            )}
          </div>
          <div>
            <p className="text-baby-blue text-md font-semibold">End Date</p>
            {/* if param date is active dont show input */}
            {toPayCommissionsParams.end_date ? (
              <p className="text-dark text-lg font-semibold p-4">
                {new Date(toPayCommissionsParams.end_date * 1000).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </p>
            ) : (
              <Input
                type="date"
                className="text-dark text-lg font-semibold w-fit"
                {...register("endDate")}
              />
            )}
          </div>
          <div className="flex items-end gap-4">
            {toPayCommissionsParams.start_date || toPayCommissionsParams.end_date ? (
              <button
                type="button"
                onClick={() => {
                  setToPayCommissionsParams({
                    ...toPayCommissionsParams,
                    start_date: "",
                    end_date: "",
                  });
                }}
                className="bg-dark text-white py-2 font-semibold px-5"
              >
                Clear
              </button>
            ) : (
              <button type="submit" className="bg-baby-blue text-dark py-2 font-semibold px-5">
                Apply
              </button>
            )}
          </div>
        </form>
        <table className="w-full">
          <thead>
            <tr>
              <th className="text-left">Commission #id</th>
              <th className="text-left">User Address</th>
              <th className="text-left">Type</th>
              <th className="text-left">Date</th>
              <th className="text-left">Amount</th>
              <th className="text-left">Action</th>
            </tr>
          </thead>
          <tbody>
            {commissionsToPay.map((commission, index) => (
              <tr
                key={commission.id}
                className={`${index % 2 === 0 ? "bg-light-gray" : "bg-white"}`}
              >
                <td className="p-4">{commission.id}</td>
                <td>{commission.to}</td>
                <td>{commission.type}</td>
                <td>{commission.date}</td>
                <td className="text-electric-blue font-semibold">
                  {commission.amount ? commission.amount / 1000000 : ""} USDT
                </td>
                <td>{commission.action}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const commissionsPaidTable = (
    <div className="w-full mt-4">
      <div className="w-full border-b border-b-dark border-dashed pb-4 flex gap-4">
        <div className="flex flex-col">
          <p className="text-baby-blue text-md font-semibold">Initial balance</p>
          <p className="text-dark text-lg font-semibold">N/A</p>
        </div>
        <div className="flex flex-col">
          <p className="text-baby-blue text-md font-semibold">Final balance</p>
          <p className="text-dark text-lg font-semibold">N/A</p>
        </div>
      </div>
      <table className="w-full mt-4">
        <thead>
          <tr>
            <th className="text-left">Commission #id</th>
            <th className="text-left">User Address</th>
            <th className="text-left">Date</th>
            <th className="text-left">Type</th>
            <th className="text-left">Status</th>
            <th className="text-left">Amount</th>
          </tr>
        </thead>
        <tbody>
          {commissionsPaid.map((commission, index) => (
            <tr key={commission.id} className={`${index % 2 === 0 ? "bg-light-gray" : "bg-white"}`}>
              <td className="p-4">{commission.id}</td>
              <td>{commission?.to}</td>
              <td>{commission.date}</td>
              <td>{commission.type}</td>
              <td>{commission.status === 1 ? "To Pay" : "Completed"}</td>
              <td className="text-electric-blue font-semibold">
                {commission.amount ? commission.amount / 1000000 : ""} USDT
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <AdminLayout>
      <div className="w-full bg-white p-4">
        <h1 className="text-xl font-semibold text-gray-800">Commissions</h1>
        {Tabs}
        {activeTab === "summary" && CommissionsSummary}
        {activeTab === "commissions-to-pay" && <CommissionsToPayTable />}
        {activeTab === "commissions-paid" && commissionsPaidTable}
      </div>
      <Modal open={commissionToPay?.id} onClose={() => setCommissionToPay({})}>
        <div className="w-full max-w-[800px] bg-white p-4 mx-auto mt-20">
          <h1 className="text-xl font-semibold text-gray-800">Are you sure you want to pay?</h1>
          <div className="flex gap-2 mt-4 flex-col">
            <p className="text-baby-blue text-md font-semibold">User Address</p>
            <p className="text-gray-600 text-lg font-semibold">{commissionToPay?.to}</p>
            <p className="text-baby-blue text-md font-semibold">Type</p>
            <p className="text-gray-600 text-lg font-semibold">{commissionToPay?.kindText}</p>
            <p className="text-baby-blue text-md font-semibold">Amount</p>
            <p className="text-gray-600 text-lg font-semibold">
              {commissionToPay?.amount ? commissionToPay?.amount / 1000000 : ""} USDT
            </p>
          </div>
          <div className="flex gap-4 mt-4 w-full justify-end">
            <button
              className="bg-baby-blue text-dark py-2 font-semibold px-5"
              disabled={isPaying}
              onClick={handlePayCommission}
            >
              {isPaying ? "Paying..." : "Pay"}
            </button>
            <button
              className="border bg-[#CCCCCC] text-[#051F40] py-2 font-semibold px-5"
              onClick={() => setCommissionToPay({})}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </AdminLayout>
  );
};
