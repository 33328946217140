import { web3AuthInstance } from "App";
import { wagmiConfig } from "App";
import { Footer } from "common/components";
import { replaceWCQrCode } from "common/util/replaceWCQrCode";
import { Navbar } from "modules/landing/components";
import React from "react";
import { useConnect } from "wagmi";

export const BenefitsPage = () => {
  const { error, connectors, connect } = useConnect(wagmiConfig);

  const handleJoin = async () => {
    // setShowModal(true);
    if (connect) {
      const observer = replaceWCQrCode({ web3AuthInstance });
      connect(
        { connector: connectors[0] },
        {
          onSuccess: () => {
            window.location.reload();
          },
          onError: () => {
            observer.disconnect();
          },
        }
      );
    }
  };

  return (
    <div>
      <Navbar />
      <>
        <div
          className="flex  flex-col justify-center items-center bg-[url('@assets/images/benefits.png')] bg-center bg-no-repeat bg-cover min-h-[300px] md:min-h-[800px]"
          style={{
            width: "auto",
          }}
        >
          <h1 className="text-5xl text-white flex items-center mb-4">
            Learn More About our Benefits
          </h1>
          <p className="text-2xl text-white max-w-[60%] text-center">
            Joining a multilevel marketing company opens doors to a plethora of opportunities,
            empowering individuals to shape their own success stories.
          </p>
          <button
            onClick={handleJoin}
            className="bg-electric-blue hover:bg-zinc-400 text-white font-bold py-2 px-20 rounded text-lg mt-20"
          >
            Join us
          </button>
        </div>
        <div className="bg-highlight-primary flex items-center justify-center py-10 min-h-10 md:min-h-20" />
      </>
      <div className="flex flex-col py-40 bg-cream ">
        <div className="w-full">
          <div className="flex justify-center pb-10">
            <div className="flex-col container px-4">
              <div className="text-center">
                <h2 className="text-5xl text-dark font-light mb-4">Combining two powerful</h2>
              </div>
              <div className="text-center">
                <h2 className="text-5xl text-dark font-light mb-4">compensation structures</h2>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center">
          <div className="container px-4">
            <div className="grid grid-cols-4 grid-rows-2 gap-4">
              <div className="md:col-span-2 bg-white col-span-4 p-6 rounded">
                <div>
                  <h3 className="text-2xl font-semibold text-dark mb-4">Family Tree</h3>
                  <p className="text-gray-600 text-lg mb-4">
                    Invite others to participate and enjoy FortuneGoat.com. Each member that you
                    sponsor can also do the same thing, creating a genealogy tree of Fun Shoppers.
                    Every time somebody belonging to your tree purchases a pack, you earn a
                    commission, up to 20 generations in depth. You can sponsor directly an unlimited
                    number of Fun Shoppers.
                  </p>
                </div>
              </div>

              <div className="md:col-span-2 md:row-span-2 col-span-4 row-span-2  rounded bg-[url('@assets/images/people-flag.png')] bg-center bg-no-repeat bg-cover md:min-h-[600px]"></div>

              <div className="md:col-span-2 bg-white col-span-4 p-6 rounded">
                <div>
                  <h3 className="text-2xl font-semibold text-dark mb-4">Infinite Dual Team</h3>
                  <p className="text-gray-600 text-lg mb-4">
                    Place the Fun Shoppers that you sponsor directly in one of two teams. Every week
                    the points that are generated by each team in an infinite depth are added. You
                    will be paid a commission applying your percentage and your cap to the team that
                    generated fewer points that week. Percentages range from 10% up to 35%. Weekly
                    cap goes from USDT $200 up to USDT $150,000.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gradient-to-b from-cream to-sky-700/50">
        <div className="flex flex-col py-20">
          <div className="w-full">
            <div className="flex justify-center py-10">
              <div className="container flex justify-center px-4">
                <div
                  className="flex justify-center items-center flex-col"
                  style={{ maxWidth: "800px" }}
                >
                  <h2 className="text-4xl text-gray-800 font-light mb-4 text-center">
                    More than a platform, a supportive family, dedicated to turning your dreams into
                    reality.
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="flex justify-center">
              <div className="container flex justify-center px-4">
                <div className="mx-4 flex items-center">
                  <button
                    onClick={handleJoin}
                    className="bg-electric-blue hover:bg-zinc-400 text-white font-bold py-2 px-20 rounded text-lg"
                  >
                    Join us
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
