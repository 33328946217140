import React, { useCallback, useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { AddressField } from "./addressField";
// import { SentryService } from "common/services/SentryErrorCapture";
import { useSendUSDTs } from "modules/admin/hooks/Funds/useSendUSDT";
import { useGetAddressBalance } from "common/hooks";

export const FundsUSDTsForm = () => {
  const { address } = useAccount({});
  const [addressTo, setAddress] = useState("");
  const [amount, setAmount] = useState("");
  const { success, error, loading, sendUSDTs, resetSendUSDTsState } = useSendUSDTs();
  const { USDTBalance, refetchUSDTbalance } = useGetAddressBalance({ address, usdt: true });
  // console.log("gasFeeData", gasFeeData);
  const refresh = useCallback(() => {
    refetchUSDTbalance(address);
  }, [refetchUSDTbalance, address]);

  useEffect(() => {
    refresh();
  }, [refresh, success, error]);

  return (
    <div className="mx-auto p-6 bg-white rounded-md shadow-md lg:min-w-[600px] h-full">
      <div className="mx-auto md:max-w-[50%] lg:max-w-[40%]">
        <h2 className="text-2xl font-semibold mb-4">
          Send USDTs
          <small
            className="ml-2 cursor-pointer text-sm text-gray-600"
            onClick={() => {
              refresh();
            }}
          >
            Refresh
          </small>
        </h2>
      </div>
      <AddressField setAddress={setAddress} />
      <div className="mb-4">
        <label
          htmlFor="usdt"
          className={`block text-sm font-medium ${
            USDTBalance <= 0 ? "text-rose-800" : "text-emerald-800"
          }`}
        >
          Your current USDT balance: {USDTBalance}
        </label>
        <label htmlFor="usdt" className="block text-sm font-medium text-gray-600">
          USDTs amount
        </label>
        <input
          type="text"
          value={amount}
          disabled={USDTBalance <= 0}
          onChange={(e) => {
            resetSendUSDTsState();
            if (USDTBalance) {
              setAmount(() => (e.target.value > USDTBalance ? USDTBalance : e.target.value));
            }
          }}
          id="usdt"
          name="usdt"
          className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
          placeholder="Enter usdt to send"
        />
      </div>
      <div className="flex w-full flex-col justify-end items-end">
        <button
          type="button"
          className="border border-dark text-dark font-bold py-3 px-8 rounded w-full md:w-fit"
          onClick={async () => {
            resetSendUSDTsState();
            await sendUSDTs({
              address: addressTo,
              amount: parseInt(amount * 10 ** 6),
              confirmations: 2,
            });
          }}
          disabled={loading}
        >
          {loading ? "sending USDTs..." : "Send USDTs"}
        </button>
        <React.Fragment>
          {error && (
            <p>
              <small className="text-rose-800">{error}</small>
            </p>
          )}
          {success && (
            <p>
              <small className="text-emerald-800">{success}</small>
            </p>
          )}
        </React.Fragment>
      </div>
    </div>
  );
};
