import { getBinaryCommission as defaultGetBinaryCommission } from "../onGetCommissions/getBinaryCommission";
import { getUnilevelCommission as defaultGetUnilevelCommission } from "../onGetCommissions/getUnilevelCommission";

export const onGetUnilevelMatchBonus = async ({
  userRank,
  getLevelAddresses,
  getMatchingBonusPercentages,
  contractFunctions,
  getLevelSum,
  getBinaryCommission = defaultGetBinaryCommission,
  getUnilevelCommission = defaultGetUnilevelCommission,
}) => {
  const result = { levels: [], total: 0 };
  if (!userRank) return result;
  let matchingBonus = 0;
  if (!userRank.leadership && !userRank.userPackage) return result;
  if (userRank.leadership) {
    matchingBonus = userRank.leadership.unilevelMatchingBonus;
  } else {
    if (userRank.userPackage) {
      matchingBonus = userRank.userPackage.unilevelBonus;
    } else {
      return result;
    }
  }

  const matchingBonusPercentages = await getMatchingBonusPercentages({
    matchingBonus,
    contractFunctions,
  });

  let addresses = [userRank.address];
  for (const bonus of matchingBonusPercentages) {
    const levelAddresses = await getLevelAddresses({ addresses, contractFunctions });
    const levelSum = await getLevelSum({
      addresses: levelAddresses,
      percentage: bonus.percentage,
      contractFunctions,
      getBinaryCommission,
      getUnilevelCommission,
    });
    result.levels.push(levelSum);
    addresses = levelAddresses;
  }
  result.total = result.levels.reduce((prev, current) => {
    return prev + current.unilevel.total;
  }, 0);
  return result;
};
