export const Pagination = ({ pagination, onPreviousClick, onNextClick }) => {
  return (
    pagination && (
      <div className="pb-52">
        <div className="flex">
          {pagination.prev_page && (
            <button
              className="text-[16px] text-[#808080] font-bold mx-10"
              onClick={() => {
                onPreviousClick();
              }}
            >{` PREVIOUS `}</button>
          )}
          {pagination.page - 1 > 0 && (
            <button
              className="text-[16px] text-[#808080] font-bold mx-10"
              onClick={() => {
                onPreviousClick();
              }}
            >{` ${pagination.page - 1} `}</button>
          )}
          <span className="text-[16px] text-dark font-bold">{pagination.page}</span>
          {pagination.page + 1 <= pagination.total_pages && (
            <button
              className="text-[16px] text-[#808080] font-semibold mx-10"
              onClick={() => {
                onNextClick();
              }}
            >{` ${pagination.page + 1} `}</button>
          )}
          {pagination.next_page && (
            <button
              className="text-[16px] text-[#808080] font-bold mx-10"
              onClick={() => {
                onNextClick();
              }}
            >{` NEXT `}</button>
          )}
        </div>
      </div>
    )
  );
};
