const BATCH_GET_COMMISSIONS_LIMIT = 50;
import { onReadContract } from "../../../onReadContract";

export const commissionsFunctions = ({ commissionsContract, commissionsABI }) => {
  const readCommissions = async ({ functionName, args }) => {
    return await onReadContract({
      contractAddress: commissionsContract,
      contractABI: commissionsABI,
      functionName,
      args,
    });
  };

  const getCommissionsTimestamps = async () => {
    const args = [];
    const commissionDatesResponse = await readCommissions({
      functionName: "getCommissionDates",
      args,
    });
    console.debug("commissionDatesResponse: ", commissionDatesResponse);
    if (commissionDatesResponse) {
      return commissionDatesResponse.map((commissionDate) => {
        return Number(commissionDate);
      });
    }
    return [];
  };

  const getDateTotalCommissions = async ({ date }) => {
    const args = [date];
    const DateTotalCommissionsResponse = await readCommissions({
      functionName: "datesCounterTotalCommissionsMap",
      args,
    });
    console.debug("DateTotalCommissionsResponse: ", DateTotalCommissionsResponse);
    return Number(DateTotalCommissionsResponse);
  };
  const getCommissionsList = async ({ date, start, limit }) => {
    const args = [date, start, limit];
    const getCommissionsListResponse = await readCommissions({
      functionName: "getCommissionsList",
      args,
    });
    console.debug("getCommissionsListResponse: ", getCommissionsListResponse);
    return getCommissionsListResponse.map((comm) => {
      return {
        id: Number(comm[0]), // indicates position in datesCounterCommissionsMap mapping array
        commissionId: Number(comm[1]), // unique commission Id to differentiate between all commissions
        to: comm[2], // commission reciever
        date: Number(comm[3]), // date timestamp
        dateCounter: Number(comm[4]), // date counter when commission was created
        amount: Number(comm[5]), // commission amount
        status: Number(comm[6]),
        kind: Number(comm[7]),
      };
    });
  };

  const getWeekCommissions = async ({ date }) => {
    const timestampTotalCommissions = await getDateTotalCommissions({
      date,
    });
    const batches = Array(
      Math.round(Math.ceil(timestampTotalCommissions / BATCH_GET_COMMISSIONS_LIMIT))
    );
    let index = 0;
    const commissions = [];
    for (const batch of batches) {
      console.debug("batch: ", index);
      console.debug("get batch addresses");
      const batchCommissions = await getCommissionsList({
        date,
        start: index,
        limit: BATCH_GET_COMMISSIONS_LIMIT,
      });
      console.debug("batchCommissions: ", batchCommissions);

      if (!!batchCommissions) {
        commissions.push(...batchCommissions);
      }

      index += BATCH_GET_COMMISSIONS_LIMIT;
    }
    return commissions;
  };

  return {
    getCommissionsTimestamps,
    getDateTotalCommissions,
    getCommissionsList,
    getWeekCommissions,
  };
};
