import { FooterMin, Logo } from "@common/components";
import { useAccount } from "wagmi";
import { torusPlugin } from "../Web3AuthConnectorInstance";
import { useNavigate } from "react-router-dom";

export const JoinUsLayout = ({
    children,
    bgWrapper,
    layoutLoading
}) => {
    const navigate = useNavigate()
    const { isConnected, address } = useAccount();
    // const { disconnect } = useDisconnect({
    //     onError: () => {
    //         localStorage.clear();
    //         window.location.reload();
    //     },
    // });

    const torusRampOn = async () => {
        if (!address || !isConnected) {
            return;
        }
        if (!torusPlugin || !torusPlugin.torusWalletInstance.isInitialized) {
            return;
        }
        try {
            await torusPlugin.initiateTopup("transak", {
                selectedAddress: address,
                selectedCurrency: "USD", // Fiat currency
                fiatValue: 500, // Fiat Value
                selectedCryptoCurrency: "USDT", // Cryptocurreny `SOL`, `MATIC` etc.
                chainNetwork: "matic", // Blockchain network
            });
        } catch (error) {
            console.log({ error });
        }
    };
    return (
        <>
            <div className="bg-dark-blue">
                <div className="flex justify-between items-center p-4">
                    <Logo variant="white" layoutLoading={layoutLoading}/>
                    <div className="flex justify-center">
                        {isConnected && (
                            <div className="mx-4 flex items-center">
                                {torusPlugin && false && (
                                    <button
                                        style={{
                                            margin: "2rem",
                                            padding: "1rem",
                                            border: "1px solid white",
                                            color: "white",
                                        }}
                                        onClick={() => torusRampOn}
                                    >
                                        Get funds
                                    </button>
                                )}
                                <button
                                    className="text-white font-semibold py-2 px-10 text-lg"
                                    onClick={() => {
                                        navigate("/logout");
                                    }}
                                >
                                    <span className="p-2">Log out</span>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                <div className="container px-4 pb-24 mx-auto">
                    <h1 className="text-white text-4xl">Join us now</h1>
                </div>
            </div>
            <div className="container px-4 mx-auto">
                <div className="grid grid-cols-3">
                    <div className="grid col-span-3 md:col-span-2">
                        <div
                            className={`${bgWrapper || "bg-white"} drop-shadow-md`}
                            style={{
                                transform: "translateY(-72px)",
                                minHeight: "96px",
                            }}
                        >
                            {children}
                        </div>
                    </div>
                </div>
            </div>
            <FooterMin />
        </>
    );
};
