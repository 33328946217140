import axios from "axios";

const API = process.env.REACT_APP_AWS_API_URL;

export const useProductsService = () => {
  return {
    async fetchProducts(params) {
      const response = await axios.post(`${API}/contracts/pa/items`, params);
      return response.data;
    },
  };
};
