import { onReadContract } from "../../../onReadContract";

export const membershipFunctions = ({ membershipContract, membershipABI }) => {
  const readMembership = async ({ functionName, args }) => {
    return await onReadContract({
      contractAddress: membershipContract,
      contractABI: membershipABI,
      functionName,
      args,
    });
  };
  const membershipCache = {};
  const membershipMap = async ({ id }) => {
    if (membershipCache && membershipCache[id]) {
      return membershipCache[id];
    }
    const membershipResponse = {
      id: 0,
      username: "",
      status: false,
      date: 0,
    };
    const args = [id];
    const membershipMapResponse = await readMembership({
      functionName: "membershipsMap",
      args,
    });
    console.debug("membershipMapResponse: ", membershipMapResponse);
    if (membershipMapResponse) {
      membershipResponse.id = membershipMapResponse[0];
      membershipResponse.username = membershipMapResponse[1];
      membershipResponse.status = Boolean(Number(membershipMapResponse[2]));
      membershipResponse.date = Number(membershipMapResponse[3]);
    }
    if (membershipCache) {
      membershipCache[id] = membershipResponse;
    }
    return membershipResponse;
  };

  return { membershipMap };
};
