import { useEffect } from "react";
import { Input, Select } from "@common/components";
import { web3AuthInstance } from "@src/App";
import { useAccount } from "wagmi";
import { onReadContract } from "common/services";
import ABI from "../../../common/contracts/FortuneGoat.json";
import { toast } from "react-toastify";
import { SentryService } from "common/services/SentryErrorCapture";


export const JoinUsStep1 = ({
    register,
    trigger,
    setValue,
    errors,
    watch,
    setError,
    clearErrors
}) => {
    const { address } = useAccount();
    const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
    const checkUserAvailability = async () =>{
        if(!address){
            throw new Error("No address found");
        }
        const userNameResponse = (await onReadContract({
            contractAddress,
            contractABI: ABI,
            functionName: "isUsernameAvailable",
            args: [watch("username")],
            from: address
        }))
        if(!userNameResponse) {
            setError('username', { type: 'custom', message: 'Username already registered' })
            toast.error("Username already registered")
        }
    }
    const checkSponsorAvailability = async () => {
        if(!address){
            throw new Error("No address found");
        }
        const sponsorResponse = (await onReadContract({
            contractAddress,
            contractABI: ABI,
            functionName: "isUsernameAvailable",
            args: [watch("sponsor")],
            from: address
        }))
        if(sponsorResponse) {
            setError('sponsor', { type: 'custom', message: 'Sponsor username not found' })
            toast.error("Sponsor username not found")
        } 
    }

    const onFocusClear = (e) =>{
        clearErrors(e.target.id)
    } 

    useEffect(() => {
        const getUserInfo = async () => {
            try {
                const response = await web3AuthInstance.getUserInfo();
                if (response?.name) {
                    setValue("fullName", response.name);
                }
                if (response?.email) {
                    setValue("email", response.email);
                }
            } catch (error) {
                SentryService("JoinUsStep1", "useEffect:getUserInfo", error)
                // console.log("error", error);
            }
        };
        if (web3AuthInstance.connected) getUserInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address, setValue, web3AuthInstance.connected]);
    return (
        <div className="p-6">
            <h2 className="text-2xl text-dark mb-6">
                Step 1, Create your account.
            </h2>
            <div className="grid grid-cols-2 gap-4">
                <div className="col-span-2">
                    <Input
                        label="Full name"
                        id="first_name"
                        type="text"
                        placeholder="Full name"
                        {...register("fullName")}
                        onBlur={() => trigger("fullName")}
                    />
                    {errors.fullName && (
                        <span className="text-red-500">
                            {errors.fullName.message}
                        </span>
                    )}
                </div>
                <div className="col-span-2">
                    <Input
                        label="Username"
                        id="username"
                        type="text"
                        placeholder="Username"
                        {...register("username")}
                        onBlur={() => checkUserAvailability()}
                        onFocus={(e)=> onFocusClear(e)}
                    />
                    {errors.username && (
                        <span className="text-red-500">
                            {errors.username.message}
                        </span>
                    )}
                </div>
                <div className="col-span-2">
                    <Input
                        label="Email"
                        id="email"
                        type="email"
                        placeholder="Email"
                        {...register("email")}
                        onBlur={() => trigger("email")}
                    />
                    {errors.email && (
                        <span className="text-red-500">
                            {errors.email.message}
                        </span>
                    )}
                </div>
                <div className="col-span-2">
                    <Input
                        label="Sponsor"
                        id="sponsor"
                        type="text"
                        placeholder="Sponsor"
                        {...register("sponsor")}
                        onBlur={() => checkSponsorAvailability()}
                        onFocus={(e)=> onFocusClear(e)}
                    />
                    {errors.sponsor && (
                        <span className="text-red-500">
                            {errors.sponsor.message}
                        </span>
                    )}
                </div>
                <div className="col-span-2 md:col-span-1">
                    <Select
                        label="Country"
                        id="country"
                        options={["Costa Rica", "México"]}
                        {...register("country")}
                        onBlur={() => trigger("country")}
                    />
                    {errors.country && (
                        <span className="text-red-500">
                            {errors.country.message}
                        </span>
                    )}
                </div>
                <div className="col-span-2 md:col-span-1">
                    <Input
                        label="Zip code"
                        id="zip_code"
                        type="text"
                        placeholder="Zip code"
                        {...register("zip")}
                        onBlur={() => trigger("zip")}
                    />
                    {errors.zip && (
                        <span className="text-red-500">
                            {errors.zip.message}
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};
