import { writeContract, waitForTransactionReceipt } from "@wagmi/core";
import { toast } from "react-toastify";
import { SentryService } from "./SentryErrorCapture";
import { wagmiConfig } from "App";

export const onWriteContract = async ({
  contractAddress,
  contractABI,
  functionName,
  args,
  from,
  value,
  gas,
  maxFeePerGas,
  maxPriorityFeePerGas,
  chainId,
  gasPrice,
  confirmations = undefined,
}) => {
  try {
    console.log("--onWriteContract--", {
      contractAddress,
      contractABI,
      functionName,
      args,
      from,
      value,
      gas,
      maxFeePerGas,
      maxPriorityFeePerGas,
      chainId,
      gasPrice,
    });
    // Type 'string' is not assignable to type '`0x${string}`'.
    const hash = await writeContract(wagmiConfig, {
      address: contractAddress,
      abi: contractABI,
      functionName,
      args,
      ...(chainId ? { chainId } : {}),
      ...(from ? { account: from } : {}),
      ...(value ? { value } : {}),
      ...(gas ? { gas } : {}),
      ...(gasPrice ? { gasPrice } : {}),
      ...(maxFeePerGas ? {maxFeePerGas} : {}),
      ...(maxPriorityFeePerGas ? {maxPriorityFeePerGas} : {}),
    });
    console.debug({hash})
    const data = await waitForTransactionReceipt(wagmiConfig,{
      hash,
      ...(confirmations ? { confirmations } : {}),
    });
    console.log("--onWriteContract TX response--", { data });
    return data;
  } catch (error) {
    toast.error("Something went wrong, please try again.");
    console.error(error);
    SentryService("onWriteContract", `${contractAddress}, ${functionName}`, error.message);
  }
};
