import axios from "axios";

const API = process.env.REACT_APP_AWS_API_URL;

export const useCommissionsService = () => {
  return {
    async fetchCommissions(params) {
      const response = await axios.get(`${API}/contracts/comm/list`, {
        params
      });
      return response.data;
    },
    async fetchCommissionsSummary() {
      const response = await axios.get(`${API}/contracts/comm/summary`);
      return response.data;
    },
  };
};
