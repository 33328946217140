import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTurboFundsService } from "modules/admin/services/TurboFunds";

export const useFetchTurboFunds = (queryKey = "turboFunds", initialParams = {}) => {
  const [params, setParams] = useState(initialParams);
  const { fetchTurboFunds } = useTurboFundsService();

  const queryResults = useQuery({
    queryKey: [queryKey, params],
    queryFn: () => fetchTurboFunds(params),
    select: (data) => data.data,
  });

  return { ...queryResults, setParams, params };
};
