import axios from "axios";
import { Button } from "common/components";
import { Input } from "common/components";
// import { TextArea } from "common/components/TextArea";
import AdminLayout from "layouts/AdminLayout";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useEditBid } from "../hooks";
import { dateToTimestamp } from "common/helpers/dateToTimestamp";
import { useAccount } from "wagmi";
import { toast } from "react-toastify";
import { Select } from "common/components";
import { onGetCategoriesList } from "modules/penny-auction/services/onGetCategoriesList";
import { useQuery } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import CustomEditor from "common/components/CustomEditor";
import { convertFromRaw, convertToRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";

export const EditProductPage = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { isFetching: isLoadingCategories, data: categoriesList } = useQuery({
    queryKey: [`categoriesList`],
    queryFn: async () => await onGetCategoriesList(),
    staleTime: 360000,
    refetchOnWindowFocus: false,
  });
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm();
  const { requestEditBid } = useEditBid();
  const { address } = useAccount();

  useEffect(() => {
    const fetchProducts = async () => {
      await axios
        .get(`${process.env.REACT_APP_AWS_API_URL}/contracts/pa/item?id=${id}`)
        .then((response) => {
          setValue("name", response.data?.data?.info?.name);
          setValue("description", response.data?.data?.info?.description);
          setValue("category", response.data?.data?.info?.category);
          setValue("maxBidders", response.data?.data?.maxBidders / 1e6);
          setValue("autoBuyPrice", response.data?.data?.autoBuyPrice / 1e6);
          setValue("timeInterval", response.data?.data?.timeInterval);
          setValue("auctionPrice", response.data?.data?.auctionPrice / 1e6);
          setValue("priceIncrements", response.data?.data?.priceIncrements / 1e6);
          setValue("addTimeOnBid", response.data?.data?.addTimeOnBid / 1e3);
          setValue("message", response.data?.data?.info ? response.data?.data?.info?.message : "");
          setValue("bidsPerClick", response.data?.data?.bidsPerClick);
          setValue("discount", response.data?.data?.discount);

          const date = new Date(response.data?.data?.startDate);

          // Get the individual components of the date
          const startDateyear = date.getFullYear();
          const startDatemonth = String(date.getMonth() + 1).padStart(2, "0");
          const startDateday = String(date.getDate()).padStart(2, "0");
          const startDatehours = String(date.getHours()).padStart(2, "0");
          const startDateminutes = String(date.getMinutes()).padStart(2, "0");
          setValue(
            "startDate",
            `${startDateyear}-${startDatemonth}-${startDateday}T${startDatehours}:${startDateminutes}`
          );
          let seconds = response.data?.data?.timeInterval ?? 0;
          const days = Math.floor(seconds / (24 * 3600));
          seconds %= 24 * 3600;
          const hours = Math.floor(seconds / 3600);
          seconds %= 3600;
          const minutes = Math.floor(seconds / 60);
          seconds %= 60;
          setValue("daysDuration", days);
          setValue("hoursDuration", hours);
          setValue("minutesDuration", minutes);
          setValue("secondsDuration", seconds);
        });
    };
    fetchProducts();
  }, [id, setValue]);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const timeInterval =
      +(data.secondsDuration || 0) +
      +(data.minutesDuration * 60 || 0) +
      +(data.hoursDuration * 3600 || 0) +
      +(data.daysDuration * 24 * 3600 || 0);
    const editorHTML = data.description;

    let htmlContent = "";

    if (editorHTML && editorHTML?.getCurrentContent) {
      const rawContentState = convertToRaw(editorHTML.getCurrentContent());
      const contentState = convertFromRaw(rawContentState);
      htmlContent = stateToHTML(contentState);
    }else{
      htmlContent = data.description;
    }

    let parsedInputs = {
      name: data.name || "",
      description: htmlContent ?? "",
      category: data.category || "",
      maxBidders: data.maxBidders ?? "",
      autoBuyPrice: data.autoBuyPrice ?? "",
      startDate: dateToTimestamp(data.startDate) ?? "",
      timeInterval: timeInterval ?? "",
      auctionPrice: data.auctionPrice ?? "",
      priceIncrements: data.priceIncrements ?? "",
      addTimeOnBid: data.addTimeOnBid ?? "",
      image: Array.from(data.image),
      message: data.message || "",
      discount: data.discount ?? "",
    };

    await requestEditBid({
      bidData: {
        userId: address,
        id,
        ...parsedInputs,
        onSuccess: async () => {
          toast.success("Product updated successfully.");
          navigate("/admin/products");
        },
        onError: async (error) => {
          setIsSubmitting(false);
          toast.error(`Failed to edit product. ${error}`);
        },
      },
    });
  };
  const categoriesOptions = categoriesList ? categoriesList.map((cat) => cat.name) : [];
  return (
    <AdminLayout>
      <div className="w-full bg-white">
        <div className="w-full px-8">
          <h1 className="text-2xl px-4 pt-8">Edit Product</h1>
          <form className="grid grid-cols-3 mt-4" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-span-3 md:col-span-2 px-4">
              <div className="mb-2">
                <div className="flex gap-4 items-center">
                  <label className="text-lg font-semibold" htmlFor="name">
                    Name
                  </label>
                  <div className="flex" title="Name of the product">
                    <FontAwesomeIcon icon={faCircleInfo} color="#4C4C4C" />
                  </div>
                </div>
                <Input
                  type="text"
                  {...register("name", { required: "This field is required." })}
                  id="name"
                />
                {errors.name && <span className="text-red-500">{errors.name.message}</span>}
              </div>
              <div className="mb-2">
                <div className="flex gap-4 items-center">
                  <label className="text-lg font-semibold" htmlFor="description">
                    Description
                  </label>
                  <div className="flex" title="Description of the product">
                    <FontAwesomeIcon icon={faCircleInfo} color="#4C4C4C" />
                  </div>
                </div>
                <div>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                      <CustomEditor
                        initialHtml={typeof field.value === "string" ? field.value : ""}
                        onChange={(editorState) => field.onChange(editorState)}
                      />
                    )}
                  />
                </div>
                {/* <TextArea {...register("description", { required: "This field is required." })} /> */}
                {errors.description && (
                  <span className="text-red-500">{errors.description.message}</span>
                )}
              </div>
              <div className="mb-2">
                <div className="flex gap-4 items-center">
                  <label className="text-lg font-semibold" htmlFor="category">
                    Category
                  </label>
                  <div className="flex" title="Category of the product">
                    <FontAwesomeIcon icon={faCircleInfo} color="#4C4C4C" />
                  </div>
                </div>
                {isLoadingCategories ? (
                  <p>Loading categories list...</p>
                ) : (
                  Array.isArray(categoriesList) && (
                    <Select
                      id="category"
                      options={categoriesOptions}
                      {...register("category", { required: "This field is required." })}
                    />
                  )
                )}
                {errors.category && <span className="text-red-500">{errors.category.message}</span>}
              </div>
              <div className="mb-2">
                <div className="flex gap-4 items-center">
                  <label className="text-lg font-semibold" htmlFor="maxBidders">
                    No new bidders price
                  </label>
                  <div
                    className="flex"
                    title="Price at which no new bidders are accepted, only current bidders continue."
                  >
                    <FontAwesomeIcon icon={faCircleInfo} color="#4C4C4C" />
                  </div>
                </div>
                <Input
                  type="number"
                  {...register("maxBidders", { required: "This field is required." })}
                  id="maxBidders"
                  step="0.01"
                />
              </div>
              <div className="mb-2">
                <div className="flex gap-4 items-center">
                  <label className="text-lg font-semibold" htmlFor="autoBuyPrice">
                    Retail Price
                  </label>
                  <div
                    className="flex"
                    title="Price at which anyone can buy the item even if they did not participate in the bids. The person who buys the product will get their bids refunded. This price is the reference when awarding the prize.
"
                  >
                    <FontAwesomeIcon icon={faCircleInfo} color="#4C4C4C" />
                  </div>
                </div>
                <Input
                  type="number"
                  {...register("autoBuyPrice", { required: "This field is required." })}
                  id="autoBuyPrice"
                  step="0.01"
                />
                {errors.autoBuyPrice && (
                  <span className="text-red-500">{errors.autoBuyPrice.message}</span>
                )}
              </div>
              <div className="mb-2">
                <div className="flex gap-4 items-center">
                  <label className="text-lg font-semibold" htmlFor="startDate">
                    Cooming soon time
                  </label>
                  <div className="flex" title="The day the penny auction starts.">
                    <FontAwesomeIcon icon={faCircleInfo} color="#4C4C4C" />
                  </div>
                </div>
                <Input
                  type="datetime-local"
                  {...register("startDate", { required: "This field is required." })}
                  id="startDate"
                />
                {errors.startDate && (
                  <span className="text-red-500">{errors.startDate.message}</span>
                )}
              </div>
              <div className="mb-2">
                <div className="flex gap-4 items-center">
                  <label className="text-lg font-semibold" htmlFor="timeZone">
                    Time Zone
                  </label>
                  <div className="flex" title="Time Zone, initially it will be Costa Rica's.">
                    <FontAwesomeIcon icon={faCircleInfo} color="#4C4C4C" />
                  </div>
                </div>
                <Select
                  id="timeZone"
                  options={["UTC", "GMT", "CET", "EST", "PST"]}
                  {...register("timeZone")}
                ></Select>
                {errors.timeZone && <span className="text-red-500">{errors.timeZone.message}</span>}
              </div>
              <div className="mb-2">
                <div className="flex gap-4 items-center">
                  <label className="text-lg font-semibold" htmlFor="timeInterval">
                    Starting date and time
                  </label>
                  <div className="flex" title="The date and time the penny auction starts.">
                    <FontAwesomeIcon icon={faCircleInfo} color="#4C4C4C" />
                  </div>
                </div>
                <div className="grid grid-cols-4 gap-1">
                  <Input
                    label="days"
                    type="number"
                    {...register("daysDuration", { required: "This field is required." })}
                    id="daysDuration"
                    defaultValue={0}
                    style={{ flex: "1 1 25%" }}
                  />
                  <Input
                    label="hours"
                    type="number"
                    {...register("hoursDuration", { required: "This field is required." })}
                    id="hoursDuration"
                    defaultValue={0}
                    style={{ flex: "1 1 25%" }}
                  />
                  <Input
                    label="minutes"
                    type="number"
                    {...register("minutesDuration", { required: "This field is required." })}
                    id="minutesDuration"
                    defaultValue={0}
                    style={{ flex: "1 1 25%" }}
                  />
                  <Input
                    label="seconds"
                    type="number"
                    {...register("secondsDuration", { required: "This field is required." })}
                    id="secondsDuration"
                    defaultValue={0}
                    style={{ flex: "1 1 25%" }}
                  />
                </div>
                {errors.timeInterval && (
                  <>
                    <span className="text-red-500">{errors.daysDuration.message}</span>
                    <span className="text-red-500">{errors.hoursDuration.message}</span>
                    <span className="text-red-500">{errors.minutesDuration.message}</span>
                    <span className="text-red-500">{errors.secondsDuration.message}</span>
                  </>
                )}
              </div>
              <div className="mb-2">
                <div className="flex gap-4 items-center">
                  <label className="text-lg font-semibold" htmlFor="message">
                    Add Message
                  </label>
                  <div
                    className="flex"
                    title="Also add the option for No New Bidders (it must appear automatically) when that price is reached, this message should automatically appear. Other messages saying Founder Only and VIP Shoppers Only."
                  >
                    <FontAwesomeIcon icon={faCircleInfo} color="#4C4C4C" />
                  </div>
                </div>
                <Input type="text" {...register("message")} id="message" />
                {errors.message && <span className="text-red-500">{errors.message.message}</span>}
              </div>
              <div className="mb-2">
                <div className="flex gap-4 items-center">
                  <label className="text-lg font-semibold" htmlFor="auctionPrice">
                    Auction Price
                  </label>
                  <div
                    className="flex"
                    title="This is the price that accumulates all the increments from each bid. It should always start at 0."
                  >
                    <FontAwesomeIcon icon={faCircleInfo} color="#4C4C4C" />
                  </div>
                </div>
                <Input
                  type="number"
                  {...register("auctionPrice", { required: "This field is required." })}
                  id="auctionPrice"
                  step="0.01"
                />
                {errors.auctionPrice && (
                  <span className="text-red-500">{errors.auctionPrice.message}</span>
                )}
              </div>
              <div className="mb-2">
                <div className="flex gap-4 items-center">
                  <label className="text-lg font-semibold" htmlFor="priceIncrements">
                    Price Increments
                  </label>
                  <div
                    className="flex"
                    title="The increment of the bidding price each time a person places a bid."
                  >
                    <FontAwesomeIcon icon={faCircleInfo} color="#4C4C4C" />
                  </div>
                </div>
                <Input
                  type="number"
                  {...register("priceIncrements", { required: "This field is required." })}
                  id="priceIncrements"
                  step="0.001"
                />
                {errors.priceIncrements && (
                  <span className="text-red-500">{errors.priceIncrements.message}</span>
                )}
              </div>
              <div className="mb-2">
                <div className="flex gap-4 items-center">
                  <label className="text-lg font-semibold" htmlFor="addTimeOnBid">
                    Reset time after bid <small>(seconds)</small>
                  </label>
                  <div
                    className="flex"
                    title="The seconds to which the clock resets after someone places a bid (click)."
                  >
                    <FontAwesomeIcon icon={faCircleInfo} color="#4C4C4C" />
                  </div>
                </div>
                <Input
                  type="number"
                  {...register("addTimeOnBid", { required: "This field is required." })}
                  id="addTimeOnBid"
                />
                {errors.addTimeOnBid && (
                  <span className="text-red-500">{errors.addTimeOnBid.message}</span>
                )}
              </div>
              <div className="mb-2">
                <div className="flex gap-4 items-center">
                  <label className="text-lg font-semibold" htmlFor="bidsPerClick">
                    Bids per click
                  </label>
                  <div
                    className="flex"
                    title="How many bids are deducted from the person who clicked in the auction, initially it will always be 1."
                  >
                    <FontAwesomeIcon icon={faCircleInfo} color="#4C4C4C" />
                  </div>
                </div>
                <Input
                  type="number"
                  {...register("bidsPerClick", { required: "This field is required." })}
                  id="bidsPerClick"
                  step="1"
                />
                {errors.bidsPerClick && (
                  <span className="text-red-500">{errors.bidsPerClick.message}</span>
                )}
              </div>
              <div className="mb-2">
                <div className="flex gap-4 items-center">
                  <label className="text-lg font-semibold" htmlFor="bidsPerClick">
                    Discount percentage
                  </label>
                  <div className="flex" title="Discount percentage on price when auction ends">
                    <FontAwesomeIcon icon={faCircleInfo} color="#4C4C4C" />
                  </div>
                </div>
                <Input
                  type="number"
                  {...register("discount", { required: "This field is required." })}
                  id="discount"
                  step="1"
                />
                {errors.discount && <span className="text-red-500">{errors.discount.message}</span>}
              </div>
            </div>
            <div className="col-span-3 md:col-span-2 px-4">
              <div className="mb-2">
                <label className="text-lg font-semibold" htmlFor="image">
                  Images <small>(Current images will be replaced)</small>
                </label>
                <Input type="file" multiple {...register("image")} id="image" />
                {errors.image && <span className="text-red-500">{errors.image.message}</span>}
              </div>
            </div>
            <div className="col-span-3 md:col-span-2 px-4">
              <div className="my-4">
                <Button
                  className="mr-2"
                  label="Back"
                  type="button"
                  onClick={() => window.history.back()}
                />
                <Button
                  disabled={isSubmitting}
                  label={isSubmitting ? "Updating product..." : "Edit product"}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </AdminLayout>
  );
};
