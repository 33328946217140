export const handleUserResponse = ({ data, setUser, setServerTime }) => {
  const usersData = JSON.parse(data);
  console.debug("users", { usersData });
  if (usersData && usersData.user) {
    setUser(usersData.user);
  }
  if (usersData && usersData.serverTime) {
    const currentTime = new Date().getTime();
    const offset = currentTime - usersData.serverTime;
    setServerTime({ serverTime: usersData.serverTime, currentTime, offset });
  }
};
