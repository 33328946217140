import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/base";

export const LoginModalHeader = ({
    setShowModal,
}: {
    setShowModal: (showModal: boolean) => void;
}) => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                color: "white",
                borderBottom: "1px solid rgba(255,255,255,0.1)",
                margin: "4px 0",
            }}
        >
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <h5 color="white">Conectar</h5>
            </div>
            <Button
                style={{
                    margin: "0.25rem",
                    background: "transparent",
                    padding: "4px 6px",
                    gap: "1rem",
                }}
                onClick={() => {
                    setShowModal(false);
                }}
            >
                <p>
                    <FontAwesomeIcon icon={faTimes} />
                </p>
            </Button>
        </div>
    );
};
