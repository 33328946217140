import axios from "axios";
import { Button } from "common/components";
import { Input } from "common/components";
import { TextArea } from "common/components/TextArea";
import AdminLayout from "layouts/AdminLayout";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useEditBid } from "../hooks";
import { dateToTimestamp } from "common/helpers/dateToTimestamp";
import { useAccount } from "wagmi";
import { toast } from "react-toastify";
import { useUpdatePackage } from "common/hooks/useUpdatePackage";
import { useFetchPackages } from "../hooks/Packages/useFetchPackages";
import { Select } from "common/components";

const MAX_IMAGE_SIZE = 5;

export const EditPackagePage = () => {
  const navigate = useNavigate();
  const pinataGatewayKey = `?pinataGatewayToken=${process.env.REACT_APP_PINATA_GATEWAY_KEY}`;
  const { data: packagesData } = useFetchPackages("packages", {
    page: 1,
    limit: 50,
    search: "",
    packageStatus: "all",
  });
  const packagesOptions = packagesData
    ? packagesData.packages.map((packData) => packData.package.name)
    : [];

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { id } = useParams();
  const [image, setImage] = useState([]);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();
  const isCheckedPackageUpgrade = watch("isUpgradePackage");
  const { requestUpdatePackage } = useUpdatePackage();
  const { address } = useAccount();

  const imageFileList = watch("image");

  useEffect(() => {
    if (imageFileList && imageFileList.length > 0) {
      if (imageFileList[0].size / 1e6 >= MAX_IMAGE_SIZE) {
        setError("image", {
          type: "string",
          message: `Image is too big (image size ${
            imageFileList[0].size / 1e6
          }MB. Max allowed ${MAX_IMAGE_SIZE} MB)`,
        });
        return;
      }
      clearErrors("image");
      const newImages = [];
      Array.from(imageFileList).forEach((file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          newImages.push(event.target.result);
          if (newImages.length === imageFileList.length) {
            setImage(newImages);
          }
        };
        reader.readAsDataURL(file);
      });
    } else {
      setImage([]);
    }
  }, [imageFileList]);

  useEffect(() => {
    const fetchPackage = async () => {
      await axios
        .get(`${process.env.REACT_APP_AWS_API_URL}/contracts/fg/package?id=${id}`)
        .then((response) => {
          setValue("name", response.data.data.packages[0].package.name);
          setValue("price", response.data.data.packages[0].package.price / 1e6);
          setValue("bidConsumable", response.data.data.packages[0].package.bidConsumable / 1e6);
          setValue("turbofund", response.data.data.packages[0].package.turboFund / 1e6);
          setValue("qvPoints", response.data.data.packages[0].package.qv);
          setValue("vat", response.data.data.packages[0].package.vat / 1e6);
          setValue("ucv", response.data.data.packages[0].package.ucv);
          setValue("bcv", response.data.data.packages[0].package.bcv);
          setValue(
            "consumablePercent",
            response.data.data.packages[0].package.consumablePercent / 1e2
          );
          setValue("isUpgradePackage", response.data.data.packages[0].package.isUpgradePackage);
          setValue("packageStatus", response.data.data.packages[0].status);
          setValue("binaryPercent", response.data.data.packages[0].binary.binaryPercent / 1e2);
          setValue("binaryCap", response.data.data.packages[0].binary.binaryCap / 1e6);
          setValue("bidPrice", response.data.data.packages[0].bid.bidPrice / 1e6);
          setValue("totalBids", response.data.data.packages[0].bid.totalBids);
          setValue(
            "bidExpirationDays",
            response.data.data.packages[0].expirations.bidExpirationDays
          );
          setValue(
            "qualificationExpirationDays",
            response.data.data.packages[0].expirations.qualificationExpirationDays
          );
          setValue(
            "cashbackExpirationDays",
            response.data.data.packages[0].expirations.cashbackExpirationDays
          );
          setValue("startupBonus", response.data.data.packages[0].bonus.startupBonus / 1e6);
          setValue("matchingBonus", response.data.data.packages[0].bonus.matchingBonus);
          setValue("unilevelBonus", response.data.data.packages[0].bonus.unilevelBonus);
          setValue("cashback", response.data.data.packages[0].cashback.cashback / 1e6);
          setValue(
            "globalPoolPercentage",
            response.data.data.packages[0].globalPoolAndTokens.globalPoolPercentage / 1e2
          );
          setValue(
            "tokensPercentage",
            response.data.data.packages[0].globalPoolAndTokens.tokensPercentage / 1e2
          );
          setValue("description", response.data.data.packages[0].extraInfo?.description);
          const startDate = new Date(response.data.data.packages[0].expirations.startDate * 1000);
          setValue(
            "startDate",
            response.data.data.packages[0].expirations.startDate == 0
              ? undefined
              : startDate.toISOString().split("T")[0]
          );
          const endDate = new Date(response.data.data.packages[0].expirations.endDate * 1000);
          setValue(
            "endDate",
            response.data.data.packages[0].expirations.endDate == 0
              ? undefined
              : endDate.toISOString().split("T")[0]
          );
          if (response.data.data.packages[0].upgradeInfo) {
            const fromInList = packagesData.packages.find(
              (packData) => packData.package.id == response.data.data.packages[0].upgradeInfo.from
            );
            setValue("fromPackage", fromInList?.package?.name);
            const toInList = packagesData.packages.find(
              (packData) => packData.package.id == response.data.data.packages[0].upgradeInfo.to
            );
            setValue("toPackage", toInList?.package?.name);
          }
          if (
            response.data.data.packages[0].extraInfo &&
            response.data.data.packages[0].extraInfo.images &&
            response.data.data.packages[0].extraInfo.images.length > 0
          ) {
            console.debug(
              `${response.data.data.packages[0].extraInfo.images[0]}${pinataGatewayKey}`
            );
            setImage([`${response.data.data.packages[0].extraInfo.images[0]}${pinataGatewayKey}`]);
          }
          // const date = new Date(response.data.data.startDate);
        });
    };
    if (packagesData) {
      fetchPackage();
    }
  }, [id, setValue, packagesData, pinataGatewayKey]);

  const onSubmit = async (data) => {
    let fromPackage;
    let toPackage;
    if (data.isUpgradePackage === true) {
      if (!data.fromPackage || !data.toPackage) {
        setError("fromPackage", {
          type: "string",
          message: "upgrade from and to packages can not be the same",
        });
        return;
      }
      if (!!data.fromPackage && !!data.toPackage && data.fromPackage === data.toPackage) {
        setError("fromPackage", {
          type: "string",
          message: "upgrade from and to packages can not be the same",
        });
        return;
      }
      fromPackage = packagesData.packages.find(
        (packData) => packData.package.name === data.fromPackage
      );
      if (fromPackage?.package.isUpgradePackage) {
        setError("fromPackage", {
          type: "string",
          message: "from package can not be an upgrade package",
        });
        return;
      }
      toPackage = packagesData.packages.find(
        (packData) => packData.package.name === data.toPackage
      );
      if (toPackage?.package.isUpgradePackage) {
        setError("toPackage", {
          type: "string",
          message: "to package can not be an upgrade package",
        });
        return;
      }
    }

    setIsSubmitting(true);

    let parsedInputs = {
      name: data.name,
      price: data.price,
      bidConsumable: data.bidConsumable,
      turboFund: data.turbofund,
      qv: data.qvPoints,
      vat: data.vat,
      ucv: data.ucv,
      bcv: data.bcv,
      consumablePercent: data.consumablePercent,
      isUpgradePackage: !!data.isUpgradePackage,
      fromPackage: fromPackage?.package?.id ?? null,
      toPackage: toPackage?.package?.id ?? null,
      binaryPercent: data.binaryPercent,
      binaryCap: data.binaryCap,
      bidPrice: data.bidPrice,
      totalBids: data.totalBids,
      bidExpirationDays: data.bidExpirationDays,
      qualificationExpirationDays: data.qualificationExpirationDays,
      cashbackExpirationDays: data.cashbackExpirationDays,
      startDate: dateToTimestamp(data.startDate) || 0,
      endDate: dateToTimestamp(data.endDate) || 0,
      startupBonus: data.startupBonus,
      matchingBonus: data.matchingBonus,
      unilevelBonus: data.unilevelBonus,
      cashback: data.cashback,
      globalPoolPercentage: data.globalPoolPercentage,
      tokensPercentage: data.tokensPercentage,
      description: data.description,
      status: data.packageStatus,
      images: Array.from(data.image),
    };

    Object.keys(parsedInputs).forEach((key) => {
      if (
        parsedInputs[key] === null ||
        parsedInputs[key] === undefined ||
        parsedInputs[key]?.length === 0
      ) {
        delete parsedInputs[key];
      }
    });

    // return console.log(parsedInputs);

    await requestUpdatePackage({
      packageData: {
        userId: address,
        packageId: id,
        ...parsedInputs,
        onSuccess: async () => {
          toast.success("Package updated successfully.");
          navigate("/admin/bids");
          setIsSubmitting(false);
        },
        onError: async (error) => {
          setIsSubmitting(false);
          toast.error(`Failed to edit package. ${error}`);
        },
      },
    });
  };
  return (
    <AdminLayout>
      <div className="w-full bg-white">
        <div className="w-full px-8">
          <h1 className="text-2xl px-4 pt-8">Edit Product</h1>
          <form className="grid grid-cols-3 mt-4" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-span-3 md:col-span-2 px-4">
              <div className="mb-2">
                <label className="text-lg font-semibold" htmlFor="name">
                  Package Name
                </label>
                <Input
                  type="text"
                  {...register("name", { required: "This field is required." })}
                  id="name"
                />
                {errors.name && <span className="text-red-500">{errors.name.message}</span>}
              </div>
              <div className="mb-2">
                <label className="text-lg font-semibold" htmlFor="price">
                  Price
                </label>
                <Input
                  type="number"
                  {...register("price", {
                    required: "This field is required.",
                    min: {
                      value: 0,
                      message: "Min value is 0",
                    },
                  })}
                  id="price"
                  step="0.01"
                />
                {errors.price && <span className="text-red-500">{errors.price.message}</span>}
              </div>
              <div className="mb-2">
                <label className="text-lg font-semibold" htmlFor="bidConsumable">
                  Bid Consumable
                </label>
                <Input
                  type="number"
                  {...register("bidConsumable", {
                    required: "This field is required.",
                    min: {
                      value: 0,
                      message: "Min value is 0",
                    },
                  })}
                  id="bidConsumable"
                  step="0.01"
                />
                {errors.bidConsumable && (
                  <span className="text-red-500">{errors.bidConsumable.message}</span>
                )}
              </div>
              <div className="mb-2">
                <label className="text-lg font-semibold" htmlFor="turbofund">
                  Turbofund
                </label>
                <Input
                  type="number"
                  {...register("turbofund", {
                    required: "This field is required.",
                    min: {
                      value: 0,
                      message: "Min value is 0",
                    },
                  })}
                  id="turbofund"
                  step="0.01"
                />
                {errors.turbofund && (
                  <span className="text-red-500">{errors.turbofund.message}</span>
                )}
              </div>
              <div className="mb-2">
                <label className="text-lg font-semibold" htmlFor="qvPoints">
                  QV Points
                </label>
                <Input
                  type="number"
                  {...register("qvPoints", {
                    required: "This field is required.",
                    min: {
                      value: 0,
                      message: "Min value is 0",
                    },
                  })}
                  id="qvPoints"
                  step="1"
                />
                {errors.qvPoints && <span className="text-red-500">{errors.qvPoints.message}</span>}
              </div>
              <div className="mb-2">
                <label className="text-lg font-semibold" htmlFor="vat">
                  VAT
                </label>
                <Input
                  type="number"
                  {...register("vat", {
                    required: "This field is required.",
                    min: {
                      value: 0,
                      message: "Min value is 0",
                    },
                  })}
                  id="vat"
                  step="1"
                />
                {errors.vat && <span className="text-red-500">{errors.vat.message}</span>}
              </div>
              <div className="mb-2">
                <label className="text-lg font-semibold" htmlFor="ucv">
                  UCV
                </label>
                <Input
                  type="number"
                  {...register("ucv", {
                    required: "This field is required.",
                    min: {
                      value: 0,
                      message: "Min value is 0",
                    },
                  })}
                  id="ucv"
                  step="1"
                />
                {errors.ucv && <span className="text-red-500">{errors.ucv.message}</span>}
              </div>
              <div className="mb-2">
                <label className="text-lg font-semibold" htmlFor="bcv">
                  BCV
                </label>
                <Input
                  type="number"
                  {...register("bcv", {
                    required: "This field is required.",
                    min: {
                      value: 0,
                      message: "Min value is 0",
                    },
                  })}
                  id="bcv"
                  step="1"
                />
                {errors.bcv && <span className="text-red-500">{errors.bcv.message}</span>}
              </div>
              <div className="mb-2">
                <label className="text-lg font-semibold" htmlFor="consumablePercent">
                  Consumable Percent
                </label>
                <Input
                  type="number"
                  id="consumablePercent"
                  {...register("consumablePercent", {
                    required: "This field is required",
                    min: {
                      value: 0,
                      message: "Min value is 0",
                    },
                  })}
                  step="0.01"
                />
                {/* <Input
                  type="number"
                  {...register("consumablePercent", { required: "This field is required." })}
                  id="consumablePercent"
                /> */}
                {errors.consumablePercent && (
                  <span className="text-red-500">{errors.consumablePercent.message}</span>
                )}
              </div>
              <div className="mb-2 flex gap-1 pt-2 items-center">
                <label className="text-lg font-semibold" htmlFor="isUpgradePackage">
                  Is Upgrade Package
                </label>
                <Input
                  className="w-6 h-6"
                  type="checkbox"
                  {...register("isUpgradePackage")}
                  id="isUpgradePackage"
                />
                {errors.isUpgradePackage && (
                  <span className="text-red-500">{errors.isUpgradePackage.message}</span>
                )}
              </div>
              {isCheckedPackageUpgrade && (
                <div className="mb-2 flex justify-around">
                  <div>
                    <label className="text-lg font-semibold" htmlFor="fromPackage">
                      Upgrade from Package
                    </label>
                    <Select
                      id="fromPackage"
                      {...register("fromPackage")}
                      options={packagesOptions}
                    />
                    {errors.fromPackage && (
                      <span className="text-red-500">{errors.fromPackage.message}</span>
                    )}
                  </div>
                  <div>
                    <label className="text-lg font-semibold" htmlFor="toPackage">
                      Upgrade to Package
                    </label>
                    <Select id="toPackage" {...register("toPackage")} options={packagesOptions} />
                    {errors.toPackage && (
                      <span className="text-red-500">{errors.toPackage.message}</span>
                    )}
                  </div>
                </div>
              )}

              <div className="mb-2">
                <label className="text-lg font-semibold" htmlFor="binaryPercent">
                  Binary Percent
                </label>
                <Input
                  type="number"
                  id="binaryPercent"
                  {...register("binaryPercent", {
                    required: "This field is required",
                    min: {
                      value: 0,
                      message: "min value is 0",
                    },
                  })}
                  step="0.01"
                />
                {/* <Input
                  type="number"
                  {...register("binaryPercent", { required: "This field is required." })}
                  id="binaryPercent"
                /> */}
                {errors.binaryPercent && (
                  <span className="text-red-500">{errors.binaryPercent.message}</span>
                )}
              </div>
              <div className="mb-2">
                <label className="text-lg font-semibold" htmlFor="binaryCap">
                  Binary Cap
                </label>
                <Input
                  type="number"
                  {...register("binaryCap", {
                    required: "This field is required.",
                    min: {
                      value: 0,
                      message: "Min value is 0",
                    },
                  })}
                  id="binaryCap"
                  step="0.01"
                />
                {errors.binaryCap && (
                  <span className="text-red-500">{errors.binaryCap.message}</span>
                )}
              </div>
              <div className="mb-2">
                <label className="text-lg font-semibold" htmlFor="bidPrice">
                  Bid Price
                </label>
                <Input
                  type="number"
                  {...register("bidPrice", {
                    required: "This field is required.",
                    min: {
                      value: 0,
                      message: "Min value is 0",
                    },
                  })}
                  id="bidPrice"
                  step="0.01"
                />
                {errors.bidPrice && <span className="text-red-500">{errors.bidPrice.message}</span>}
              </div>
              <div className="mb-2">
                <label className="text-lg font-semibold" htmlFor="totalBids">
                  Total Bids
                </label>
                <Input
                  type="number"
                  {...register("totalBids", {
                    required: "This field is required.",
                    min: {
                      value: 0,
                      message: "Min value is 0",
                    },
                  })}
                  id="totalBids"
                  step="1"
                />
                {errors.totalBids && (
                  <span className="text-red-500">{errors.totalBids.message}</span>
                )}
              </div>
              <div className="mb-2">
                <label className="text-lg font-semibold" htmlFor="bidExpirationDays">
                  Bid Expirations Days
                </label>
                <Input
                  type="number"
                  {...register("bidExpirationDays", {
                    required: "This field is required.",
                    min: {
                      value: 0,
                      message: "Min value is 0",
                    },
                  })}
                  id="bidExpirationDays"
                  step="1"
                />
                {errors.bidExpirationDays && (
                  <span className="text-red-500">{errors.bidExpirationDays.message}</span>
                )}
              </div>
              <div className="mb-2">
                <label className="text-lg font-semibold" htmlFor="qualificationExpirationDays">
                  Qualification Expiration Days
                </label>
                <Input
                  type="number"
                  {...register("qualificationExpirationDays", {
                    required: "This field is required.",
                    min: {
                      value: 0,
                      message: "Min value is 0",
                    },
                  })}
                  id="qualificationExpirationDays"
                  step="1"
                />
                {errors.qualificationExpirationDays && (
                  <span className="text-red-500">{errors.qualificationExpirationDays.message}</span>
                )}
              </div>
              <div className="mb-2">
                <label className="text-lg font-semibold" htmlFor="cashbackExpirationDays">
                  Cashback Expiration Days
                </label>
                <Input
                  type="number"
                  {...register("cashbackExpirationDays", {
                    required: "This field is required.",
                    min: {
                      value: 0,
                      message: "Min value is 0",
                    },
                  })}
                  id="cashbackExpirationDays"
                  step="1"
                />
                {errors.cashbackExpirationDays && (
                  <span className="text-red-500">{errors.cashbackExpirationDays.message}</span>
                )}
              </div>
              <div className="mb-2">
                <label className="text-lg font-semibold" htmlFor="startDate">
                  Start Date
                </label>
                <Input type="date" {...register("startDate")} id="startDate" />
                {errors.startDate && (
                  <span className="text-red-500">{errors.startDate.message}</span>
                )}
              </div>
              <div className="mb-2">
                <label className="text-lg font-semibold" htmlFor="endDate">
                  End Date
                </label>
                <Input type="date" {...register("endDate")} id="endDate" />
                {errors.endDate && <span className="text-red-500">{errors.endDate.message}</span>}
              </div>
              <div className="mb-2">
                <label className="text-lg font-semibold" htmlFor="startupBonus">
                  Startup Bonus
                </label>
                <Input
                  type="number"
                  {...register("startupBonus", {
                    required: "This field is required.",
                    min: {
                      value: 0,
                      message: "Min value is 0",
                    },
                  })}
                  id="startupBonus"
                  step="0.01"
                />
                {errors.startupBonus && (
                  <span className="text-red-500">{errors.startupBonus.message}</span>
                )}
              </div>
              <div className="mb-2">
                <label className="text-lg font-semibold" htmlFor="matchingBonus">
                  Matching Bonus
                </label>
                <Input
                  type="number"
                  {...register("matchingBonus", {
                    required: "This field is required.",
                    min: {
                      value: 0,
                      message: "Min value is 0",
                    },
                  })}
                  id="matchingBonus"
                  step="1"
                />
                {errors.matchingBonus && (
                  <span className="text-red-500">{errors.matchingBonus.message}</span>
                )}
              </div>
              <div className="mb-2">
                <label className="text-lg font-semibold" htmlFor="unilevelBonus">
                  Unilevel Bonus
                </label>
                <Input
                  type="number"
                  {...register("unilevelBonus", {
                    required: "This field is required.",
                    min: {
                      value: 0,
                      message: "Min value is 0",
                    },
                  })}
                  id="unilevelBonus"
                  step="1"
                />
                {errors.unilevelBonus && (
                  <span className="text-red-500">{errors.unilevelBonus.message}</span>
                )}
              </div>
              <div className="mb-2">
                <label className="text-lg font-semibold" htmlFor="cashback">
                  Cashback
                </label>
                <Input
                  type="number"
                  {...register("cashback", {
                    required: "This field is required.",
                    min: {
                      value: 0,
                      message: "Min value is 0",
                    },
                  })}
                  id="cashback"
                  step="0.01"
                />
                {errors.cashback && <span className="text-red-500">{errors.cashback.message}</span>}
              </div>
              <div className="mb-2">
                <label className="text-lg font-semibold" htmlFor="globalPoolPercentage">
                  Global Pool Percentage
                </label>
                <Input
                  type="number"
                  id="globalPoolPercentage"
                  {...register("globalPoolPercentage", {
                    required: "This field is required",
                    min: {
                      value: 0,
                      message: "min value is 0",
                    },
                  })}
                  step="0.01"
                />
                {/* <Input
                  type="number"
                  {...register("globalPoolPercentage", { required: "This field is required." })}
                  id="globalPoolPercentage"
                /> */}
                {errors.globalPoolPercentage && (
                  <span className="text-red-500">{errors.globalPoolPercentage.message}</span>
                )}
              </div>
              <div className="mb-2">
                <label className="text-lg font-semibold" htmlFor="tokensPercentage">
                  Tokens Percentage
                </label>
                <Input
                  type="number"
                  id="tokensPercentage"
                  {...register("tokensPercentage", {
                    required: "This field is required",
                    min: {
                      value: 0,
                      message: "min value is 0",
                    },
                  })}
                  step="0.01"
                />
                {/* <Input
                  type="number"
                  {...register("tokensPercentage", { required: "This field is required." })}
                  id="tokensPercentage"
                /> */}
                {errors.tokensPercentage && (
                  <span className="text-red-500">{errors.tokensPercentage.message}</span>
                )}
              </div>
              <div className="mb-2">
                <label className="text-lg font-semibold" htmlFor="description">
                  Description
                </label>
                <TextArea {...register("description")} id="description" />
                {errors.description && (
                  <span className="text-red-500">{errors.description.message}</span>
                )}
              </div>
            </div>

            <div className="col-span-3 md:col-span-2 px-4">
              <div className="mb-2 flex gap-1 pt-2 items-center">
                <label className="text-lg font-semibold" htmlFor="isUpgradePackage">
                  Package status
                </label>
                <Input
                  className="w-6 h-6"
                  type="checkbox"
                  {...register("packageStatus")}
                  id="packagrStatus"
                />
                {errors.packagrStatus && (
                  <span className="text-red-500">{errors.packagrStatus.message}</span>
                )}
              </div>
            </div>
            <div className="col-span-3 md:col-span-2 px-4">
              <div className="mb-2">
                <h2 className="text-lg font-semibold">Image</h2>
                <div className="flex w-full">
                  {image.length > 0 && (
                    <img
                      src={image[0]}
                      alt={`Imagen cargada 1`}
                      style={{ width: "150px", margin: "10px" }}
                    />
                  )}
                </div>
                <div className="flex w-full">
                  {image.length === 0 && (
                    <img
                      src="https://via.placeholder.com/150"
                      alt="product"
                      style={{ width: "150px" }}
                    />
                  )}
                </div>
                <Input type="file" {...register("image")} id="image" />
                {errors.image && <span className="text-red-500">{errors.image.message}</span>}
              </div>
            </div>
            <div className="col-span-3 md:col-span-2 px-4">
              <div className="my-4">
                <Button
                  className="mr-2"
                  label="Back"
                  type="button"
                  onClick={() => window.history.back()}
                />
                <Button
                  disabled={isSubmitting}
                  label={isSubmitting ? "Updating product..." : "Edit product"}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </AdminLayout>
  );
};
