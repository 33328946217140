import { useNavigate } from "react-router-dom";
import { Button } from "@common/components";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAccount } from "wagmi";

export const JoinUsStep5 = ({ watch }) => {
    const navigate = useNavigate();

    const { address } = useAccount();

    return (
        <div className="p-6 w-full flex flex-col" style={{ minHeight: "80vh" }}>
            <h2 className="text-3xl text-dark mb-6 uppercase font-bold">
                Welcome!
            </h2>
            {/* <p className="text-xl text-dark mb-6 font-semibold">
                Payment completed
            </p> */}
            <div className="grid grid-cols-2 gap-4 border-b border-clair-gray pb-20">
                <div className="col-span-2 xl:col-span-1">
                    <div className="flex flex-col">
                        <div className="flex">
                            <div className="flex justify-center items-center bg-lime-400 rounded-full w-20 h-20 mx-4">
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    color="white"
                                    size="3x"
                                />
                            </div>
                            {/* <p className="text-4xl text-dark mt-4">
                                ${watch("amount")}.00
                            </p> */}
                        </div>
                        <p className="text-md text-clair-gray mx-4 my-4">
                            Effective from {new Date().toLocaleDateString()}
                        </p>
                    </div>
                </div>
                <div className="col-span-2 xl:col-span-1">
                    <div className="flex flex-col w-full">
                        <p className="text-lg text-dark mx-4 font-bold">
                            Account
                        </p>
                        <p className="text-md text-dark mx-4">
                            {watch("fullName")}
                        </p>
                        <p className="text-md text-dark mx-4 font-semibold">
                            {watch("email")}
                        </p>
                        <p className="text-sm text-gray-500 mx-4 break-words">
                            {address}
                        </p>
                    </div>
                </div>
            </div>
            <div className="flex justify-center items-center mt-6 flex-col">
                <Button
                    label="Go to dashboard"
                    onClick={() => navigate("/app/dashboard")}
                />
            </div>
        </div>
    );
};
