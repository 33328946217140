import { CONSTANTS } from "../../../constants/constants";

export const onGetLeadership = async ({ contractFunctions, userRank }) => {
  const result = {
    leadershipPool: 0,
    percentage: 0,
    total: 0,
  };
  if (!userRank.userPackage && !userRank.leadership) return result;

  result.leadershipPool = await contractFunctions.userPoints.getLeadershipPool();
  if (userRank.leadership) {
    result.percentage = userRank.leadership.globalPoolPercentage;
  } else {
    const packagesList = await contractFunctions.packages.packagesList();
    const filteredPackage = packagesList.filter(
      (pack) => pack.fullPackage.id == userRank.userPackage?.packageId
    );
    if (filteredPackage.length == 0) {
      return result;
    }
    const globalPoolPercentage = filteredPackage[0].globalPoolAndTokens.globalPoolPercentage;
    const globalPoolExpirationDays =
      filteredPackage[0].globalPoolAndTokens.globalPoolExpirationDays;
    if (
      (userRank.userPackage?.paidDate || 0) + globalPoolExpirationDays * 24 * 60 * 60 <
      CONSTANTS.CURRENT_DATE_TIME_IN_SECONDS
    ) {
      return result;
    }
    result.percentage = globalPoolPercentage;
  }
  //we convert percentage from integer to 2 decimals then we divide by 100 thats why 1e4
  result.total = result.leadershipPool * (result.percentage / 1e4);

  return result;
};
