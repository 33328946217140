import { Logo } from "@common/components";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBox, faCoins, faWallet } from "@fortawesome/free-solid-svg-icons";
import { Footer } from "common/components";

import BagHappy from "@assets/icons/admin/bag-happy.svg";
import BagTimer from "@assets/icons/admin/bag-timer.svg";
import BuyCrypto from "@assets/icons/admin/buy-crypto.svg";
import Calendar from "@assets/icons/admin/calendar.svg";
import Crown from "@assets/icons/admin/crown.svg";
import MoneySend from "@assets/icons/admin/money-send.svg";
import People from "@assets/icons/admin/people.svg";
import Receipt2 from "@assets/icons/admin/receipt-2.svg";
import ReceiptItem from "@assets/icons/admin/receipt-item.svg";
import { useCallback, useEffect, useState } from "react";
import DropdownComponent  from "common/components/Dropdown";
import { web3AuthInstance } from "App";
import { useGetUserInfo } from "common/hooks/useGetUserInfo";

const AdminLayout = ({ children }) => {
  const [active, setActive] = useState("");

  const options = [
    { title: "Dashboard", redirectionUrl: "/app/dashboard" },
    { title: "Store", redirectionUrl: "/app/store" },
    { title: "Profile", redirectionUrl: "/app/profile" },
    { title: "Logout", redirectionUrl: "/logout" },
  ];

  const { userInfo } = useGetUserInfo();
  const [profileImg, setProfileImg] = useState("");

  const getUserInfo = useCallback(async () => {
    try {
      const response = await web3AuthInstance.getUserInfo();
      if (response?.profileImage) {
        setProfileImg(response.profileImage);
      }
    } catch (error) {
      console.log("error", error);
    }
  }, []);

  // const handleJoin = async () => {
  //   // setShowModal(true);
  //   if (connect)
  //     connect(
  //       { connector: connectors[0] },
  //       {
  //         onSuccess: () => {
  //           getUserInfo();
  //         },
  //       }
  //     );
  // };

  useEffect(() => {
    if (web3AuthInstance.connected) getUserInfo();
  }, [web3AuthInstance.connected]);

  const handleActive = () => {
    switch (location.pathname) {
      case "/admin/funds":
        setActive("/admin/funds");
        break;
      case "/admin/balances":
        setActive("/admin/balances");
        break;
      case "/admin/products":
        setActive("/admin/products");
        break;
      case "/admin/past-products":
        setActive("/admin/past-products");
        break;
      case "/admin/calendar":
        setActive("/admin/calendar");
        break;
      case "/admin/users":
        setActive("/admin/users");
        break;
      case "/admin/users/detail":
        setActive("/admin/users");
        break;
      case "/admin/organization-users":
        setActive("/admin/organization-users");
        break;
      case "/admin/commissions":
        setActive("/admin/commissions");
        break;
      case "/admin/turbo-funds":
        setActive("/admin/turbo-funds");
        break;
      case "/admin/bids":
        setActive("/admin/bids");
        break;
      default:
        setActive("");
        break;
    }
  };
  useEffect(() => {
    handleActive();
  }, [location.pathname]);
  return (
    <div className="flex flex-col h-full min-h-screen">
      <header>
        <nav className="bg-[#F8F8F8] flex justify-between items-center p-4 sticky top-0">
          <Logo variant="dark-letters" />
          <div className="flex justify-center">
            <div className="mx-4 flex items-center">
              <div className="flex items-center gap-x-2">
                {userInfo ? (
                  <>
                    {profileImg && profileImg.length > 0 ? (
                      <img
                        className="h-12 w-12 cursor-pointer rounded-full object-cover hidden md:block"
                        src={profileImg}
                      />
                    ) : (
                      <div className="h-12 w-12 bg-clair-gray rounded-full"></div>
                    )}
                    <DropdownComponent
                      title={userInfo && `${userInfo.info.name}`}
                      items={options}
                    />
                  </>
                ) : (
                  <>
                  </>
                )}
              </div>
            </div>
          </div>
        </nav>
      </header>
      <div className="sm:flex flex-1 bg-[#E3E3D7] p-0 m-0">
        <div className="lg:min-w-[250px] border-r border-r-[#808080] p-0 m-0">
          <ul className="p-0 m-0">
            <li
              className={`text-lg text-dark border-b border-b-[#808080] p-5 ${
                active === "/admin/funds" ? "bg-white" : ""
              }`}
            >
              <Link to="/admin/funds" className="flex flex-col items-center">
                <img src={BuyCrypto} alt="Money Send" className="w-8 h-8" />
                Funds
              </Link>
            </li>
            <li
              className={`text-lg text-dark border-b border-b-[#808080] p-5 ${
                active === "/admin/balances" ? "bg-white" : ""
              }`}
            >
              <Link to="/admin/balances" className="flex flex-col items-center">
                <img src={Receipt2} alt="Money Send" className="w-8 h-8" />
                Balances
              </Link>
            </li>
            <li
              className={`text-lg text-dark border-b border-b-[#808080] p-5 ${
                active === "/admin/products" ? "bg-white" : ""
              }`}
            >
              <Link to="/admin/products" className="flex flex-col items-center">
                <img src={BagHappy} alt="Money Send" className="w-8 h-8" />
                Live Products
              </Link>
            </li>
            <li
              className={`text-lg text-dark border-b border-b-[#808080] p-5 ${
                active === "/admin/past-products" ? "bg-white" : ""
              }`}
            >
              <Link to="/admin/past-products" className="flex flex-col items-center">
                <img src={BagTimer} alt="Money Send" className="w-8 h-8" />
                Past Products
              </Link>
            </li>
            <li
              className={`text-lg text-dark border-b border-b-[#808080] p-5 ${
                active === "/admin/calendar" ? "bg-white" : ""
              }`}
            >
              <Link to="/admin/calendar" className="flex flex-col items-center">
                <img src={Calendar} alt="Money Send" className="w-8 h-8" />
                Calendar
              </Link>
            </li>
            <li
              className={`text-lg text-dark border-b border-b-[#808080] p-5 ${
                active === "/admin/users" ? "bg-white" : ""
              }`}
            >
              <Link to="/admin/users" className="flex flex-col items-center">
                <img src={People} alt="Money Send" className="w-8 h-8" />
                Users
              </Link>
            </li>
            <li
              className={`text-lg text-dark border-b border-b-[#808080] p-5 ${
                active === "/admin/organization-users" ? "bg-white" : ""
              }`}
            >
              <Link to="/admin/organization-users" className="flex flex-col items-center">
                <img src={People} alt="Money Send" className="w-8 h-8" />
                Organization Users
              </Link>
            </li>
            <li
              className={`text-lg text-dark border-b border-b-[#808080] p-5 ${
                active === "/admin/commissions" ? "bg-white" : ""
              }`}
            >
              <Link to="/admin/commissions" className="flex flex-col items-center">
                <img src={ReceiptItem} alt="Money Send" className="w-8 h-8" />
                Commissions
              </Link>
            </li>
            <li
              className={`text-lg text-dark border-b border-b-[#808080] p-5 ${
                active === "/admin/turbo-funds" ? "bg-white" : ""
              }`}
            >
              <Link to="/admin/turbo-funds" className="flex flex-col items-center">
                <img src={Crown} alt="Money Send" className="w-8 h-8" />
                Turbo Funds
              </Link>
            </li>
            <li
              className={`text-lg text-dark border-b border-b-[#808080] p-5 ${
                active === "/admin/bids" ? "bg-white" : ""
              }`}
            >
              <Link to="/admin/bids" className="flex flex-col items-center">
                <img src={MoneySend} alt="Money Send" className="w-8 h-8" />
                Bids
              </Link>
            </li>
          </ul>
        </div>
        <div className="p-5 w-full">{children}</div>
      </div>
      <div className="bg-[#E3E3D7]">
        <Footer />
      </div>
    </div>
  );
};

export default AdminLayout;
