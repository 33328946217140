import { LoginModalHeader } from "./LoginModalHeader";
import { LoginModalBody } from "./LoginModalBody";

export const LoginModal = ({
    setShowModal,
    showModal,
    connectors,
    connect,
    error,
}) => {
    return (
        <>
            {/* <div mb={2} display="grid" gap="1rem"> */}
            <div style={{ marginBottom: 2, display: "grid", gap: "1rem" }}>
                {showModal && (
                    // <div display="flex" justifyContent="center" alignItems="center" gap="0.5rem">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "0.5rem",
                        }}
                    >
                        <p>Conectando...</p>
                    </div>
                )}
                {/* <w3m-button label="Conecta para iniciar" loadingLabel="Conectando..." balance={false} /> */}
                {error && <p>{error.message}</p>}
            </div>
            <div
                id="loginModalBackground"
                style={{
                    overflow: "hidden",
                    position: "fixed",
                    width: "100%",
                    height: "100%",
                    top: 0,
                    left: 0,
                    // opacity: showModal ? 1 : 0,
                    background: "rgba(0,0,0,0.25)",
                    display: showModal ? "flex" : "none",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: showModal ? "2" : "-1",
                    transition: "all 0.3s ease",
                }}
                onClick={(e) => {
                    // @ts-expect-error something is wrong with the types
                    if (e?.target?.id === "loginModalBackground")
                        setShowModal(false);
                }}
            >
                <div
                    style={{
                        background: "#191a1a",
                        color: "#fff",
                        maxWidth: "360px",
                        width: "100%",
                        display: "grid",
                        padding: "1rem",
                        borderRadius: "30px",
                    }}
                >
                    <LoginModalHeader setShowModal={setShowModal} />
                    <LoginModalBody connectors={connectors} connect={connect} />
                </div>
            </div>
        </>
    );
};
