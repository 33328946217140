export const onGetNavigateUrl = ({ page, category, search, sort }) => {
  let currentUrl = `${window.location.pathname}${window.location.search}`;

  const updateQueryParam = (url, param, value) => {
    const encodedParam = encodeURIComponent(param);
    const encodedValue = encodeURIComponent(value);
    const regex = new RegExp(`([?&])${encodedParam}=.*?(&|$)`, "i");

    if (value === null || value === "") {
      if (url.match(regex)) {
        url = url.replace(regex, (match, p1, p2) => (p2 === "&" ? p1 : "")).replace(/&$/, "");
        return url;
      }
      return url;
    } else {
      const separator = url.includes("?") ? "&" : "?";
      if (url.match(regex)) {
        return url.replace(regex, `$1${encodedParam}=${encodedValue}$2`);
      } else {
        return url + separator + encodedParam + "=" + encodedValue;
      }
    }
  };

  if (page !== undefined) {
    currentUrl = updateQueryParam(currentUrl, "page", page);
  }

  if (category !== undefined) {
    currentUrl = updateQueryParam(currentUrl, "category", category);
  }

  if (search !== undefined) {
    currentUrl = updateQueryParam(currentUrl, "search", search);
  }

  if (sort !== undefined) {
    currentUrl = updateQueryParam(currentUrl, "sort", sort);
  }

  // Clean up any trailing '&' or '?' that might be left after removing a parameter
  currentUrl = currentUrl.replace(/[?&]$/, "");

  return currentUrl;
};
