const levelAddressesPromises = async (addressToGet, contractFunctions) => {
  await new Promise((resolve) => setTimeout(resolve, 50));
  const sponsored = await contractFunctions.unilevel.unilevelUsersSponsoredBy(addressToGet);
  if (sponsored) {
    return sponsored.map((sponsor) => sponsor.id);
  }
};

export const getLevelAddresses = async ({ addresses, contractFunctions }) => {
  addresses = addresses.filter((v) => v);
  if (!addresses || addresses.length == 0) return [];

  const sponsoredList = [];
  for (const currentAddress of addresses) {
    const packagex = await levelAddressesPromises(currentAddress, contractFunctions);
    if (packagex) {
      sponsoredList.push(packagex);
    }
  }

  return sponsoredList.flatMap((v) => v);
};
