import { GenericList } from "common/components";
import AdminLayout from "layouts/AdminLayout";
import React, { useEffect, useState } from "react";
import { useFetchOrganizationUsers } from "../hooks/OrganizationUsers/useFetchOrganizationUsers";
import { useNavigate } from "react-router-dom";
import { EditOrganizationUserModal } from "../components/OrganizationUsers/EditOrganizationUserModal";

export const OrganizationUsersPage = () => {
  const [searchInput, setSearchInput] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const {
    data: usersData,
    isPending,
    params,
    setParams,
  } = useFetchOrganizationUsers("organizationUsers", {
    page: 1,
    limit: 10,
    search: "",
  });

  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  useEffect(() => {
    if (!usersData) return;
    setUsers(
      usersData?.users.map((user) => {
        return {
          id: user.id,
          role: user.userRoles.length > 0 ? user.userRoles[0]?.name : "N/A",
          name: user.name.length > 0 ? user.name : user.username,
          status: user.statusText,
          actions: (
            <div className="flex gap-x-2">
              <button
                className="border bg-[#87BAD7] text-[#051F40] font-semibold px-4 py-2"
                onClick={() => {
                  setIsEditModalOpen(true);
                  setUserToEdit(user);
                }}
              >
                Edit
              </button>
              {/* <button
                className="border bg-[#CCCCCC] text-[#051F40] font-semibold px-4 py-2"
                onClick={() => {
                  setIsDeleteModalOpen(true);
                  setUserToDelete(user);
                }}
              >
                Delete
              </button> */}
            </div>
          ),
        };
      })
    );
  }, [usersData]);

  const columns = {
    name: { header: "Name" },
    role: { header: "Role" },
    status: { header: "Status" },
    actions: {
      header: "Actions",
    },
  };

  const headerOptions = (
    <div className="flex w-full justify-between">
      <div className="flex-1 border border-[#161C24]">
        <input
          type="text"
          placeholder="Search"
          className="p-2 w-3/4"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              setParams({ ...params, search: searchInput });
            }
          }}
        />
        <button
          className="bg-[#1840CE] text-white px-4 py-2 w-1/4"
          onClick={() => {
            setParams({ ...params, search: searchInput });
          }}
        >
          Search
        </button>
      </div>
      <button
        className="bg-[#BCE210] text-[#051F40] font-semibold px-4 py-2 ml-2"
        onClick={() => navigate("/admin/organization-users/create")}
      >
        Create New User
      </button>
    </div>
  );

  const [activeTab, setActiveTab] = useState("personal-details");
  const activeClass = "border-b-2 border-b-[#1840CE]";

  const Tabs = (
    <div className="grid grid-cols-3 w-full">
      <div
        className={`flex border-b-2 justify-center p-4 cursor-pointer ${
          activeTab === "personal-details" ? activeClass : ""
        }`}
        onClick={() => setActiveTab("personal-details")}
      >
        Personal details
      </div>
      <div
        className={`flex border-b-2 justify-center p-4 cursor-pointer ${
          activeTab === "permissions" ? activeClass : ""
        }`}
        onClick={() => setActiveTab("permissions")}
      >
        Permissions
      </div>
    </div>
  );

  return (
    <AdminLayout>
      <div className="container mx-auto">
        <div className="bg-white px-8">
          <div className="w-full">
            <h1 className="text-xl font-semibold px-4 pt-8">Organization Users</h1>
          </div>
          <GenericList
            data={users}
            columns={columns}
            headerOptions={headerOptions}
            isLoading={isPending}
          />
          <div className="w-full justify-center flex p-5">
            {usersData?.pagination && (
              <div className="pb-52">
                <div className="flex">
                  {usersData?.pagination.prev_page && (
                    <button
                      className="text-[16px] text-[#808080] font-bold mx-10"
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                        setParams({ ...params, page: usersData?.pagination.page - 1 });
                      }}
                    >{` PREVIOUS `}</button>
                  )}
                  {usersData?.pagination.page - 1 > 0 && (
                    <button
                      className="text-[16px] text-dark font-bold mx-10"
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                        setParams({ ...params, page: usersData?.pagination.page - 1 });
                      }}
                    >{` ${usersData?.pagination.page - 1} `}</button>
                  )}
                  <span className="text-[16px] text-dark font-bold">
                    {usersData?.pagination.page}
                  </span>
                  {usersData?.pagination.page + 1 <= usersData?.pagination.total_pages && (
                    <button
                      className="text-[16px] text-dark font-bold mx-10"
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                        setParams({ ...params, page: usersData?.pagination.page + 1 });
                      }}
                    >{` ${usersData?.pagination.page + 1} `}</button>
                  )}
                  {usersData?.pagination.next_page && (
                    <button
                      className="text-[16px] text-[#808080] font-bold mx-10"
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                        setParams({ ...params, page: usersData?.pagination.page + 1 });
                      }}
                    >{` NEXT `}</button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {userToEdit && (
        <EditOrganizationUserModal
          userToEdit={userToEdit}
          isEditModalOpen={isEditModalOpen}
          onClose={() => {
            setIsEditModalOpen(false);
            setUserToEdit(null);
          }}
          onUpdate={() => {}}
        />
      )}
    </AdminLayout>
  );
};
